import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Button,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { createCustomer } from "../../actions/wallet";
const CreateCustomer = ({ onCustomerCreated }) => {
  const [modal_center, setModalCenter] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    source: "wallet",
    country: "ae",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleModal = () => {
    setModalCenter(!modal_center);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.firstName) formErrors.firstName = "First Name is required";
    if (!formData.lastName) formErrors.lastName = "Last Name is required";
    if (!formData.email) formErrors.email = "Email is required";
    if (!formData.mobileNumber) formErrors.mobileNumber = "Mobile Number is required";
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      setIsSubmitting(true);
      const response = await createCustomer(formData);
      setIsSubmitting(false);
      if (response.success) {
        setFormData({ firstName: "", lastName: "", email: "", mobileNumber: "", source: "wallet", country: "ae" });
        toggleModal();
        onCustomerCreated();
      } else {
        // Handle error (e.g., show an error message)
      }
    } else {
      setErrors(formErrors);
    }
  };
  return (
    <>
      <div className="d-flex flex-wrap align-items-start gap-2">
        <button className="btn btn-success add-btn" onClick={toggleModal}>
          <i className="ri-user-fill me-1 align-bottom"></i> Create new customer
        </button>
      </div>

      <Modal isOpen={modal_center} toggle={toggleModal} centered>
        <ModalHeader className="modal-title pb-3 bg-light" toggle={toggleModal}>
          Create New Customer
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row className="g-3">
              <Col lg={6}>
                <div>
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <Input
                    type="text"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    invalid={!!errors.firstName}
                    placeholder="Enter First Name"
                  />
                  <FormFeedback>{errors.firstName}</FormFeedback>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <Input
                    type="text"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    invalid={!!errors.lastName}
                    placeholder="Enter Last Name"
                  />
                  <FormFeedback>{errors.lastName}</FormFeedback>
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <Input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    invalid={!!errors.email}
                    placeholder="Enter Email Id"
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <label htmlFor="mobileNumber" className="form-label">
                    Mobile Number
                  </label>
                  <Input
                    type="text"
                    id="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    invalid={!!errors.mobileNumber}
                    placeholder="Enter Mobile Number"
                  />
                  <FormFeedback>{errors.mobileNumber}</FormFeedback>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <Button
                    color="light"
                    onClick={toggleModal}
                    className="w-25"
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button color="primary" className="w-75" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? <Spinner size="sm" /> : "Submit"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateCustomer;
