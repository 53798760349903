import { toast } from 'react-toastify';
/**
 *
 * @param {*} error
 */
export const errorHandling = (error) => {
  // console.error(error);
};

/**
 *
 * @param {*} error
 */
export const alertError = (error) => {
  let errorMsg = error.message;
  switch (errorMsg) {
    case 'jwt expired':
      errorMsg = 'Your session has expired...';
      break;
    case 'invalid token':
      errorMsg = "You don't have the right access...";
      break;
    default:
      errorMsg;
  }

  toast(errorMsg || 'Something went wrong...', {
    position: 'top-right',
    hideProgressBar: true,
    closeOnClick: false,
    className: 'bg-danger text-white mt-5',
    autoClose: 3500,
  });
};

export const alertSuccess = (msg) => {
  toast(msg, {
    position: 'top-right',
    hideProgressBar: true,
    closeOnClick: false,
    className: 'bg-success text-white mt-5',
    autoClose: 3500,
  });
};
