import React, { useEffect } from 'react';
import {
  Col,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Form,
} from 'reactstrap';
import { COUNTRIES } from '../../../constants/index';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { createOrUpdateReward } from '../../../actions/rewards';
import { useNavigate } from 'react-router-dom';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';

const RewardPtcRateForm = ({ reward, submitHandler = () => {} }) => {
  const navigate = useNavigate();

  const ptcValidationSchema = Yup.object({
    ptcRates: Yup.array().of(
      Yup.object().shape({
        countryCode: Yup.string()
          .required('Country code is required')
          .length(2, 'Country code must be exactly 2 characters')
          .typeError('Country code must be a string'),
        points: Yup.number()
          .required('Points are required')
          .min(1, 'Points must be greater than 0')
          .typeError('Points must be a number'),
        currencyValue: Yup.number()
          .required('Currency value is required')
          .min(1, 'Currency value must be greater than 0')
          .typeError('Currency value must be a number'),
      })
    ),
  });

  const initialValues = {
    ptcRates: COUNTRIES?.map((country) => {
      return {
        countryCode: country?.value,
        points: '',
        currencyValue: '',
      };
    }),
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ptcValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const inputData = {
          _id: reward?._id,
          ...values,
        };

        const { success, message, data } = await createOrUpdateReward(
          inputData
        );

        if (success) {
          alertSuccess(message);
          navigate(`/reward-types/detail/${data?._id}/redemption`);
          submitHandler();
        } else {
          alertError({ message: message });
        }

        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
  });

  useEffect(() => {
    if (reward?.ptcRates?.length) {
      formik?.setValues({
        ptcRates: reward?.ptcRates,
      });
    } else {
      formik?.setValues({ ...initialValues });
    }
  }, [reward]);

  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    values,
    isSubmitting,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        {/* <h4 class="fs-14 fw-medium mb-3 card-title">Points to Currency rates</h4> */}
        <Row>
          <Col md={10} xl={7} xxl={6}>
            <table className="align-top mb-0 w-100">
              <tbody>
                {values?.ptcRates?.map((item, index) => {
                  let country = COUNTRIES?.find((itm) => {
                    return item?.countryCode === itm?.value;
                  });
                  return (
                    <tr>
                      <td>
                        <p className="mb-3">
                          <strong>{country?.label}</strong>
                        </p>
                      </td>
                      <td className="p-0">
                        <FormGroup className="p-3 pe-2 bg-light border border-end-0 rounded-start border-soft-dark">
                          <InputGroup>
                            <Input
                              type="number"
                              name={`ptcRates.${index}.points`}
                              id={`ptcRates.${index}.points`}
                              placeholder="Enter point"
                              min={0}
                              value={values?.ptcRates?.[index]?.points}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={
                                touched?.ptcRates?.[index]?.points &&
                                !!errors?.ptcRates?.[index]?.points
                              }
                            />
                            <InputGroupText className="rounded-end">
                              Points
                            </InputGroupText>
                            <FormFeedback>
                              {errors?.ptcRates?.[index]?.points}
                            </FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      </td>
                      <td className="p-0 text-center">
                        <div
                          className="p-2 bg-light border-bottom border-top  border-soft-dark mb-3 d-flex align-items-center justify-content-center"
                          style={{ height: '71.5px' }}
                        >
                          <p className="mb-0 fs-18">=</p>
                        </div>
                      </td>
                      <td className="p-0">
                        <FormGroup className="p-3 ps-2 bg-light border border-start-0 rounded-end border-soft-dark">
                          <InputGroup>
                            <Input
                              type="number"
                              name={`ptcRates.${index}.currencyValue`}
                              id={`ptcRates.${index}.currencyValue`}
                              placeholder="Enter Amount"
                              min={0}
                              value={values?.ptcRates?.[index]?.currencyValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={
                                touched?.ptcRates?.[index]?.currencyValue &&
                                !!errors?.ptcRates?.[index]?.currencyValue
                              }
                            />
                            <InputGroupText className="rounded-end">
                              {country?.currency}
                            </InputGroupText>
                            <FormFeedback>
                              {errors?.ptcRates?.[index]?.currencyValue}
                            </FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="border rounded-1 border-soft-dark bg-light mw-100 p-3 mb-3">
              <p className="m-0 text-center">
                Please select countries in <strong>Basic Details</strong> before
                adding Redeemed Thresholds.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
            <div className="d-flex gap-2 justify-content-end">
              <Button outline color="danger" size="md">
                Cancel
              </Button>

              <Button
                type="submit"
                color="primary"
                size="md"
                className="bg-gradient px-5"
              >
                Save & Next
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  );
};

export default RewardPtcRateForm;
