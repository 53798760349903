import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { Modal, ModalBody, Button } from "reactstrap";

// ==============================|| CUSTOMER - DELETE ||============================== //

export default function AlertDelete({
  title,
  open,
  handleClose,
  loader,
  deleteHandler = () => {},
}) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <Button
        color="danger"
        size="sm"
        onClick={() => {
          setIsOpenModal(true);
        }}
      >
        <i className="bx bx-trash fs-18"></i>
      </Button>
      <Modal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
        centered
        size="xs"
        aria-labelledby="column-delete-title"
        aria-describedby="column-delete-description"
      >
        <ModalBody>
          <div className="d-flex flex-column align-items-center mt-2 my-1">
            <div
              className="d-flex justify-content-center align-items-center bg-danger text-white rounded-circle"
              style={{ width: "72px", height: "72px", fontSize: "1.75rem" }}
            >
              <i className="bx bx-trash ms-2 me-2 fs-28"></i>
            </div>
            <div className="text-center mt-3">
              <h4>Are you sure you want to delete?</h4>
              <p>
                By deleting, All data related to this fields will be deleted.
              </p>
            </div>
            <div className="d-flex justify-content-between w-100 mt-2">
              <Button
                color="secondary"
                outline
                onClick={() => setIsOpenModal(false)}
                block
                className="me-4"
              >
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  deleteHandler();
                  setIsOpenModal(false);
                }}
                autoFocus
                block
              >
                Delete
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

AlertDelete.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  deleteHandler: PropTypes.func,
};
