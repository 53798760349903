import React, { useState, useEffect } from 'react';
import {
  CardBody,
  Col,
  Row,
  Button,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Form,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { updatePromotionBudget } from '../../../actions/promotion';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';
import LimitFields from './LimitFields';
const PromotionBudgeting = ({
  onCancel,
  promotionId,
  promotionDetail,
  updateHandler = () => {},
}) => {
  const validationSchema = Yup.object().shape({
    budgeting: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required(),
        name: Yup.string().optional(),
        globalLimits: Yup.array().of(
          Yup.object().shape({
            timePeriod: Yup.string().required(),
            redemptions: Yup.object().shape({
              maxValue: Yup.number().required(),
              isActive: Yup.boolean().required(),
            }),
            amount: Yup.object().shape({
              maxValue: Yup.number().required(),
              isActive: Yup.boolean().required(),
            }),
          })
        ),
        customerLimits: Yup.array().of(
          Yup.object().shape({
            timePeriod: Yup.string().required(),
            redemptions: Yup.object().shape({
              maxValue: Yup.number().required(),
              isActive: Yup.boolean().required(),
            }),
            amount: Yup.object().shape({
              maxValue: Yup.number().required(),
              isActive: Yup.boolean().required(),
            }),
          })
        ),
      })
    ),
  });

  const initialValues = {
    budgeting: [
      {
        type: 'campaign',
        name: 'Campaign',
        isGlobalLimitsActive: false,
        isCustomerLimitsActive: false,
        globalLimits: [],
        customerLimits: [],
      },
      // {
      //   type: 'reward',
      //   name: 'Ahlan Points',
      //   isActive: false,
      //   globalLimits: [],
      //   customerLimits: [],
      // },
    ],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const inputData = {
          promotionId: promotionId,
          budgeting: values?.budgeting,
        };

        const { success, message } = await updatePromotionBudget(inputData);
        if (success) {
          updateHandler();
          alertSuccess(message);
        } else {
          alertError({ message: 'Failed!, Please try again.' });
        }

        setSubmitting(false);
        console.log(res, 'tres-final');
      } catch (error) {
        console.error(error);
      }
    },
  });

  useEffect(() => {
    if (promotionDetail?.budgeting?.length > 0) {
      formik.setFieldValue(`budgeting`, promotionDetail?.budgeting);
    } else {
      formik.setFieldValue(`budgeting`, initialValues?.budgeting);
    }
  }, [promotionDetail]);

  const addNewCustomerLimit = (budget, budgetIndex) => {
    updateBudget(
      {
        ...budget,
        customerLimits: [
          {
            timePeriod: 'daily',
            redemptions: {
              maxValue: '',
              isActive: true,
            },
            amount: {
              maxValue: '',
              isActive: true,
            },
          },
          ...budget?.customerLimits,
        ],
      },
      budgetIndex
    );
  };

  const addNewGlobalLimit = (budget, budgetIndex) => {
    updateBudget(
      {
        ...budget,
        globalLimits: [
          {
            timePeriod: 'weekly',
            redemptions: {
              maxValue: '',
              isActive: true,
            },
            amount: {
              maxValue: '',
              isActive: true,
            },
          },
          ...budget?.globalLimits,
        ],
      },
      budgetIndex
    );
  };

  const removeGlobalLimit = (budgetingIndex, limitIndex) => {
    formik.setFieldValue(`budgeting[${budgetingIndex}]`, {
      ...values?.budgeting?.[budgetingIndex],
      globalLimits: values?.budgeting?.[budgetingIndex]?.globalLimits?.filter(
        (_, index) => index !== limitIndex
      ),
    });
  };

  const removeCustomerLimit = (budgetingIndex, limitIndex) => {
    formik.setFieldValue(`budgeting[${budgetingIndex}]`, {
      ...values?.budgeting?.[budgetingIndex],
      customerLimits: values?.budgeting?.[
        budgetingIndex
      ]?.customerLimits?.filter((_, index) => index !== limitIndex),
    });
  };

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    handleChange,
    handleBlur,
    values,
  } = formik;

  const updateBudget = (budget, budgetIndex) => {
    formik.setFieldValue(`budgeting[${budgetIndex}]`, budget);
  };

  return (
    <CardBody className="p-4">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          {values?.budgeting?.map((budget, budgetIndex) => {
            return (
              <div className="pb-3">
                <div className="d-flex flex-row justify-content-between ">
                  <FormGroup switch>
                    <Label check className="fs-16">
                      {`Apply ${budget?.name} limits`}
                    </Label>
                    <Input
                      type="switch"
                      role="switch"
                      checked={
                        values?.budgeting?.[budgetIndex]
                          ?.isGlobalLimitsActive || false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        formik.setFieldValue(
                          `budgeting[${budgetIndex}].isGlobalLimitsActive`,
                          isChecked
                        );
                      }}
                    />
                  </FormGroup>
                  {values?.budgeting?.[budgetIndex]?.isGlobalLimitsActive ? (
                    <a
                      size="sm"
                      style={{ width: 82 }}
                      className="cursor-pointer text-decoration-underline fs-11"
                      color="outline-primary"
                      onClick={(e) => {
                        e?.preventDefault();
                        addNewGlobalLimit(budget, budgetIndex);
                      }}
                    >
                      +Add Limit
                    </a>
                  ) : null}
                </div>
                {values?.budgeting?.[budgetIndex]?.isGlobalLimitsActive &&
                  budget?.globalLimits?.map((limit, limitIndex) => (
                    <LimitFields
                      key={limitIndex}
                      limitIndex={limitIndex}
                      budgetIndex={budgetIndex}
                      limit={limit}
                      isGlobal={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      removeGlobalLimit={removeGlobalLimit}
                      removeCustomerLimit={removeCustomerLimit}
                    />
                  ))}
                <div className="d-flex flex-row justify-content-between mt-4">
                  <FormGroup switch>
                    <Label check className="fs-16">
                      {`Apply ${budget?.name} limits per customer`}
                    </Label>
                    <Input
                      type="switch"
                      role="switch"
                      checked={
                        values?.budgeting?.[budgetIndex]
                          ?.isCustomerLimitsActive || false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        formik.setFieldValue(
                          `budgeting[${budgetIndex}].isCustomerLimitsActive`,
                          isChecked
                        );
                      }}
                    />
                  </FormGroup>
                  {values?.budgeting?.[budgetIndex]?.isCustomerLimitsActive ? (
                    <a
                      size="sm"
                      style={{ width: 82 }}
                      className="cursor-pointer text-decoration-underline fs-11"
                      color="outline-primary"
                      onClick={() => {
                        addNewCustomerLimit(budget, budgetIndex);
                      }}
                    >
                      +Add Limit
                    </a>
                  ) : null}
                </div>
                {values?.budgeting?.[budgetIndex]?.isCustomerLimitsActive &&
                  budget?.customerLimits?.map((limit, limitIndex) => (
                    <LimitFields
                      limitIndex={limitIndex}
                      budgetIndex={budgetIndex}
                      limit={limit}
                      isGlobal={false}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      removeGlobalLimit={removeGlobalLimit}
                      removeCustomerLimit={removeCustomerLimit}
                    />
                  ))}
              </div>
            );
          })}
          <div className="d-flex gap-2 justify-content-end">
            <Button outline color="danger" size="md" onClick={onCancel}>
              Cancel
            </Button>

            <Button
              loading={false}
              type="submit"
              color="primary"
              size="md"
              className="bg-gradient px-5"
            >
              {promotionDetail ? 'Save Draft' : 'Save & Next'}
            </Button>
          </div>
        </Form>
      </FormikProvider>
      {isSubmitting ? <InnerLoader /> : null}
    </CardBody>
  );
};

export default PromotionBudgeting;
