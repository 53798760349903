import React, { useEffect } from 'react';
import {
  Button,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Form,
} from 'reactstrap';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';
import { createOrUpdateReward } from '../../../actions/rewards';
import { FilePond, registerPlugin } from 'react-filepond';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import './style.scss';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { uploadFile } from '../../../actions/upload';
import { useNavigate } from 'react-router-dom';

const countryOptions = [
  { value: 'ae', label: 'UAE' },
  { value: 'om', label: 'Oman' },
  { value: 'bh', label: 'Bahrain' },
  { value: 'qa', label: 'Qatar' },
];

const RewardTypeBasicSettingsForm = ({ submitHandler, reward = null }) => {
  const navigate = useNavigate();

  const initialValues = {
    name: '',
    description: '',
    logo: '',
    icon: '',
    isActive: false,
    brands: [
      {
        brandId: 'danubehome_online',
        name: 'Danube Home Online',
        enabledCountries: [],
        isApplyForAllCountries: true,
      },
      {
        brandId: 'milano',
        name: 'Milano',
        enabledCountries: [],
        isApplyForAllCountries: true,
      },
      {
        brandId: 'properties',
        name: 'Danube Properties',
        enabledCountries: [],
        isApplyForAllCountries: true,
      },
    ],
  };

  const validationSchema = Yup?.object({
    name: Yup?.string()?.required('Name is required'),
    description: Yup?.string()
      ?.required('Description is required')
      ?.max(255, 'Description must be less than 255 characters'),
    logo: Yup?.mixed()?.required('Logo is required'),
    icon: Yup?.mixed()?.required('Icon is required'),
    brands: Yup?.array()
      ?.of(
        Yup?.object({
          brandId: Yup?.string()?.required('Brand ID is required'),
          enabledCountries: Yup?.array()
            ?.when('$isApplyForAllCountries', {
              is: false,
              then: Yup?.array()?.min(1, 'Select at least one country'),
              otherwise: Yup?.array(),
            })
            ?.required('Enabled Countries are required'),
          isApplyForAllCountries: Yup?.boolean()?.required(
            'This field is required'
          ),
        })
      )
      ?.required('Must have brands')
      ?.min(1, 'At least one brand is required'),
  });

  const uploadFileHandler = async (file) => {
    const data = new FormData();
    data.append('file', file);
    const res = await uploadFile(data);
    return res?.success ? res?.data : null;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let inputData = { ...values };

        if (values?.icon && typeof values?.icon === 'object') {
          const iconUrl = await uploadFileHandler(values?.icon);
          if (iconUrl) {
            inputData.icon = await iconUrl;
          }
        }

        if (values?.logo && typeof values?.logo === 'object') {
          const logoUrl = await uploadFileHandler(values?.logo);
          if (logoUrl) {
            inputData.logo = await logoUrl;
          }
        }

        const { success, message, data } = await createOrUpdateReward(
          inputData
        );

        if (success) {
          alertSuccess(message);
          navigate(`/reward-types/detail/${data?._id}/tiers`);
          submitHandler();
        } else {
          alertError({ message: message });
        }

        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
  });

  useEffect(() => {
    if (reward) {
      formik?.setValues({
        name: reward?.name,
        brands: reward?.brands,
        description: reward?.description,
        logo: reward?.logo,
        icon: reward?.icon,
        id: reward?.id,
        isActive: reward?.isActive,
      });
    } else {
      formik?.setValues({ ...initialValues });
    }
  }, [reward]);

  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    values,
    isSubmitting,
    setFieldValue,
  } = formik;

  const handleSelectChange = (event, brand, setFieldValue, values) => {
    const brandIndex = values?.brands?.findIndex(
      (item) => item?.brandId === brand?.brandId
    );

    const options = event?.target?.options || [];
    const selectedOptions = [
      ...(values?.brands?.[brandIndex]?.enabledCountries || []),
    ];

    for (let i = 0; i < options.length; i++) {
      if (options?.[i]?.selected) {
        const value = options?.[i]?.value;
        const valueIndex = selectedOptions?.indexOf(value);

        if (valueIndex === -1) {
          selectedOptions?.push(value);
        } else {
          selectedOptions?.splice(valueIndex, 1);
        }
      }
    }

    setFieldValue(`brands.${brandIndex}.enabledCountries`, selectedOptions);
  };

  return (
    <div className="reward-type-form">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-row mb-4 justify-content-between">
            <FormGroup className="full-width">
              <Label for="teamName">Reward Name</Label>
              <Input
                id="name"
                name="name"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.name}
                invalid={!!errors?.name && touched?.name}
              />
              <FormFeedback>{touched?.name && errors?.name}</FormFeedback>
            </FormGroup>
          </div>
          <div className="d-flex flex-row mb-4 justify-content-between gap-4">
            <div className="half-width">
              <FormGroup>
                <Label for="teamName">Logo Image</Label>
                {typeof values?.logo === 'object' || !values?.logo ? (
                  <FilePond
                    files={values?.logo ? [values?.logo] : []}
                    onupdatefiles={(files) => {
                      setFieldValue('logo', files?.[0]?.file);
                    }}
                    allowMultiple={false}
                    maxFiles={1}
                    name="logo"
                    className="filepond filepond-input-multiple"
                  />
                ) : (
                  <div className="border round p-4 position-relative">
                    <img
                      src={values?.logo}
                      alt="Uploaded Logo"
                      style={{ width: 100, maxHeight: 100 }}
                    />
                    <div
                      className="p-2 fs-18 trash-icon-container"
                      onClick={() => {
                        setFieldValue('logo', null);
                      }}
                    >
                      <i className="bx bx-trash"></i>
                    </div>
                  </div>
                )}
              </FormGroup>
            </div>
            <div className="half-width">
              <FormGroup>
                <Label for="teamName">Icon Image</Label>
                {typeof values?.icon === 'object' || !values?.icon ? (
                  <FilePond
                    files={values?.icon ? [values?.icon] : []}
                    onupdatefiles={(files) => {
                      setFieldValue('icon', files?.[0]?.file);
                    }}
                    allowMultiple={false}
                    maxFiles={1}
                    name="icon"
                    className="filepond filepond-input-multiple"
                  />
                ) : (
                  <div className="border round p-4 position-relative">
                    <img
                      src={values?.icon}
                      alt="Uploaded Icon"
                      style={{ width: 100, maxHeight: 100 }}
                    />
                    <div
                      className="p-2 fs-18 trash-icon-container"
                      onClick={() => {
                        setFieldValue('icon', null);
                      }}
                    >
                      <i className="bx bx-trash"></i>
                    </div>
                  </div>
                )}
              </FormGroup>
            </div>
          </div>
          {values?.brands?.map((brand, index) => {
            return (
              <div className="d-flex flex-column mb-4">
                <div className="full-width">
                  <Label>
                    <strong>{brand?.name || brand?.brandId}</strong>
                  </Label>
                </div>

                <div className="full-width">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name={`brands.${index}.isApplyForAllCountries`}
                        id={`brands.${index}.isApplyForAllCountries`}
                        onChange={(e) => {
                          setFieldValue(
                            `brands.${index}.isApplyForAllCountries`,
                            e.target.checked
                          );
                          if (e.target.checked) {
                            setFieldValue(
                              `brands.${index}.enabledCountries`,
                              []
                            );
                          }
                        }}
                        checked={brand?.isApplyForAllCountries}
                      />
                      Apply all countries
                    </Label>
                  </FormGroup>
                  {!brand?.isApplyForAllCountries ? (
                    <FormGroup className="mt-3">
                      <Label for={`brands.${index}.enabledCountries`}>
                        Enabled Countries(multiple select option)
                      </Label>
                      <Input
                        type="select"
                        name={`brands.${index}.enabledCountries`}
                        id={`brands.${index}.enabledCountries`}
                        multiple
                        value={brand.enabledCountries}
                        onChange={(event) =>
                          handleSelectChange(
                            event,
                            brand,
                            setFieldValue,
                            values
                          )
                        }
                        onBlur={handleBlur}
                        invalid={
                          touched.brands?.[index]?.enabledCountries &&
                          !!errors.brands?.[index]?.enabledCountries
                        }
                      >
                        {countryOptions.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                            className="pt-1 pb-1"
                          >
                            {option.label}
                          </option>
                        ))}
                      </Input>
                      <FormFeedback>
                        {errors.brands?.[index]?.enabledCountries}
                      </FormFeedback>
                    </FormGroup>
                  ) : null}
                </div>
              </div>
            );
          })}

          <div className="d-flex flex-row mb-4 gap-4">
            <FormGroup className="full-width">
              <Label for="description">Description</Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.description && !!errors.description}
                style={{ width: '100%' }}
              />
              <FormFeedback>{errors.description}</FormFeedback>
            </FormGroup>
          </div>

          <div className="d-flex flex-row gap-4">
            <Label>Status :</Label>
            <FormGroup className="ms-3">
              <div class="form-check form-switch">
                <Label>{values?.isActive ? 'Enabled' : 'Disabled'}</Label>
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="enable-reward"
                  name="enable-reward"
                  checked={values?.isActive}
                  onChange={() => {
                    setFieldValue('isActive', !values?.isActive);
                  }}
                />
              </div>
            </FormGroup>
          </div>
          <hr />

          <div className="d-flex gap-2 justify-content-end">
            <Button
              outline
              color="danger"
              size="md"
              onClick={(e) => {
                e?.stopPropagation();
                setValues({ ...initialValues });
              }}
            >
              Cancel
            </Button>

            <Button
              loading={false}
              type="submit"
              color="primary"
              size="md"
              className="bg-gradient px-5"
              disabled={isSubmitting}
            >
              Save & Next
            </Button>
          </div>

          {/* <div className="d-flex flex-row  mt-3 float-end">
            <Button
              color="light"
              size="sm"
              onClick={(e) => {
                e?.stopPropagation();
                setValues({ ...initialValues });
                closeHandler();
              }}
            >
              Close
            </Button>
            <Button color="primary" size="sm" className="ms-2" type="submit">
              {reward ? 'Update' : 'Create'}
            </Button>
          </div> */}
        </Form>
      </FormikProvider>

      {isSubmitting ? <InnerLoader /> : null}
    </div>
  );
};

export default RewardTypeBasicSettingsForm;
