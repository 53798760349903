import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { getPromotions } from '../../actions/promotion';
import { formatDate } from '../../helpers/formatDate';
import { formatTime } from '../../helpers/formatTime';
import InnerLoader from '../../components/Common/InnerLoader';

import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Progress,
} from 'reactstrap';
import PromotionsList from './PromotionsList';
import PromotionDetail from './PromotionDetail';
import { If } from 'react-if';
import { isValidUUID } from '../../helpers/isValidUUID';
const PromotionTable = ({
  selectedTag = null,
  selectedStatus = null,
  selectedCountry = null,
  searchKey = '',
  startDate = null,
  endDate = null,
  selectedSortBy = null,
}) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [showPromotionDetail, setPromotionDetailOpen] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [storeDeleteId, setStoreDeleteId] = useState('');
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const getPromotionsFunc = async () => {
    setLoading(true);

    const variables = {
      input: {
        pageOffset: pageOffset,
        pageSize: pageSize,
        filters: {
          status: selectedStatus?.value ? [selectedStatus?.value] : [],
          country: selectedCountry?.value ? [selectedCountry?.value] : [],
          tags: selectedTag?.value ? [selectedTag?.value] : [],
          promotionName: !isValidUUID(searchKey) ? searchKey : null,
          promotionIds: isValidUUID(searchKey) ? [searchKey] : [],
          startDate: startDate,
          endDate: endDate,
        },
        sortBy: selectedSortBy?.value,
      },
    };

    const { data } = await getPromotions(variables);

    setTimeout(() => {
      setData(data?.promotions || []);
      setTotalPages(data?.totalPages);
      setLoading(false);
    }, 400);
  };

  useEffect(() => {
    getPromotionsFunc();
  }, [pageOffset]);

  useEffect(() => {
    if (pageOffset === 0) {
      getPromotionsFunc();
    } else {
      setPageOffset(0);
    }
  }, [
    selectedCountry,
    selectedStatus,
    selectedTag,
    searchKey,
    endDate,
    selectedSortBy,
  ]);

  const pages = [...Array(totalPages)?.keys()];

  document.title = 'Promotion List';

  //

  return (
    <>
      <Modal
        size="xxl"
        className="modal-right"
        isOpen={showPromotionDetail}
        toggle={() => {
          window.history?.back();
          setPromotionDetailOpen(!showPromotionDetail);
        }}
      >
        {/* <ModalHeader toggle={() => setOpen(!open)}>Delete Customer</ModalHeader> */}
        <ModalBody>
          <If condition={showPromotionDetail}>
            <div style={{ overflowY: 'scroll' }}>
              <PromotionDetail
                promotionIdFromPopup={showPromotionDetail}
                isPopup
              />
            </div>
          </If>
        </ModalBody>
      </Modal>
      <div className="table-responsive table-card p-3">
        {(data || []).map((item, key) => (
          <div className="border rounded p-3 mb-2" key={`promo-${key}`}>
            <Row>
              <Col md={9} className="border-end d-flex gap-2">
                <div className="m-0">
                  <span
                    className="d-flex bg-soft-secondary p-2 rounded-2 opacity-25"
                    style={{ fontSize: 42 }}
                  >
                    <i className="bx bxs-discount text-secondary"></i>
                  </span>
                </div>
                <div className="m-0 w-100">
                  <div className="d-flex flex-wrap justify-content-between mb-1 gap-2">
                    <h5 className="m-0 fs-14 text-body d-flex flex-wrap align-items-center gap-2">
                      <a href={`/promotions/detail/${item?._id}/basic`}>
                        {item?.basicSettings?.promotionName}
                      </a>
                    </h5>
                    <span>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="button"
                          className="btn btn-secondary px-3 py-1 fs-11 d-flex gap-1"
                          id="dropdownMenuButton"
                        >
                          <i className="ri-line-chart-line"></i> Coupon Usage
                        </DropdownToggle>
                        <DropdownMenu
                          className="p-3"
                          style={{ width: 280 }}
                          end={true}
                        >
                          <div className="d-flex justify-content-between py-1">
                            <p className="fs-11 mb-1">
                              Total active coupon
                              <strong className="d-block fs-20 fw-bold">
                                1800
                              </strong>
                            </p>
                            <p className="fs-11 mb-1">
                              Remaining
                              <strong className="d-block fs-20 fw-bold text-success">
                                200
                              </strong>
                            </p>
                          </div>
                          <Progress
                            color="primary"
                            value={70}
                            className="progress-xl"
                            animated
                          />
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </span>
                    {/* <p className="m-0">
                    <strong>{item.couponUsed} Used</strong>
                  </p> */}
                  </div>
                  <div className="d-flex gap-1 justify-content-between">
                    <div className="m-0">
                      {item?.basicSettings?.country?.length ? (
                        <p className="d-flex flex-wrap align-items-center mb-1 gap-1 fs-11 text-muted mb-1 fw-normal">
                          Country:
                          <span className="d-flex flex-wrap align-items-center gap-1">
                            {item?.basicSettings?.country?.map(
                              (country, index) => (
                                <span
                                  key={`c-${index}`}
                                  className={`badge rounded-pill text-success bg-soft-success`}
                                >
                                  {country}
                                </span>
                              )
                            )}
                          </span>
                        </p>
                      ) : null}

                      <div className="d-flex flex-wrap gap-1 mb-1">
                        <p className="m-0 d-flex flex-wrap align-items-center">
                          <span className="fs-11 text-muted">
                            Trigger Type:{' '}
                            <strong>
                              {item.promotionType === 'campaign'
                                ? 'Auto Applied'
                                : 'By Applying Coupon Code'}
                            </strong>
                          </span>
                        </p>
                        <span className="fs-11 text-muted">|</span>
                        <p className="m-0 d-flex flex-wrap align-items-center">
                          <span className="fs-11 text-muted">
                            Effects: <strong>{item.ruke}</strong>
                          </span>
                        </p>
                      </div>
                      <div className="d-flex flex-wrap gap-1  mb-1">
                        {/* <p className="m-0 d-flex flex-wrap align-items-center gap-1 fs-11 text-muted fw-normal">
                          Coupon: <strong>{item.coupon}</strong>
                        </p> */}
                        {item?.couponApply?.length ? (
                          <p className="m-0 d-flex flex-wrap align-items-center gap-1 fs-11 text-muted fw-normal">
                            <span className="d-flex flex-wrap align-items-center gap-1">
                              {item?.couponApply?.map((sItem, index) => (
                                <span
                                  key={`cp-${index}`}
                                  className={`badge rounded-pill bg-soft-${sItem?.className} text-${sItem?.className}`}
                                >
                                  {sItem?.label}
                                </span>
                              ))}
                            </span>
                          </p>
                        ) : null}
                      </div>
                      {item?.basicSettings?.tags?.length ? (
                        <p className="m-0 d-flex flex-wrap align-items-center gap-1 fs-11 text-muted mb-1 fw-normal">
                          Tag:
                          <span className="d-flex flex-wrap align-items-center gap-1">
                            {item?.basicSettings?.tags?.map((tag, tagIndex) => {
                              return (
                                <span
                                  key={`tag-${tagIndex}`}
                                  className={`badge rounded-pill bg-soft-primary text-primary`}
                                >
                                  {tag}
                                </span>
                              );
                            })}
                          </span>
                        </p>
                      ) : null}
                      <div className="d-flex flex-wrap align-items-center gap-1 mb-1">
                        <p className="m-0 fs-11 text-muted fw-normal d-flex flex-wrap gap-1">
                          Start Date :
                          <strong>
                            {item?.schedule?.startDate
                              ? formatDate(item?.schedule?.startDate)
                              : '-'}
                          </strong>
                        </p>
                        <span className="fs-12 text-muted">|</span>
                        <p className="m-0 fs-11 text-muted fw-normal d-flex flex-wrap gap-1">
                          End Date :
                          <strong>
                            {item?.schedule?.endDate
                              ? formatDate(item?.schedule?.endDate)
                              : '-'}
                          </strong>
                        </p>
                      </div>
                    </div>
                    <Link
                      onClick={() => {
                        // window.location.href = `#detail/${item._id}`;
                        setPromotionDetailOpen(item._id);
                      }}
                      to={`#detail/${item._id}/basic`}
                      className="fs-10 d-flex align-items-center mt-auto"
                    >
                      View Details <i className="bx bx-chevron-right fs-22"></i>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex flex-wrap flex-column justify-content-between gap-1 h-100 position-relative">
                  <div className="position-absolute end-0 fs-18 text-secondary">
                    <i className="bx bx-bookmark"></i>
                    <i className="bx bxs-bookmark"></i>
                  </div>
                  <p className="fs-11 text-muted mb-0 fw-normal d-flex flex-wrap flex-column align-items-start gap-1 h-100">
                    Revenue
                    {item?.revenue ? (
                      <strong className="fs-22 text-body">
                        {item.revenue}
                      </strong>
                    ) : (
                      <strong className="fs-22 text-body">5000</strong>
                    )}
                    <span
                      className={`mt-auto fs-10 mb-1 fw-medium fs-13 px-2 badge badge-soft-success`}
                    >
                      {item.couponStatus || 'Active'}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>

      <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
        <div className="col-sm"></div>
        {totalPages > 1 ? (
          <div className="col-sm-auto mt-3 mt-sm-0">
            <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
              <li className={`page-item  ${pageOffset <= 1 ? 'disabled' : ''}`}>
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    setPageOffset(pageOffset - 1);
                  }}
                >
                  ←
                </Link>
              </li>

              {pages?.map((page, index) => {
                return (
                  <li
                    className={`page-item ${
                      pageOffset === index ? 'active' : ''
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => setPageOffset(index)}
                    >
                      {index + 1}
                    </Link>
                  </li>
                );
              })}

              <li className="page-item">
                <Link
                  to="#"
                  className={`page-link ${
                    pageOffset >= totalPages ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    setPageOffset(pageOffset + 1);
                  }}
                >
                  →
                </Link>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
      {loading ? <InnerLoader /> : null}
    </>
  );
};

export default PromotionTable;
