import React from 'react';
import {
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  Button,
} from 'reactstrap';

const timePeriodOptions = [
  {
    label: 'this week',
    value: 'weekly',
  },
  {
    label: 'this month',
    value: 'monthly',
  },
  {
    label: 'this quarter',
    value: 'quarterly',
  },
  {
    label: 'this year',
    value: 'yearly',
  },
  {
    label: 'promotion duration',
    value: 'promotionDuration',
  },
];

const LimitFields = ({
  limit,
  limitIndex,
  budgetIndex,
  isGlobal,
  handleBlur = () => {},
  handleChange = () => {},
  setFieldValue = () => {},
  values,
  errors,
  touched,
  removeGlobalLimit = () => {},
  removeCustomerLimit = () => {},
}) => {
  const limitField = isGlobal ? 'globalLimits' : 'customerLimits';

  return (
    <div className="border mt-2 px-3 position-relative">
      <div className="d-flex flex-row gap-2 mt-3">
        <div className="mt-2">
          <FormGroup check>
            <Input
              type="checkbox"
              checked={
                values?.budgeting?.[budgetIndex]?.[limitField]?.[limitIndex]
                  ?.redemptions?.isActive || false
              }
              onChange={(e) => {
                const isChecked = e.target.checked;
                setFieldValue(
                  `budgeting[${budgetIndex}].[${limitField}][${limitIndex}].redemptions.isActive`,
                  isChecked
                );
              }}
            />
          </FormGroup>
        </div>
        <div className="mt-2">Maximum </div>
        <div>
          <FormGroup>
            <Input
              type="number"
              id={`budgeting[${budgetIndex}].[${limitField}][${limitIndex}].redemptions.maxValue`}
              name={`budgeting[${budgetIndex}].[${limitField}][${limitIndex}].redemptions.maxValue`}
              value={limit?.redemptions?.maxValue || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={
                !!errors?.budgeting?.[budgetIndex]?.[limitField]?.[limitIndex]
                  ?.redemptions?.maxValue &&
                touched?.budgeting?.[budgetIndex]?.[limitField]?.[limitIndex]
                  ?.redemptions?.maxValue
              }
            />
          </FormGroup>
        </div>
        <div className="mt-2"> redemptions within</div>
        <div>
          <FormGroup className="mb-0 pb-0">
            <Input
              type="select"
              id={`budgeting[${budgetIndex}].[${limitField}][${limitIndex}].timePeriod`}
              name={`budgeting[${budgetIndex}].[${limitField}][${limitIndex}].timePeriod`}
              value={limit?.timePeriod}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={
                !!errors?.budgeting?.[budgetIndex]?.[limitField]?.[limitIndex]
                  ?.timePeriod &&
                touched?.budgeting?.[budgetIndex]?.[limitField]?.[limitIndex]
                  ?.timePeriod
              }
            >
              {timePeriodOptions?.map((option, index) => {
                return (
                  <option key={`time-op-${index}`} value={option?.value}>
                    {option?.label}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </div>
      </div>

      <div className="d-flex flex-row gap-2 mt-0">
        <div className="mt-2">
          <FormGroup check>
            <Input
              type="checkbox"
              checked={
                values?.budgeting?.[budgetIndex]?.[limitField]?.[limitIndex]
                  ?.amount?.isActive || false
              }
              onChange={(e) => {
                const isChecked = e.target.checked;
                setFieldValue(
                  `budgeting[${budgetIndex}].[${limitField}][${limitIndex}].amount.isActive`,
                  isChecked
                );
              }}
            />
          </FormGroup>
        </div>
        <div className="mt-2">Maximum discount of </div>
        <div>
          <InputGroup>
            <Input
              id={`budgeting[${budgetIndex}].[${limitField}][${limitIndex}].amount.maxValue`}
              name={`budgeting[${budgetIndex}].[${limitField}][${limitIndex}].amount.maxValue`}
              type="number"
              value={
                values?.budgeting?.[budgetIndex]?.[limitField]?.[limitIndex]
                  ?.amount?.maxValue
              }
              invalid={
                !!errors?.budgeting?.[budgetIndex]?.[limitField]?.[limitIndex]
                  ?.amount?.maxValue &&
                touched?.budgeting?.[budgetIndex]?.[limitField]?.[limitIndex]
                  ?.amount?.maxValue
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputGroupText>AED</InputGroupText>
          </InputGroup>
        </div>
        <div className="mt-2">within </div>
        <div>
          <FormGroup className="mb-0 pb-0">
            <Input
              type="select"
              id={`budgeting[${budgetIndex}].[${limitField}][${limitIndex}].timePeriod`}
              name={`budgeting[${budgetIndex}].[${limitField}][${limitIndex}].timePeriod`}
              value={limit?.timePeriod}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={
                !!errors?.budgeting?.[budgetIndex]?.[limitField]?.[limitIndex]
                  ?.timePeriod &&
                touched?.budgeting?.[budgetIndex]?.[limitField]?.[limitIndex]
                  ?.timePeriod
              }
            >
              {timePeriodOptions?.map((option, index) => {
                return (
                  <option key={`time-op2-${index}`} value={option?.value}>
                    {option?.label}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </div>
      </div>
      <Button
        type="button"
        size="lg"
        outline
        color="danger"
        className="btn-ghost-danger shadow-none px-2 py-1 position-absolute"
        onClick={() => {
          isGlobal
            ? removeGlobalLimit(budgetIndex, limitIndex)
            : removeCustomerLimit(budgetIndex, limitIndex);
        }}
        style={{
          top: 10,
          right: 10,
        }}
      >
        <i class="ri-delete-bin-line align-middle"></i>
      </Button>
    </div>
  );
};

export default LimitFields;
