import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import classnames from 'classnames';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import { getTransactions } from '../../../actions/wallet/index';
import { formatDate } from '../../../helpers/formatDate';
import { formatTime } from '../../../helpers/formatTime';
import TransactionDetailsPopup from '../WalletTransactions/TransactionDetailsPopup';
import moment from 'moment';

const NoRecordsMessage = () => {
  return (
    <tr>
      <td colSpan="7" className="text-center py-4">
        <h5 className="fs-13 my-1 fw-normal">No transactions found.</h5>
      </td>
    </tr>
  );
};

const TransactionTable = (props) => {
  const { search, mode, status, mobileNumber } = props;
  const [cardHeaderTab, setcardHeaderTab] = useState('wallet-all');
  const [transactions, setTransactions] = useState([]);
  const [pageOffset, setPageOffset] = useState(0); // Initialize to 0
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const cardHeaderToggle = (tab) => {
    if (cardHeaderTab !== tab) {
      setcardHeaderTab(tab);
    }
  };

  useEffect(() => {
    getTransactionsFunc();
  }, [pageOffset, search, mode, status]);

  const pages = [...Array(totalPages)?.keys()];

  const getTransactionsFunc = async () => {
    const data = {
      country: 'ae',
      mobileNumber: mobileNumber,
      page: pageOffset,
      pageSize: pageSize,
      search: search || '',
      mode: mode || '',
      status: status || '',
      sortBy: 'createdAt',
      sortOrder: 'DESC',
    };

    const res = await getTransactions(data);
    if (res?.success) {
      setTransactions(res?.data?.data);
      setTotalPages(res?.data?.pageInfo?.totalPages);
    }
  };

  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const tabs = [
    {
      label: 'All',
      id: 'wallet-all',
    },
    {
      label: 'Pending & Expiring',
      id: 'wallet-pending',
    },
  ];

  const getStatusClass = (status) => {
    switch (status) {
      case 'approved':
        return 'badge bg-success';
      case 'pending':
      case 'hold':
        return 'badge bg-warning';
      case 'rejected':
        return 'badge bg-danger';
      default:
        return 'badge bg-secondary'; // fallback class
    }
  };

  return (
    <React.Fragment>
      <div className="table-card">
        <div className="card-header align-items-center d-flex">
          <div className="flex-shrink-0 ms-2">
            <Nav
              tabs
              className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
            >
              {tabs?.map((tab, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: cardHeaderTab === tab?.id,
                      })}
                      onClick={() => {
                        cardHeaderToggle(tab?.id);
                      }}
                    >
                      {tab?.label}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </div>
        </div>
        <CardBody className="border-right border-left">
          <TabContent activeTab={cardHeaderTab} className="text-muted">
            {tabs?.map((tab) => {
              return (
                <TabPane key={tab.id} tabId={tab?.id}>
                  <div className="table-responsive table-card">
                    <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Date</th>
                          <th>Transaction ID</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th className="text-right">Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.length === 0 ? (
                          <NoRecordsMessage />
                        ) : (
                          transactions.map((transaction, index) => (
                            <tr key={index}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-13 my-1">{index + 1}</h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <h5 className="fs-13 my-1 fw-normal">
                                  {moment(transaction?.createdAt).format(
                                    'DD MMMM YYYY'
                                  )}
                                </h5>
                                <span className="fs-10 text-muted">
                                  {moment(transaction?.createdAt).format(
                                    'hh:mm:ss A'
                                  )}
                                </span>
                              </td>
                              <td>
                                <h5 className="fs-13 my-1 fw-normal">
                                  {transaction?.token}
                                </h5>
                              </td>
                              <td>
                                <div className="d-flex gap-2">
                                  <span
                                    className={`${
                                      transaction?.mode === 'credit'
                                        ? 'text-success'
                                        : 'text-danger'
                                    }`}
                                  >
                                    <FeatherIcon
                                      icon={`${
                                        transaction?.mode === 'credit'
                                          ? 'arrow-up-right'
                                          : 'arrow-down-right'
                                      }`}
                                      style={{ width: 16 }}
                                    />
                                  </span>
                                  <div>
                                    <h5 className="fs-13 my-1 fw-normal">
                                      {transaction.title}
                                    </h5>
                                    <p className="fs-10 mb-0 text-muted">
                                      Source : Online , Ref# :{' '}
                                      {transaction.transactionNo}
                                    </p>
                                    {transaction.expiryAt ? (
                                      moment().isAfter(
                                        moment(transaction.expiryAt)
                                      ) ? (
                                        <p className="fs-10 mb-0 text-danger">
                                          Expired on{' '}
                                          {moment(transaction.expiryAt).format(
                                            'DD MMMM YYYY'
                                          )}
                                        </p>
                                      ) : (
                                        <p className="fs-10 mb-0 text-warning">
                                          Expiry on{' '}
                                          {moment(transaction.expiryAt).format(
                                            'DD MMMM YYYY'
                                          )}
                                        </p>
                                      )
                                    ) : null}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span
                                  className={getStatusClass(transaction.status)}
                                >
                                  {transaction.status}
                                </span>
                              </td>
                              <td>
                                <h5
                                  className={`${
                                    transaction?.mode === 'credit'
                                      ? 'text-success'
                                      : 'text-danger'
                                  } fs-13 my-1 fw-semiBold text-right`}
                                >
                                  {transaction?.currency}{' '}
                                  {Number(transaction?.amount).toFixed(2)}
                                </h5>
                              </td>
                              <td>
                                <TransactionDetailsPopup
                                  transactionId={transaction.id}
                                />
                                {transaction.status === 'pending' ? (
                                  <button className="rounded-pill btn btn-success py-1 fs-11 ms-2">
                                    Approve
                                  </button>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </TabPane>
              );
            })}
          </TabContent>
        </CardBody>
      </div>

      <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
        <div className="col-sm"></div>
        {totalPages > 1 ? (
          <div className="col-sm-auto mt-3 mt-sm-0">
            <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
              <li className={`page-item ${pageOffset <= 0 ? 'disabled' : ''}`}>
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    setPageOffset(pageOffset - 1);
                  }}
                >
                  ←
                </Link>
              </li>

              {pages?.map((page, index) => {
                return (
                  <li
                    key={index}
                    className={`page-item ${
                      pageOffset === index ? 'active' : ''
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => setPageOffset(index)}
                    >
                      {index + 1}
                    </Link>
                  </li>
                );
              })}

              <li
                className={`page-item ${
                  pageOffset >= totalPages - 1 ? 'disabled' : ''
                }`}
              >
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    setPageOffset(pageOffset + 1);
                  }}
                >
                  →
                </Link>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      <Offcanvas
        isOpen={isRight}
        toggle={toggleRightCanvas}
        id="offcanvasRight"
        direction="end"
      >
        <OffcanvasHeader toggle={toggleRightCanvas} id="offcanvasRightLabel">
          Recent Acitivity
        </OffcanvasHeader>
        <OffcanvasBody>...</OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default TransactionTable;
