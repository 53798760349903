import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import BreadCrumb from '../../../components/Common/BreadCrumb';
const RewardApiAuth = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Ahlan'} titleActive="API Authentication" />
        <Card className="card mx-auto my-5" style={{ maxWidth: '680px' }}>
          {/* Filters and search */}
          <CardBody className="border-top p-4 card-body">
            <Row className="g-3">
              <Col md={12}>
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option>Select your Application </option>
                  <option defaultValue="1">OMS</option>
                  <option defaultValue="2">Website</option>
                  <option defaultValue="3">Ahlan App</option>
                </select>
              </Col>
              <Col md={12}>
                <Input
                  className="form-control "
                  placeholder="Public KEY"
                  type="text"
                />
              </Col>
              <Col md={12}>
                <Input
                  className="form-control "
                  placeholder="Private KEY"
                  type="text"
                />
              </Col>
              <Col md={12}>
                <button className="bg-gradient btn btn-primary">
                  Generate New Key
                </button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default RewardApiAuth;
