import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
  Label,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { Link } from 'react-router-dom';

const Filters = () => {
  const [search, setSearch] = useState('');
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
  }

  return (
    <>
      {/* Filters and search */}
      <CardBody className="border border border-dashed card-body">
        <Row className="g-3">
          <Col className="d-flex flex-wrap align-items-start gap-2">
            <div
              className="search-box d-inline-block w-100"
              style={{ maxWidth: 360 }}
            >
              <Input
                className="form-control search"
                placeholder="Search by Role Id / Mobile no / E-mail"
                type="text"
              />
              <i className="bx bx-search-alt search-icon"></i>
            </div>
            <div>
              <Flatpickr
                className="form-control"
                options={{
                  mode: 'range',
                  dateFormat: 'Y-m-d',
                }}
                placeholder="Filter by Date"
              />
            </div>
            <Select id="admin_status" placeholder="Select Option">
              <Option>sdsd</Option>
            </Select>

            <Select id="admin_status" placeholder="Select Option">
              <Option>sdsd</Option>
            </Select>
            <div className="d-flex gap-2">
              <div>
                <Button
                  color="secondary"
                  outline
                  className="shadow-none py-0 lh-1 d-flex align-items-center justify-content-center"
                  style={{ height: 38 }}
                  id="tooltipTop"
                  onClick={() => tog_backdrop()}
                >
                  <i className="ri-file-transfer-line fs-18 me-2"></i> Export
                  All
                </Button>
                <UncontrolledTooltip placement="top" target="tooltipTop">
                  Please select a date range to export data. The maximum
                  supported date range is 3 month.
                </UncontrolledTooltip>
              </div>

              <Button
                color="light"
                outline
                className="shadow-none py-0 lh-1 d-flex align-items-center justify-content-center border-dark-subtle"
                style={{ height: 38 }}
              >
                <i className="mdi mdi-restore fs-18 text-dark"></i>
              </Button>
              <Button
                color="primary"
                outline
                className="shadow-none py-0 lh-1 d-flex align-items-center justify-content-center"
                style={{ height: 38 }}
              >
                <i className="ri-search-line fs-18"></i>
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>

      {/* Static Backdrop Modal */}
      <Modal
        isOpen={modal_backdrop}
        toggle={() => {
          tog_backdrop();
        }}
        backdrop={'static'}
        id="staticBackdrop"
        centered
      >
        <ModalHeader
          className="modal-title"
          id="rolesAndPermissions"
          toggle={() => {
            tog_backdrop();
          }}
        ></ModalHeader>
        <ModalBody className="text-center pb-5">
          <div className="bg-success-subtle rounded-circle d-inline-flex align-items-center justify-content-center p-3">
            <i
              className="mdi mdi-file-check text-success lh-1"
              style={{ fontSize: '7cqmin' }}
            ></i>
          </div>

          <div className="mt-4">
            <h4 className="mb-3">The download link has been sent.</h4>
            <p className="text-muted mb-0">
              Your export request is being processed. You will receive an email
              to the address you are logged in with when it is ready.
            </p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Filters;
