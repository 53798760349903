import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteField, getAllFields } from '../../../actions/promotion/fields';
import { If } from 'react-if';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import { formatDate } from '../../../helpers/formatDate';
import { formatTime } from '../../../helpers/formatTime';
import { Link } from 'react-router-dom';
import AddConfigs from './AddConfig';
import AlertDelete from './AlertDelete';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import { getAllConfigs } from '../../../actions/promotion/generals';
import { configTypesList } from './constants';

// ==============================|| Field - LIST ||============================== //

const Configs = (...rest) => {
  const [data, setData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [field, setField] = useState(null);
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [pageOffset, setPageOffset] = useState(0);
  const params = useParams();
  console.log('restrestrestrestrest', params);

  const getConfigs = async () => {
    setLoading(true);
    const { data, meta } = await getAllConfigs({
      configName: params.configName,
      configEnv: 'development',
      pageOffset: Math.max(pageOffset - 1, 0),
      pageSize: 10,
    });

    setTimeout(() => {
      setData(data);
      setMeta(meta);
      setLoading(false);
    }, 400);
  };

  useEffect(() => {
    getConfigs();
  }, [pageOffset]);

  const handleAdd = (f) => {
    if (f?._id) {
      setField({ ...f });
    } else {
      setField(null);
    }
    // navigate('/configurations/fields/addfields/new');
    setAdd(!add);

    // when closing and there is data in field object it get cleared
  };

  const handleClose = () => {
    setDeleteOpen(!deleteOpen);
  };

  const deleteUserHandler = async (fieldDeleteId) => {
    const res = await deleteField({ fieldId: fieldDeleteId });
    if (res?.deleteField?.success) {
      alertSuccess('Field deleted successfully');
      getConfigs();
      handleClose(true);
    } else {
      alertError('Failed!. Please try again later..');
    }
  };

  const handleRefreshData = () => {
    getConfigs();
  };
  const handlePageOffset = (value) => {
    setPageOffset(value);
  };
  console.log('configTypesList', configTypesList[params.configName]);
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Admin'} titleActive="Configurations" />
        <Card>
          <CardHeader className="border-0">
            <Row className="g-4 align-items-center">
              <Col sm></Col>
              <Col sm="auto">
                <div className="d-flex flex-wrap align-items-start gap-2">
                  <AddConfigs
                    configIdFromProps={null}
                    fconfigDetailFromProp={null}
                    onCancel={handleAdd}
                    getConfigs={getConfigs}
                    configName={params.configName}
                  />
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <table className="table table-nowrap table-centered align-middle mb-0">
                <thead className="bg-light text-muted">
                  <tr>
                    {configTypesList[params.configName].map((config) => {
                      return (
                        <th>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-13 my-1">{config}</h5>
                            </div>
                          </div>
                        </th>
                      );
                    })}

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {(data || []).map((field, key) => (
                    <tr key={key}>
                      {/* <td>
                        <div className="d-flex align-items-center">
                          <div>
                            <h5 className="fs-13 my-1">{field?.configName}</h5>
                          </div>
                        </div>
                      </td> */}
                      {configTypesList[params.configName].map((config) => {
                        return (
                          <td>
                            <div className="d-flex align-items-center">
                              <div>
                                {
                                  field?.values.find(
                                    (a) => a.fieldCode === config
                                  )?.fieldValue
                                }
                              </div>
                            </div>
                          </td>
                        );
                      })}

                      {/* <td>
                        <h5 className="fs-13 my-1 fw-normal">
                          {field?.configEnv}
                        </h5>
                      </td> */}

                      <td>
                        <div className="d-flex flex-row">
                          <div className="ms-2 me-2">
                            <AddConfigs
                              configIdFromProps={field?._id}
                              configDetailFromProp={field ? { ...field } : null}
                              onCancel={handleAdd}
                              getConfigs={getConfigs}
                              configName={params.configName}
                            />
                          </div>
                          <div>
                            <AlertDelete
                              deleteHandler={() =>
                                deleteUserHandler(field?._id)
                              }
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Configs;
