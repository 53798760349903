import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from 'reactstrap';

const TransactionTypeTable = () => {
  const TransactionDetailsTable = [
    {
      id: '1',
      createdDate: 'Tue 19 sep',
      createdTime: '08:00 AM',
      pointThreshold: '0.75',
      country: 'UAE',
      ahlanTier: 'Platinum',
      ahlanTierClass: 'platinum',
      downgrade: 'NO',
      downgradeIcon: 'x',
      downgradeClass: 'text-danger',
      status: 'Active',
      statusClass: 'text-success',
    },
    {
      id: '2',
      createdDate: 'Tue 19 oct',
      createdTime: '08:00 AM',
      pointThreshold: '0.50',
      country: 'OMAN',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      downgrade: 'YES',
      downgradeIcon: 'check',
      downgradeClass: 'text-success',
      status: 'Active',
      statusClass: 'text-success',
    },
  ];
  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };
  return (
    <React.Fragment>
      <div className="table-responsive table-card">
        <table className="table table-hover table-centered align-middle table-nowrap mb-0">
          <thead>
            <tr>
              <th></th>
              <th>Tier</th>
              <th>Point Threshold</th>
              <th>Country</th>
              <th>Created Date</th>
              <th>Downgrade</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(TransactionDetailsTable || []).map((item, key) => (
              <tr key={key}>
                <td>
                  <div className="d-flex align-items-center">
                    <div>
                      <h5 className="fs-13 my-1">{item.id}</h5>
                    </div>
                  </div>
                </td>
                <td>
                  <h5 className={`fs-10 my-1  badge ${item?.ahlanTierClass}`}>
                    {item.ahlanTier}
                  </h5>
                </td>
                <td>
                  <h5 className="fs-13 my-1">{item.pointThreshold}</h5>
                </td>
                <td>
                  <h5 className="fs-13 my-1">{item.country}</h5>
                </td>
                <td>
                  <h5 className="fs-13 my-1 fw-normal">{item.createdDate}</h5>
                  <span className="fs-10 text-muted">{item.createdTime}</span>
                </td>
                <td>
                  <h5 className="fs-13 my-1 fw-normal d-flex gap-2">
                    <span className={`${item?.downgradeClass}`}>
                      <i className={`bx bx-${item?.downgradeIcon} fs-20`}></i>
                    </span>
                    {item.downgrade}
                  </h5>
                </td>
                <td>
                  <h5 className={`${item?.statusClass} fs-13 my-1 fw-semiBold`}>
                    {item.status}
                  </h5>
                </td>
                <td>
                  <div className="d-flex justify-content-end mx-5">
                    <Link
                      to="/rewards/ahlan/tire-master/create"
                      className="rounded-pill btn btn-light py-1 fs-11"
                    >
                      View Details
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            Showing <span className="fw-semibold">5</span> of
            <span className="fw-semibold">25</span> Results
          </div>
        </div>
        <div className="col-sm-auto mt-3 mt-sm-0">
          <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
            <li className="page-item disabled">
              <Link to="#" className="page-link">
                ←
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                1
              </Link>
            </li>
            <li className="page-item active">
              <Link to="#" className="page-link">
                2
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                3
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                →
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/*  */}
      <Offcanvas
        isOpen={isRight}
        toggle={toggleRightCanvas}
        id="offcanvasRight"
        direction="end"
      >
        <OffcanvasHeader toggle={toggleRightCanvas} id="offcanvasRightLabel">
          Recent Acitivity
        </OffcanvasHeader>
        <OffcanvasBody>...</OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default TransactionTypeTable;
