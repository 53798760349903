import React, { useState } from 'react';
import { Button, Input, InputGroup, FormGroup, Label } from 'reactstrap';
import { If } from 'react-if';
import QueryBuilderApp from '../QueryBuilderApp';
import { DISCOUNT_TYPES } from '../../../constants';
import Collections from '../Collections';

const CONDITIONAL_DISCOUNT_ATTRIBUTES = [
  {
    label: 'Discount',
    value: 'totalDiscount',
  },
];

const DISCOUNT_ON_PRICE_TYPES = [
  {
    label: 'Selling Price',
    value: 'sp',
  },
  {
    label: 'Actual Price',
    value: 'ap',
  },
];
const CONDITIONAL_DISCOUNT_OPERATIONS = [
  {
    label: 'Equal To',
    value: 'eq',
  },
  {
    label: 'Greater Than',
    value: 'gt',
  },
  {
    label: 'Greater Than or Equal To',
    value: 'gte',
  },
  {
    label: 'Lesser Than',
    value: 'lt',
  },
  {
    label: 'Lesser Than Or Equal To',
    value: 'lte',
  },
  {
    label: 'Between',
    value: 'btw',
  },
];

const EffectTransactionConditionalOption = ({
  optionCount,
  index,
  handleValueChange,
  handleRemoveOption,
  option,
  combinedUnitBasedDiscount,
  effect = {},
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div
      key={index}
      className="border p-3 mb-3 rounded position-relative bg-white"
    >
      <div>
        <>
          <If condition={!effect.combinedUnitBasedDiscount}>
            <>
              <>
                <div className="d-flex gap-2">
                  <FormGroup className="mb-1">
                    <Label for="itemConditionAttribute">
                      Condition Attribute
                    </Label>
                    <Input
                      disabled={effect.combinedUnitBasedDiscount}
                      type="select"
                      id="itemConditionAttribute"
                      value={option?.itemConditionAttribute}
                      onChange={(e) =>
                        handleValueChange(
                          index,
                          e.target.value,
                          'itemConditionAttribute'
                        )
                      }
                    >
                      {CONDITIONAL_DISCOUNT_ATTRIBUTES?.map((item, idx) => (
                        <option value={item?.value} key={`dt-${idx}`}>
                          {item?.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup className="mb-1">
                    <Label for="discount-type-select">Operation</Label>
                    <Input
                      disabled={effect.combinedUnitBasedDiscount}
                      type="select"
                      id="discount-type-select"
                      value={option?.itemConditionOperation}
                      onChange={(e) =>
                        handleValueChange(
                          index,
                          e.target.value,
                          'itemConditionOperation'
                        )
                      }
                    >
                      {CONDITIONAL_DISCOUNT_OPERATIONS?.map((item, idx) => (
                        <option value={item?.value} key={`dt-${idx}`}>
                          {item?.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label for="discount-value-number">Value</Label>
                    <Input
                      disabled={effect.combinedUnitBasedDiscount}
                      type="number"
                      id="discount-value-number"
                      value={`${option?.itemConditionValue}`}
                      required
                      onChange={(e) =>
                        handleValueChange(
                          index,
                          e.target.value,
                          'itemConditionValue'
                        )
                      }
                    />
                  </FormGroup>
                  <If condition={option.itemConditionOperation === 'btw'}>
                    <FormGroup>
                      <Label for="discount-value-number2">Value 2</Label>
                      <Input
                        disabled={combinedUnitBasedDiscount}
                        type="number"
                        id="discount-value-number2"
                        value={`${option?.itemConditionValue2}`}
                        required
                        onChange={(e) =>
                          handleValueChange(
                            index,
                            e.target.value,
                            'itemConditionValue2'
                          )
                        }
                      />
                    </FormGroup>
                  </If>
                </div>
                <div className="d-flex gap-2">
                  <FormGroup>
                    <Label for="discount-value-number">Discount Value</Label>
                    <Input
                      disabled={effect.combinedUnitBasedDiscount}
                      type="number"
                      id="discount-value-number"
                      value={`${option?.itemConditionDiscountValue}`}
                      required
                      onChange={(e) =>
                        handleValueChange(
                          index,
                          e.target.value,
                          'itemConditionDiscountValue'
                        )
                      }
                    />
                  </FormGroup>

                  <If condition={effect?.discountType === 'percentage'}>
                    <FormGroup>
                      <Label for="discount-value-number">
                        Maximum Discount Value
                      </Label>
                      <Input
                        disabled={effect.combinedUnitBasedDiscount}
                        type="number"
                        id="discount-value-number"
                        value={`${option?.maxDiscountValue}`}
                        required
                        onChange={(e) =>
                          handleValueChange(
                            index,
                            e.target.value,
                            'maxDiscountValue'
                          )
                        }
                      />
                    </FormGroup>
                  </If>
                  <FormGroup className="mb-1">
                    <Label for="discount-type-select">
                      Discount on Price Type
                    </Label>
                    <Input
                      disabled={effect.combinedUnitBasedDiscount}
                      type="select"
                      id="discount-type-select-price-type"
                      value={option?.discountOnPriceType}
                      onChange={(e) =>
                        handleValueChange(
                          index,
                          e.target.value,
                          'discountOnPriceType'
                        )
                      }
                    >
                      {DISCOUNT_ON_PRICE_TYPES?.map((item, idx) => (
                        <option value={item?.value} key={`dt-${idx}`}>
                          {item?.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
                <If condition={option?.discountOnPriceType === 'ap'}>
                  <FormGroup check>
                    <Label
                      for={`${index}useSalePriceIfDiscountedPriceIsHigherThanSalePrice`}
                    >
                      Use Sale Price If Discounted Price Is Higher Than Sale
                      Price
                    </Label>
                    <Input
                      disabled={effect.combinedUnitBasedDiscount}
                      type="checkbox"
                      id={`${index}useSalePriceIfDiscountedPriceIsHigherThanSalePrice`}
                      // value={`${option?.useSalePriceIfDiscountedPriceIsHigherThanSalePrice}`}
                      checked={
                        option?.useSalePriceIfDiscountedPriceIsHigherThanSalePrice !==
                        false
                      }
                      required
                      onChange={() =>
                        handleValueChange(
                          index,
                          option?.useSalePriceIfDiscountedPriceIsHigherThanSalePrice
                            ? false
                            : true,
                          'useSalePriceIfDiscountedPriceIsHigherThanSalePrice'
                        )
                      }
                    />
                  </FormGroup>
                </If>
              </>
              <div className="d-flex gap-2">
                <Button
                  color={'outline-danger'}
                  size="sm"
                  onClick={() => {
                    handleRemoveOption(index);
                  }}
                >
                  Remove Discount Rule
                </Button>
              </div>
            </>
          </If>
        </>
      </div>
    </div>
  );
};

export default EffectTransactionConditionalOption;
