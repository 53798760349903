import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import profileBg from '../../../assets/images/auth-one-bg.jpg';
import { alertSuccess, generateColor } from '../../../helpers/general';
import { useDispatch, useSelector } from 'react-redux';
import Address from './Address';
import UserDetails from './UserDetails';
import Journey from './Journey';
import TransactionTable from './Transactions/TransactionTable';
import Dashboard from './Dashboard/Dashboard';
import { getAllConfigs } from '../../../actions/promotion/generals';
import { getMemberDetail, listMembers } from '../../../actions/members/list';
import { If } from 'react-if';
import { getRewardsMaster } from '../../../actions/rewards';

/**
 *
 * @returns
 */
const Profile = () => {
  document.title = 'Profile';
  let params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [member, setMember] = useState({});
  const [configs, setSelectedConfig] = useState(null);
  const [rewards, setRewards] = useState([]);

  const getConfigs = async () => {
    try {
      setIsLoading(true);
      const { data } = await getAllConfigs({
        configName: 'members',
        configEnv: process.env.NODE_ENV,
        pageOffset: Math?.max(0 - 1, 0),
        pageSize: 1000,
      });

      const res = await getRewardsMaster(data);
      setRewards(res?.data?.rewardTypes || []);

      setFields([...data]);
      const fieldConfigs = {};

      data.map((item) => {
        let fieldType = null;
        let isLoyaltyCardUniqueID = false;
        let isDisplayInProfile = false;

        item.values.forEach((value) => {
          if (value.fieldCode === 'fieldType') {
            fieldType = value.fieldValue;
          }
        });
        if (fieldType === 'OtherField') {
          item.values.forEach((value) => {
            if (value.fieldCode === 'isLoyaltyCardUniqueID') {
              if (!fieldConfigs['loyaltyCardNumbers']) {
                fieldConfigs['loyaltyCardNumbers'] = [];
              }
              isLoyaltyCardUniqueID = true;
            }
            if (value.fieldCode === 'displayInProfile') {
              if (!fieldConfigs['displayInProfile']) {
                fieldConfigs['displayInProfile'] = [];
              }
              isDisplayInProfile = true;
            }
          });
        }
        item.values.forEach((value) => {
          if (value.fieldCode === 'fieldId') {
            if (isDisplayInProfile) {
              fieldConfigs['displayInProfile'].push(value.fieldValue);
            }
            if (isLoyaltyCardUniqueID) {
              fieldConfigs['loyaltyCardNumbers'].push(value.fieldValue);
            }
            if (fieldType !== 'OtherField') {
              fieldConfigs[fieldType] = value.fieldValue;
            }
          }
        });
      });
      setSelectedConfig(fieldConfigs);
      console.log('fieldConfigs', fieldConfigs, data);
      const member = await getMemberDetail({ id: params.id });
      setMember(member);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getConfigs();
  }, []);

  //

  const [activeTab, setActiveTab] = useState('1');

  const dispatch = useDispatch();

  // form validation

  setTimeout(() => {
    setIsLoading(false);
  }, 3500);
  /**
   *
   * @param {*} tab
   */
  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div
              className="profile-wid-bg profile-setting-img"
              style={{ height: 100 }}
            >
              <img src={profileBg} className="profile-wid-img" alt="" />
            </div>
          </div>
          <Row>
            <Col md={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <If condition={member?._id}>
                    <UserDetails
                      member={member}
                      configs={configs}
                      rewards={rewards}
                    />
                  </If>
                </CardBody>
              </Card>
            </Col>

            <Col md={9}>
              <Card className="mt-md-n5">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          tabChange('1');
                        }}
                      >
                        Dashboard
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          tabChange('2');
                        }}
                        type="button"
                      >
                        Purchases
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => {
                          tabChange('3');
                        }}
                        type="button"
                      >
                        Activity
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === '4' })}
                        onClick={() => {
                          tabChange('4');
                        }}
                        type="button"
                      >
                        Ahlan Reward Points
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === '5' })}
                        onClick={() => {
                          tabChange('5');
                        }}
                        type="button"
                      >
                        Wallet Transactions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === '6' })}
                        onClick={() => {
                          tabChange('6');
                        }}
                        type="button"
                      >
                        Address
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Dashboard />
                    </TabPane>

                    {/*  Transactions */}
                    <TabPane tabId="2">
                      <If condition={member?._id}>
                        <TransactionTable member={member} />
                      </If>
                    </TabPane>

                    {/*  Journey  */}
                    <TabPane tabId="3">
                      <Journey />
                    </TabPane>

                    {/*  Address */}
                    <TabPane tabId="4">
                      <Address />
                    </TabPane>
                    {/*  Address */}
                    <TabPane tabId="5">
                      <Address />
                    </TabPane>
                    <TabPane tabId="6">
                      <Address />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Profile;
