import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import BreadCrumb from '../../components/Common/BreadCrumb';
import Select from 'react-select';
import TransactionTable from './ExportsListTable';
const ExportsList = () => {
  const [search, setSearch] = useState('');
  //
  document.title = 'Exported requests';
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Admin'} titleActive="Exported requests" />
        <Card>
          <CardBody className="border">
            <Row className="g-3">
              <Col>
                <TransactionTable />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ExportsList;
