import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  ModalFooter,
} from 'reactstrap';

import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { autoGenerateCoupons } from '../../../actions/promotion';

//Import Flatepicker
import Flatpickr from 'react-flatpickr';

import InnerLoader from '../../../components/Common/InnerLoader';

import { alertError, alertSuccess } from '../../../helpers/errorHandling';

const couponLetterTypes = [
  {
    label: 'Alphanumeric',
    value: 'alphanumeric',
  },
  {
    label: 'Alphabetic',
    value: 'alphabetic',
  },
  {
    label: 'Numeric',
    value: 'numeric',
  },
];

// ==============================|| CUSTOMER - ADD / EDIT ||============================== //

const AutoGenerateCoupons = ({
  onCancel = () => {},
  getStores = () => {},
  setCoupons,
  coupons,
  setPageOffset,
  setTotalPages,
  setIsRefreshTable,
  promotionId,
  promotionDetail,
}) => {
  const [autoCouponConfig, setAutoCouponConfig] = useState({
    length: null,
    type: 'alphanumeric',
    prefix: '',
    suffix: '',
    startsAt: null,
    expiresAt: null,
    redeemableQuantity: 0,
    noOfCoupons: 0,
  });

  const autoCouponConfigSchema = Yup.object().shape({
    length: Yup.string().required('Coupon length is required'),
    noOfCoupons: Yup.number().required('Number of coupons is required'),
    type: Yup.string().required('Letter type is required'),
    prefix: Yup.string().optional(), // Prefix is optional, no error message needed
    suffix: Yup.string().optional(), // Suffix is optional, no error message needed
    startsAt: Yup.date().required('Starts At is required'),
    expiresAt: Yup.date().required('Expires At is required'),
    redeemableQuantity: Yup.number().required(
      'Redeemable quantity is required'
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...autoCouponConfig },
    validationSchema: autoCouponConfigSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const variables = {
          input: {
            promotionId: promotionId,
            ...values,
          },
        };

        const { success, message, data } = await autoGenerateCoupons(variables);
        if (success) {
          setCoupons([...data?.coupons]);
          setPageOffset(data?.pageOffset);
          setTotalPages(data?.totalPages);
          setIsRefreshTable(true);
        }

        if (success) {
          alertSuccess('Coupons generated successfully.');
        } else {
          alertError({ message: message });
        }

        setSubmitting(false);
        onCancel();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Row spacing={3}>
          <Col xl={2} lg={2} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="promotion-name">Length of Coupon Code</Label>

              <Input
                required
                id="length"
                name="length"
                placeholder="Coupon length"
                style={{ width: '100%' }}
                type="number"
                min={1}
                onChange={(e) => {
                  setAutoCouponConfig({
                    ...autoCouponConfig,
                    length: Number(e?.target?.value),
                  });
                }}
                //   value={values?.length}
              />
              {/* <FormFeedback>
                {touched.promotionName && errors.promotionName}
              </FormFeedback> */}
            </FormGroup>
          </Col>
          <Col xl={2} lg={2} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="promotion-name">Number of Coupons</Label>
              <Input
                required
                id="noOfCoupons"
                name="noOfCoupons"
                placeholder="Number of Coupons"
                style={{ width: '100%' }}
                type="number"
                min={1}
                onChange={(e) => {
                  setAutoCouponConfig({
                    ...autoCouponConfig,
                    noOfCoupons: Number(e?.target?.value),
                  });
                }}
                //   value={values?.noOfCoupons}
              />
            </FormGroup>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="type">Letters type</Label>
              <Input
                type="select"
                id="type"
                name="type"
                // value={values?.type}
                onChange={(e) => {
                  setAutoCouponConfig({
                    ...autoCouponConfig,
                    type: e?.target?.value,
                  });
                }}
              >
                {couponLetterTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="type">Prefix</Label>
              <Input
                id="prefix"
                name="prefix"
                placeholder="Prefix"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setAutoCouponConfig({
                    ...autoCouponConfig,
                    prefix: e?.target?.value,
                  });
                }}
                //   value={values?.prefix}
              />
            </FormGroup>
          </Col>
          <Col xl={2} lg={2} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="type">Suffix</Label>
              <Input
                id="suffix"
                name="suffix"
                placeholder="Suffix"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setAutoCouponConfig({
                    ...autoCouponConfig,
                    suffix: e?.target?.value,
                  });
                }}
                //   value={values?.suffix}
              />
            </FormGroup>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="type">Start Date</Label>
              <Flatpickr
                className="form-control"
                id="startsAt"
                name="startsAt"
                placeholder="Start Date"
                options={{
                  enableTime: true,
                  // dateFormat: 'Y-m-d H:i',
                  dateFormat: 'Y-m-d h:i K',
                  time_24hr: false,
                }}
                onChange={(values) => {
                  setAutoCouponConfig({
                    ...autoCouponConfig,
                    startsAt: values?.[0],
                  });
                }}
              />
              {/* <Input
                required
                type="datetime-local"
                id="startsAt"
                name="startsAt"
                placeholder="Start Date"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setAutoCouponConfig({
                    ...autoCouponConfig,
                    startsAt: e?.target?.value,
                  });
                }}
                //   value={values?.startsAt}
              /> */}
            </FormGroup>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="type">End Date</Label>
              <Flatpickr
                className="form-control"
                type="datetime-local"
                id="expiresAt"
                name="expiresAt"
                placeholder="End Date"
                onChange={(values) => {
                  setAutoCouponConfig({
                    ...autoCouponConfig,
                    expiresAt: values?.[0],
                  });
                }}
                options={{
                  enableTime: true,
                  // dateFormat: 'Y-m-d H:i',
                  dateFormat: 'Y-m-d h:i K',
                  time_24hr: false,
                }}
              />
              {/* <Input
                required
                type="datetime-local"
                id="expiresAt"
                name="expiresAt"
                placeholder="End Date"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setAutoCouponConfig({
                    ...autoCouponConfig,
                    expiresAt: e?.target?.value,
                  });
                }}
                //   value={values?.expiresAt}
              /> */}
            </FormGroup>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="type">Redeemable Quantity</Label>
              <Input
                required
                type="number"
                id="redeemableQuantity"
                name="redeemableQuantity"
                placeholder="Redeemable Quantity"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setAutoCouponConfig({
                    ...autoCouponConfig,
                    redeemableQuantity: Number(e?.target?.value),
                  });
                }}
                //   value={values?.redeemableQuantity}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col></Col>
        </Row>
        <ModalFooter>
          <Button
            className="btn  btn-light py-1 px-4"
            size="md"
            data-bs-dismiss="modal"
            onClick={onCancel}
          >
            Close
          </Button>
          <Button
            color="secondary"
            className=" py-1 px-4"
            size="md"
            type="submit"
          >
            Generate
          </Button>
        </ModalFooter>
      </Form>
      {isSubmitting ? <FullPageLoader /> : null}
    </FormikProvider>
  );
};

export default AutoGenerateCoupons;
