import React, { useState } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";

const TransactionTypeTable = () => {
  const TransactionDetailsTable = [
    {
      id: "1",
      createdDate: "Tue 19 sep",
      createdTime: "08:00 AM",
      description: "Birth Day 2X Points",
      transitionType: "BIRTHDAY",
      holdPeriod: "0 Days",
      status: "Active",
      feaIconClass: "text-success",
      validity: "30 Days",
      validityEndDate: "Tue 19 Dec 2024",
      validityEndTime: "08:00 AM",
    },
    {
      id: "2",
      createdDate: "Tue 19 oct",
      createdTime: "08:00 AM",
      description: "Purchase Furniture 2X Points",
      transitionType: "ORDER",
      holdPeriod: "15 Days",
      status: "Active",
      feaIconClass: "text-success",
      validity: "180 Days",
      validityEndDate: "Tue 19 Dec 2024",
      validityEndTime: "08:00 AM",
    },
  ];
  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };
  return (
    <React.Fragment>
      <div className="table-responsive table-card">
        <table className="table table-hover table-centered align-middle table-nowrap mb-0">
          <thead>
            <tr>
              <th></th>
              <th>Description</th>
              <th>Transaction Type</th>
              <th>Created Date</th>
              <th>Hold Period</th>
              <th>Status</th>
              <th>Validity</th>
            </tr>
          </thead>
          <tbody>
            {(TransactionDetailsTable || []).map((item, key) => (
              <tr key={key}>
                <td>
                  <div className="d-flex align-items-center">
                    <div>
                      <h5 className="fs-13 my-1">{item.id}</h5>
                    </div>
                  </div>
                </td>
                <td>
                  <h5 className="fs-13 my-1 fw-normal">{item.description}</h5>
                </td>
                <td>
                  <h5 className="fs-13 my-1">{item.transitionType}</h5>
                </td>
                <td>
                  <h5 className="fs-13 my-1 fw-normal">{item.createdDate}</h5>
                  <span className="fs-10 text-muted">{item.createdTime}</span>
                </td>
                <td>
                  <h5 className="fs-13 my-1 fw-normal">{item.holdPeriod}</h5>
                </td>
                <td>
                  <h5
                    className={`${item?.feaIconClass} fs-13 my-1 fw-semiBold`}
                  >
                    {item.status}
                  </h5>
                </td>
                <td>
                  <h5 className="fs-13 my-1 fw-normal">{item.validity}</h5>
                  {item.validityEndDate ? (
                    <span className="fs-10 text-muted">
                      Ends on - {item.validityEndTime}, {item.validityEndDate}
                    </span>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            Showing <span className="fw-semibold">5</span> of
            <span className="fw-semibold">25</span> Results
          </div>
        </div>
        <div className="col-sm-auto mt-3 mt-sm-0">
          <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
            <li className="page-item disabled">
              <Link to="#" className="page-link">
                ←
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                1
              </Link>
            </li>
            <li className="page-item active">
              <Link to="#" className="page-link">
                2
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                3
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                →
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/*  */}
      <Offcanvas
        isOpen={isRight}
        toggle={toggleRightCanvas}
        id="offcanvasRight"
        direction="end"
      >
        <OffcanvasHeader toggle={toggleRightCanvas} id="offcanvasRightLabel">
          Recent Acitivity
        </OffcanvasHeader>
        <OffcanvasBody>...</OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default TransactionTypeTable;
