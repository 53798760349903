import React from 'react';
import moment from 'moment';
import {
  ButtonGroup,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import { longTextBreak } from '../../helpers/general';
import { ROUTE_ROLE_CREATE } from '../../constants/route';
import { Link } from 'react-router-dom';

const Role = ({ item }) => {
  const { _id, createdAt, updatedAt, name, description, permissions } =
    item || {};

  return (
    <>
      <td>
        <div className="d-flex gap-2 align-items-start">
          <div>
            <p className="my-1">
              <strong className="p-0">{name}</strong>
            </p>
            <div className="d-flex flex-column align-items-left gap-0 p-0">
              <p className="fs-11 text-muted fw-normal d-flex flex-wrap gap-1 m-0">
                Created at: {moment(createdAt).format('LL')},{' '}
                {moment(createdAt).format('LT')}
              </p>
              <p className="fs-11 text-muted fw-normal d-flex flex-wrap gap-1 m-0">
                Update at: {moment(updatedAt).format('LL')},{' '}
                {moment(updatedAt).format('LT')}
              </p>
            </div>
          </div>
        </div>
      </td>
      <td>
        {description && (
          <p className="my-1">{longTextBreak(description, 100)}</p>
        )}
      </td>
      <td>
        {permissions?.map((sItem, index) => (
          <p
            key={`${sItem}${index}`}
            className="my-1 d-flex align-items-center gap-1 text-capitalize"
          >
            <i className="mdi mdi-check text-success fs-18"></i>
            {sItem?.replace('.', ' ')}
          </p>
        ))}
        <p className="text-primary d-flex gap-1 m-0 px-3">
          <u>View All</u>
        </p>
      </td>
      {/* <td>
        {permissions?.map((sItem, index) => (
          <p key={index} className="my-1 d-flex align-items-center gap-1">
            <i className="mdi mdi-close text-danger fs-18"></i>
            {sItem?.replace('.', ' ')}
          </p>
        ))}
        <p className="text-primary d-flex gap-1 m-0 px-3">
          <u>View All</u>
        </p>
      </td> */}
      <td>
        <ButtonGroup>
          <UncontrolledDropdown>
            <DropdownToggle
              tag="button"
              className="btn btn-light fs-22 px-1 shadow-none border border-1"
              style={{
                lineHeight: 0,
                paddingTop: '14px',
                paddingBottom: '10px',
              }}
            >
              <i className="ri-more-fill"></i>
            </DropdownToggle>
            <DropdownMenu className="p-0">
              <Link to={ROUTE_ROLE_CREATE?.replace(':id', _id)}>
                <DropdownItem className="p-2 text-dark fw-11">
                  <i className="bx bx-edit-alt"></i> Edit
                </DropdownItem>
              </Link>

              <DropdownItem className="p-2 text-danger fw-11">
                <i className="bx bx-trash"></i> Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ButtonGroup>
      </td>
    </>
  );
};

export default Role;
