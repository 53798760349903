import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ROUTE_DASHBOARD,
  ROUTE_USER_LISTING,
  ROUTE_USER_LOGOUT,
  ROUTE_PROFILE,
  ROUTE_WALLET,
  ROUTE_WALLET_DASHBOARD,
  ROUTE_WALLET_TRANSACTIONS,
  ROUTE_WALLET_OTHER_REPORTS,
  ROUTE_WALLET_USERS_LIST,
  ROUTE_LIST_ALL_CUSTOMERS,
  ROUTE_PROMOTION_DASHBOARD,
  ROUTE_PROMOTION_LIST,
  ROUTE_CONFIGURATIONS,
  ROUTE_ROLE_LISTING,
  ROUTE_EXPORTS,
  ROUTE_PROMOTION_PRIORITIZATION,
} from '../../constants/route';
import { useLocation } from 'react-router-dom';
import { getRewardsMaster } from '../../actions/rewards';

const NavData = () => {
  const history = useNavigate();
  const location = useLocation();
  //
  const [isDashboard, setIsDashboard] = useState(false);
  const [isAllAdmins, setIsAllAdmins] = useState(false);
  const [isAllCustomers, setIsAllCustomers] = useState(false);

  const [isSettings, setIsSettings] = useState(false);
  const [isWallet, setIsWallet] = useState(false);
  const [isAhlan, setIsAhlan] = useState(false);
  const [isPromotion, setIsPromotion] = useState(false);
  const [isConfig, setIsConfig] = useState(false);
  const [selectedMenuId, setSelectedMenuId] = useState('');
  const [rewards, setRewards] = useState([]);
  const [exportsList, setExportsList] = useState([]);

  const rewardsMenuItems = rewards?.map((item) => {
    const id = item?.name?.toLowerCase()?.replace(/ /g, '-');
    return {
      id: id,
      label: item?.name,
      icon: 'bx bx-coin-stack',
      link: `${id}`,
      stateVariables: selectedMenuId === id,
      click: (e) => {
        e.preventDefault();
        setSelectedMenuId(selectedMenuId === id ? '' : id);
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: `reward-${id}-dashboard`,
          label: 'Dashboard',
          link: `/rewards/${id}/dashboard`,
          parentId: id,
        },
        {
          id: `reward-${id}-transactions`,
          label: 'Transactions',
          link: `/rewards/${id}/transactions`,
          parentId: id,
        },
        {
          id: `reward-${id}-tire-master`,
          label: 'Tire Master',
          link: `/rewards/${id}/tire-master`,
          parentId: id,
        },
        {
          id: `reward-${id}-transaction-types`,
          label: 'Transaction Types',
          link: `/rewards/${id}/transaction-types`,
          parentId: id,
        },
      ],
    };
  });

  const menuItems = [
    {
      label: 'Menu',
      isHeader: true,
    },
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'bx bxs-dashboard',
      link: ROUTE_DASHBOARD,
      stateVariables: isDashboard,
      click: (e) => {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        updateIconSidebar(e);
      },
    },
    {
      id: 'wallet',
      label: 'Wallet',
      icon: 'bx bx-wallet-alt',
      link: ROUTE_WALLET,
      stateVariables: isWallet,
      click: (e) => {
        e.preventDefault();
        setIsWallet(!isWallet);
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'wallet-dashboard',
          label: 'Dashboard',
          link: ROUTE_WALLET_DASHBOARD,
          parentId: 'wallet',
        },
        {
          id: 'wallet-transactions',
          label: 'Transactions',
          link: ROUTE_WALLET_TRANSACTIONS,
          parentId: 'wallet',
        },
        {
          id: 'wallet-other-reports',
          label: 'Other Reports',
          link: ROUTE_WALLET_OTHER_REPORTS,
          parentId: 'wallet',
        },
        {
          id: 'wallet-list',
          label: 'List',
          link: ROUTE_WALLET_USERS_LIST,
          parentId: 'wallet',
        },
      ],
    },
    ...(rewardsMenuItems || []),
    {
      id: 'promotion',
      label: 'Promotion',
      icon: 'bx bx-message-square-detail',
      link: ROUTE_PROMOTION_DASHBOARD,
      stateVariables: isPromotion,
      click: (e) => {
        e.preventDefault();
        setIsPromotion(!isPromotion);
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'promotion-dashboard',
          label: 'Dashboard',
          link: ROUTE_PROMOTION_DASHBOARD,
          parentId: 'promotions',
        },
        {
          id: 'promotions',
          label: 'Promotions',
          link: ROUTE_PROMOTION_LIST,
          parentId: 'promotions',
        },
        {
          id: 'prioritization',
          label: 'Prioritization',
          link: ROUTE_PROMOTION_PRIORITIZATION,
          parentId: 'promotions',
        },
      ],
    },
    {
      id: 'exported-requests ',
      label: 'Exported Requests ',
      icon: 'ri-file-text-line',
      link: ROUTE_EXPORTS,
      stateVariables: exportsList,
      click: (e) => {
        e.preventDefault();
        setExportsList(!exportsList);
        updateIconSidebar(e);
      },
    },
    {
      id: 'customers',
      label: 'Customers',
      icon: 'bx bx-user-circle',
      link: ROUTE_LIST_ALL_CUSTOMERS,
      stateVariables: isAllCustomers,
      click: (e) => {
        e.preventDefault();
        setIsAllCustomers(!isAllCustomers);
        updateIconSidebar(e);
      },
    },
    {
      id: 'adminUser',
      label: 'Admin Users',
      icon: 'bx bx-crown',
      link: '/#',
      stateVariables: isAllAdmins,
      click: (e) => {
        e.preventDefault();
        setIsAllAdmins(!isAllAdmins);
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'roles',
          label: 'Roles',
          link: ROUTE_ROLE_LISTING,
          parentId: 'settings',
        },
        {
          id: 'users',
          label: 'Users',
          link: ROUTE_USER_LISTING,
          parentId: 'users',
        },
      ],
    },
    //
    {
      id: 'settings',
      label: 'Settings',
      icon: 'bx bxs-key',
      link: '/#',
      stateVariables: isSettings,
      click: function (e) {
        e.preventDefault();
        setIsSettings(!isSettings);
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'profile',
          label: 'Profile',
          link: ROUTE_PROFILE,
          parentId: 'settings',
        },
        {
          id: 'logout',
          label: 'Log Out',
          link: ROUTE_USER_LOGOUT,
          parentId: 'settings',
        },
      ],
    },
    {
      id: 'configurations',
      label: 'Configurations',
      icon: 'bx bx-cog',
      link: ROUTE_CONFIGURATIONS,
      stateVariables: isConfig,
      click: (e) => {
        e.preventDefault();
        setIsConfig(!isConfig);
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'fields',
          label: 'Fields',
          link: '/configurations/fields',
          parentId: 'fields',
        },
        {
          id: 'general/basket-playground',
          label: 'Basket Playground',
          link: '/configurations/general/basket-playground',
          parentId: 'Basket Playground',
        },
        {
          id: 'teams',
          label: 'Teams',
          link: '/configurations/teams',
          parentId: 'teams',
        },
        {
          id: 'reward-types',
          label: 'Reward Types',
          link: '/reward-types/list',
          parentId: 'reward-types',
        },
        {
          id: 'members',
          label: 'Members',
          link: '/configurations/general/members',
          parentId: 'Members',
        },
        {
          id: 'languages',
          label: 'Languages',
          link: '/configurations/general/languages',
          // parentId: 'Members',
        },
      ],
    },
  ];

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu');
      const iconItems = ul.querySelectorAll('.nav-icon.active');
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove('active');
        var id = item.getAttribute('subitems');
        if (document.getElementById(id))
          document.getElementById(id).classList.remove('show');
      });
    }
  }

  useEffect(() => {
    updateRewardsMenu();
  }, []);

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel');
  }, [history, location]);

  const updateRewardsMenu = async () => {
    try {
      const data = {
        pageOffset: 0,
        pageSize: 20,
      };
      const res = await getRewardsMaster(data);
      if (res?.success) {
        setRewards([...(res?.data?.rewardTypes || [])]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return <>{menuItems}</>;
};
export default NavData;
