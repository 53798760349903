import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  errorMsg: '',
};

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setErrorMsg(state, action) {
      state.errorMsg = action?.payload;
    },
  },
});

export const { setUser, setErrorMsg } = authSlice.actions;
export default authSlice.reducer;
