import { combineReducers } from 'redux';

// Front
import LayoutReducer from './layouts/reducer';

// Authentication
import AuthReducer from './auth/reducer';
import PromotionReducer from './promotions/reducer';
import DashboardReducer from './dashboard/reducer';

const rootReducer = combineReducers({
  layout: LayoutReducer,
  user: AuthReducer,
  dashboard: DashboardReducer,
  promotions: PromotionReducer,
});

export default rootReducer;
