import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fields: [],
  errorMsg: '',
  fieldsMap: {},
};

const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    setFields(state, action) {
      state.fields = action.payload;
    },
    setFieldsMap(state, action) {
      state.fieldsMap = action.payload;
    },
    setComboForPreview(state, action) {
      state.selectedComboForPreview = action.payload;
    },
  },
});

export const { setFields, setFieldsMap, setComboForPreview } =
  promotionSlice.actions;
export default promotionSlice.reducer;
