import React, { useState } from "react";
import CountUp from "react-countup";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { AverageSpending, AverageVisit } from "./AreaCharts";
import { CustomerLifeTimeValue } from "./ColumnCharts";
import { MostEngagedCategories } from "./BarCharts";
import {
  EngagedScore,
  HeighLtv,
  Recommended,
  PurchaseFrequency,
  ConvenienceShopper,
  Churn,
} from "./RadialbarCharts";
import { Link } from "react-router-dom";
import barCode from "../../../../assets/images/ahlanBarCode.svg";
import { ChannelPreference, DevicesUsage } from "./ECharts";
const Dashboard = () => {
  return (
    <>
      <Row>
        <Col md={6}>
          <Card className="border">
            <CardBody>
              <div>
                <span className="fs-12 mb-1 text-muted d-block">
                  Customer Life-time Value
                </span>
                <h2 className="fs-24 card-title mb-0">
                  AED <CountUp start={0} end={500} decimals={0} duration={2} />K
                </h2>
              </div>
              <CustomerLifeTimeValue dataColors='["--vz-primary"]' />
            </CardBody>
          </Card>
          <Card className="border">
            <CardBody>
              <div>
                <span className="fs-12 mb-1 text-muted d-block">
                  Average Spending
                </span>
                <h2 className="fs-24 card-title mb-0">
                  AED <CountUp start={0} end={500} decimals={0} duration={2} />K
                </h2>
              </div>
              <AverageSpending dataColors='["--vz-primary", "--vz-success"]' />
            </CardBody>
          </Card>

          <Card className="border">
            <CardBody>
              <div>
                <h5 className="fs-14">Engaged Score</h5>
              </div>
              <EngagedScore dataColors='["--vz-primary"]' />
            </CardBody>
          </Card>
          <Card className="border">
            <CardBody>
              <div>
                <h5 className="fs-14">Channel Preference</h5>
              </div>
              <ChannelPreference dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]' />
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
          <Row>
            <Col md={6}>
              <Card
                className="border"
                style={{ height: "calc(100% - 1.5rem)" }}
              >
                <CardBody>
                  <div>
                    <h5 className="fs-14">No. of Purchase</h5>
                    <h2 className="fs-28 card-title mb-0">
                      <CountUp start={0} end={500} decimals={0} duration={2} />
                    </h2>
                  </div>
                  <div className="mt-2">
                    <Link className="fs-10 text-primary d-flex align-item-center">
                      <i className="bx bx-plus fs-14"></i> Crete Segment
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card
                className="border"
                style={{ height: "calc(100% - 1.5rem)" }}
              >
                <CardBody>
                  <div>
                    <h5 className="fs-14">Segments</h5>
                    <div className="d-flex flex-wrap gap-2 mt-3">
                      <span className="badge bg-primary text-white fw-semibold fs-9">
                        Promotions
                      </span>
                      <span className="badge bg-danger text-white fw-semibold fs-9">
                        Birthday
                      </span>
                      <span className="badge bg-success text-white fw-semibold fs-9">
                        Bank Promo
                      </span>
                      <span className="badge bg-warning text-white fw-semibold fs-9">
                        marketing Promo
                      </span>
                    </div>
                    <div className="mt-3">
                      <Link className="fs-10 text-primary d-flex align-item-center">
                        <i className="bx bx-plus fs-14"></i> Crete Segment
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card
                className="text-bg-primary bg-gradient"
                style={{ height: "calc(100% - 1.5rem)" }}
              >
                <CardBody className="bg-gradient">
                  <Row className="gap-1">
                    <Col md={12}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="fs-16 text-white fw-semibold">
                          Wallet
                        </span>
                        <i className="bx bx-chip h2 mb-0 text-white-75"></i>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="card-number d-flex flex-column">
                        <span className="fs-20 fw-bold">5000 AED</span>
                      </div>
                    </Col>
                    <Col md={12}>
                      <p className="fs-10">
                        AED <span>22</span> expire on 31 DEC 2023
                      </p>
                      <Link
                        to="/wallet/list/details"
                        className="fs-10 btn rounded-pill btn-light waves-effect px-2 py-1"
                      >
                        View Details
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card
                className="text-bg-dark gold"
                style={{ height: "calc(100% - 1.5rem)" }}
              >
                <CardBody className="bg-gradient text-dark">
                  <Row className="gap-1">
                    <Col md={12}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="fs-16 fw-semibold">Reward</span>
                        <img
                          src={barCode}
                          alt=""
                          style={{ maxHeight: 31, mixBlendMode: "multiply" }}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="card-number d-flex">
                        <span className="fs-20 fw-bold">5000 Pts</span>
                      </div>
                    </Col>
                    <Col md={12}>
                      <p className="fs-10">22 Nos expire on 31 DEC 2023</p>
                      <Link
                        to="/ahlan/members/details"
                        className="fs-10 btn rounded-pill btn-dark waves-effect px-2 py-1"
                      >
                        View Details
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card className="border">
            <CardBody>
              <div>
                <h5 className="fs-14">Most Engaged Categories</h5>
              </div>
              <MostEngagedCategories dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info", "--vz-warning", "--vz-danger"]' />
            </CardBody>
          </Card>
          <Card className="border">
            <CardBody>
              <div>
                <span className="fs-12 mb-1 text-muted d-block">
                  Average Visit
                </span>
                <h2 className="fs-24 card-title mb-0">
                  <CountUp start={0} end={8} decimals={0} duration={2} />
                  <span className="fs-12 mb-1 text-muted">/ Year</span>
                </h2>
              </div>
              <AverageVisit dataColors='["--vz-primary", "--vz-success"]' />
            </CardBody>
          </Card>
          <Card className="border">
            <CardBody>
              <div>
                <h5 className="fs-14">Devices Usage</h5>
              </div>
              <DevicesUsage dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]' />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
