import promoRestService from '../../services/PromoRestService';

export const uploadFile = async (data) => {
  try {
    const response = await promoRestService.post('api/v1/adm/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response?.status === 201)
      return {
        ...(response?.data || { success: false, data: null }),
      };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};
