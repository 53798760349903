import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { PROMOTION_API_SERVICE_BASE_URL, AUTH_TOKEN } from '../constants/index';
import { getItem, deleteItem } from '../helpers/cookies';
import { alertError, errorHandling } from '../helpers/errorHandling';

/**
 * SERVICE ENDPOINT
 */
const endpoint = `${PROMOTION_API_SERVICE_BASE_URL}graphql`;
const httpLink = createHttpLink({
  uri: endpoint,
});

/**
 * GQL CONTEXT
 */
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getItem(AUTH_TOKEN);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-apollo-operation-name': 'upload',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    for (const { message } of graphQLErrors) {
      try {
        errorHandling(message);
      } catch (error) {
        // Handle the error or log it
      }
    }
  }
  //
  if (networkError) {
    errorHandling(networkError);
  }

  // handle n/w errors
  if (networkError?.statusCode === 401 || networkError?.statusCode === 403) {
    deleteItem(AUTH_TOKEN);
  }

  // logout if the below error, status code  or error message coming...
  if (response && response.errors) {
    for (const error of response.errors) {
      if (
        error?.extensions?.code === 'UNAUTHENTICATED' ||
        error?.extensions?.response?.statusCode === 401 ||
        error?.extensions?.response?.statusCode === 403 ||
        error?.extensions?.response?.message === 'invalid token' ||
        error?.extensions?.response?.message === 'jwt expired' ||
        error?.extensions?.response?.message ===
          'User not found with the provided email.'
      ) {
        deleteItem(AUTH_TOKEN);
      }
    }
  }
});

const uploadLink = createUploadLink({
  uri: () => {
    const endpoint = `${PROMOTION_API_SERVICE_BASE_URL}graphql`;
    return endpoint;
  },
});

const cache = new InMemoryCache({
  addTypename: false,
});

export const ApolloClientPromoService = new ApolloClient({
  cache: cache,
  link: from([errorLink, authLink.concat(httpLink)]),
});

export const ApolloClientPromoUploadService = new ApolloClient({
  cache: cache,
  link: from([errorLink, authLink.concat(uploadLink)]),
});
