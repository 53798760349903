import React, { useState } from 'react';

const CheckboxBig = ({
  value,
  onChange,
  id,
  checked,
  title,
  description,
  icon,
}) => {
  return (
    <div className="col-auto customBox">
      <div className="form-check card-radio">
        <input
          id={id}
          name={id}
          type="radio"
          value={value}
          checked={checked}
          onChange={onChange}
          className="form-check-input"
        />
        <label className="form-check-label p-0   shadow-sm" for={id}>
          <div className="box">
            <div className="icon">
              <i className={`${icon} text-success`}></i>
            </div>
            <div className="content">
              <h3>{title}</h3>
              <p className="text-muted">{description}</p>
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};
export default CheckboxBig;
