import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import WalletTable from './WalletTable';
import Select from 'react-select';
import CreateCustomer from '../../../components/CreateCustomers/CreateCustomer';
import { BALANCE_OPTION, COUNTRIES } from '../../../constants';
import { WALLET_STATUS } from '../../../constants';
import WalletMembersExportBtn from '../WalletTransactions/components/WalletMembersExportBtn';
import Flatpickr from 'react-flatpickr';
import { format } from 'date-fns';

const WalletUsersList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState(null);
  const [countryFilter, setCountryFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [balanceOptionFilter, setBalanceOptionFilter] = useState(null);
  const [dateUpto, setDateUpto] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const flatpickrRef = useRef(null);

  const resetSearch = () => {
    setSearchFilter(null);
    setCountryFilter(null);
    setStatusFilter(null);
    setBalanceOptionFilter(null);
    setDateUpto(null);
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.setDate(null); // Clear the Flatpickr UI
    }
  };

  const handleCustomerCreated = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };
  document.title = 'Wallet';
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Wallet'} titleActive="Wallet Master" />
        <Card>
          <CardHeader className="border-0">
            <Row className="g-4 align-items-center">
              <Col sm></Col>
              <Col sm="auto">
                <CreateCustomer onCustomerCreated={handleCustomerCreated} />
              </Col>
            </Row>
          </CardHeader>
          {/* Filters and search */}
          <CardBody className="border border border-dashed card-body">
            <Row className="g-3">
              <Col className="d-flex flex-wrap align-items-start gap-2">
                <div className={'search-box me-2 mb-2 d-inline-block'}>
                  <Input
                    className="form-control search"
                    placeholder="Search by Wallet Id / mobile no"
                    type="text"
                    autoComplete="off"
                    value={searchFilter ?? ''}
                    onChange={(event) =>
                      setSearchFilter(event?.target?.value || '')
                    }
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
                <div>

                  <Select
                    id="admin_status"
                    placeholder="Select a Country"
                    options={COUNTRIES}
                    onChange={(option) => setCountryFilter(option)}
                  ></Select>
                </div>
                <div></div>
                <div>
                  <Flatpickr
                    ref={flatpickrRef}
                    className="form-control"
                    options={{
                      dateFormat: 'Y-m-d'
                    }}
                    placeholder="Balance Up to"
                    value={dateUpto}
                    onChange={(selectedDate) => {
                      // Format the date to "YYYY-MM-DD" and store it as a string
                      const formattedDate = format(selectedDate[0], 'yyyy-MM-dd');
                      setDateUpto(formattedDate);
                    }}
                  />

                </div>
                <div>
                  <Select
                    id="admin_status"
                    placeholder="Select a Status"
                    options={WALLET_STATUS}
                    onChange={(option) => setStatusFilter(option)}
                  ></Select>
                </div>
                <div>
                  <Select
                    id="balance_option"
                    placeholder="Select a Balance Option"
                    options={BALANCE_OPTION}
                    onChange={(option) => setBalanceOptionFilter(option)}
                  ></Select>
                </div>
                <div>


                  <Button
                    className="btn btn-outline  btn-light"
                    onClick={() => resetSearch()}
                  >
                    <i className="ri-catalog-fill me-1 align-bottom"></i>
                    Reset
                  </Button>

                  <Button color={`primary`} className="btn-load">
                    <span className="d-flex align-items-center">
                      <span className="flex-grow-1 me-2">
                        <i className="ri-search-fill me-1 align-bottom"></i>
                        Search
                      </span>
                      {isLoading && (
                        <Spinner
                          size="sm"
                          className="flex-shrink-0"
                          role="status"
                        >
                          Loading...
                        </Spinner>
                      )}
                    </span>
                  </Button>
                </div>
                <div className="ms-auto">
                  <WalletMembersExportBtn
                    search={searchFilter}
                    country={countryFilter?.value}
                    status={statusFilter?.value}
                    balanceOption={balanceOptionFilter?.value}
                    dateUpto={dateUpto}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="border">
            <Row className="g-3">
              <Col>
                <WalletTable
                  refreshKey={refreshKey}
                  search={searchFilter}
                  country={countryFilter?.value}
                  status={statusFilter?.value}
                  balanceOption={balanceOptionFilter?.value}
                  setIsLoading={setIsLoading}
                  dateUpto={dateUpto}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default WalletUsersList;
