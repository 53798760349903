export const formatTime = (dateProp, type = "default") => {
  const date = new Date(dateProp);

  if (type === "default") {
    return new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  }
};
