import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

const CustomCombinatorSelector = ({ value, options, handleOnChange }) => {
  const labels = { or: 'Any of the following', and: 'All of the following' };
  return (
    <UncontrolledDropdown>
      <DropdownToggle className="btn btn-sm shadow-none p-0" color="label">
        {labels[value]}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((option) => (
          // <option key={option.name} value={option.name}>
          //   {option.label}
          // </option>
          <DropdownItem
            value={option.name}
            key={option.name}
            onClick={() => {
              handleOnChange(option.value);
            }}
          >
            {option?.label}
          </DropdownItem>
        ))}
        {/* {effectTypes?.map((item, index) => {
        if (!usedEffects.includes(item.value)) {
          return (
            <DropdownItem
              value={item?.value}
              key={`et-${index}`}
              onClick={() => {
                handleAddEffect(item?.value);
              }}
            >
              {item?.label}
            </DropdownItem>
          );
        }
      })} */}
        {/* <DropdownItem>Transactions</DropdownItem> */}
        {/* <DropdownItem>Reward Points</DropdownItem>
      <DropdownItem>Wallet</DropdownItem>
      <DropdownItem>Gift Voucher</DropdownItem>
      <DropdownItem>Coupons</DropdownItem> */}
      </DropdownMenu>
    </UncontrolledDropdown>
    // <select value={value} onChange={(e) => handleOnChange(e.target.value)}>
    //   {options.map((option) => (
    //     <option key={option.name} value={option.name}>
    //       {option.label}
    //     </option>
    //   ))}
    // </select>
  );
};

export default CustomCombinatorSelector;
