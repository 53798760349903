import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    UncontrolledTooltip,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import { generateWalletMembersListReport } from '../../../../actions/wallet';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const WalletMembersExportBtn = (props) => {
    const { status, country = 'ae', search, balanceOption, dateUpto } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [modal_backdrop, setmodal_backdrop] = useState(false);
    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
    }

    const handleWalletMemberExport = () => {
        getWalletMembersFunc();
    };

    const getWalletMembersFunc = async () => {
        setIsLoading(true);
        const data = {
            country: country,
            exportType: 'walletmembers',
            status: status || '',
            search: search || '',
            balanceOption: balanceOption || "all",
            dateUpto: dateUpto || ''

        };

        const res = await generateWalletMembersListReport(data);
        if (res?.data?.statusCode && res?.data?.statusCode === 200) {
            tog_backdrop();
        } else {
            toast.error(res?.message);
        }
        setIsLoading(false);
    };

    return (
        <>
            <Button
                color="secondary"
                outline
                className="shadow-none py-0 lh-1 d-flex align-items-center justify-content-center"
                style={{ height: 38 }}
                // disabled={dateRange.length < 2}
                onClick={handleWalletMemberExport}
            >
                <i className="ri-file-transfer-line fs-18 me-2"></i> Export All
                {/* {dateRange.length === 2 ? (
                    <i className="ri-file-transfer-line fs-18 me-2"></i>
                ) : null}{' '}
               
                {dateRange.length < 2 ? (
                    <i
                        className="ri-information-line fs-18 ms-2"
                        style={{ pointerEvents: 'all' }}
                        id="exportTooltip"
                    ></i>
                ) : null} */}
            </Button>

            {/* {dateRange.length < 2 ? (
                <UncontrolledTooltip placement="left" target="exportTooltip">
                    <p className="p-2 mb-0">
                        Please select a date range to export data. The maximum supported
                        date range is 1 month.
                    </p>
                </UncontrolledTooltip>
            ) : null} */}

            <Modal
                isOpen={modal_backdrop}
                toggle={() => {
                    tog_backdrop();
                }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
            >
                <ModalHeader
                    className="modal-title"
                    id="rolesAndPermissions"
                    toggle={() => {
                        tog_backdrop();
                    }}
                ></ModalHeader>
                <ModalBody className="text-center pb-5">
                    <div className="bg-success-subtle rounded-circle d-inline-flex align-items-center justify-content-center p-3">
                        <i
                            className="mdi mdi-file-check text-success lh-1"
                            style={{ fontSize: '7cqmin' }}
                        ></i>
                    </div>

                    <div className="mt-4">
                        <h4 className="mb-3">The download link has been sent.</h4>
                        <p className="text-muted mb-0">
                            Your export request is being processed. You will receive an email
                            to the address you are logged in with when it is ready.
                        </p>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default WalletMembersExportBtn;
