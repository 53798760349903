import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

const UserDetails = ({ member, configs = {}, rewards = [] }) => {
  document.title = `Profile - ${member?.[configs?.FirstName]} ${
    member?.[configs?.LastName]
  }`;
  console.log('rewardsrewardsrewards', rewards);
  return (
    <>
      <div className="text-center">
        <div className="profile-user position-relative d-inline-block mx-auto mb-4">
          <div className="flex-shrink-0 align-self-center me-2">
            <div className="avatar-md fs-22">
              <span className="avatar-title rounded-circle text-uppercase"></span>
            </div>
          </div>
        </div>
        <h5 className="fs-16 mb-1">
          {member?.[configs?.FirstName]} {member?.[configs.LastName]}
        </h5>
        <button
          type="button"
          className="btn rounded-pill btn-primary waves-effect mt-1 mb-3 py-1 px-2 fs-10"
        >
          Edit Details
        </button>
        <span className="fs-10 mb-1 text-muted d-block">
          Last Updated at - Tue 23 Jan, 08:00 AM
        </span>
      </div>
      <hr />
      <div className="mt-3">
        <p>
          <span className="fs-10 mb-1 text-muted d-block">E-mail Address</span>
          {member?.[configs?.Email]}
        </p>
        <p>
          <span className="fs-10 mb-1 text-muted d-block">Phone Number</span>
          <span className="d-flex align-items-center">
            {member?.[configs?.MobileNumber]}
            <span className="badge rounded-pill  fw-normal mx-1 bg-success">
              <i className="bx bx-check-double"></i>Verified
            </span>
          </span>
        </p>
        <p>
          <span className="fs-10 mb-1 text-muted d-block">Country</span>
          {member?.[configs.country]}
        </p>
        {rewards?.map((reward) => {
          return (
            <p>
              <span className="fs-10 mb-1 text-muted d-block">
                {reward.name}
              </span>
              <span className="d-flex align-items-center gap-2">
                {get(member, 'ahlanUAE.id')}
                <span className="fs-10 my-1 badge gold text-muted">
                  Gold Member
                </span>
              </span>
            </p>
          );
        })}

        <p>
          <span className="fs-10 mb-1 text-muted d-block">
            Wallet Card Number
          </span>
          <span className="d-flex align-items-center gap-2">4123509876</span>
        </p>
        <p>
          <span className="fs-10 mb-1 text-muted d-block">Joining Date</span>
        </p>
        <p>
          <span className="fs-10 mb-1 text-muted d-block">Created at</span>
          Danube Home, ICT Store
        </p>
        <p>
          <span className="fs-10 mb-1 text-muted d-block">Sign Up Type</span>
          <span className="d-flex gap-2">
            <span className="d-flex gap-1 badge bg-dark-subtle text-body fs-11">
              <i class="ri-google-fill"></i> google
            </span>
            <span className="d-flex gap-1 badge bg-dark-subtle text-body fs-11">
              <i class="ri-facebook-fill"></i> Facebook
            </span>
            <span className="d-flex gap-1 badge bg-dark-subtle text-body fs-11">
              <i class="ri-apple-fill"></i> Apple
            </span>
          </span>
        </p>
      </div>
      <hr />
      <div className="mt-3">
        <div className="mb-3">
          <img
            src="https://media.wired.com/photos/59269cd37034dc5f91bec0f1/191:100/w_1280,c_limit/GoogleMapTA.jpg"
            alt=""
            width="100%"
            height="auto"
          />
        </div>
        <p className="mb-0">
          <span className="fs-10 mb-1 text-muted d-block">Address</span>
          B16 Flat No 203 7CR7+4F8 - Al Qusais Industrial Area - Al Qusais
          Industrial Area 5 - Dubai - United Arab Emirates
        </p>
      </div>
    </>
  );
};

export default UserDetails;
