import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { getGreeting } from '../../helpers/general';
import { useDispatch, useSelector } from 'react-redux';

const Section = (props) => {
  const { dashboard } = useSelector((state) => state?.dashboard || {});
  const dispatch = useDispatch();
  const { admin: adminState } = useSelector((state) => state.admin || {});
  const { firstName, lastName } = adminState || {};
  useEffect(() => {
    if (!dashboard.widgets) {
      fetchDashboard();
    }
  }, []);
  const fetchDashboard = useCallback(async () => {
    try {
      // await getDashboardData(dispatch);
    } catch (error) {
      console.error('Failed to fetch ', error);
    }
  }, []);
  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">
                {getGreeting()}, {firstName} {lastName}!
              </h4>
              <p className="text-muted mb-0">
                Welcome to Danube Home e-Wallet.
              </p>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-sm-auto">
                    <div className="input-group">
                      <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        options={{
                          mode: 'range',
                          dateFormat: 'd M, Y',
                          defaultDate: ['01 Jan 2022', '31 Jan 2022'],
                        }}
                      />
                      <div className="input-group-text bg-primary border-primary text-white">
                        <i className="ri-calendar-2-line"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <button
                      onClick={() => fetchDashboard()}
                      type="button"
                      className="btn btn-soft-success"
                    >
                      <i className="ri-pulse-line align-middle me-1"></i>{' '}
                      Refresh Analytics
                    </button>
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
