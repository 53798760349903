import React, { useEffect } from 'react';
import {
  Col,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Label,
  Form,
} from 'reactstrap';
import { COUNTRIES } from '../../../constants/index';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { createOrUpdateReward } from '../../../actions/rewards';
import { useNavigate } from 'react-router-dom';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';

const RewardRedemptionRulesForm = ({ reward, submitHandler = () => {} }) => {
  const navigate = useNavigate();

  const redemptionRulesValidationSchema = Yup.object().shape({
    redemptionRules: Yup.array().of(
      Yup.object().shape({
        countryCode: Yup.string()
          .required('Country code is required')
          .length(2, 'Country code must be exactly 2 characters')
          .typeError('Country code must be a string'),
        minPointsRequiredToRedeem: Yup.number()
          .required('Minimum points required to redeem is required')
          .min(1, 'Minimum points must be greater than 0')
          .typeError('Minimum points must be a number'),
        maxPointsPerTxn: Yup.number().when('isMaxPointsLimitEnabled', {
          is: true,
          then: Yup.number()
            .required('Maximum points per transaction is required when enabled')
            .min(1, 'Maximum points must be greater than 0')
            .typeError('Maximum points must be a number'),
          otherwise: Yup.number().optional(),
        }),
        isMaxPointsLimitEnabled: Yup.boolean().optional(),
      })
    ),
  });

  const initialValues = {
    redemptionRules: COUNTRIES?.map((country) => {
      return {
        countryCode: country?.value,
        minPointsRequiredToRedeem: null,
        maxPointsPerTxn: 0,
        isMaxPointsLimitEnabled: false,
      };
    }),
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: redemptionRulesValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const inputData = {
          _id: reward?._id,
          ...values,
        };

        const { success, message, data } = await createOrUpdateReward(
          inputData
        );

        if (success) {
          alertSuccess(message);
          navigate(`/reward-types/detail/${data?._id}/media`);
          submitHandler();
        } else {
          alertError({ message: message });
        }

        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
  });

  useEffect(() => {
    if (reward?.redemptionRules?.length) {
      formik?.setValues({
        redemptionRules: reward?.redemptionRules,
      });
    } else {
      formik?.setValues({ ...initialValues });
    }
  }, [reward]);

  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    values,
    isSubmitting,
    setFieldValue,
  } = formik;

  console.log(values, 'values');
  console.log(errors, 'errors');

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        {/* <h4 class="fs-14 fw-medium mb-3 card-title">Set Redemption Points</h4> */}
        <Row>
          {values?.redemptionRules?.map((item, index) => {
            let country = COUNTRIES?.find((itm) => {
              return item?.countryCode === itm?.value;
            });
            return (
              <Col md={6} xl={4} xxl={3} className="mb-3">
                <div className="p-3 pb-0 bg-light border rounded border-soft-dark">
                  <Row className="mb-0">
                    <Col md="12">
                      <p className="mb-3 pb-2 border-bottom">
                        <strong>{country?.label}</strong>
                      </p>
                    </Col>
                    <Col md="12">
                      <Label>Min. Points require to redeem</Label>
                      <FormGroup>
                        <InputGroup>
                          <Input
                            type="number"
                            name={`redemptionRules.${index}.minPointsRequiredToRedeem`}
                            id={`redemptionRules.${index}.minPointsRequiredToRedeem`}
                            placeholder="Enter point"
                            min={0}
                            value={
                              values?.redemptionRules?.[index]
                                ?.minPointsRequiredToRedeem
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              touched?.redemptionRules?.[index]
                                ?.minPointsRequiredToRedeem &&
                              !!errors?.redemptionRules?.[index]
                                ?.minPointsRequiredToRedeem
                            }
                          />
                          <InputGroupText className="rounded-end">
                            Points
                          </InputGroupText>
                          <FormFeedback>
                            {
                              errors?.redemptionRules?.[index]
                                ?.minPointsRequiredToRedeem
                            }
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <div className="form-check form-switch form-switch-md form-switch-secondary mb-3">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          name={`redemptionRules.${index}.isMaxPointsLimitEnabled`}
                          checked={
                            values?.redemptionRules?.[index]
                              ?.isMaxPointsLimitEnabled
                          }
                          onChange={handleChange}
                        />
                        <Label
                          className="form-check-label "
                          htmlFor="SwitchCheck2"
                        >
                          Set Max. Limits per transaction
                        </Label>
                      </div>
                      {values?.redemptionRules?.[index]
                        ?.isMaxPointsLimitEnabled ? (
                        <FormGroup>
                          <InputGroup>
                            <Input
                              type="number"
                              name={`redemptionRules.${index}.maxPointsPerTxn`}
                              id={`redemptionRules.${index}.maxPointsPerTxn`}
                              placeholder="Enter point"
                              min={0}
                              value={
                                values?.redemptionRules?.[index]
                                  ?.maxPointsPerTxn
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={
                                touched?.redemptionRules?.[index]
                                  ?.maxPointsPerTxn &&
                                !!errors?.redemptionRules?.[index]
                                  ?.maxPointsPerTxn
                              }
                            />
                            <InputGroupText className="rounded-end">
                              Points
                            </InputGroupText>
                            <FormFeedback>
                              {
                                errors?.redemptionRules?.[index]
                                  ?.maxPointsPerTxn
                              }
                            </FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      ) : null}
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          })}
        </Row>

        <Row>
          <Col>
            <hr />
            <div className="d-flex gap-2 justify-content-end">
              <Button outline color="danger" size="md">
                Cancel
              </Button>

              <Button
                type="submit"
                color="primary"
                size="md"
                className="bg-gradient px-5"
              >
                Save & Next
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  );
};

export default RewardRedemptionRulesForm;
