import { gql } from '@apollo/client';

/**
 * GET USER REPORTS LIST
 */

export const USER_REPORTS_DATA = gql`
  query USER_REPORTS_DATA($pagination: PaginationInput!) {
    exportReportList(pagination: $pagination) {
      items {
        _id
        fileName
        filePath
        expiryDate
        country
        additionalInfo
        createdAt
        createdBy
      }
      pagination {
        currentPage
        totalPages
        currentItem
        totalItems
      }
    }
  }
`;
