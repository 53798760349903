import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { getWallets } from '../../../actions/wallet';
import Pagination from '../../../components/Common/Pagination';
import moment from 'moment';
const NoRecordsMessage = () => {
  return (
    <tr>
      <td colSpan="8" className="text-center py-4">
        <h5 className="fs-13 my-1 fw-normal">No wallets found.</h5>
      </td>
    </tr>
  );
};

const WalletTable = (props) => {
  const {
    refreshKey,
    search,
    setIsLoading,
    country,
    status,
    balanceOption,
    dateUpto,
  } = props;
  const [wallets, setWallets] = useState([]);
  const [pageOffset, setPageOffset] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [isDataLoader, setIsDataLoader] = useState(false);

  useEffect(() => {
    getWalletFunc();
  }, [
    pageOffset,
    refreshKey,
    search,
    country,
    status,
    balanceOption,
    dateUpto,
  ]);

  const pages = [...Array(totalPages)?.keys()];

  const getWalletFunc = async () => {
    setIsLoading(true);
    setIsDataLoader(true);
    const data = {
      country: country || 'all',
      status: status,
      balanceOption,
      dateUpto,
      page: pageOffset,
      pageSize: pageSize,
      search: search || '',
      sortBy: 'createdAt',
      sortOrder: 'DESC',
    };

    const res = await getWallets(data);
    if (res?.success) {
      setWallets(res?.data?.data);
      setTotalPages(res?.data?.pageInfo?.totalPages);
      setTotalItems(res?.data?.pageInfo?.totalItems);
    } else {
      setWallets();
    }

    setIsLoading(false);
    setIsDataLoader(false);
  };

  return (
    <>
      {isDataLoader ? (
        <div className="w-100 h-100 position-fixed top-0 start-0 bg-dark bg-opacity-25 z-3">
          <div className="position-absolute top-50 start-50">
            <Spinner color="dark">Loading...</Spinner>
          </div>
        </div>
      ) : null}

      <div className="table-responsive table-card">
        <table className="table table-nowrap table-centered align-middle">
          <thead className="bg-light text-muted">
            <tr>
              <th></th>
              <th>Created Date</th>
              <th>Wallet Id</th>
              <th>Customer Details</th>
              <th>Country</th>
              <th>Available Balance</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {wallets.length === 0 ? (
              <NoRecordsMessage />
            ) : (
              (wallets || []).map((wallet, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div>
                        <h5 className="fs-13 my-1">{index + 1}</h5>
                      </div>
                    </div>
                  </td>
                  <td>
                    <h5 className="fs-13 my-1 fw-normal">
                      {moment(wallet?.createdAt).format(
                        'DD MMMM YYYY hh:mm:ss A'
                      )}
                    </h5>
                    <span className="fs-10 text-muted">
                      Last Updated at -{' '}
                      {moment(wallet?.updatedAt).format(
                        'DD MMMM YYYY hh:mm:ss A'
                      )}
                    </span>
                  </td>
                  <td>
                    <h5 className="fs-13 my-1 fw-normal">{wallet.uniqueId}</h5>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div>
                        <h5 className="fs-13 my-1">
                          {wallet.firstName} {wallet.lastName}
                        </h5>
                        <span className="fs-12 text-muted">
                          Mob : {wallet.mobileNumber}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <h5 className="fs-13 my-1 fw-normal">{wallet.country}</h5>
                  </td>
                  <td>
                    <h5 className="fs-13 my-1 fw-semiBold text-right">
                      {wallet.currency}{' '}
                      {Number(
                        wallet.balance.redeemableAmount.toFixed(2)
                      ).toLocaleString()}
                    </h5>
                  </td>
                  <td>
                    <h5
                      className={`fs-11 my-1 fw-normal badge badge-${
                        wallet.status === 1
                          ? 'soft-success'
                          : wallet.status === 0
                          ? 'soft-danger'
                          : ''
                      }`}
                    >
                      {wallet?.status === 1 ? 'Active' : 'Inactive'}
                    </h5>
                  </td>
                  <td>
                    <Link
                      to={`/wallet/list/details/${wallet.country}/${wallet.mobileNumber}`}
                      className="rounded-pill btn btn-light py-1 fs-11"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        totalPages={totalPages}
        totalItems={totalItems}
        currentPage={1}
        onPageChange={setPageOffset}
      />
    </>
  );
};

export default WalletTable;
