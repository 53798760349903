export const formatCurrency = (value, country) => {
    if (value === 0) return ''; // Return an empty string for zero

    // Determine the currency code based on the country
    let currencyCode;
    switch (country) {
        case 'ae':
            currencyCode = 'AED';
            break;
        case 'om':
            currencyCode = 'OMR';
            break;
        case 'bh':
            currencyCode = 'BHD';
            break;
        case 'qa':
            currencyCode = 'QAR';
            break;
        default:
            currencyCode = 'USD'; // Default currency if country is not matched
            break;
    }

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
    }).format(value);
};
