import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Row,
  Col,
  CardBody,
  ModalFooter,
} from 'reactstrap';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { createUpdateBasicSettings } from '../../actions/promotion';
import { alertError, alertSuccess } from '../../helpers/errorHandling';

export const COUNTRIES = ['OMAN', 'BAHRAIN', 'QATAR', 'UAE'];

const tagsGlobal = ['Points', 'Transactional'];

const PromotionBasicSettings = ({
  promotionId,
  promotionDetail = null,
  onCancel = () => {},
  updateHandler = () => {},
}) => {
  const isCreating = false;
  const navigate = useNavigate();

  const StoreSchema = Yup.object().shape({
    promotionName: Yup.string()
      .max(100, 'Promotion Name must be at most 100 characters')
      .min(10, 'Promotion Name must be at least 10 characters')
      .required('Promotion Name is required')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Only letters, numbers, and spaces are allowed'
      ),
    promotionDescription: Yup.string().max(500).min(5),
    country: Yup.array()
      .min(1, 'At least 1 country is required')
      .required('Country is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      promotionName: promotionDetail?.basicSettings?.promotionName || '',
      promotionDescription:
        promotionDetail?.basicSettings?.promotionDescription || '',
      country: promotionDetail?.basicSettings?.country || [],
      promotionType:
        promotionDetail?.promotionType || 'PROMOTION_TYPE_TRANSACTIONAL',
      tags: promotionDetail?.basicSettings?.tags || [],
      discard: promotionDetail?.basicSettings?.discard || 'except_individual',
      alwaysRun: promotionDetail?.basicSettings?.alwaysRun || false,
    },

    validationSchema: StoreSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let success;
        let message;
        let error;
        if (promotionId) {
          const inputData = {
            ...values,
          };
          const {
            success: createStoreSuccess,
            message: createMessage,
            error: gqError,
          } = await createUpdateBasicSettings(values, promotionId);
          success = createStoreSuccess;
          if (success && promotionId) {
            navigate(`/promotions/detail/${promotionId}/schedule`);
          }
          error = gqError;
        } else {
          const {
            success: createStoreSuccess,
            id,
            message: createMessage,
            error: gqError,
          } = await createUpdateBasicSettings(values);
          success = createStoreSuccess;
          if (success && id) {
            navigate(`/promotions/detail/${id}/schedule`);
          }
          error = gqError;
        }
        if (success) {
          updateHandler();
          alertSuccess(
            isCreating
              ? 'Promotion Basic Settings created successfully.'
              : 'Promotion Basic Settings updated successfully.'
          );
        } else {
          alertError('Failed!. Please try again later..');
        }

        setSubmitting(false);
        onCancel();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values: {
      country,

      tags,
      discard,
      alwaysRun,
    },
  } = formik;

  return (
    <CardBody className="p-4">
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="promotion-name">Promotion Name</Label>
                    <Input
                      id="promotion-name"
                      name="promotionName"
                      placeholder="Enter Promotion Name"
                      {...getFieldProps('promotionName')}
                      invalid={
                        touched.promotionName && Boolean(errors.promotionName)
                      }
                    />
                    <FormFeedback>
                      {touched.promotionName && errors.promotionName}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="promotion-description">
                      Promotion Description
                    </Label>
                    <Input
                      type="textarea"
                      id="promotion-description"
                      placeholder="Enter Promotion Description"
                      {...getFieldProps('promotionDescription')}
                      invalid={
                        touched.promotionDescription &&
                        Boolean(errors.promotionDescription)
                      }
                    />
                    <FormFeedback>
                      {touched.promotionDescription &&
                        errors.promotionDescription}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="country">Choose Countries</Label>
                    <Select
                      id="country"
                      isMulti
                      options={COUNTRIES?.map((country) => ({
                        value: country,
                        label: country,
                      }))}
                      value={country?.map((country) => ({
                        value: country,
                        label: country,
                      }))}
                      onChange={(selected) => {
                        const selectedValues = selected
                          ? selected.map((item) => item.value)
                          : [];
                        setFieldValue('country', selectedValues);
                      }}
                      classNamePrefix="select"
                      invalid={touched.country && Boolean(errors.country)}
                    />

                    {touched?.country && errors?.country && (
                      <FormFeedback className="d-block">
                        {errors?.country}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label for="tags">Tags</Label>
                    <CreatableSelect
                      id="tags"
                      isMulti
                      options={tagsGlobal.map((tag) => ({
                        value: tag,
                        label: tag,
                      }))}
                      value={tags.map((tag) => ({
                        value: tag,
                        label: tag,
                      }))}
                      onChange={(selected) => {
                        const selectedValues = selected
                          ? selected.map((item) => item.value)
                          : [];
                        setFieldValue('tags', selectedValues);
                      }}
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>

          {!promotionId?.includes('__v') ? (
            <ModalFooter className="sticky-modal-footer">
              <hr />
              <div className="d-flex gap-2 justify-content-end">
                <Button outline color="danger" size="md" onClick={onCancel}>
                  Cancel
                </Button>

                <Button
                  loading={false}
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  size="md"
                  className="bg-gradient px-5"
                >
                  {promotionDetail?.promotionName
                    ? 'Save Draft'
                    : 'Save & Next'}
                </Button>
              </div>
            </ModalFooter>
          ) : null}
        </Form>
      </FormikProvider>
    </CardBody>
  );
};

PromotionBasicSettings.propTypes = {
  promotion: PropTypes.any,
  onCancel: PropTypes.func,
  updateHandler: PropTypes.func,
};

export default PromotionBasicSettings;
