import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import withRouter from '../../components/Common/withRouter';
import { ROUTE_USER_LOGIN } from '../../constants/route';
import { deleteItem } from '../../helpers/cookies';
import { AUTH_TOKEN } from '../../constants';
import { setUser } from '../../slices/auth/reducer';
/**
 *
 * @returns
 */
const Logout = () => {
  //
  const dispatch = useDispatch();

  //
  useEffect(() => {
    dispatch(setUser({}));
    deleteItem(AUTH_TOKEN);
  }, []);
  return <Navigate to={ROUTE_USER_LOGIN} />;
};

export default withRouter(Logout);
