import React from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

const RecentTransaction = () => {
  const RecentTransaction = [
    {
      id: '1',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      walletId: '21213323232',
      description: 'Return Credit',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ AED 500.00',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
    },
    {
      id: '2',
      date: 'Tue 19 Dec',
      time: '08:20 AM',
      transactionId: '13232325444',
      walletId: '2333223232',
      description: 'Order Payment',
      descriptionNote: 'Store: danubehome.com, Ref: SO123476',
      amount: '- AED 100.00',
      transactionType: 'Debited',
      feaIconClass: 'text-danger',
      feaIcon: 'arrow-up-right',
    },
    {
      id: '3',
      date: 'Tue 19 Dec',
      time: '08:30 AM',
      transactionId: '13232325444',
      walletId: '2333223232',
      description: 'Gift Card Redeem',
      descriptionNote: 'Store: Itc, Ref: SO129786',
      amount: '+ AED 500.00',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
    },
    {
      id: '4',
      date: 'Tue 19 Dec',
      time: '08:31 AM',
      transactionId: '13232325444',
      walletId: '2333223232',
      description: 'Marketing Promotion',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ AED 1000.00',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
    },
    {
      id: '5',
      date: 'Tue 19 Dec',
      time: '08:36 AM',
      transactionId: '13232325444',
      walletId: '2333223232',
      description: 'Ahlan Redeem',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ AED 50.20',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
    },
  ];
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Recent Transactions</h4>
          <div className="flex-shrink-0">
            <UncontrolledDropdown
              className="card-header-dropdown"
              direction="start"
            >
              <DropdownToggle tag="a" className="text-reset" role="button">
                <span className="fw-semibold text-uppercase fs-12">
                  Sort by:{'  '}
                </span>
                <span className="text-muted">
                  All <i className="mdi mdi-chevron-down ms-1"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem>All</DropdownItem>
                <DropdownItem>Yesterday</DropdownItem>
                <DropdownItem>Paid</DropdownItem>
                <DropdownItem>Pending</DropdownItem>
                <DropdownItem>Canaled</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive table-card">
            <table className="table table-hover table-centered align-middle table-nowrap mb-0">
              <thead>
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>Transaction ID</th>
                  <th>Wallet ID</th>
                  <th>Description</th>
                  <th className="text-right">Amount</th>
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {(RecentTransaction || []).map((item, key) => (
                  <tr key={key}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div>
                          <h5 className="fs-13 my-1">{item.id}</h5>
                        </div>
                      </div>
                    </td>
                    <td>
                      <h5 className="fs-13 my-1 fw-normal">{item.date}</h5>
                      <span className="fs-10 text-muted">{item.time}</span>
                    </td>
                    <td>
                      <h5 className="fs-13 my-1 fw-normal">
                        #{item.transactionId}
                      </h5>
                    </td>
                    <td>
                      <h5 className="fs-13 my-1 fw-normal">#{item.walletId}</h5>
                    </td>
                    <td>
                      <div className="d-flex">
                        <span className={`${item?.feaIconClass}`}>
                          <FeatherIcon
                            icon={item?.feaIcon}
                            style={{ width: 18, marginRight: 10 }}
                          />
                        </span>
                        <div>
                          <h5 className="fs-13 my-1 fw-normal">
                            {item.description}
                          </h5>
                          <span className="fs-10 text-muted">
                            {item.descriptionNote}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <h5
                        className={`${item?.feaIconClass} fs-13 my-1 fw-semiBold text-right`}
                      >
                        {item.amount}
                      </h5>
                    </td>
                    {/* <td className="text-right">
                      <h5 className="fs-13 my-1 fw-normal d-flex align-items-center justify-content-end">
                        
                        <span style={{ width: 70 }}>
                          {item.transactionType}
                        </span>
                      </h5>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
            <div className="col-sm">
              <div className="text-muted">
                Showing <span className="fw-semibold">5</span> of{' '}
                <span className="fw-semibold">25</span> Results
              </div>
            </div>
            <div className="col-sm-auto mt-3 mt-sm-0">
              <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                <li className="page-item disabled">
                  <Link to="#" className="page-link">
                    ←
                  </Link>
                </li>
                <li className="page-item">
                  <Link to="#" className="page-link">
                    1
                  </Link>
                </li>
                <li className="page-item active">
                  <Link to="#" className="page-link">
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link to="#" className="page-link">
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link to="#" className="page-link">
                    →
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RecentTransaction;
