import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Row,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Form,
  InputGroupText,
  InputGroup,
  Card,
  CardBody,
} from 'reactstrap';
import { createOrUpdateTeam } from '../../../actions/promotion/team';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';

const CreateTeamForm = ({ submitHandler, team = null, closeHandler }) => {
  const initialValues = {
    teamName: '',
    monthlyBudgets: {
      january: 0,
      february: 0,
      march: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
    },
    yearlyBudget: 0,
    budgetDistribution: 'equal',
  };

  const validationSchema = Yup?.object()?.shape({
    teamName: Yup?.string()?.required('Team name is required'),
    monthlyBudgets: Yup?.object()?.shape({
      january: Yup?.number()?.required('Required')?.min(0, 'Must be positive'),
      february: Yup?.number()?.required('Required')?.min(0, 'Must be positive'),
      march: Yup?.number()?.required('Required')?.min(0, 'Must be positive'),
      april: Yup?.number()?.required('Required')?.min(0, 'Must be positive'),
      may: Yup?.number()?.required('Required')?.min(0, 'Must be positive'),
      june: Yup?.number()?.required('Required')?.min(0, 'Must be positive'),
      july: Yup?.number()?.required('Required')?.min(0, 'Must be positive'),
      august: Yup?.number()?.required('Required')?.min(0, 'Must be positive'),
      september: Yup?.number()
        ?.required('Required')
        ?.min(0, 'Must be positive'),
      october: Yup?.number()?.required('Required')?.min(0, 'Must be positive'),
      november: Yup?.number()?.required('Required')?.min(0, 'Must be positive'),
      december: Yup?.number()?.required('Required')?.min(0, 'Must be positive'),
    }),
    yearlyBudget: Yup?.number()
      ?.required('Yearly budget is required')
      ?.min(0, 'Must be positive'),
    budgetDistribution: Yup?.string()?.required(
      'Budget distribution method is required'
    ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const inputData = {
          ...values,
        };

        const { success, message } = await createOrUpdateTeam(inputData);

        if (success) {
          alertSuccess(message);
          submitHandler();
        } else {
          alertError({ message });
        }

        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
  });

  useEffect(() => {
    if (team) {
      formik?.setValues({
        teamName: team?.teamName,
        teamId: team?._id,
        yearlyBudget: team?.yearlyBudget,
        monthlyBudgets: team?.monthlyBudgets,
        budgetDistribution: 'manual',
      });
    } else {
      formik?.setValues({ ...initialValues });
    }
  }, [team]);

  const distributeYearlyBudgetEqually = () => {
    try {
      const budgetPerMonth = formik.values.yearlyBudget / 12;
      const updatedMonthlyBudgets = Object.keys(
        formik.values.monthlyBudgets
      ).reduce((acc, month) => {
        acc[month] = budgetPerMonth;
        return acc;
      }, {});
      formik.setValues({
        ...formik.values,
        monthlyBudgets: updatedMonthlyBudgets,
      });
    } catch (error) {}
  };

  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    values,
    isSubmitting,
  } = formik;

  useEffect(() => {
    try {
      if (values?.budgetDistribution !== 'equal') {
        const totalBudget = Object.values(values?.monthlyBudgets).reduce(
          (sum, value) => {
            if (typeof value === 'number' && value > 0) {
              return sum + value;
            }
            return sum;
          },
          0
        );

        formik.setFieldValue('yearlyBudget', totalBudget);
      }
    } catch (error) {
      console.log(error);
    }
  }, [values?.monthlyBudgets]);

  useEffect(() => {
    if (values?.budgetDistribution === 'equal' && values?.yearlyBudget > 0) {
      distributeYearlyBudgetEqually();
    }
  }, [values?.yearlyBudget, values?.budgetDistribution]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="teamName">Team Name</Label>
                <Input
                  id="teamName"
                  name="teamName"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.teamName}
                  invalid={!!errors?.teamName && touched?.teamName}
                />
                <FormFeedback>
                  {touched?.teamName && errors?.teamName}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="yearlyBudget">Yearly Budget</Label>
                <InputGroup>
                  <Input
                    id="yearlyBudget"
                    name="yearlyBudget"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.yearlyBudget}
                    invalid={!!errors?.yearlyBudget && touched?.yearlyBudget}
                  />
                  <InputGroupText>AED</InputGroupText>
                </InputGroup>
                <FormFeedback>
                  {touched?.yearlyBudget && errors?.yearlyBudget}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Card className="border-1">
            <CardBody>
              <FormGroup>
                <Label>Monthly Budget Distribution</Label>

                <div className="d-flex align-items-center gap-3">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="budgetDistribution"
                        value="equal"
                        checked={formik.values.budgetDistribution === 'equal'}
                        onChange={handleChange}
                      />
                      Distribute yearly budget Equally
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="budgetDistribution"
                        value="manual"
                        checked={formik.values.budgetDistribution === 'manual'}
                        onChange={handleChange}
                        invalid={!!formik?.errors?.budgetDistribution}
                      />
                      Enter Manually
                    </Label>
                    <FormFeedback>
                      {formik.errors.budgetDistribution}
                    </FormFeedback>
                  </FormGroup>
                </div>
              </FormGroup>
              <Row form>
                {Object?.keys(values?.monthlyBudgets)?.map((month) => (
                  <Col md={6} key={month}>
                    <FormGroup>
                      <Label for={month}>
                        {month?.charAt(0)?.toUpperCase() + month?.slice(1)}
                      </Label>
                      <InputGroup>
                        <Input
                          id={month}
                          name={`monthlyBudgets.${month}`}
                          type="number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values?.monthlyBudgets?.[month]}
                          disabled={values.budgetDistribution === 'equal'}
                          invalid={
                            !!errors?.monthlyBudgets?.[month] &&
                            touched?.monthlyBudgets?.[month]
                          }
                        />
                        <InputGroupText>AED</InputGroupText>
                      </InputGroup>

                      <FormFeedback>
                        {touched?.monthlyBudgets?.[month] &&
                          errors?.monthlyBudgets?.[month]}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
          <div className="d-flex flex-row  mt-3">
            <Button
              color="dark"
              size="sm"
              onClick={(e) => {
                e?.stopPropagation();
                setValues({ ...initialValues });
                closeHandler();
              }}
            >
              Close
            </Button>
            <Button color="primary" size="sm" className="ms-2" type="submit">
              {team ? 'Update' : 'Create'}
            </Button>
          </div>
        </Form>
      </FormikProvider>

      {isSubmitting ? <InnerLoader /> : null}
    </>
  );
};

export default CreateTeamForm;
