import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Input,
  Label,
  Card,
  Row,
  Col,
  FormGroup,
  Spinner,
  Badge,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import { getAllConfigs } from '../../../actions/promotion/generals';
import InnerLoader from '../../../components/Common/InnerLoader';
import './style.scss';
import {
  getItemCollections,
  createItemCollections,
  downloadCollection,
} from '../../../actions/promotion';
import debounce from 'lodash.debounce';

const Collections = ({
  handleCollection = () => {},
  ids = [],
  collectionsProp = [],
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [open, setOpen] = useState('');
  const [collections, setCollections] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [collectionName, setCollectionName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isDownloading, setIsDownloading] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [collectionId, setCollectionId] = useState('');
  const [selectedCollections, setSelectedCollections] =
    useState(collectionsProp);
  const [selectedCollectionIds, setSelectedCollectionIds] = useState(ids);
  const [searchKey, setSearchKey] = useState('');

  const toggle = (id) => {
    try {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
      setCollectionId('');
      setCollectionName(null);
      setCsvFile(null);
      setEditingId(null);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      getCollections();
    }
  }, [pageOffset, isOpenModal]);

  const createItemCollectionsFunc = async () => {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('collectionName', collectionName);
      data.append('collectionId', collectionId);
      data.append('file', csvFile);

      const res = await createItemCollections(data);

      if (res?.success) {
        alertSuccess('Collection created successfully');
        await resetToInitialState();
        getCollections();
      } else {
        alertError('Failed!,  Please try again later');
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCollections = async (searchKeyParam = '') => {
    try {
      setIsLoading(true);
      const { data, meta } = await getAllConfigs({
        configName: 'collections',
        configEnv: process.env.NODE_ENV,
        pageOffset: pageOffset,
        searchKey: searchKeyParam,
        pageSize: 10,
      });

      const transformedData = data.map((item) => {
        const valuesObject = item.values.reduce(
          (acc, { fieldCode, fieldValue }) => {
            if (fieldCode === 'skus') {
              acc[fieldCode] = JSON.parse(fieldValue);
            } else {
              acc[fieldCode] = fieldValue;
            }
            return acc;
          },
          {}
        );

        return {
          ...item,
          values: valuesObject,
        };
      });
      setTotalPages(meta?.totalPages);
      setCollections(transformedData);
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadCollectionFunc = async (id) => {
    try {
      setIsDownloading(id);
      const res = await downloadCollection(id);
      setTimeout(() => {
        setIsDownloading(null);
      }, 400);
    } catch (error) {
      console.log(error);
    }
  };

  const pages = [...Array(totalPages)?.keys()];

  const selectCollectionHandler = async (collection) => {
    try {
      const updatedSelectedCollections = selectedCollections;
      const index = selectedCollections?.findIndex(
        (item) => item?._id === collection?._id
      );

      if (index === -1) {
        setSelectedCollections([
          {
            _id: collection?._id,
            collectionName: collection?.values?.collectionName,
          },
          ...updatedSelectedCollections,
        ]);
      } else {
        setSelectedCollections([
          ...updatedSelectedCollections?.filter(
            (item) => item?._id !== collection?._id
          ),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetToInitialState = async () => {
    try {
      setOpen('');
      setCollectionName('');
      setCsvFile(null);
      setCollectionId('');
      setPageOffset(0);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    resetToInitialState();
  }, [isOpenModal]);

  useEffect(() => {
    const ids = [...selectedCollections?.map((item) => item?._id)];

    setSelectedCollectionIds([...ids]);

    handleCollection({ ids: ids, collections: selectedCollections });
  }, [selectedCollections]);

  const debouncedGetCollections = debounce(getCollections, 400);

  const handleSearch = (e) => {
    try {
      setPageOffset(0);
      setSearchKey(e?.target?.value);
      debouncedGetCollections(e?.target?.value);
    } catch (error) {
      console.log(error);
    }
  };

  const renderDropzone = () => {
    return (
      <div className="mt-3">
        <Dropzone
          onDrop={(files) => {
            setCsvFile(files?.[0]);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone d-flex align-items-center">
              <div
                className="dz-message cursor-pointer mt-2"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                </div>
                <h4>Drop files here or click to upload.</h4>
              </div>
            </div>
          )}
        </Dropzone>
        {csvFile ? (
          <div className="dropzone-previews mt-3" id="file-previews">
            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
              <div className="p-2">
                <Row className="align-items-center">
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {csvFile?.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{csvFile?.formattedSize}</strong>
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div className="d-flex flex-row border border-info p-1 rounded">
        {selectedCollections?.length > 0 ? (
          <div className="d-flex align-items-center ">
            <Badge
              className="me-1 p-2 fs-12 text-capitalize text-truncate"
              style={{ maxWidth: 130 }}
              color="info"
            >
              {selectedCollections?.[0]?.collectionName}
            </Badge>
          </div>
        ) : null}

        {selectedCollections?.length > 1 ? (
          <div className="d-flex align-items-center ">
            <Badge
              className="me-1 p-2 fs-12 text-capitalize text-muted text-truncate"
              color="light"
            >
              {`${selectedCollections?.length - 1}+ More`}
            </Badge>
          </div>
        ) : null}
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            setIsOpenModal(true);
          }}
          className="pt-1 pb-1"
        >
          + Add
        </Button>
      </div>

      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(!isOpenModal);
        }}
        centered={true}
        size="lg"
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            setIsOpenModal(!isOpenModal);
          }}
        >
          Please select the collections you wish to include.
        </ModalHeader>
        <ModalBody className="collections-modal">
          <div className="hide-accordion-arrow">
            <Accordion open={open} toggle={toggle}>
              <AccordionItem className="mb-3">
                <AccordionHeader targetId={`${collections?.length}`}>
                  <i className="bx bx-plus fs-14 pe-2"></i>
                  Add new collection
                </AccordionHeader>
                <AccordionBody accordionId={`${collections?.length}`}>
                  <div>
                    <FormGroup>
                      <Label>Collection Name</Label>
                      <Input
                        type="text"
                        id="field-collection-name"
                        placeholder="Collection Name"
                        onChange={(e) => {
                          setCollectionName(e?.target?.value);
                        }}
                        value={collectionName}
                      />
                    </FormGroup>
                  </div>
                  {renderDropzone()}
                  <div className="d-flex flex-row  mt-3">
                    <Button
                      color="dark"
                      size="sm"
                      onClick={() => {
                        setOpen('');
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        createItemCollectionsFunc();
                      }}
                      disabled={!collectionName || !csvFile}
                      className="ms-2"
                    >
                      Create
                    </Button>
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>

          <div className="d-flex flex-row mb-2">
            <div className="search-box mb-2 d-inline-block w-100">
              <Input
                className="form-control search"
                placeholder="Search collections here"
                type="text"
                value={searchKey}
                onChange={(e) => handleSearch(e)}
              />
              <i className="bx bx-search-alt search-icon"></i>
            </div>
            {/* <div>
              <Button
                color={`primary`}
                className="btn-load"
                onClick={() => handleSearch(e)}
              >
                <span className="d-flex align-items-center">
                  <span className="flex-grow-1 me-2">
                    <i className="ri-search-fill me-1 align-bottom"></i>
                    Search
                  </span>
                </span>
              </Button>
            </div> */}
          </div>

          <div className="hide-accordion-arrow">
            <Accordion open={open} toggle={() => {}}>
              {collections?.map((collection, index) => {
                return (
                  <AccordionItem>
                    <AccordionHeader targetId={`${index}`}>
                      <div className="d-flex flex-row justify-content-between align-items-center w-100">
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                            onClick={(e) => {
                              e.stopPropagation();
                              selectCollectionHandler(collection);
                            }}
                            checked={selectedCollectionIds?.includes(
                              collection?._id
                            )}
                          />
                          <Label
                            className="form-check-label text-capitalize"
                            onClick={(e) => {
                              e.stopPropagation();
                              selectCollectionHandler(collection);
                            }}
                          >
                            {collection?.values?.collectionName}
                          </Label>
                        </div>

                        <div className="d-flex flex-row">
                          <Button
                            color="info"
                            size="sm"
                            className="me-2 pt-1 pb-0"
                            onClick={(e) => {
                              e.stopPropagation();
                              downloadCollectionFunc(collection?._id);
                            }}
                          >
                            {isDownloading === collection?._id ? (
                              <Spinner size="sm" />
                            ) : (
                              <i className="bx bx-download fs-15"></i>
                            )}
                          </Button>

                          <Button
                            color="info"
                            size="sm"
                            className="pt-1 pb-0"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingId(collection?._id);
                              setOpen(`${index}`);
                              setCollectionName(
                                collection?.values?.collectionName
                              );
                              setCollectionId(collection?._id);
                            }}
                          >
                            <i className="bx bxs-edit fs-15"></i>
                          </Button>
                        </div>
                      </div>
                    </AccordionHeader>
                    {collection?._id === editingId ? (
                      <AccordionBody accordionId={`${index}`}>
                        <div>
                          <FormGroup>
                            <Label>Collection Name</Label>
                            <Input
                              type="text"
                              id="field-collection-name"
                              placeholder="Collection Name"
                              onChange={(e) => {
                                setCollectionName(e?.target?.value);
                              }}
                              value={collectionName}
                              disabled
                            />
                          </FormGroup>
                        </div>
                        {renderDropzone()}

                        <div className="d-flex flex-row mt-3">
                          <Button
                            color="dark"
                            size="sm"
                            onClick={() => {
                              setOpen('');
                            }}
                          >
                            Close
                          </Button>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              createItemCollectionsFunc();
                            }}
                            disabled={!collectionName || !csvFile}
                            className="ms-2"
                          >
                            Update
                          </Button>
                        </div>
                      </AccordionBody>
                    ) : null}
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center mt-4">
            <div className="align-items-center justify-content-between row text-center text-sm-start">
              {totalPages > 1 ? (
                <div className="col-sm-auto mt-3 mt-sm-0">
                  <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                    <li
                      className={`page-item  ${
                        pageOffset < 1 ? 'disabled' : ''
                      }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => {
                          setPageOffset(pageOffset - 1);
                        }}
                      >
                        ←
                      </Link>
                    </li>

                    {pages?.map((page, index) => {
                      return (
                        <li
                          className={`page-item ${
                            pageOffset === index ? 'active' : ''
                          }`}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => setPageOffset(index)}
                          >
                            {index + 1}
                          </Link>
                        </li>
                      );
                    })}

                    <li className="page-item">
                      <Link
                        to="#"
                        className={`page-link ${
                          pageOffset + 1 >= totalPages ? 'disabled' : ''
                        }`}
                        onClick={() => {
                          setPageOffset(pageOffset + 1);
                        }}
                      >
                        →
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                setIsOpenModal(false);
              }}
            >
              Save
            </Button>
          </div>
          {isLoading ? <InnerLoader /> : null}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Collections;
