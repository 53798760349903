import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Form,
} from 'reactstrap';

import { alertError, alertSuccess } from '../../../helpers/errorHandling';

import InnerLoader from '../../../components/Common/InnerLoader';
import './style.scss';

import { PROMOTION_REPORT_GROUP_BY_OPTIONS } from '../../../constants';

import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { updatePromotionReportConfig } from '../../../actions/promotion/index';

const PromotionConfigForm = ({
  promotionId,
  submitHandler = () => {},
  closeHandler = () => {},
  reportConfigForEdit = null,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup?.object()?.shape({
    _id: Yup.string().optional(),
    configName: Yup?.string()
      ?.required('Config Name is required')
      ?.min(2, 'Config Name must be at least 2 characters'),
    fieldCode: Yup?.string()?.required(),
    filters: Yup?.array()?.of(Yup?.string())?.optional(),
    groupBy: Yup?.array()?.of(Yup?.string())?.required(),
  });

  const initialValues = {
    configName: '',
    fieldCode: '',
    groupBy: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        const { success, message } = await updatePromotionReportConfig({
          reportConfig: {
            ...values,
          },
          promotionId: promotionId,
        });

        if (success) {
          alertSuccess(message);
          submitHandler();
        } else {
          alertError({ message: message });
        }

        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      } catch (error) {
        setIsLoading(false);
        alertError({ message: 'Failed!,  Please try again later' });
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (reportConfigForEdit?._id) {
      setValues({
        _id: reportConfigForEdit?._id,
        configName: reportConfigForEdit?.configName,
        fieldCode: reportConfigForEdit?.fieldCode,
        groupBy: reportConfigForEdit?.groupBy,
      });
    }
  }, [reportConfigForEdit]);

  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    values,
    isSubmitting,
  } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <Label for="configName">Config Name</Label>
            <Input
              id="configName"
              name="configName"
              placeholder="Config Name"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.configName}
              invalid={!!errors?.configName && touched?.configName}
            />
            <FormFeedback>
              {touched?.configName && errors?.configName}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="fieldCode">Config Field Code</Label>
            <Input
              id="fieldCode"
              name="fieldCode"
              placeholder="Config Field Code"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.fieldCode}
              invalid={!!errors?.fieldCode && touched?.fieldCode}
            />
            <FormFeedback>
              {touched?.fieldCode && errors?.fieldCode}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="groupBy">Group By</Label>
            <Input
              id="groupBy"
              name="groupBy"
              type="select"
              multiple
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.groupBy}
              invalid={!!errors?.groupBy && touched?.groupBy}
            >
              {PROMOTION_REPORT_GROUP_BY_OPTIONS?.map((item, idx) => (
                <option value={item?.value} key={`dt-${idx}`}>
                  {item?.label}
                </option>
              ))}
            </Input>

            <FormFeedback>{touched?.groupBy && errors?.groupBy}</FormFeedback>
          </FormGroup>

          <div className="d-flex flex-row justify-content-end mt-3 w-100">
            <Button
              color="dark"
              size="sm"
              onClick={() => {
                closeHandler();
              }}
            >
              Close
            </Button>
            <Button color="primary" size="sm" type="submit" className="ms-2">
              {reportConfigForEdit ? 'Update' : 'Create'}
            </Button>
          </div>
        </Form>
      </FormikProvider>
      {isLoading ? <InnerLoader /> : null}
    </>
  );
};

export default PromotionConfigForm;
