import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import "./ProfileStyles.scss";

const Journey = () => {
  return (
    <>
      <Row>
        <Col lg={12}>
          <div className="timeline-2">
            <div className="timeline-year">
              <p className="shadow bg-light">Dec 2024</p>
            </div>
            <div className="timeline-continue">
              <Row className="timeline-right">
                <Col xs={12}>
                  <p className="timeline-date-custom">Feb 04</p>
                </Col>
                <Col xs={12}>
                  <div className="d-flex timeline-box-custom align-items-start gap-1">
                    <span
                      className="bg-light rounded-circle p-1 d-flex align-items-center"
                      style={{ width: 20, height: 20 }}
                    >
                      <i class="bx bx-cart"></i>
                    </span>
                    <div className="timeline-text">
                      <h6 className="my-1 text-body">New Transactions</h6>
                      <p className="mb-1 fs-12 text-body-secondary">
                        Order #9876543210
                      </p>
                      <div className="d-flex gap-2">
                        <span class="badge rounded-pill bg-light fw-normal fs-10 text-muted d-block">
                          09:00 PM
                        </span>
                        <span class="badge rounded-pill bg-light fw-normal fs-10 text-muted d-block">
                          AED 2700
                        </span>
                        <span class=" badge rounded-pill bg-light fw-normal fs-10 text-muted d-block">
                          Online
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={12}>
                  <div className="d-flex timeline-box-custom align-items-start gap-1">
                    <span
                      className="bg-light rounded-circle p-1 d-flex align-items-center"
                      style={{ width: 20, height: 20 }}
                    >
                      <i class="bx bx-star"></i>
                    </span>
                    <div className="timeline-text">
                      <h6 className="my-1 text-body">New Point Transfer</h6>
                      <p className="mb-1 fs-12 text-body-secondary">
                        +32 points earned from the order “9876543210” with the
                        campaign “1point for every AED spent”
                      </p>
                      <div className="d-flex gap-2">
                        <span class="badge rounded-pill bg-light fw-normal fs-10 text-muted d-block">
                          09:00 PM
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="timeline-right">
                <Col xs={12}>
                  <p className="timeline-date-custom">Jan 20</p>
                </Col>
                <Col xs={12}>
                  <div className="d-flex timeline-box-custom align-items-start gap-1">
                    <span
                      className="bg-light rounded-circle p-1 d-flex align-items-center"
                      style={{ width: 20, height: 20 }}
                    >
                      <i class="bx bx-trophy"></i>
                    </span>
                    <div className="timeline-text">
                      <h6 className="my-1 text-body">New Tire</h6>
                      <p className="mb-1 fs-12 text-body-secondary">
                        Gained the first tore “Gold”
                      </p>
                      <div className="d-flex gap-2">
                        <span class="badge rounded-pill bg-light fw-normal fs-10 text-muted d-block">
                          09:00 PM
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="d-flex timeline-box-custom align-items-start gap-1">
                    <span
                      className="bg-light rounded-circle p-1 d-flex align-items-center"
                      style={{ width: 20, height: 20 }}
                    >
                      <i class="bx bx-gift"></i>
                    </span>
                    <div className="timeline-text">
                      <h6 className="my-1 text-body">Update Profile</h6>
                      <p className="mb-1 fs-12 text-body-secondary">
                        Birthday added
                      </p>
                      <div className="d-flex gap-2">
                        <span class="badge rounded-pill bg-light fw-normal fs-10 text-muted d-block">
                          09:00 PM
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {/* 2021 */}
            <div className="timeline-year">
              <p className="shadow bg-light">Jan 2021</p>
            </div>
            <div className="timeline-continue">
              <Row className="timeline-right">
                <Col xs={12}>
                  <p className="timeline-date-custom">Feb 04</p>
                </Col>
                <Col xs={12}>
                  <div className="d-flex timeline-box-custom align-items-start gap-1">
                    <span
                      className="bg-light rounded-circle p-1 d-flex align-items-center"
                      style={{ width: 20, height: 20 }}
                    >
                      <i class="bx bx-cart"></i>
                    </span>
                    <div className="timeline-text">
                      <h6 className="my-1 text-body">New Transaction</h6>
                      <p className="mb-1 fs-12 text-body-secondary">
                        Order #9876543211
                      </p>
                      <div className="d-flex gap-2">
                        <span class="badge rounded-pill bg-light fw-normal fs-10 text-muted d-block">
                          09:00 PM
                        </span>
                        <span class="badge rounded-pill bg-light fw-normal fs-10 text-muted d-block">
                          AED 2700
                        </span>
                        <span class=" badge rounded-pill bg-light fw-normal fs-10 text-muted d-block">
                          Mobile App
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={12}>
                  <div className="d-flex timeline-box-custom align-items-start gap-1">
                    <span
                      className="bg-light rounded-circle p-1 d-flex align-items-center"
                      style={{ width: 20, height: 20 }}
                    >
                      <i class="bx bx-gift"></i>
                    </span>
                    <div className="timeline-text">
                      <h6 className="my-1 text-body">New Reward</h6>
                      <p className="mb-1 fs-12 text-body-secondary">
                        Coupon “-10% for the next purchase” used
                      </p>
                      <div className="d-flex gap-2">
                        <span class="badge rounded-pill bg-light fw-normal fs-10 text-muted d-block">
                          09:00 PM
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="timeline-right">
                <Col xs={12}>
                  <p className="timeline-date-custom">Jan 20</p>
                </Col>
                <Col xs={12}>
                  <div className="d-flex timeline-box-custom align-items-start gap-1">
                    <span
                      className="bg-light rounded-circle p-1 d-flex align-items-center"
                      style={{ width: 20, height: 20 }}
                    >
                      <i class="bx bx-star"></i>
                    </span>
                    <div className="timeline-text">
                      <h6 className="my-1 text-body">New Point Transfer</h6>
                      <p className="mb-1 fs-12 text-body-secondary">
                        +32 points earned from the order “9876543210” with the
                        campaign “1point for every AED spent”
                      </p>
                      <div className="d-flex gap-2">
                        <span class="badge rounded-pill bg-light fw-normal fs-10 text-muted d-block">
                          09:00 PM
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={12}>
                  <div className="d-flex timeline-box-custom align-items-start gap-1">
                    <span
                      className="bg-light rounded-circle p-1 d-flex align-items-center"
                      style={{ width: 20, height: 20 }}
                    >
                      <i class="bx bx-gift"></i>
                    </span>
                    <div className="timeline-text">
                      <h6 className="my-1 text-body">New Reward</h6>
                      <p className="mb-1 fs-12 text-body-secondary">
                        Reward “-10% for the next purchase” redeemed
                      </p>
                      <div className="d-flex gap-2">
                        <span class="badge rounded-pill bg-light fw-normal fs-10 text-muted d-block">
                          09:00 PM
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="timeline-launch">
              <div className="timeline-box shadow">
                <div className="timeline-text">
                  <h5>Customer Activity</h5>
                  <p className="text-muted text-capitalize mb-0">
                    Wow...!!! What a Journey So Far...!!!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Journey;
