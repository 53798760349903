import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';

import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  checkAvailabilityOfCoupons,
  createOrUpdateCoupon,
} from '../../../actions/promotion';

import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import FullPageLoader from '../../../components/Common/FullPageLoader';

import Flatpickr from 'react-flatpickr';

const GenerateCouponDirect = ({
  onCancel,
  getStores = () => {},
  setCoupons,
  coupons,
  setPageOffset,
  setTotalPages,
  setIsRefreshTable,
  promotionId,
  promotionDetail,
}) => {
  const [couponConfig, setCouponConfig] = useState({
    startsAt: null,
    expiresAt: null,
    redeemableQuantity: 0,
  });

  const [codesText, setCodesText] = useState('');
  const [codesError, setCodesError] = useState(null);
  const [newCodesText, setNewCodesText] = useState('');

  const directCouponSchema = Yup.object().shape({
    codesText: Yup.string().required('Codes required'),
    startsAt: Yup.date().required('Starts At is required'),
    expiresAt: Yup.date().required('Expires At is required'),
    redeemableQuantity: Yup.number().required(
      'Redeemable quantity is required'
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...couponConfig, codesText: codesText },
    validationSchema: directCouponSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const isAllow = await checkAvailabilityOfCouponsFunc();
        if (isAllow) {
          let coupons = values?.codesText?.split(',')?.map((code) => ({
            code: code,
            expiresAt: values?.expiresAt,
            redeemableQuantity: Number(values?.redeemableQuantity),
            startsAt: values?.startsAt,
            promotionId: promotionId,
          }));

          const variables = {
            input: {
              coupons: coupons,
            },
          };
          const { success, message, data } = await createOrUpdateCoupon(
            variables
          );
          if (success) {
            setTotalPages(data?.totalPages);
            setPageOffset(data?.totalPages);
            setCoupons([...data?.coupons]);
            setIsRefreshTable(true);
          }

          if (success) {
            alertSuccess('Coupons generated  successfully.');
          } else {
            alertError('Please try again after some time.');
          }

          setSubmitting(false);
          onCancel();
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const checkAvailabilityOfCouponsFunc = async () => {
    if (codesText?.length > 3) {
      const codes = codesText?.split(',');
      if (codes?.length > 0) {
        const variables = {
          input: {
            codes: codes,
          },
        };

        const { data, success } = await checkAvailabilityOfCoupons(variables);

        if (data?.existingCodes?.length > 0) {
          setCodesError(
            `The following coupons already exist ${data?.existingCodes?.join(
              ','
            )}.`
          );
          setNewCodesText(data?.newCodes?.join(','));
          return false;
        } else {
          setCodesError(null);
          setNewCodesText('');
          return true;
        }
      } else {
        return true;
      }
    }
  };

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Row gap={3} marginTop={2}>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormGroup>
              <Label>Enter You coupon codes here</Label>
              <Input
                id="outlined-multiline-flexible"
                required
                placeholder="(eg:APP5,DH2289ED)"
                value={codesText}
                multiple
                onChange={(e) => {
                  setCodesText(e?.target?.value?.toUpperCase());
                  setCodesError(null);
                }}
                style={{
                  width: '100%',
                }}
              />
              {codesError ? (
                <>
                  <span variant="body2" color="error" marginTop={0.4}>
                    {codesError}
                  </span>
                  <Button
                    size="small"
                    onClick={() => {
                      setCodesText(newCodesText);
                      setCodesError(null);
                    }}
                    style={{
                      color: '#000',
                      textDecoration: 'underline',
                    }}
                  >
                    Remove existing coupons
                  </Button>
                </>
              ) : null}
            </FormGroup>
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label>Start Date</Label>
              <Flatpickr
                className="form-control"
                id="startsAt"
                name="startsAt"
                placeholder="Start Date"
                options={{
                  enableTime: true,
                  // dateFormat: 'Y-m-d H:i',
                  dateFormat: 'Y-m-d h:i K',
                  time_24hr: false,
                }}
                onChange={(values) => {
                  setCouponConfig({
                    ...couponConfig,
                    startsAt: values?.[0],
                  });
                }}
              />
              {/* <Input
                required
                type="datetime-local"
                id="outlined-required"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setCouponConfig({
                    ...couponConfig,
                    startsAt: e?.target?.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              /> */}
            </FormGroup>
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label>End Date</Label>

              <Flatpickr
                className="form-control"
                type="datetime-local"
                id="expiresAt"
                name="expiresAt"
                placeholder="End Date"
                onChange={(values) => {
                  setCouponConfig({
                    ...couponConfig,
                    expiresAt: values?.[0],
                  });
                }}
                options={{
                  enableTime: true,
                  dateFormat: 'Y-m-d h:i K',
                  time_24hr: false,
                }}
              />

              {/* <Input
                required
                type="datetime-local"
                id="outlined-required"
                label="End Date"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setCouponConfig({
                    ...couponConfig,
                    expiresAt: e?.target?.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              /> */}
            </FormGroup>
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label>Redeemable Quantity</Label>
              <Input
                required
                type="number"
                id="outlined-required"
                label="Redeemable Quantity"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setCouponConfig({
                    ...couponConfig,
                    redeemableQuantity: Number(e?.target?.value),
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <Button
              color="secondary"
              className="rounded-pill py-1 px-4"
              size="md"
              type="submit"
              disabled={codesError}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      {isSubmitting ? <FullPageLoader /> : null}
    </FormikProvider>
  );
};

GenerateCouponDirect.propTypes = {};

export default GenerateCouponDirect;
