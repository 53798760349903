import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { COMPANY_NAME, DEVELOPER_NAME } from '../../constants';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>
              {new Date().getFullYear()} © {COMPANY_NAME}.
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by {DEVELOPER_NAME}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
