import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, CardHeader, Row } from 'reactstrap';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import { Authorize } from '../../../routes/PermissionProvider';
import { AUTH_PERMISSION } from '../permission.keys';
import {
  fetchAllUsersAction,
  getRolesOptionsListAction,
} from '../../../actions/auth/action';
import UserFilters from '../../../components/Users/Filters';
import Pagination from '../../../components/Common/Pagination';
import UserItem from '../../../components/Users/User';
import { ROUTE_USER_CREATE } from '../../../constants/route';
import { Link } from 'react-router-dom';

const Users = () => {
  document.title = 'All Users List';

  const [search, setSearch] = useState(null);
  const [enabled, setEnabled] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [rolesOptions, setRolesOptions] = useState([]);

  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  // Fetch data from the API
  const fetchList = useCallback(async (data) => {
    setIsLoading(true);

    try {
      const response = await fetchAllUsersAction({ ...data });

      const { items = [], pagination = {} } = response || {};
      const { currentPage = 1, totalPages = 1, totalItems = 0 } = pagination;

      setList(items);
      setCurrentPage(currentPage);
      setTotalPages(totalPages);
      setTotalItems(totalItems);
    } catch (error) {
      console.error('Failed to fetch admin list:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch data based on the filter values change
  useEffect(() => {
    const delay = setTimeout(() => {
      fetchList({
        pagination: { page: currentPage, limit: 10 },
        filter: {
          search,
          enabled,
        },
      });
    }, 300); // Debounce time of 300 milliseconds
    return () => clearTimeout(delay);
  }, [search, enabled, currentPage, fetchList]);

  // loading roles list
  useEffect(() => {
    const fetchPermissions = async () => {
      const roleList = await getRolesOptionsListAction();
      setRolesOptions(roleList);
    };
    fetchPermissions();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'All Users'} titleActive="Manage Users" />
        <Card>
          <CardHeader className="border-0">
            <Row className="g-4 align-items-center">
              <Col sm></Col>
              <Col sm="auto">
                {/* <Authorize permission={AUTH_PERMISSION.ALLOW_CREATE}> */}
                <div className="d-flex flex-wrap align-items-start gap-2">
                  <Link
                    to={ROUTE_USER_CREATE?.replace(':id', 'new')}
                    className="btn btn-success add-btn"
                  >
                    <i className="ri-user-fill me-1 align-bottom"></i>
                    Create new user
                  </Link>
                </div>

                {/* </Authorize> */}
              </Col>
            </Row>
          </CardHeader>{' '}
          {/* */}
          <UserFilters />
          <CardBody className="border">
            <Row className="g-3">
              <Col>
                <div className="border rounded p-3 mb-2">
                  <div className="table-responsive table-card">
                    <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>Contact</th>
                          <th>Role</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list?.map((item, key) => (
                          <tr key={key}>
                            <UserItem item={item} roleList={rolesOptions} />
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <Pagination
                  totalPages={totalPages}
                  totalItems={totalItems}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Users;
