import React, { useState } from 'react';
import {
  Button,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  ButtonGroup,
} from 'reactstrap';
import UnitBasedOption from './UnitBasedOption';
import { DISCOUNT_TYPES } from '../../../constants';
import { If } from 'react-if';
import EffectTransactionConditionalOption from './EffectTransactionConditionalOption';

const EffectTransactionConditional = ({
  onChange = () => {},
  effect = {},
  eIndex,
  tIndex,
}) => {
  const [options, setOptions] = useState(effect.transactionConditional || []);

  const handleAddOption = () => {
    setOptions([
      ...options,
      {
        itemConditionAttribute: 'totalDiscount',
        itemConditionOperation: 'lt',
        itemConditionValue: 0,
        discountValue: 0,
        maxDiscountValue: 0,
        discountOnPriceType: 'sp',
      },
    ]);
  };

  const handleValueChange = (index, value, name) => {
    const updatedOptions = [...options];
    updatedOptions[index][name] = value;
    setOptions(updatedOptions);
    onChange('transactionConditional', [...updatedOptions], eIndex, tIndex);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
    onChange('transactionConditional', [...updatedOptions], eIndex, tIndex);
  };

  const optionCount = options.length;
  return (
    <>
      <div className="w-100">
        {options.map((option, index) => (
          <EffectTransactionConditionalOption
            key={index}
            optionCount={optionCount}
            option={option}
            index={index}
            setOptions={setOptions}
            handleValueChange={handleValueChange}
            handleRemoveOption={handleRemoveOption}
            combinedUnitBasedDiscount={effect?.combinedUnitBasedDiscount}
            effect={effect}
          />
        ))}
        <div className="my-2 d-flex gap-2">
          <Button color="primary" onClick={handleAddOption} size="sm">
            Add Condition{options.length + 1}
          </Button>
        </div>
      </div>
    </>
  );
};

export default EffectTransactionConditional;
