import axios from 'axios';
import { PROMOTION_API_SERVICE_BASE_URL } from '../../constants';
import { ApolloClientPromoService } from '../../services/ApolloClientPromoService';

export const listMembers = async (data) => {
  const result = await axios.post(
    `${PROMOTION_API_SERVICE_BASE_URL}members/list`,
    data
  );

  return result?.data;
};

export const getMemberDetail = async (data) => {
  const result = await axios.post(
    `${PROMOTION_API_SERVICE_BASE_URL}members/get`,
    data
  );
  return result?.data;
};

export const getMemberTransactions = async (data) => {
  const result = await axios.post(
    `${PROMOTION_API_SERVICE_BASE_URL}members/transactions`,
    data
  );
  return result?.data;
};
