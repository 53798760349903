import { gql } from '@apollo/client';

export const GET_CONFIG_LIST = gql`
  query getConfigList($input: ConfigListInput!) {
    getConfigList(input: $input) {
      totalPages
      totalConfigs
      currentOffset
      configs {
        _id
        configEnv
        configName
        configLabel
        values {
          fieldCode
          fieldValue
        }
      }
    }
  }
`;

export const GET_CONFIG_DETAIL = gql`
  query getConfigDetail($configId: String!) {
    getConfigDetail(configId: $configId) {
      _id
      configName
      configEnv

      values {
        fieldCode
        fieldValue
      }
    }
  }
`;

export const CREATE_UPDATE_BASIC_SETTINGS = gql`
  mutation createAndUpdateBasicDetailsConfig(
    $input: ConfigInputBasicSettings!
    $configId: String
  ) {
    createAndUpdateBasicDetailsConfig(input: $input, configId: $configId) {
      id
      success
      message
    }
  }
`;
export const DELETE_FIELD = gql`
  mutation deleteConfig($configId: String!) {
    deleteConfig(configId: $configId) {
      id
      success
      message
    }
  }
`;
