
import React from "react";
import { Row, Col } from "reactstrap";

const WalletInfo = ({ wallet }) => {
  if (!wallet) {
    return null;
  }

  return (
    <div className="mx-auto">
      <div className="text-bg-primary bg-gradient p-4 rounded-3 mb-4">
        <Row className="gap-2">
          <Col md={12}>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <i className="bx bx-wallet-alt h1 mb-0 text-white-50"></i>
              <span className="fs-20 text-white-50 fw-semibold">
                Wallet
              </span>
            </div>
          </Col>
          <Col md={12} className="mt-2">
            <div className="card-number d-flex flex-column">
              <span className="fs-20" style={{ letterSpacing: "2px" }}>
                {wallet.uniqueId}
              </span>
            </div>
          </Col>
          <Col md={12} className="mt-4">
            <div className="text-white-50">Account Holder</div>
            <div id="card-holder-elem" className="fw-medium fs-14">
              {wallet.firstName} {wallet.lastName}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WalletInfo;

