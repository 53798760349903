import { gql } from '@apollo/client';

const user = ` 
    _id
    email
    firstName
    lastName
    status
    privilege
    emailVerified
    mobile
    roles
    createdAt
    updatedAt
    
`;

const role = ` 
    _id
    name
    description
    isSystem
    permissions
    enabled
    createdAt
    updatedAt
`;

/**
 * USER LOGIN
 */
export const USER_LOGIN = gql`
  mutation USER_LOGIN($input: LoginUserInput!) {
    login(input: $input) {
      token
      user {
         ${user}
      }
    }
  }
`;

/**
 * GET USER PROFILE
 */
export const USER_PROFILE = gql`
  query USER_PROFILE {
    profile {
      ${user}
    }
  }
`;

/**
 * CREATE
 */
export const CREATE_SUB_USER = gql`
  mutation CREATE_SUB_USER($input: CreateUserInput!) {
    createSubUser(input: $input) { 
      ${user} 
    }
  }
`;

/**
 * PERMISSION LIST
 */
export const PERMISSION_LIST = gql`
  mutation PERMISSION_LIST {
    permissionList {
      name
      permissions {
        name
        description
        icon
        permission
      }
    }
  }
`;

/**
 * CREATE ROLE
 */
export const CREATE_ROLE = gql`
  mutation CREATE_ROLE($input: CreateRoleInput!) {
    createRole(input: $input) {
       ${role}
    }
  }
`;

/**
 * Update ROLE
 */
export const UPDATE_ROLE = gql`
  mutation UPDATE_ROLE($input: CreateRoleInput!) {
    updateRole(input: $input) {
       ${role}
    }
  }
`;

/**
 * VIEW ROLE
 */
export const VIEW_ROLE = gql`
  query VIEW_ROLE($id: String!) {
    viewRole(id: $id) {
       ${role}
    }
  }
`;

/**
 * ROLES LISTING
 */

export const ROLES_LISTING = gql`
  query ROLES_LISTING(
    $pagination: PaginationInput!
    $filter: RoleFilterInput!
  ) {
    roleListing(pagination: $pagination, filter: $filter) {
      items {
       ${role}
      }
      pagination {
        currentPage
        totalPages
        currentItem
        totalItems
      }
    }
  }
`;

/**
 * USER LISTING
 */

export const USERS_LISTING = gql`
  query USERS_LISTING(
    $pagination: PaginationInput!
    $filter: UserFilterInput!
  ) {
    userListing(pagination: $pagination, filter: $filter) {
      items {
       ${user}
      }
      pagination {
        currentPage
        totalPages
        currentItem
        totalItems
      }
    }
  }
`;

/**
 * ROLES OPTION LIST
 */
export const ROLE_OPTION_LIST = gql`
  query ROLE_OPTION_LIST {
    getRolesOptions {
      label
      value
    }
  }
`;

/**
 * GET SUB USER
 */
export const SUB_USER_VIEW = gql`
  query SUB_USER_VIEW($_id: String!) {
    viewSubUser(_id: $_id) {
       ${user}
    }
  }
`;
