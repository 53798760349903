import WalletRestService from '../../services/WalletRestService';

export const getWallets = async (data) => {
  try {
    const response = await WalletRestService.post('/wallet/list', data);
    console.log(response, 'res-20');
    return {
      success: true,
      error: false,
      data: response.data,
    };
  } catch (error) { }
};

export const getTransactions = async (data) => {
  try {
    const response = await WalletRestService.post('/transaction/list', data);
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};

export const getRecentTransactions = async (data) => {
  try {
    const response = await WalletRestService.post(
      '/transaction/list-recent',
      data
    );
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};

export const getTransactionDetails = async (data) => {
  try {
    const response = await WalletRestService.get(
      `transaction/${data.id}`,
      data
    );
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};

export const getWalletDetails = async (data) => {
  try {
    const response = await WalletRestService.get(
      `/wallet/${data.country}/${data.mobileNumber}`,
      data
    );
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};

export const createCustomer = async (data) => {
  try {
    const response = await WalletRestService.post('/wallet/create', data);
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};

export const getWalletSummary = async (data) => {
  try {
    const response = await WalletRestService.post('/wallet/stats', data);
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};

export const generateTransactionReport = async (data) => {
  try {
    const response = await WalletRestService.post(
      `transaction/export-data`,
      data
    );
    console.log(response, 'response');
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
      message: error?.response?.data?.message,
    };
  }
};

export const getChartTransactionsData = async (data) => {
  try {
    const response = await WalletRestService.post(
      '/transaction/chart-data',
      data
    );
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};

export const generateWalletMembersListReport = async (data) => {
  try {
    const response = await WalletRestService.post(
      `wallet/export-wallet-members`,
      data
    );
    console.log(response, 'response');
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
      message: error?.response?.data?.message,
    };
  }
};

export const getExpiredTransactions = async (data) => {
  try {
    const response = await WalletRestService.post('/transaction/expired-list', data);
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};


export const generateOtherReport = async (data) => {
  try {
    const response = await WalletRestService.post(
      `transaction/export-data`,
      data
    );
    console.log(response, 'response');
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
      message: error?.response?.data?.message,
    };
  }
};

export const getLedgerTransactions = async (data) => {
  try {
    const response = await WalletRestService.post('/transaction/get-transaction-ledger', data);
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};

export const generateLedger = async (data) => {
  try {
    const response = await WalletRestService.post(
      `transaction/export-transaction-ledger`,
      data
    );
    console.log(response, 'response');
    return {
      success: true,
      data: response?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
      message: error?.response?.data?.message,
    };
  }
};

export const updateDjvBulk = async (formData) => {
  try {
    const response = await WalletRestService.post(
      `transaction/update-djv-bulk`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return {
      success: true,
      updatedCount: response.data?.updatedCount || 0,
    };
  } catch (error) {
    return {
      success: false,
      message: error?.response?.data?.message,
    };
  }
};
