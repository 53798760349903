import React from "react";
import CountUp from "react-countup";
import {
  Card,
  CardBody,
  Col,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import DashboardCharts from "./DashboardCharts";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const Data = (props) => {
  const {
    creditTotal,
    redeemableAmount,
    debitTotal,
    creditPending,
    debitHold
  } = props.wallet.balance;
  const {
    currency
  } = props.wallet;
  const walletData = [
    {
      id: 1,
      label: "Lifetime Credited",
      counter: creditTotal,
      pendingLabel: "Pending Credit",
      pendingCounter: creditPending,
      separator: ",",
      series: [95],
      color: "#45cb85d9",
      fontColor: "success",
      feaIconClass: "text-success",
      feaIcon: "arrow-up-right",
    },
    {
      id: 2,
      label: "Lifetime Debited",
      counter: debitTotal,
      pendingLabel: "Debit Hold",
      pendingCounter: debitHold,
      separator: ",",
      series: [97],
      color: "#f06548",
      fontColor: "danger",
      feaIconClass: "text-danger",
      feaIcon: "arrow-down-right",
    },
  ];
  return walletData.map((item, key) => (
    <Col md={12} key={key}>
      <Card className="card-animate overflow-hidden" key={key}>
        <div className="position-absolute start-0" style={{ zIndex: "0" }}>
          <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 120"
            width="200"
            height="120"
          >
            <path
              id="Shape 8"
              style={{ opacity: ".05", fill: "#299cdb" }}
              d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
            />
          </svg>
        </div>
        <CardBody style={{ zIndex: "1" }}>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between gap-3 w-100">
              <h4 className="d-flex align-items-center fs-14 fw-medium text-truncate mb-0">
                {item?.label}
              </h4>
              <h4 className="fs-20 fw-semibold ff-secondary mb-0 d-flex align-items-center gap-1">
                <span className={`${item.feaIconClass}`}>
                  <FeatherIcon icon={item?.feaIcon} style={{ width: 26 }} />
                </span>
                <span
                  data-target="36894"
                  className={"counter-value text-" + item.fontColor}
                >
                  {currency}{" "}
                  <CountUp
                    start={0}
                    end={item?.counter}
                    separator={item?.separator}
                    duration={4}
                    decimals={2}
                  />
                </span>
              </h4>
            </div>
            {/* <div className="flex-shrink-0">
              <DashboardCharts seriesData={item?.series} colors={item?.color} />
            </div> */}
          </div>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between gap-3 w-100">
              <p className="d-flex align-items-center fs-11 fw-normal text-truncate mb-0">{item?.pendingLabel}</p>
              <p className="fs-11 fw-semibold ff-secondary mb-0 d-flex align-items-center gap-1">
                <span data-target="36894" className="counter-value"> {currency}
                  <span className="ps-1">{item?.pendingCounter}</span>
                </span>
              </p>
            </div>
          </div>

        </CardBody>
      </Card>
    </Col>
  ));
};

export default Data;
