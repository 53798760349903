import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

const MemberRow = ({ item, key, configs }) => {
  return (
    <div className="border rounded p-3 mb-2" key={key}>
      <Row className="gap-3">
        <Col md={8} className="border-end">
          <div className="d-flex flex-wrap justify-content-between">
            <h5 className="fs-16 text-body my-1 d-flex flex-wrap align-items-center gap-2">
              {item[configs.FirstName]} {item[configs.LastName]}
              <span
                className={`fs-11 fw-normal badge badge-${item?.customerStatusClass}`}
              >
                {item.customerStatus}
              </span>
            </h5>
          </div>
          <p className="fs-12 text-muted my-1 fw-normal">
            Country: <strong>{item[configs.country]}</strong>
          </p>
          <div className="d-flex flex-wrap gap-1">
            <span className="fs-12 text-muted">
              E-mail: <strong>{item[configs.Email]}</strong>
            </span>
            <span className="fs-12 text-muted">|</span>
            <p className="p-0 m-0 d-flex flex-wrap align-items-center">
              <span className="fs-12 text-muted">
                Mob:{' '}
                <strong>{item[configs.MobileNumber] || 'Unavailable'}</strong>
              </span>
              {item?.customerMobVerification ? (
                <span
                  className={`badge rounded-pill  fw-normal mx-1 d-flex flex-wrap align-items-center ${item?.verificationIconClass}`}
                >
                  <i className={`${item?.verificationIcon}`}></i>
                  {item.customerMobVerification}
                </span>
              ) : null}
              {item?.customerMobVerificationBtn ? (
                <button
                  onClick={() => tog_center()}
                  className="btn rounded-pill btn-primary waves-effect waves-light py-0 px-1 fs-10 mx-1"
                >
                  {item.customerMobVerificationBtn}
                </button>
              ) : null}
            </p>
          </div>
          {item?.segmentsList?.length ? (
            <p className="d-flex flex-wrap align-items-center gap-1 fs-12 text-muted my-1 fw-normal">
              Segments:
              <span className="d-flex flex-wrap align-items-center gap-1">
                {item?.segmentsList?.map((sItem, index) => (
                  <span
                    key={index}
                    className={`badge rounded-pill bg-${sItem?.className}`}
                  >
                    {sItem?.label}
                  </span>
                ))}
              </span>
            </p>
          ) : null}
          <div className="d-flex flex-wrap align-items-center gap-1">
            <p className="fs-12 text-muted my-1 fw-normal d-flex flex-wrap gap-1">
              Joined Since:
              <strong>{item[configs.JoinedDate]}</strong>
            </p>
          </div>
        </Col>
        <Col>
          <div className="d-flex flex-wrap justify-content-between h-100">
            <div className="d-flex flex-wrap flex-column justify-content-between gap-1 h-100">
              <p className="fs-12 text-muted mb-0 fw-normal d-flex flex-wrap flex-column align-items-start gap-1">
                Wallet Balance:
                {item?.walletBalance ? (
                  <strong className="fs-16 text-body">
                    {item.walletBalance}
                  </strong>
                ) : null}
                {item?.walletStatus ? (
                  <span
                    className={`fs-10 my-1 fw-normal badge badge-${item?.walletStatusClass}`}
                  >
                    {item.walletStatus}
                  </span>
                ) : null}
              </p>
              <p className="fs-12 text-muted mb-0 fw-normal d-flex flex-wrap flex-column align-items-start gap-1">
                Reward Point:
                {item?.ahlanPoint ? (
                  <strong className="fs-16 text-body">{item.ahlanPoint}</strong>
                ) : null}
                {item?.ahlanStatus ? (
                  <span
                    className={`fs-10 my-1 fw-normal badge badge-${item?.ahlanStatusClass}`}
                  >
                    {item.ahlanStatus}
                  </span>
                ) : null}
              </p>
            </div>
          </div>
        </Col>
        <Col>
          <div className="d-flex flex-wrap flex-column justify-content-between align-items-end h-100">
            {item?.ahlanTier ? (
              <span className={`fs-10 text-body badge ${item?.ahlanTierClass}`}>
                {item?.ahlanTier}
              </span>
            ) : null}
            <Link
              to={`/members/profile/${item._id}`}
              className="fs-10 d-flex align-items-center mt-auto"
            >
              View Details <i class="bx bx-chevron-right fs-22"></i>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default MemberRow;
