import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
const ErrorMessagesInLang = ({
  languages = [],
  onChange = () => {},
  ...rest
}) => {
  const [options, setOptions] = useState(rest.value || []);
  console.log('languages options', options, languages);
  const handleValueChange = (index, value) => {
    const updatedOptions = [...options];
    if (!updatedOptions[index]) {
      updatedOptions[index] = {
        langCode: languages[index].langCode,
        value: value,
      };
    }
    updatedOptions[index].value = value;
    setOptions(updatedOptions);
  };

  useEffect(() => {
    onChange(options);
  }, [options]);
  useEffect(() => {
    setOptions(rest.value || []);
  }, [rest.value]);

  return (
    <>
      {languages?.map((lang, index) => (
        <>
          <Row className="align-items-end">
            <Col xs={12}>
              <FormGroup>
                <Label for="field-fieldGroup">{lang.label}</Label>
                <Input
                  label="Value"
                  value={options?.[index]?.value}
                  onChange={(e) => handleValueChange(index, e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      ))}
    </>
  );
};

export default ErrorMessagesInLang;
