import axios from 'axios';
import { getItem, deleteItem } from '../helpers/cookies';
import { AUTH_TOKEN, PROMOTION_API_SERVICE_BASE_URL } from '../constants';

const endpoint = PROMOTION_API_SERVICE_BASE_URL;
const promoRestService = axios?.create({
  baseURL: endpoint,
});

promoRestService?.interceptors?.request?.use(
  async (config) => {
    const token = getItem(AUTH_TOKEN);
    config.headers['Content-Type'] = config.headers['Content-Type']
      ? config.headers['Content-Type']
      : 'application/json';
    config.headers['Cache-Control'] = 'no-cache';
    config.headers['authorization'] = token ? `Bearer ${token}` : '';
    config.headers.platform = 'web';
    return config;
  },
  (err) => {
    return Promise?.reject(err);
  }
);

export default promoRestService;
