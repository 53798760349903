import React, { useState, useEffect } from 'react';
import PromotionReportConfig from '../PromotionReportConfig';
import PieChart from './charts/pieChart';

const PromotionAnalysis = ({
  promotionId,
  promotionDetail,
  fetchPromotionDetail,
}) => {
  return (
    <div className="p-3">
      <PromotionReportConfig
        promotionId={promotionId}
        promotionDetail={promotionDetail}
        fetchPromotionDetail={fetchPromotionDetail}
      />
      <div className="d-flex flex-row flex-wrap gap-4 mt-4">
        {promotionDetail?.reports?.today ? (
          <div className="d-flex flex-column align-items-center justify-content-center border h-100 p-4">
            <p className="m-0 d-flex flex-wrap align-items-center mb-2">
              <span className="fs-13 text-muted text-capitalize">
                <strong>Today</strong>
              </span>
            </p>
            <p className="m-0 d-flex flex-wrap align-items-center">
              <span className="fs-13 text-muted text-capitalize">
                Spends:{' '}
                <strong>{`AED ${promotionDetail?.reports?.today?.spends}`}</strong>
              </span>
            </p>
            <p>
              <span className="fs-13 text-muted text-capitalize">
                Revenue:{' '}
                <strong>{`AED ${promotionDetail?.reports?.today?.revenue}`}</strong>
              </span>
            </p>
          </div>
        ) : null}
        {promotionDetail?.reports?.weekly ? (
          <div className="d-flex flex-column align-items-center justify-content-center border h-100 p-4">
            <p className="m-0 d-flex flex-wrap align-items-center mb-2">
              <span className="fs-13 text-muted text-capitalize">
                <strong>Weekly</strong>
              </span>
            </p>
            <p className="m-0 d-flex flex-wrap align-items-center">
              <span className="fs-13 text-muted text-capitalize">
                Spends:{' '}
                <strong>{`AED ${promotionDetail?.reports?.weekly?.spends}`}</strong>
              </span>
            </p>
            <p>
              <span className="fs-13 text-muted text-capitalize">
                Revenue:{' '}
                <strong>{`AED ${promotionDetail?.reports?.weekly?.revenue}`}</strong>
              </span>
            </p>
          </div>
        ) : null}
        {promotionDetail?.reports?.monthly ? (
          <div className="d-flex flex-column align-items-center justify-content-center border h-100 p-4">
            <p className="m-0 d-flex flex-wrap align-items-center mb-2">
              <span className="fs-13 text-muted text-capitalize">
                <strong>Monthly</strong>
              </span>
            </p>
            <p className="m-0 d-flex flex-wrap align-items-center">
              <span className="fs-13 text-muted text-capitalize">
                Spends:{' '}
                <strong>{`AED ${promotionDetail?.reports?.monthly?.spends}`}</strong>
              </span>
            </p>
            <p>
              <span className="fs-13 text-muted text-capitalize">
                Revenue:{' '}
                <strong>{`AED ${promotionDetail?.reports?.monthly?.revenue}`}</strong>
              </span>
            </p>
          </div>
        ) : null}
        {promotionDetail?.reports?.yearly ? (
          <div className="d-flex flex-column align-items-center justify-content-center border h-100 p-4">
            <p className="m-0 d-flex flex-wrap align-items-center mb-2">
              <span className="fs-13 text-muted text-capitalize">
                <strong>Yearly</strong>
              </span>
            </p>
            <p className="m-0 d-flex flex-wrap align-items-center">
              <span className="fs-13 text-muted text-capitalize">
                Spends:{' '}
                <strong>{`AED ${promotionDetail?.reports?.yearly?.spends}`}</strong>
              </span>
            </p>
            <p>
              <span className="fs-13 text-muted text-capitalize">
                Revenue:{' '}
                <strong>{`AED ${promotionDetail?.reports?.yearly?.revenue}`}</strong>
              </span>
            </p>
          </div>
        ) : null}
        {promotionDetail?.reports?.allTime ? (
          <div className="d-flex flex-column align-items-center justify-content-center border h-100 p-4">
            <p className="m-0 d-flex flex-wrap align-items-center mb-2">
              <span className="fs-13 text-muted text-capitalize">
                <strong>Total</strong>
              </span>
            </p>
            <p className="m-0 d-flex flex-wrap align-items-center">
              <span className="fs-13 text-muted text-capitalize">
                Spends:{' '}
                <strong>{`AED ${promotionDetail?.reports?.allTime?.spends}`}</strong>
              </span>
            </p>
            <p>
              <span className="fs-13 text-muted text-capitalize">
                Revenue:{' '}
                <strong>{`AED ${promotionDetail?.reports?.allTime?.revenue}`}</strong>
              </span>
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PromotionAnalysis;
