import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';
import './style.scss';

import debounce from 'lodash.debounce';
import { DISCOUNT_TYPES } from '../../../constants';
import {
  getCombos,
  createOrUpdateCombo,
  deleteComboById,
} from '../../../actions/promotion/combo';
import CreateComboForm from './CreateComboForm';
import CreateComboFromCsv from './CreateComboFromCsv';
import { setComboForPreview } from '../../../slices/promotions/reducer';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

const Combo = ({ promotionId, effectId }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [combos, setCombos] = useState([]);
  const [products, setProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCombos, setSelectedCombos] = useState();
  const [searchKey, setSearchKey] = useState('');
  const { selectedComboForPreview } = useSelector(
    (state) => state.promotions || {}
  );

  useEffect(() => {
    // if (isOpenModal) {
    getCombosFunc();
    // }
  }, [pageOffset]);

  // useEffect(() => {
  //   if (isOpenModal === false) {
  //     getCombosFunc();
  //   }
  // }, [isOpenModal]);

  const getCombosFunc = async (searchKeyProp = null) => {
    try {
      setIsLoading(true);
      const inputData = {
        promotionId: promotionId,
        effectId: effectId,
        pageOffset: searchKeyProp ? 0 : pageOffset,
        // pageOffset: isOpenModal ? (searchKeyProp ? 0 : pageOffset) : 0,
        pageSize: 6,
        keyword: searchKeyProp === null ? searchKey : searchKeyProp,
      };

      const { data, message, success } = await getCombos(inputData);

      if (success) {
        // if (isOpenModal) {
        setCombos(data?.combos);
        setTotalPages(data?.totalPages);
        // } else {
        //   setSelectedCombos([
        //     ...(data?.combos?.filter((item) => item?.effectIds?.length > 0) ||
        //       []),
        //   ]);
        // }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const selectComboHandler = async (combo, comboIndex) => {
    try {
      setIsLoading(true);
      const updatedCombo = { ...combo };
      const updatedCombos = [...combos];

      const index = combo?.effectIds?.findIndex((item) => item === effectId);

      if (index === -1) {
        updatedCombo.effectIds = [...updatedCombo?.effectIds, effectId];
      } else {
        updatedCombo.effectIds = [
          ...updatedCombo?.effectIds?.filter((item) => item !== effectId),
        ];
      }

      const inputData = [updatedCombo];
      const { success } = await createOrUpdateCombo(inputData);
      updatedCombos[comboIndex] = { ...updatedCombo };
      setCombos([...updatedCombos]);
      setTimeout(() => {
        setIsLoading(false);
      }, 400);
    } catch (error) {
      console.log(error);
    }
  };

  const createComboHandler = () => {
    if (pageOffset === 0) {
      getCombosFunc();
    } else {
      setPageOffset(0);
    }
  };

  const debouncedGetCombos = debounce(getCombosFunc, 400);

  const handleSearch = (e) => {
    try {
      if (pageOffset !== 0) {
        setPageOffset(0);
      }
      setSearchKey(e?.target?.value);
      debouncedGetCombos(e?.target?.value);
    } catch (error) {
      console.log(error);
    }
  };
  const dispatch = useDispatch();
  const pages = [...Array(totalPages)?.keys()];
  const handleComboItemClick = (combo) => {
    dispatch(setComboForPreview(combo));
  };

  const deleteComboByIdFunc = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteComboById({ _id: id });
      const { success, message } = res;
      if (success) {
        alertSuccess(message);
        getCombosFunc();
      } else {
        alertError({ message: message || 'Failed!,  Please try again later' });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 400);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="d-flex flex-column w-100">
        {/* {selectedCombos?.map((combo, index) => {
          return index < 3 && combo?.effectIds?.includes(effectId) ? (
            <div
              className={classNames('container mb-3 row', {
                'bg-success': combo === selectedComboForPreview,
              })}
              onClick={() => {
                handleComboItemClick(combo);
              }}
            >
              <div className="col-4">
                {combo?.rule?.products?.map((product, index) => (
                  <div
                    className={`row border-bottom border-start ${
                      index === 0 ? 'border-top' : ''
                    }`}
                  >
                    <div className="col-8 border-end p-2">{product?.sku}</div>
                    <div className="col-4 p-2">{product?.qty}</div>
                  </div>
                ))}
              </div>

              <div className="col-4 border">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                  <div className="text-capitalize">
                    {combo?.rule?.discountType?.replace('_', ' ')}
                  </div>
                  <div>{combo?.rule?.discountValue}</div>
                </div>
              </div>

              {combo?.rule?.maxDiscount ? (
                <div className="col-4 border-top border-bottom border-end">
                  <div className="d-flex gap-0 flex-column align-items-center justify-content-center h-100">
                    <div className="text-capitalize">Max Discount</div>
                    <div>{combo?.rule?.maxDiscount}</div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null;
        })} */}
        <div className="d-flex justify-content-between align-items-baseline mb-3">
          <div className="search-box d-inline-block w-100">
            <Input
              className="form-control search"
              placeholder="Search combos here"
              type="text"
              value={searchKey}
              onChange={(e) => handleSearch(e)}
            />
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
        <div className="combo-list">
          {combos?.map((combo, index) => {
            return (
              <div
                // className="d-flex flex-row justify-content-between align-items-center w-100"
                className={classNames(
                  'd-flex flex-row justify-content-between align-items-center w-100',
                  {
                    'bg-success': combo === selectedComboForPreview,
                  }
                )}
                onClick={() => {
                  handleComboItemClick(combo);
                }}
              >
                <div className="container pe-0 ps-0">
                  <div className="d-flex flex-row pb-3">
                    {/* <div className="col-auto border p-2 ">
                      <div className="form-check">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="auth-remember-check"
                          onClick={(e) => {
                            e.stopPropagation();
                            selectComboHandler(combo, index);
                          }}
                          checked={combo?.effectIds?.includes(effectId)}
                        />
                      </div>
                    </div> */}

                    <div className="col-4">
                      {combo?.rule?.products?.map((product, index) => (
                        <div
                          className={`d-flex flex-row border-bottom p-0 ${
                            index === 0 ? 'border-top' : ''
                          }`}
                        >
                          <div className="col-8 border-end p-2">
                            {product?.sku}
                          </div>
                          <div className="col-4 p-2">{product?.qty}</div>
                        </div>
                      ))}
                    </div>

                    <div className="col-3 border">
                      <div className="d-flex gap-0 flex-column align-items-center justify-content-center h-100">
                        <div className="text-capitalize">
                          {combo?.rule?.discountType?.replace('_', ' ')}
                        </div>
                        <div>{combo?.rule?.discountValue}</div>
                      </div>
                    </div>
                    {combo?.rule?.maxDiscount ? (
                      <div className="col-2 border-top border-bottom border-end">
                        <div className="d-flex gap-0 flex-column align-items-center justify-content-center h-100">
                          <div className="text-capitalize">Max Discount</div>
                          <div>{combo?.rule?.maxDiscount}</div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-auto border-end border-top border-bottom">
                      <div className="d-flex p-3 flex-column align-items-center justify-content-center h-100">
                        <CreateComboForm
                          effectId={effectId}
                          promotionId={promotionId}
                          submitHandler={createComboHandler}
                          comboProp={combo}
                        />

                        <a
                          size="sm"
                          className="cursor-pointer text-decoration-underline fs-13 mt-2"
                          color="outline-primary"
                          onClick={() => deleteComboByIdFunc(combo?._id)}
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="d-flex flex-row justify-content-between align-items-center mt-4">
          <div className="align-items-center justify-content-between row text-center text-sm-start">
            {totalPages > 1 ? (
              <div className="col-sm-auto mt-3 mt-sm-0">
                <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                  <li
                    className={`page-item  ${pageOffset < 1 ? 'disabled' : ''}`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => {
                        setPageOffset(pageOffset - 1);
                      }}
                    >
                      ←
                    </Link>
                  </li>

                  {pages?.map((page, index) => {
                    return (
                      <li
                        className={`page-item ${
                          pageOffset === index ? 'active' : ''
                        }`}
                      >
                        <Link
                          to="#"
                          className="page-link"
                          onClick={() => setPageOffset(index)}
                        >
                          {index + 1}
                        </Link>
                      </li>
                    );
                  })}

                  <li className="page-item">
                    <Link
                      to="#"
                      className={`page-link ${
                        pageOffset + 1 >= totalPages ? 'disabled' : ''
                      }`}
                      onClick={() => {
                        setPageOffset(pageOffset + 1);
                      }}
                    >
                      →
                    </Link>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
          {/* <Button
            color="primary"
            size="sm"
            onClick={() => {
              setIsOpenModal(false);
            }}
            type="button"
          >
            Save
          </Button> */}
        </div>

        <div className="d-flex flex-row mb-3 justify-content-end gap-2">
          <div>
            <CreateComboFromCsv
              effectId={effectId}
              promotionId={promotionId}
              submitHandler={createComboHandler}
            />
          </div>
          <div>
            <CreateComboForm
              effectId={effectId}
              promotionId={promotionId}
              submitHandler={createComboHandler}
            />
          </div>
        </div>

        {/* <Button
          color="primary"
          size="sm"
          onClick={() => {
            setIsOpenModal(true);
          }}
          className="pt-1 pb-1 w-25"
        >
          See More & Choose Combo
        </Button> */}
      </div>

      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(!isOpenModal);
        }}
        centered={true}
        size="lg"
        onClosed={() => {
          setPageOffset(0);
          setSearchKey('');
        }}
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            setIsOpenModal(!isOpenModal);
          }}
        >
          Please select the combos you wish to include.
        </ModalHeader>
        <ModalBody className="combos-modal">
          {/* <div className="d-flex flex-row mb-3 justify-content-end gap-2">
            <div>
              <CreateComboFromCsv
                effectId={effectId}
                promotionId={promotionId}
                submitHandler={createComboHandler}
              />
            </div>
            <div>
              <CreateComboForm
                effectId={effectId}
                promotionId={promotionId}
                submitHandler={createComboHandler}
              />
            </div>
          </div> */}

          {isLoading ? <InnerLoader /> : null}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Combo;
