import React from 'react';
import './assets/scss/themes.scss';
import Route from './routes';
/**
 *
 * @returns
 */
const App = () => {
  return (
    <>
      <Route />
    </>
  );
};

export default App;
