import React from 'react';
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from 'reactstrap';
import { If, Then } from 'react-if';
import EffectTransactionUnitBased from './EffectTransactionUnitBased';
import { DISCOUNT_TYPES } from '../../../constants';
import EffectTransactionConditional from './EffectTransactionConditional';
import Combo from '../Combo';
export default function EffectTransaction({
  effect,
  effectChangeHandler,
  eIndex,
  tIndex,
  discountTypes,
  promotionId,
}) {
  return (
    <>
      <FormGroup>
        <Label for="discount-type-select">Discount Type</Label>
        <Input
          type="select"
          id="discount-type-select"
          className="form-select"
          value={effect?.discountType}
          onChange={(e) => {
            effectChangeHandler(
              'discountType',
              e?.target?.value,
              eIndex,
              tIndex
            );
          }}
        >
          <option value={''}>-- Choose Discount Type --</option>
          {discountTypes?.map((item, index) => (
            <option value={item?.value} key={`dt-${index}`}>
              {item?.label}
            </option>
          ))}
        </Input>
      </FormGroup>

      <If
        condition={
          effect?.combinedUnitBasedDiscount === true &&
          effect.discountType === 'unitBased'
        }
      >
        <>
          <FormGroup>
            <Label for="discount-type-select">Discount Type</Label>
            <Input
              type="select"
              id="discount-type-select"
              value={effect?.combinedDiscountType}
              onChange={(e) => {
                effectChangeHandler(
                  'combinedDiscountType',
                  e?.target?.value,
                  eIndex,
                  tIndex
                );
                // handleValueChange(index, e.target.value, 'discountType');
              }}
            >
              {DISCOUNT_TYPES?.map((item, idx) => (
                <option value={item?.value} key={`dt-${idx}`}>
                  {item?.label}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="combined-discount-value">Discount Value</Label>
            <Input
              id="combined-discount-value"
              value={`${effect?.combinedDiscountValue}`}
              label="Discount Value"
              type="number"
              size="small"
              required
              style={{
                width: '100%',
              }}
              LabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                effectChangeHandler(
                  'combinedDiscountValue',
                  Number(e?.target?.value),
                  eIndex,
                  tIndex
                );
              }}
            />
          </FormGroup>
          <If condition={effect?.combinedDiscountType === 'percentage'}>
            <FormGroup>
              <Label for="combinedMaxValue">Maximum Value</Label>
              <Input
                id="combinedMaxValue"
                value={`${effect?.combinedMaxValue}`}
                label="Maximum Limit"
                type="number"
                size="small"
                required
                style={{
                  width: '100%',
                }}
                LabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  effectChangeHandler(
                    'combinedMaxValue',
                    Number(e?.target?.value),
                    eIndex,
                    tIndex
                  );
                }}
              />
            </FormGroup>
          </If>
        </>
      </If>
      <If condition={effect.applyLimitedCombination}>
        <FormGroup>
          <Label for="unitBasedLimitedCombination">Limited Combination</Label>
          <Input
            id="unitBasedLimitedCombination"
            value={`${effect?.unitBasedLimitedCombination}`}
            label="Limited Combination"
            type="number"
            size="small"
            required
            style={{
              width: '100%',
            }}
            LabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              effectChangeHandler(
                'unitBasedLimitedCombination',
                Number(e?.target?.value),
                eIndex,
                tIndex
              );
            }}
          />
        </FormGroup>
      </If>
      <If
        condition={
          effect?.useSegmentedMaxDiscount === true &&
          effect.discountType === 'segmented'
        }
      >
        <>
          <FormGroup>
            <Label for="segmentedMaxDiscount">Maximum Value</Label>
            <Input
              id="segmentedMaxDiscount"
              value={`${effect?.segmentedMaxDiscount}`}
              label="Maximum Limit"
              type="number"
              size="small"
              required
              style={{
                width: '100%',
              }}
              LabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                effectChangeHandler(
                  'segmentedMaxDiscount',
                  Number(e?.target?.value),
                  eIndex,
                  tIndex
                );
              }}
            />
          </FormGroup>
        </>
      </If>
      <If condition={effect?.discountType === 'unitBased'}>
        <div className="mt-n3 w-100">
          <FormGroup check inline className="">
            <Input
              id="combinedUnitBasedDiscount"
              type="checkbox"
              checked={effect?.combinedUnitBasedDiscount}
              // color="primary"
              onChange={() => {
                effectChangeHandler(
                  'combinedUnitBasedDiscount',
                  effect?.combinedUnitBasedDiscount ? false : true,
                  eIndex,
                  tIndex
                );
              }}
              // size="sm"
            ></Input>
            <Label for="combinedUnitBasedDiscount">
              Apply Combined Discount
            </Label>
          </FormGroup>
          <FormGroup check inline className="">
            <Input
              id={`applyLimitedCombination_${effect.id}`}
              type="checkbox"
              checked={effect?.applyLimitedCombination}
              // color="primary"
              onChange={() => {
                effectChangeHandler(
                  'applyLimitedCombination',
                  effect?.applyLimitedCombination ? false : true,
                  eIndex,
                  tIndex
                );
              }}
              // size="sm"
            ></Input>
            <Label for={`applyLimitedCombination_${effect.id}`}>
              Apply Limited Combination
            </Label>
          </FormGroup>
        </div>
      </If>
      <If condition={effect?.discountType === 'segmented'}>
        <>
          <div className="mt-n3 w-100">
            <FormGroup check inline className="">
              <Input
                id="useSegmentedMaxDiscount"
                type="checkbox"
                checked={effect?.useSegmentedMaxDiscount}
                // color="primary"
                onChange={() => {
                  effectChangeHandler(
                    'useSegmentedMaxDiscount',
                    effect?.useSegmentedMaxDiscount ? false : true,
                    eIndex,
                    tIndex
                  );
                }}
                // size="sm"
              ></Input>
              <Label for="useSegmentedMaxDiscount">
                Apply Maximum Discount For all segments
              </Label>
            </FormGroup>
          </div>
          <div className=" w-100">
            <FormGroup check inline className="">
              <Input
                id={`{weightedDiscountOnEachSegment${eIndex}-${tIndex}`}
                type="checkbox"
                checked={effect?.weightedDiscountOnEachSegment}
                // color="primary"
                onChange={() => {
                  effectChangeHandler(
                    'weightedDiscountOnEachSegment',
                    effect?.weightedDiscountOnEachSegment ? false : true,
                    eIndex,
                    tIndex
                  );
                }}
                // size="sm"
              ></Input>
              <Label for={`{weightedDiscountOnEachSegment${eIndex}-${tIndex}`}>
                Apply Weighted Discount on Each Group
              </Label>
            </FormGroup>
          </div>
        </>
      </If>
      <If
        condition={
          effect?.discountType === 'percentage' ||
          effect?.discountType === 'fixed'
        }
      >
        <>
          <If condition={!effect?.conditionalDiscounts}>
            <Then>
              <>
                <FormGroup>
                  <Label for="discount-value-number">Discount Value</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      id="discount-value-number"
                      value={effect?.discountValue}
                      onChange={(e) => {
                        effectChangeHandler(
                          'discountValue',
                          Number(e?.target?.value),
                          eIndex,
                          tIndex
                        );
                      }}
                      required
                      className="form-control"
                    />
                    {/* {effect?.discountType === "percentage" && (
                <InputGroupAddon addonType="append">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
              )} */}
                  </InputGroup>
                </FormGroup>

                <div className="">
                  <Label for="max-limit">Maximum Limit</Label>
                  <Input
                    id="max-limit"
                    value={`${effect?.maxLimit}`}
                    label="Maximum Limit"
                    type="number"
                    size="small"
                    required
                    style={{
                      width: '100%',
                    }}
                    LabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      effectChangeHandler(
                        'maxLimit',
                        Number(e?.target?.value),
                        eIndex,
                        tIndex
                      );
                    }}
                  />
                </div>
              </>
            </Then>
          </If>
          <div className="w-100">
            <FormGroup check inline className="">
              <Input
                id="conditionalDiscounts"
                type="checkbox"
                checked={effect?.conditionalDiscounts}
                // color="primary"
                onChange={() => {
                  effectChangeHandler(
                    'conditionalDiscounts',
                    effect?.conditionalDiscounts ? false : true,
                    eIndex,
                    tIndex
                  );
                }}
                // size="sm"
              ></Input>
              <Label for="conditionalDiscounts">
                Use Conditional Discounts
              </Label>
            </FormGroup>
          </div>
          {effect?.conditionalDiscounts && (
            <div className="w-100">
              <EffectTransactionConditional
                onChange={effectChangeHandler}
                eIndex={eIndex}
                tIndex={tIndex}
                effect={effect}
              />
            </div>
          )}
        </>
      </If>
      <If condition={effect.discountType === 'combo'}>
        <>
          <div className="d-flex w-100">
            <Combo
              promotionId={promotionId}
              effectId={effect?.id}
              // handleComboItemClick={handleComboItemClick}
            />
          </div>
        </>
      </If>
      {(effect?.discountType === 'unitBased' ||
        effect?.discountType === 'segmented' ||
        effect?.discountType === 'gift') && (
        <>
          <EffectTransactionUnitBased
            onChange={effectChangeHandler}
            eIndex={eIndex}
            tIndex={tIndex}
            effect={effect}
          />
        </>
      )}
    </>
  );
}
