import React from 'react';
import { Spinner } from 'reactstrap';

const InnerLoader = (props) => {
  return (
    <div className="d-flex justify-content-center align-items-center mx-2 mt-2 position-absolute top-0 left-0 right-0 bottom-0 w-100 h-100">
      <Spinner color="primary"> Loading... </Spinner>
    </div>
  );
};

export default InnerLoader;
