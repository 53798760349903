import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { RevenueChartData } from './RevenueChartData';
import { getChartTransactionsData } from '../../../actions/wallet';

const RevenueChart = () => {
  const [chartData, setchartData] = useState([]);
  const [seletedChartType, setSeletedChartType] = useState('currentYear');
  useEffect(() => {
    getTransactionData();
  }, [seletedChartType]);
  const getTransactionData = async () => {
    try {
      const data = {
        country: 'ae',
        chartType: seletedChartType,
      };
      // Call your API function to fetch transactions
      const res = await getChartTransactionsData(data);
      if (res?.success) {
        setchartData(res?.data?.data || []);

        console.log(res?.data?.data, 'response');
      } else {
        console.error(
          'Failed to fetch transactions:',
          res?.error || 'Unknown error'
        );
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const chartDataArray = [
    {
      today: [
        {
          name: 'Revenue',
          data: [10, 45, 30, 35, 50, 55, 70, 120, 150, 160, 210, 240],
        },
        {
          name: 'Expenses',
          data: [12, 17, 75, 82, 44, 35, 52, 75, 112, 108, 56, 289],
        },
      ],
    },
    {
      lastWeek: [
        {
          name: 'Revenue',
          data: [40, 55, 40, 35, 20, 35, 40, 70, 110, 140, 230, 210],
        },
        {
          name: 'Expenses',
          data: [32, 37, 65, 22, 44, 85, 42, 75, 122, 118, 156, 199],
        },
      ],
    },
    {
      lastMonth: [
        {
          name: 'Revenue',
          data: [40, 35, 20, 65, 80, 65, 120, 90, 50, 80, 170, 150],
        },
        {
          name: 'Expenses',
          data: [22, 37, 25, 62, 34, 75, 142, 145, 122, 108, 136, 199],
        },
      ],
    },
    {
      currentYear: [
        {
          name: 'Revenue',
          data: [40, 55, 40, 35, 20, 35, 40, 70, 110, 140, 230, 210],
        },
        {
          name: 'Expenses',
          data: [32, 37, 65, 22, 44, 85, 42, 75, 122, 118, 156, 199],
        },
      ],
    },
  ];

  const getTotal = (key) => {
    const transData = chartData.find((item) => item.name === key);
    if (transData) {
      // Sum the values in the Credit data array
      const total = transData.data.reduce((acc, curr) => acc + curr, 0);
      if (total > 10000) {
        return `AED ${(total / 1000).toFixed(2)} K`;
      } else {
        return `AED ${total}`;
      }
    }
  };
  const onChangeChartPeriod = (pType) => {
    setSeletedChartType(pType);
    // setchartData(getDataForKey(pType));
  };
  return (
    <React.Fragment>
      <Card className="card-height-100">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Wallet Revenue Overview
          </h4>
          <div className="flex-shrink-0">
            <UncontrolledDropdown className="card-header-dropdown">
              <DropdownToggle
                className="text-reset dropdown-btn"
                tag="a"
                role="button"
              >
                <span className="fw-semibold text-uppercase fs-12">
                  Sort by:{' '}
                </span>
                <span className="text-muted">
                  {seletedChartType.charAt(0).toUpperCase() +
                    seletedChartType.slice(1)}
                  <i className="mdi mdi-chevron-down ms-1"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    onChangeChartPeriod('today');
                  }}
                  className={seletedChartType === 'today' ? 'active' : ''}
                >
                  Today
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    onChangeChartPeriod('lastWeek');
                  }}
                  className={seletedChartType === 'lastWeek' ? 'active' : ''}
                >
                  Last Week
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    onChangeChartPeriod('lastMonth');
                  }}
                  className={seletedChartType === 'lastMonth' ? 'active' : ''}
                >
                  Last Month
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    onChangeChartPeriod('currentYear');
                  }}
                  className={seletedChartType === 'currentYear' ? 'active' : ''}
                >
                  Current Year
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </CardHeader>
        <CardBody className="px-0">
          <ul className="list-inline main-chart text-center mb-0">
            <li className="list-inline-item chart-border-left me-0 border-0">
              <h4 className="text-primary">
                {getTotal('Credit')}
                <span className="text-muted d-inline-block fs-13 align-middle ms-2">
                  Credit
                </span>
              </h4>
            </li>
            <li className="list-inline-item chart-border-left me-0">
              <h4>
                {getTotal('Debit')}
                <span className="text-muted d-inline-block fs-13 align-middle ms-2">
                  Debit
                </span>
              </h4>
            </li>
          </ul>

          <div id="revenue-expenses-charts" dir="ltr">
            <RevenueChartData
              series={chartData}
              dataColors='["--vz-success", "--vz-danger"]'
              selectType={seletedChartType}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RevenueChart;
