import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Input,
  Button,
} from 'reactstrap';

const ErrorMessageCaptureModal = ({
  show,
  onDeleteClick,
  onCloseClick,
  rule,
  onChange,
}) => {
  const [value, setValue] = useState(rule.errorMessage);
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader>Set an Error Message On Failures</ModalHeader>
      <hr />
      <ModalBody className="">
        <Row>
          <Col>
            <Label>English</Label>
            <Input
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              required
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Label>Arabic</Label>
            <Input
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              required
            />
          </Col>
        </Row>
      </ModalBody>
      <hr />
      <ModalFooter>
        <Button
          type="button"
          className="btn w-sm btn-light"
          data-bs-dismiss="modal"
          onClick={onCloseClick}
        >
          Close
        </Button>
        <Button
          type="button"
          className="btn w-sm btn-danger "
          id="delete-record"
          onClick={() => {
            onChange(value);
            onCloseClick();
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ErrorMessageCaptureModal;
