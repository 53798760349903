import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Container, Row, Button, Alert } from 'reactstrap';

// Import Images
import emailVerificationImage from '../../assets/images/email-verification.jpeg';

const EmailVerification = () => {
  document.title = 'Email Verification';

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const status = query.get('status');
  const msg = query.get('msg');

  let message = '';
  let alertColor = '';
  let info = '';

  if (status === 'true') {
    message = msg || 'Your email has been successfully verified!';
    alertColor = 'success';
    info =
      'We will sent you the password on your email, please check and login.';
  } else {
    message =
      msg || 'There was an issue verifying your email. Please try again.';
    alertColor = 'danger';
  }

  return (
    <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
      <div className="auth-page-content overflow-hidden p-0 text-center">
        <Container fluid>
          <Row className="justify-content-center">
            <Col xl={4} className="text-center">
              <img
                src={emailVerificationImage}
                alt="Email Verification"
                className="img-fluid mb-4"
                style={{ width: '70%' }}
              />
              <h1 className="display-4">
                {status === 'true' ? 'Success!' : 'Verification Failed'}
              </h1>
              <Alert color={alertColor} className="mb-4">
                {message}
                <br />
                {info}
              </Alert>

              <Link to="/dashboard">
                <Button color="success" className="btn-lg">
                  <i className="mdi mdi-home me-2"></i>Back to Home
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default EmailVerification;
