import { useCallback, useEffect, useState } from 'react';
import {
  defaultValidator,
  QueryBuilder,
  Rule,
  update,
  RuleComponents,
} from 'react-querybuilder';
import toArray from 'lodash.toarray';
import Select from 'react-select';
import { convertFieldToQueryBuilderFormat } from '../../../helpers/convertFieldToQueryBuilderFormat';
import 'react-querybuilder/dist/query-builder.css';
// import './queryBuilder.css';
// import './style.scss';
import '../../Ahlan/CreateTierMaster/styles.scss';
import { getAllFields } from '../../../actions/promotion/fields';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Label,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
} from 'reactstrap';
import ErrorMessageCaptureModal from '../../../components/Common/ErrorMessageCaptureModal';
import { QueryBuilderBootstrap } from '@react-querybuilder/bootstrap';
import CustomValueEditor from './CustomValueEditor';
import { Else, If, Then } from 'react-if';
import { useSelector } from 'react-redux';
import RuleText from './RuleText';
import CustomCombinator from './CustomCombinator';
import RewardTierRuleGroup from '../../Reward/RewardTypes/RewardTierRuleGroup';

export const validator = (r) => !!r.value;

const RuleRenderer = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const { fieldsMap } = useSelector((state) => state.promotions || {});
  return (
    <div
      wrap
      gap="small"
      className="mb-0 trigger-hover ruleGroup p-0 border-0 bg-transparent abcd "
    >
      <If condition={isEdit}>
        <Then>
          <RuleText
            operation={props.rule}
            onEditToggle={() => {
              setIsEdit(!isEdit);
            }}
            fieldsMap={fieldsMap}
          />
          <Modal isOpen={isEdit} toggle={() => setIsEdit(!isEdit)} size="lg">
            <ModalHeader
              className="modal-title pb-3 bg-light"
              toggle={() => setIsEdit(!isEdit)}
            >
              Create / Edit Rule
            </ModalHeader>
            <ModalBody>
              <div className="rulePopup trigger-hover">
                <Rule {...props} />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => setIsEdit(!isEdit)}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </Then>
        <Else>
          <RuleText
            operation={props.rule}
            onEditToggle={() => {
              setIsEdit(!isEdit);
            }}
            fieldsMap={fieldsMap}
          />
        </Else>
      </If>

      {/* <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} marginTop={1}>
          <Input
            value={props.rule?.errorMessage}
            onChange={onChange}
            required
          />
        </Col>
      </Row> */}
    </div>
  );
};

const initialQuery = { combinator: 'and', rules: [] };

export const QueryBuilderApp = (props) => {
  const {
    updateQuery = () => {},
    data,
    fieldGroups = [],
    isUseRewardTierRuleGroup = false,
  } = props;
  const [query, setQuery] = useState(data || initialQuery);

  const { fields } = useSelector((state) => state.promotions || {});
  const [selectedGroup, setSelectedGroup] = useState(null);
  function handleSelectGroups(selectedGroup) {
    setSelectedGroup(selectedGroup);
  }

  const handleQueryChange = (value) => {
    setQuery(value);
    updateQuery(value);
  };

  useEffect(() => {
    if (data?.rules?.length) {
      setQuery(data);
    } else {
      setQuery(initialQuery);
    }
  }, [data]);

  const CustomRuleAction = (props) => {
    const { handleOnClick, label, ...rest } = props;

    return (
      <a
        size="sm"
        style={{ width: 82, right: 153 }}
        className="cursor-pointer text-decoration-underline position-absolute fs-11"
        color="outline-primary"
        onClick={handleOnClick}
        // icon={<SearchOutlined />}
      >
        +New Condition
      </a>
    );
  };
  const CustomGroupAction = (props) => {
    const { handleOnClick, label, ...rest } = props;

    return (
      <a
        size="sm"
        style={{ width: 117, right: 18 }}
        className="cursor-pointer text-decoration-underline position-absolute fs-11"
        color="outline-primary"
        onClick={handleOnClick}
        // icon={<SearchOutlined />}
      >
        +New Condition Group
      </a>
    );
  };
  const CustomRemoveRuleAction = (props) => {
    const {
      handleOnClick,
      label,
      ruleOrGroup: rule,
      schema,
      path,
      ...rest
    } = props;
    const onChange = (e) => {
      schema.dispatchQuery(
        update(schema.getQuery(), 'errorMessage', e?.target?.value, path)
      );
    };
    const [showModal, setShowModal] = useState(false);

    return (
      <span className="">
        <Button
          aria-label="delete"
          color="soft-dark"
          onClick={handleOnClick}
          size="sm"
          className="shadow-none rule-remove btn-danger fs-20"
        >
          <i class="bi bi-x"></i>
        </Button>
        <a
          className="show-on-hover cursor-pointer "
          type="default"
          shape="circle"
          icon={<i className="bx bx-cog fs-22"></i>}
          onClick={() => setShowModal(true)}
        >
          {showModal && (
            <ErrorMessageCaptureModal
              show={showModal}
              onCloseClick={() => setShowModal(false)}
              rule={rule}
              onChange={(value) => onChange({ target: { value } })}
            />
          )}
          <i class="bi bi-gear"></i>
        </a>
      </span>
    );
  };
  const getGroupedFields = useCallback(() => {
    const groupedFieldsObj = fields?.reduce((acc, field) => {
      if (
        (fieldGroups?.length && fieldGroups?.includes(field.group)) ||
        !fieldGroups?.length
      ) {
        if (!acc[field.group]) {
          acc[field.group] = { label: field.group, options: [] };
        }
        acc[field.group].options.push({
          ...field,
          // isOptionSelected: value === field.value,
        });
        return acc;
      }

      return acc;
    }, {});
    return toArray(groupedFieldsObj);
  }, [fields]);
  const groupedFields = getGroupedFields();

  const CustomFieldSelector = ({
    handleOnChange,
    value,
    schema,
    path,
    ...rest
  }) => {
    const [showSubOpertator, setShowSubOpertator] = useState(false);

    return (
      <>
        <Select
          value={fields.find((field) => field.value === value)}
          // searchable
          onChange={(item) => {
            handleOnChange(item.value);
          }}
          styles={{ minWidth: 200 }}
          options={groupedFields}
        />
      </>
    );
  };

  const CustomOperatorSelector = ({
    handleOnChange,
    value,
    options,
    fieldData,
    schema,
    path,
    rule,
    ...rest
  }) => {
    return (
      <>
        <If condition={fieldData?.name?.indexOf(':') > -1}>
          <Select
            value={fields.find((field) => field?.value === rule?.computeField)}
            // searchable
            onChange={(item) => {
              schema.dispatchQuery(
                update(schema.getQuery(), 'computeField', item.value, path)
              );
            }}
            styles={{ minWidth: 200 }}
            options={fields.filter((field) => field.enableSumQty)}
          />
        </If>
        <Select
          value={options.find((option) => option.value === value)}
          // searchable
          onChange={(item) => {
            handleOnChange(item.value);
          }}
          styles={{ maxWidth: 100, width: 50 }}
          options={options}
        />
      </>
    );
  };

  let controlElements = {
    rule: RuleRenderer,
    fieldSelector: CustomFieldSelector,
    // valueSelector: CustomValueSelector,
    operatorSelector: CustomOperatorSelector,
    addRuleAction: CustomRuleAction,
    addGroupAction: CustomGroupAction,
    removeRuleAction: CustomRemoveRuleAction,
    valueEditor: CustomValueEditor,
    combinatorSelector: CustomCombinator,
  };

  if (isUseRewardTierRuleGroup) {
    controlElements = { ...controlElements, ruleGroup: RewardTierRuleGroup };
  }

  return (
    <QueryBuilderBootstrap>
      <QueryBuilderDnD
        dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}
        enableDragAndDrop={false}
      >
        <QueryBuilder
          disabled={props.disabled}
          fields={groupedFields || fields}
          query={query}
          addRuleToNewGroups
          listsAsArrays
          showNotToggle={false}
          validator={defaultValidator}
          controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
          onQueryChange={handleQueryChange}
          combinators={[
            { name: 'and', label: 'All of the following:' },
            { name: 'or', label: 'Any of the following:' },
            // { name: 'CUSTOM', label: 'Custom Combination' },
          ]}
          controlElements={controlElements}
        />

        {/* <div>{JSON.stringify(query)}</div> */}
        {/* <div>{JSON.stringify(formatQuery(query, 'jsonlogic'))}</div> */}
      </QueryBuilderDnD>
    </QueryBuilderBootstrap>
  );
};

QueryBuilderApp.propTypes = {
  updateQuery: PropTypes.func,
  data: PropTypes.any,
};

export default QueryBuilderApp;
