import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

//import images
import { ROUTE_USER_LOGOUT, ROUTE_PROFILE } from '../../constants/route';
import { useNavigate } from 'react-router-dom';
import { generateColor } from '../../helpers/general';
import { COUNTRIES } from '../../constants';

const ProfileDropdown = () => {
  //
  const history = useNavigate();

  //
  const { user: userAdmin } = useSelector((state) => state?.user || {});
  const { firstName, lastName, privilege, country } = userAdmin || {};
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);

  // toggle
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  // change route
  const changeRoute = (route) => {
    history(route);
  };

  return (
    <>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn shadow-none">
          <span className="d-flex align-items-center">
            <div className="flex-shrink-0 align-self-center me-2">
              <div className="avatar-xs">
                <span
                  className="avatar-title rounded-circle text-uppercase"
                  style={{
                    background: generateColor(`${firstName}`),
                  }}
                >
                  {firstName && firstName[0]}
                </span>
              </div>
            </div>
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {firstName} {lastName}
              </span>
              <span
                className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text"
                style={{ textTransform: 'capitalize' }}
              >
                <span className="me-1">
                  {COUNTRIES?.find((CN) => CN?.value === country)?.flag}{' '}
                </span>
                {privilege?.replace('_', ' ')}{' '}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {firstName}!</h6>

          <DropdownItem onClick={() => changeRoute(ROUTE_PROFILE)}>
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Help</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>

          <DropdownItem onClick={() => changeRoute(ROUTE_PROFILE)}>
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem>
          <DropdownItem onClick={() => changeRoute(ROUTE_PROFILE)}>
            <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Lock screen</span>
          </DropdownItem>
          <DropdownItem onClick={() => changeRoute(ROUTE_USER_LOGOUT)}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ProfileDropdown;
