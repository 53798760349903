import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  ButtonGroup,
} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';

// third-party

import './coupon.scss';

// project-imports

import {
  updatePromotionSchedule,
  createOrUpdateCoupon,
  getCoupons,
  updatePromotionTriggers,
  deleteCouponById,
} from '../../../actions/promotion';

import { QueryBuilderApp } from '../QueryBuilderApp';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';

import CheckboxBigSquare from '../../../components/Common/CheckboxBigSquare';
import CreateCouponOptions from './CreateCouponOptions';
import { If } from 'react-if';

// constant

export const DISCARD_SUBSEQUENTLY_OPTIONS = [
  {
    value: 'except_individual',
    label: 'All Promotions(Except Individual Run)',
  },
  {
    value: 'select_promotions',
    label: 'Only Select Promotions',
  },
  {
    value: 'all_except_few_selected',
    label: 'All Promotions Except Few Selected',
  },
];

const PromotionCoupons = ({
  onCancel = () => {},
  getStores = () => {},
  promotionId,
  promotionDetail,
  updateHandler = () => {},
}) => {
  let navigate = useNavigate();
  const [isCouponRequired, setIsCouponRequired] = useState(
    promotionDetail.promotionType !== 'campaign'
  );
  const [coupons, setCoupons] = useState([]);
  const [couponQuery, setCouponQuery] = useState(null);
  const [priority, setPriority] = useState(0);
  const [discard, setDiscard] = useState('except_individual');
  const [alwaysRun, setAlwaysRun] = useState(false);

  useEffect(() => {
    setCouponQuery(promotionDetail?.triggerRules);
    setPriority(promotionDetail?.basicSettings?.priority);
    setDiscard(promotionDetail?.basicSettings?.discard);
    setAlwaysRun(promotionDetail?.basicSettings?.alwaysRun);
    setIsCouponRequired(promotionDetail?.promotionType !== 'campaign');
  }, [promotionDetail]);

  const saveTriggers = async () => {
    // dispatch(setIsShowRuleValidationError(true));
    if (true) {
      // const triggers = generateTriggerJsonLogic(query);
      const variables = {
        input: {
          promotionId: promotionId,
          triggerRules: couponQuery,
          promotionType: isCouponRequired ? 'coupon' : 'campaign',
          discard,
          priority,
          alwaysRun,
        },
      };

      const { success, data, message } = await updatePromotionTriggers(
        variables
      );
      if (success) {
        alertSuccess(message);
      } else {
        alertError('Failed!, Please try again after some time');
      }
      // dispatch(setIsShowRuleValidationError(false));
    } else {
      console.log('Invalid query');
    }
  };

  return (
    <CardBody className="p-4">
      <Row className="mb-4">
        <Col xs={12}>
          <h6 class="mb-0 fw-semibold text-uppercase">Trigger Type</h6>
          <div className="mb-2">
            <p class="text-muted">When to trigger this campaign?</p>
          </div>
          <Row>
            <CheckboxBigSquare
              id="manual-coupon"
              title="Applying a Coupon"
              description=" This promotion will be triggered when a coupon is
                        applied"
              onChange={(event) => {
                setIsCouponRequired(true);
              }}
              checked={isCouponRequired}
              icon="bx bxs-coupon"
            />
            <CheckboxBigSquare
              id="auto-applied"
              title="Auto Applied"
              description="This promotion is auto applied when the trigger
                        conditions are met"
              onChange={(event) => {
                setIsCouponRequired(false);
              }}
              checked={!isCouponRequired}
              icon="ri-compass-discover-line"
            />
            <CheckboxBigSquare
              id="auto-applied"
              title="Activity Based"
              description="This campaign is activated based on an activity or an event. Eg: User Registration, Wishlist (Not on transactions)"
              onChange={(event) => {
                setIsCouponRequired(false);
              }}
              checked={!isCouponRequired}
              icon="ri-flag-2-line"
            />
            <CheckboxBigSquare
              id="calendar-based"
              title="User Event Based"
              description="This campaign is triggered based on user eg:- birthday, anniversary, etc."
              onChange={(event) => {
                setIsCouponRequired(false);
              }}
              checked={!isCouponRequired}
              icon="ri-calendar-event-line"
            />

            <div />
          </Row>
        </Col>
      </Row>
      <If condition={!isCouponRequired}>
        <>
          <Row className="mb-3">
            <Col>
              <h6 class="mb-0 fw-semibold text-uppercase">ALWAYS RUN</h6>

              <div class="form-check form-switch form-switch-lg" dir="ltr">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="alwaysRun"
                  checked={alwaysRun}
                  onChange={(event) => {
                    setAlwaysRun(event.target.checked);
                  }}
                />

                {/* <label class="form-check-label" for="customSwitchsizelg">
                  Always Run
                </label> */}
              </div>
              <If condition={alwaysRun}>
                <>
                  <small class="text-muted">
                    This promotion will run regradless of other promotion's
                    "Discard Subsequently" settings
                  </small>
                </>
              </If>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <h6 class="mb-0 fw-semibold text-uppercase">
                  <Label for="discard">Discard Subsequently </Label>
                </h6>
                <Select
                  options={DISCARD_SUBSEQUENTLY_OPTIONS}
                  value={DISCARD_SUBSEQUENTLY_OPTIONS.find((opt) => {
                    return opt.value === discard;
                  })}
                  onChange={(selected) => {
                    setDiscard(selected?.value);
                  }}
                  // classNamePrefix="select"
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      </If>

      {/* {!promotionId?.includes('__v') && (
        <Row className="mb-2">
          <Col xs={12}>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  checked={!isCouponRequired}
                  onChange={() => setIsCouponRequired(!isCouponRequired)}
                  size="md"
                />
                No Coupon Required
              </Label>
            </FormGroup>
          </Col>
        </Row>
      )} */}

      {isCouponRequired ? (
        <CreateCouponOptions
          promotionId={promotionId}
          promotionDetail={promotionDetail}
          onCancel={onCancel}
        />
      ) : (
        <>
          <Row className="mb-4 mt-4">
            <Col>
              <h6 class="mb-0 fw-semibold text-uppercase">Trigger Condition</h6>
              <div className="mb-2">
                <p class="text-muted">
                  This promotion will be applied only when the below conditions
                  are met
                </p>
              </div>
              <QueryBuilderApp
                updateQuery={setCouponQuery}
                data={couponQuery}
                fieldGroups={['basket', 'user']}
              />
            </Col>
          </Row>
          {!promotionId?.includes('__v') && (
            <div className="d-flex gap-2 justify-content-end">
              <Button outline color="danger" size="md" onClick={() => {}}>
                Cancel
              </Button>

              <Button
                loading={false}
                color="primary"
                size="md"
                className="bg-gradient px-5"
                onClick={() => {
                  saveTriggers();
                }}
              >
                {promotionDetail ? 'Save Draft' : 'Save & Next'}
              </Button>
            </div>
          )}
        </>
      )}
    </CardBody>
  );
};

PromotionCoupons.propTypes = {
  promotion: PropTypes.any,
  onCancel: PropTypes.func,
  promotionId: PropTypes.any,
  promotionDetail: PropTypes.any,
  updateHandler: PropTypes.func,
};

export default PromotionCoupons;
