import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

// Import Images
import error500 from '../../assets/images/404-error.png';

const UnAuthorized = () => {
  document.title = 'Un Authorized Error  ';

  return (
    <React.Fragment>
      <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="auth-page-content overflow-hidden p-0">
          <Container fluid={true}>
            <Row className="justify-content-center">
              <Col xl={4} className="text-center">
                <div>
                  <img
                    width={'70%'}
                    src={error500}
                    alt=""
                    className="img-fluid error-500-img error-img"
                  />
                  <h4>Un Authorized Error!</h4>
                  <p className="text-muted w-75 mx-auto">
                    You do not have sufficient role to perform this action.
                  </p>
                  <Link to="/dashboard" className="btn btn-success">
                    <i className="mdi mdi-home me-1"></i>Back to home
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UnAuthorized;
