import { createSlice } from '@reduxjs/toolkit';
//constants
import {
  layoutTypes,
  leftSidebarTypes,
  layoutModeTypes,
  layoutWidthTypes,
  layoutPositionTypes,
  topBarThemeTypes,
  leftSidBarSizeTypes,
  leftSideBarViewTypes,
  preloaderTypes,
  sideBarVisibilityTypes,
} from '../../constants/layout';

export const initialState = {
  layoutType: layoutTypes.VERTICAL,
  leftSidebarType: leftSidebarTypes.DARK,
  layoutModeType: layoutModeTypes.LIGHT_MODE,
  layoutWidthType: layoutWidthTypes.FLUID,
  layoutPositionType: layoutPositionTypes.FIXED,
  topBarThemeType: topBarThemeTypes.LIGHT,
  leftSidBarSizeType: leftSidBarSizeTypes.DEFAULT,
  leftSideBarViewType: leftSideBarViewTypes.DEFAULT,
  preloader: preloaderTypes.DISABLE,
  sideBarVisibilityType: sideBarVisibilityTypes.SHOW,
};

const LayoutSlice = createSlice({
  name: 'LayoutSlice',
  initialState,
  reducers: {
    changeLayoutAction(state, action) {
      state.layoutType = action.payload;
    },
    changeLayoutModeAction(state, action) {
      state.layoutModeType = action.payload;
    },
    changeSidebarThemeAction(state, action) {
      state.leftSidebarType = action.payload;
    },
    changeLayoutWidthAction(state, action) {
      state.layoutWidthType = action.payload;
    },
    changeLayoutPositionAction(state, action) {
      state.layoutPositionType = action.payload;
    },
    changeTopBarThemeAction(state, action) {
      state.topBarThemeType = action.payload;
    },
    changeLeftSideBarSizeTypeAction(state, action) {
      state.leftSidBarSizeType = action.payload;
    },
    changeLeftSideBarViewTypeAction(state, action) {
      state.leftSideBarViewType = action.payload;
    },

    changePreLoaderAction(state, action) {
      state.preloader = action.payload;
    },
    changeSidebarVisibilityAction(state, action) {
      state.sideBarVisibilityType = action.payload;
    },
  },
});

export const {
  changeLayoutAction,
  changeLayoutModeAction,
  changeSidebarThemeAction,
  changeLayoutWidthAction,
  changeLayoutPositionAction,
  changeTopBarThemeAction,
  changeLeftSideBarSizeTypeAction,
  changeLeftSideBarViewTypeAction,
  changePreLoaderAction,
  changeSidebarVisibilityAction,
} = LayoutSlice.actions;

export default LayoutSlice.reducer;
