import React from 'react';
import { Link } from 'react-router-dom';
import {
  ButtonGroup,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { ROUTE_USER_CREATE } from '../../constants/route';
import moment from 'moment';
import { STATUS_CLASS } from '../../constants';

const User = ({ item, roleList }) => {
  const {
    _id,
    firstName,
    lastName,
    email,
    emailVerified,
    mobile,
    roles = [],
    createdAt,
    updatedAt,
  } = item || {};

  return (
    <>
      <td>
        <div className="d-flex gap-2 align-items-start">
          <div>
            <p className="my-1">
              <strong className="p-0">
                {firstName} {lastName}
              </strong>
            </p>
            <div className="d-flex flex-column align-items-left gap-0 p-0">
              <p className="fs-11 text-muted fw-normal d-flex flex-wrap gap-1 m-0">
                Created at: {moment(createdAt).format('LL')},{' '}
                {moment(createdAt).format('LT')}
              </p>
              <p className="fs-11 text-muted fw-normal d-flex flex-wrap gap-1 m-0">
                Update at: {moment(updatedAt).format('LL')},{' '}
                {moment(updatedAt).format('LT')}
              </p>
            </div>
          </div>
        </div>
      </td>
      <td>
        <p className="p-0 m-0 mb-1 d-flex align-items-center gap-1">
          {email}
          <a href="#" className="opacity-50">
            <i class="bx bxs-copy fs-16 text-muted"></i>
          </a>
          <span
            className={`badge rounded-pill py-1 fw-normal mx-1 d-flex align-items-center bg-${STATUS_CLASS[emailVerified]}`}
          >
            <i className={'bx bx-check-double'}></i>
            {emailVerified ? 'Verified' : 'Not Verified'}
          </span>
        </p>
        <p className="p-0 m-0">{mobile}</p>
      </td>
      <td>
        <div className="d-inline-flex flex-column align-items-start gap-2">
          {roleList
            ?.filter((role) => roles?.includes(role?.value))
            .map((role, index) => (
              <p
                key={index}
                className={`badge ${
                  role.value === 'default_role'
                    ? 'bg-warning-subtle text-warning'
                    : 'border border-dark text-dark opacity-25'
                } rounded-pill d-inline-flex align-items-center gap-1 m-0`}
              >
                <i className="bx bx-user-circle fs-13"></i>
                {role.label}
              </p>
            ))}
        </div>
      </td>

      <td>
        <span
          className={`badge bg-soft-${STATUS_CLASS[item?.status]} text-${
            STATUS_CLASS[item?.status]
          } rounded-pill text-capitalize`}
        >
          {item.status}
        </span>
      </td>
      <td>
        <ButtonGroup>
          <UncontrolledDropdown>
            <DropdownToggle
              tag="button"
              className="btn btn-light fs-22 px-1 shadow-none border border-1"
              style={{
                lineHeight: 0,
                paddingTop: '14px',
                paddingBottom: '10px',
              }}
            >
              <i className="ri-more-fill"></i>
            </DropdownToggle>
            <DropdownMenu className="p-0">
              <Link to={ROUTE_USER_CREATE?.replace(':id', _id)}>
                <DropdownItem className="p-2 text-dark fw-11">
                  <i className="bx bx-edit-alt"></i> Edit
                </DropdownItem>
              </Link>

              <DropdownItem className="p-2 text-danger fw-11">
                <i className="bx bx-trash"></i> Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ButtonGroup>
      </td>
    </>
  );
};

export default User;
