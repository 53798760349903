import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Input,
  Label,
  Form,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  getRolesOptionsListAction,
  viewSubUserAction,
  updateUserAction,
  createUserAction,
} from '../../../actions/auth/action';
import { COUNTRIES } from '../../../constants';
import {
  ROUTE_ROLE_LISTING,
  ROUTE_USER_LISTING,
} from '../../../constants/route';
import { useNavigate, useParams } from 'react-router-dom';

// Validation schema
const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email address is required'),
  country: Yup.object().nullable().required('Country is required'),
  roles: Yup.array().of(Yup.object()).required('At least one role is required'),
  additionalRoles: Yup.array().of(Yup.object()).nullable(),
});

const CreateUsers = () => {
  const history = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  // change route
  const changeRoute = (route) => {
    history(route);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const roles = await getRolesOptionsListAction();
        setRolesOptions(roles);

        if (id && id !== 'new') {
          setIsEditMode(true);
          const userData = await viewSubUserAction(id);

          // Update Formik values
          formik.setValues({
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            roles:
              userData.roles
                ?.map((role) => roles.find((r) => r.value === role))
                .filter(Boolean) || [],

            country:
              COUNTRIES.find((country) => country.value === userData.country) ||
              null,

            additionalRoles:
              userData.additionalRoles
                ?.map((role) => roles.find((r) => r.value === role))
                .filter(Boolean) || [],
          });
        } else {
          setIsEditMode(false);
        }
      } catch (error) {
        setRolesOptions([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Formik hook
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      roles: [],
      additionalRoles: [],
      country: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      // setIsLoading(true);
      // const countryCode = values?.country?.value;
      // const extraRoleKeys =
      //   values?.additionalRoles?.map((ar) => ar?.value) || [];
      // const roleKeys = values?.roles?.map((ar) => ar?.value) || [];
      // const { country, roles, additionalRoles, ...rest } = values || {};

      // const submittedValues = {
      //   ...rest,
      //   country: countryCode,
      //   roles: roleKeys,
      //   extraRoles: extraRoleKeys,
      // };
      console.log(values);
      // try {
      //   if (isEditMode) {
      //     // await updateUserAction({ id, input: submittedValues });
      //     // Add success handling here
      //   } else {
      //     // await createUserAction({ input: submittedValues });
      //     // Add success handling here
      //   }
      // } catch (error) {
      //   // Add error handling here
      // } finally {
      //   setIsLoading(false);
      // }
    },
  });

  // Handle Select change
  const handleSelectChange = (field) => (selectedOption) => {
    formik.setFieldValue(field, selectedOption);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          titleInActive={'All Users'}
          titleActive={isEditMode ? `Edit User` : 'Create User'}
        />
        <Card>
          <CardBody className="card-body">
            <Form onSubmit={formik.handleSubmit}>
              <div className="mb-4 border rounded-1">
                <h5 className="border-bottom p-3 bg-light">User information</h5>
                <div className="p-3">
                  <Row>
                    <Col className="mb-3">
                      <Label htmlFor="firstName" className="form-label">
                        First name *
                      </Label>
                      <Input
                        type="text"
                        className={`form-control ${
                          formik.errors.firstName && formik.touched.firstName
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="firstName"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.firstName && formik.touched.firstName && (
                        <div className="text-danger">
                          {formik.errors.firstName}
                        </div>
                      )}
                    </Col>
                    <Col className="mb-3">
                      <Label htmlFor="lastName" className="form-label">
                        Last name *
                      </Label>
                      <Input
                        type="text"
                        className={`form-control ${
                          formik.errors.lastName && formik.touched.lastName
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="lastName"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.lastName && formik.touched.lastName && (
                        <div className="text-danger">
                          {formik.errors.lastName}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Label htmlFor="email" className="form-label">
                        Email address *
                      </Label>
                      <Input
                        type="email"
                        className={`form-control ${
                          formik.errors.email && formik.touched.email
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <div className="text-danger">{formik.errors.email}</div>
                      )}
                    </Col>
                    <Col className="mb-3">
                      <Label htmlFor="country" className="form-label">
                        Country *
                      </Label>
                      <Select
                        id="country"
                        name="country"
                        value={formik.values.country}
                        isClearable={true}
                        onChange={handleSelectChange('country')}
                        options={COUNTRIES}
                      />
                      {formik.errors.country && formik.touched.country && (
                        <div className="text-danger">
                          {formik.errors.country}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Label htmlFor="roles" className="form-label">
                        Roles *
                      </Label>
                      <Select
                        isMulti
                        id="roles"
                        name="roles"
                        value={formik.values.roles}
                        isClearable={true}
                        onChange={handleSelectChange('roles')}
                        options={rolesOptions}
                      />
                      {formik.errors.roles && formik.touched.roles && (
                        <div className="text-danger">{formik.errors.roles}</div>
                      )}
                    </Col>
                    <Col className="mb-3">
                      <Label htmlFor="additionalRoles" className="form-label">
                        Additional Role *
                      </Label>
                      <Select
                        id="additionalRoles"
                        name="additionalRoles"
                        value={formik.values.additionalRoles}
                        isMulti={true}
                        isClearable={true}
                        onChange={handleSelectChange('additionalRoles')}
                        options={rolesOptions}
                      />
                      {formik.errors.additionalRoles &&
                        formik.touched.additionalRoles && (
                          <div className="text-danger">
                            {formik.errors.additionalRoles}
                          </div>
                        )}
                    </Col>
                  </Row>
                </div>
              </div>

              <Row>
                <Col className="mb-3">
                  <p className="p-2 bg-light rounded-1 border text-center fst-italic text-muted">
                    After clicking 'Create new user,' an email will be sent to
                    the user's email address with a verification link. Once the
                    email is verified, the user will receive another email with
                    their password!.
                  </p>
                  <hr />
                  <div className="d-flex gap-2 justify-content-end">
                    <Button
                      type="button"
                      outline
                      color="dark"
                      size="md"
                      onClick={() => changeRoute(ROUTE_ROLE_LISTING)}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      size="md"
                      className="bg-gradient px-5"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spinner size="sm" className="me-2">
                          Loading...
                        </Spinner>
                      ) : null}
                      {isEditMode ? 'Update User' : 'Create User'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default CreateUsers;
