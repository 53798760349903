import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import RewardTypeList from './RewardsMasterList';
import BreadCrumb from '../../../components/Common/BreadCrumb';

const RewardsMaster = () => {
  document.title = 'Rewards';
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Admin'} titleActive="Rewards" />
        <Card>
          <CardBody className="border">
            <RewardTypeList />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default RewardsMaster;
