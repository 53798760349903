import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import profileBg from '../../assets/images/auth-one-bg.jpg';
import { alertSuccess, generateColor } from '../../helpers/general';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { passwordResetAction } from '../../actions/login/action';
import { useDispatch, useSelector } from 'react-redux';

/**
 *
 * @returns
 */
const Settings = () => {
  document.title = 'Profile Settings ';

  //
  const { user: userState } = useSelector((state) => state.user || {});
  const { firstName, lastName, role, email, telephone } = userState || {};
  const [activeTab, setActiveTab] = useState('1');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  // form validation

  const passwordValidation = Yup.string()
    .required('Please enter your password')
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      'Password must contain at least one numeric character and one special character'
    );
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: null,
      newPassword: null,
      confirmNewPassword: null,
    },
    validationSchema: Yup.object({
      oldPassword: passwordValidation,
      newPassword: passwordValidation,
      confirmNewPassword: passwordValidation,
    }),

    onSubmit: (values) => {
      setIsLoading(true);
      const { oldPassword, newPassword, confirmNewPassword } = values || {};
      passwordReset({ oldPassword, newPassword, confirmNewPassword });
      validation.resetForm();
    },
  });

  // Login Api Call
  const passwordReset = async ({
    oldPassword,
    newPassword,
    confirmNewPassword,
  }) => {
    const { token = null } = await passwordResetAction(
      { input: { oldPassword, newPassword, confirmNewPassword } },
      dispatch,
      history
    );
    if (token) {
      alertSuccess('Password Reset Successfully...');
    }
  };

  setTimeout(() => {
    setIsLoading(false);
  }, 3500);
  /**
   *
   * @param {*} tab
   */
  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={profileBg} className="profile-wid-img" alt="" />
            </div>
          </div>
          <Row>
            <Col md={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                      <div className="flex-shrink-0 align-self-center me-2">
                        <div className="avatar-md fs-22">
                          <span
                            className="avatar-title rounded-circle text-uppercase"
                            style={{
                              background: generateColor(`${firstName}`),
                            }}
                          >
                            {firstName && firstName[0]}
                          </span>
                        </div>
                      </div>
                    </div>
                    <h5 className="fs-16 mb-1">
                      {firstName} {lastName}
                    </h5>
                    <p
                      className="text-muted mb-0"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {role?.replace('-', ' ')}
                    </p>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-5">
                    <div className="flex-grow-1">
                      <h5 className="card-title mb-0">Complete Your Profile</h5>
                    </div>
                  </div>
                  <div className="progress animated-progress custom-progress progress-label">
                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      style={{ width: '30%' }}
                      aria-valuenow="30"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="label">30%</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={9}>
              <Card className="mt-md-n5">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          tabChange('1');
                        }}
                      >
                        <i className="fas fa-home"></i>
                        Personal Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          tabChange('2');
                        }}
                        type="button"
                      >
                        <i className="far fa-user"></i>
                        Change Password
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => {
                          tabChange('3');
                        }}
                        type="button"
                      >
                        <i className="far fa-envelope"></i>
                        Privacy Policy
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Form>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="firstnameInput"
                                className="form-label"
                              >
                                First Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="firstnameInput"
                                placeholder="Enter your firstname"
                                defaultValue={firstName}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="lastnameInput"
                                className="form-label"
                              >
                                Last Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="lastnameInput"
                                placeholder="Enter your lastname"
                                defaultValue={lastName}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="phonenumberInput"
                                className="form-label"
                              >
                                Telephone Number
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter your phone number"
                                value={telephone}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="emailInput"
                                className="form-label"
                              >
                                Email Address
                              </Label>
                              <Input
                                type="email"
                                className="form-control"
                                id="emailInput"
                                placeholder="Enter your email"
                                defaultValue={email}
                                disabled={!!email}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="designationInput"
                                className="form-label"
                              >
                                Role
                              </Label>
                              <Input
                                type="text"
                                className="form-control text-uppercase"
                                placeholder="Role"
                                disabled
                                value={role}
                              />
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <button type="button" className="btn btn-primary">
                                Updates
                              </button>
                              <button
                                type="button"
                                className="btn btn-soft-success"
                              >
                                Cancel
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>

                    {/*  Reset Pswd */}
                    <TabPane tabId="2">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <Row className="g-2">
                          <Col lg={4}>
                            <div>
                              <Label className="form-label">
                                Old Password*
                              </Label>
                              <Input
                                type="password"
                                className="form-control"
                                id="oldPassword"
                                placeholder="Enter current password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.oldPassword || ''}
                                invalid={
                                  validation.touched.oldPassword &&
                                  validation.errors.oldPassword
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.oldPassword &&
                              validation.errors.oldPassword ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.oldPassword}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div>
                              <Label className="form-label">
                                New Password*
                              </Label>
                              <Input
                                type="password"
                                className="form-control"
                                id="newPassword"
                                placeholder="Enter new password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.newPassword || ''}
                                invalid={
                                  validation.touched.newPassword &&
                                  validation.errors.newPassword
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.newPassword &&
                              validation.errors.newPassword ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.newPassword}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div>
                              <Label className="form-label">
                                Confirm Password*
                              </Label>
                              <Input
                                type="password"
                                className="form-control"
                                placeholder="Confirm password"
                                id="confirmNewPassword"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.confirmNewPassword || ''
                                }
                                invalid={
                                  validation.touched.confirmNewPassword &&
                                  validation.errors.confirmNewPassword
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.confirmNewPassword &&
                              validation.errors.confirmNewPassword ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.confirmNewPassword}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="text-end">
                              <Button
                                color="success"
                                disabled={isLoading ? true : false}
                                className="btn btn-success w-25"
                                type="submit"
                              >
                                {isLoading ? (
                                  <Spinner size="sm" className="me-2">
                                    Loading...
                                  </Spinner>
                                ) : null}
                                Reset Password{' '}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                      <div className="mt-4 mb-3 border-bottom pb-2">
                        <h5 className="card-title">Login History</h5>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <div className="flex-shrink-0 avatar-sm">
                          <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                            <i className="ri-smartphone-line"></i>
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h6>iPhone 12 Pro</h6>
                          <p className="text-muted mb-0">
                            Los Angeles, United States - March 16 at 2:47PM
                          </p>
                        </div>
                        <div>
                          <Link to="#">Logout</Link>
                        </div>
                      </div>
                    </TabPane>
                    {/*  Reset Pswd  */}

                    <TabPane tabId="3">
                      <div className="mb-4 pb-2">
                        <h5 className="card-title text-decoration-underline mb-3">
                          Security:
                        </h5>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                          <div className="flex-grow-1">
                            <h6 className="fs-14 mb-1">
                              Two-factor Authentication
                            </h6>
                            <p className="text-muted">
                              Two-factor authentication is an enhanced security
                              meansur. Once enabled, you'll be required to give
                              two types of identification when you log into
                              Google Authentication and SMS are Supported.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Enable Two-facor Authentication
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                          <div className="flex-grow-1">
                            <h6 className="fs-14 mb-1">
                              Secondary Verification
                            </h6>
                            <p className="text-muted">
                              The first factor is a password and the second
                              commonly includes a text with a code sent to your
                              smartphone, or biometrics using your fingerprint,
                              face, or retina.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Set up secondary method
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                          <div className="flex-grow-1">
                            <h6 className="fs-14 mb-1">Backup Codes</h6>
                            <p className="text-muted mb-sm-0">
                              A backup code is automatically generated for you
                              when you turn on two-factor authentication through
                              your iOS or Android Twitter app. You can also
                              generate a backup code on twitter.com.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Generate backup codes
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <h5 className="card-title text-decoration-underline mb-3">
                          Application Notifications:
                        </h5>
                        <ul className="list-unstyled mb-0">
                          <li className="d-flex">
                            <div className="flex-grow-1">
                              <label
                                htmlFor="directMessage"
                                className="form-check-label fs-14"
                              >
                                Direct messages
                              </label>
                              <p className="text-muted">
                                Messages from people you follow
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="directMessage"
                                  defaultChecked
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-14"
                                htmlFor="desktopNotification"
                              >
                                Show desktop notifications
                              </Label>
                              <p className="text-muted">
                                Choose the option you want as your default
                                setting. Block a site: Next to "Not allowed to
                                send notifications," click Add.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="desktopNotification"
                                  defaultChecked
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-14"
                                htmlFor="emailNotification"
                              >
                                Show email notifications
                              </Label>
                              <p className="text-muted">
                                Under Settings, choose Notifications. Under
                                Select an account, choose the account to enable
                                notifications for.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="emailNotification"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-14"
                                htmlFor="chatNotification"
                              >
                                Show chat notifications
                              </Label>
                              <p className="text-muted">
                                To prevent duplicate mobile notifications from
                                the Gmail and Chat apps, in settings, turn off
                                Chat notifications.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="chatNotification"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-14"
                                htmlFor="purchaesNotification"
                              >
                                Show purchase notifications
                              </Label>
                              <p className="text-muted">
                                Get real-time purchase alerts to protect
                                yourself from fraudulent charges.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="purchaesNotification"
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <h5 className="card-title text-decoration-underline mb-3">
                          Delete This Account:
                        </h5>
                        <p className="text-muted">
                          Go to the Data & Privacy section of your profile
                          Account. Scroll to "Your data & privacy options."
                          Delete your Profile Account. Follow the instructions
                          to delete your account :
                        </p>
                        <div>
                          <Input
                            type="password"
                            className="form-control"
                            id="passwordInput"
                            placeholder="Enter your password"
                            defaultValue="make@321654987"
                            style={{ maxWidth: '265px' }}
                          />
                        </div>
                        <div className="hstack gap-2 mt-3">
                          <Link to="#" className="btn btn-soft-danger">
                            Close & Delete This Account
                          </Link>
                          <Link to="#" className="btn btn-light">
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Settings;
