import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../../components/Common/ChartsDynamicColor";

const MostEngagedCategories = ({ dataColors }) => {
  var chartDatalabelsBarColors = getChartColorsArray(dataColors);
  const series = [
    {
      data: [400, 430, 448, 470, 540, 580],
    },
  ];
  var options = {
    chart: {
      type: "bar",
      height: 500,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: chartDatalabelsBarColors,
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
        fontSize: 11,
        fontWeight: 400,
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      offsetX: 0,
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      width: 5,
      colors: ["#fff"],
    },
    xaxis: {
      categories: [
        "Furniture",
        "Garden",
        "Sofas & Recliners",
        "Bath Accessories",
        "Kitchen Accessories",
        "Swings",
      ],
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    title: {
      //   text: "Custom DataLabels",
      align: "center",
      floating: true,
      style: {
        fontWeight: 400,
        fontSize: 12,
      },
    },
    subtitle: {
      //   text: "Category Names as DataLabels inside bars",
      align: "center",
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        className="apex-charts"
        options={options}
        series={series}
        type="bar"
        height={300}
      />
    </React.Fragment>
  );
};

export { MostEngagedCategories };
