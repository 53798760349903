import React, { useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  FormGroup,
  Label,
  InputGroupText,
} from 'reactstrap';
import { If } from 'react-if';
import QueryBuilderApp from '../QueryBuilderApp';
import { DISCOUNT_TYPES } from '../../../constants';
import EffectTransactionConditional from './EffectTransactionConditional';

const UnitBasedOption = ({
  optionCount,
  index,
  handleValueChange,
  handleRemoveOption,
  option,
  combinedUnitBasedDiscount,
  effect = {},
}) => {
  const [showDiscountRule, setShowDiscountRule] = useState(
    !!option?.discountValue
  );
  const [showItemQueryBuilder, setShowItemQueryBuilder] = useState(
    !!option?.rule || true
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <div
      key={index}
      className="border p-3 mb-3 rounded position-relative"
      style={{ background: 'white' }}
    >
      <div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <Label className="mb-0">
            <b>{`${effect.discountType === 'gift' ? 'Gift' : 'Bundle'} ${
              index + 1
            }`}</b>{' '}
            <i>of</i> <b>{optionCount}</b>
          </Label>
          <Button
            aria-label="delete"
            color="soft-dark"
            onClick={() => handleRemoveOption(index)}
            size="sm"
            className="shadow-none rule-remove btn-danger fs-20 p-0"
          >
            <i class="bi bi-x"></i>
          </Button>
        </div>
        <If condition={effect.discountType !== 'segmented'}>
          <FormGroup>
            <InputGroup>
              <Input
                type="select"
                value={option.groupUnitBy}
                onChange={(e) =>
                  handleValueChange(index, e.target.value, 'groupUnitBy')
                }
              >
                <option value="fixed">Group By Fixed Units</option>
                <option value="range">Group By Range of Units</option>
              </Input>
              <If condition={option.groupUnitBy === 'range'}>
                <>
                  <InputGroupText>Between</InputGroupText>
                  <Input
                    type="number"
                    value={option.minUnits}
                    onChange={(e) =>
                      handleValueChange(index, e.target.value, 'minUnits')
                    }
                  />

                  <InputGroupText>And</InputGroupText>
                </>
              </If>
              <Input
                type="number"
                value={option.onUnit}
                onChange={(e) =>
                  handleValueChange(index, e.target.value, 'onUnit')
                }
              />
              <InputGroupText>
                <If condition={option.groupUnitBy === 'range'}>Max </If> Units
              </InputGroupText>
              <If condition={index > 0}>
                <Input
                  id={`matchWithGroup${effect.id}_${index}`}
                  type="select"
                  checked={option?.matchItemAgainstGroup}
                  // color="primary"
                  onChange={(e) => {
                    handleValueChange(
                      index,
                      e.target.value,
                      'matchItemAgainstGroup'
                    );
                  }}
                  // size="sm"
                >
                  <option value="-1" key={`dt-none`}>
                    --Select Match --
                  </option>
                  {new Array(index).fill(1).map((i, idx) => {
                    return (
                      <option value={idx} key={`dt-${i}`}>
                        {idx === 0 ? 'Bundle Item 1' : `Bundle Item ${idx + 1}`}
                      </option>
                    );
                  })}
                </Input>

                <InputGroupText>Match Items Of Group</InputGroupText>
              </If>
            </InputGroup>
            <FormGroup check inline className="">
              <Input
                id={`sameItem${effect.id}_${index}`}
                type="checkbox"
                checked={option?.applicableOnOnSameItem}
                // color="primary"
                onChange={() => {
                  handleValueChange(
                    index,
                    !option.applicableOnOnSameItem,
                    'applicableOnOnSameItem'
                  );
                }}
                // size="sm"
              ></Input>
              <Label for={`sameItem${effect.id}_${index}`}>
                Applicable Only Same Item / SKU
              </Label>
            </FormGroup>
          </FormGroup>
        </If>
        <If condition={showItemQueryBuilder}>
          <>
            <div className="p-3 border rounded-1">
              <If
                condition={
                  option.matchItemAgainstGroup === undefined ||
                  option.matchItemAgainstGroup === '' ||
                  option.matchItemAgainstGroup === '-1'
                }
              >
                <div>
                  <label className=" mb-3 text-muted text-small">
                    Choose Items Statisfying below filters
                  </label>
                  <QueryBuilderApp
                    data={option.rule || undefined}
                    updateQuery={(query) =>
                      handleValueChange(index, query, 'rule')
                    }
                    fieldGroups={['item']}
                  />
                </div>
              </If>
              <If
                condition={
                  !combinedUnitBasedDiscount ||
                  effect.discountType !== 'unitBased'
                }
              >
                <>
                  <div className="d-flex gap-2 mt-3">
                    {showDiscountRule ? (
                      <>
                        <FormGroup className="mb-1">
                          <Label for="discount-type-select">
                            Discount Type
                          </Label>
                          <Input
                            disabled={
                              combinedUnitBasedDiscount &&
                              effect.discountType === 'unitBased'
                            }
                            type="select"
                            id="discount-type-select"
                            value={option?.discountType}
                            onChange={(e) =>
                              handleValueChange(
                                index,
                                e.target.value,
                                'discountType'
                              )
                            }
                          >
                            {DISCOUNT_TYPES?.map((item, idx) => (
                              <option value={item?.value} key={`dt-${idx}`}>
                                {item?.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                        <If condition={!option?.conditionalDiscounts}>
                          <>
                            <FormGroup>
                              <Label for="discount-value-number">
                                Discount Value
                              </Label>
                              <Input
                                disabled={
                                  combinedUnitBasedDiscount &&
                                  effect.discountType === 'unitBased'
                                }
                                type="number"
                                id="discount-value-number"
                                value={`${option?.discountValue}`}
                                required
                                onChange={(e) =>
                                  handleValueChange(
                                    index,
                                    e.target.value,
                                    'discountValue'
                                  )
                                }
                              />
                            </FormGroup>
                            <If
                              condition={option?.discountType === 'percentage'}
                            >
                              <FormGroup>
                                <Label for="discount-value-number">
                                  Maximum Discount Value
                                </Label>
                                <Input
                                  disabled={
                                    combinedUnitBasedDiscount &&
                                    effect.discountType === 'unitBased'
                                  }
                                  type="number"
                                  id="discount-value-number"
                                  value={`${option?.maxDiscountValue}`}
                                  required
                                  onChange={(e) =>
                                    handleValueChange(
                                      index,
                                      e.target.value,
                                      'maxDiscountValue'
                                    )
                                  }
                                />
                              </FormGroup>
                            </If>
                          </>
                        </If>
                      </>
                    ) : null}
                  </div>

                  <div className="w-100">
                    <FormGroup check inline className="">
                      <Input
                        id={`conditionalDiscounts-${effect.id}_${index}`}
                        type="checkbox"
                        checked={option?.conditionalDiscounts}
                        // color="primary"
                        onChange={() => {
                          handleValueChange(
                            index,
                            option?.conditionalDiscounts ? false : true,
                            'conditionalDiscounts'
                          );
                        }}
                        // size="sm"
                      ></Input>
                      <Label for={`conditionalDiscounts-${effect.id}_${index}`}>
                        Use Conditional Discounts
                      </Label>
                    </FormGroup>
                  </div>
                  {option?.conditionalDiscounts && (
                    <div className="w-100">
                      <EffectTransactionConditional
                        onChange={(key, values) => {
                          handleValueChange(index, values, key);
                        }}
                        eIndex={0}
                        tIndex={0}
                        effect={option}
                      />
                    </div>
                  )}
                  <div className="d-flex gap-2">
                    <Button
                      color={
                        !showDiscountRule ? 'outline-primary' : 'outline-danger'
                      }
                      size="sm"
                      onClick={() => {
                        handleValueChange(index, 0, 'discountValue');
                        setShowDiscountRule(!showDiscountRule);
                      }}
                    >
                      {showDiscountRule ? 'Remove ' : 'Apply '} Discount Rule
                    </Button>
                  </div>
                </>
              </If>
            </div>
          </>
        </If>
      </div>
    </div>
  );
};

export default UnitBasedOption;
