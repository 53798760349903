import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withRouter from '../Common/withRouter';

//import Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import RightSidebar from '../Common/RightSidebar';

//import actions
import {
  changeLayout,
  changeSideBarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopBarTheme,
  changeLeftSideBarSizeType,
  changeLeftSideBarViewType,
  changeSidebarVisibility,
} from '../../actions/layouts/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';

const Layout = (props) => {
  const dispatch = useDispatch();
  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topBarThemeType,
    leftSidBarSizeType,
    leftSideBarViewType,
    sideBarVisibilityType,
  } = useSelector((state) => ({
    layoutType: state.layout.layoutType,
    leftSidebarType: state.layout.leftSidebarType,
    layoutModeType: state.layout.layoutModeType,
    layoutWidthType: state.layout.layoutWidthType,
    layoutPositionType: state.layout.layoutPositionType,
    topBarThemeType: state.layout.topBarThemeType,
    leftSidBarSizeType: state.layout.leftSidBarSizeType,
    leftSideBarViewType: state.layout.leftSideBarViewType,
    sideBarVisibilityType: state.layout.sideBarVisibilityType,
  }));

  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topBarThemeType ||
      leftSidBarSizeType ||
      leftSideBarViewType ||
      sideBarVisibilityType
    ) {
      window.dispatchEvent(new Event('resize'));
      dispatch(changeLeftSideBarViewType(leftSideBarViewType));
      dispatch(changeLeftSideBarSizeType(leftSidBarSizeType));
      dispatch(changeSideBarTheme(leftSidebarType));
      dispatch(changeLayoutMode(layoutModeType));
      dispatch(changeLayoutWidth(layoutWidthType));
      dispatch(changeLayoutPosition(layoutPositionType));
      dispatch(changeTopBarTheme(topBarThemeType));
      dispatch(changeLayout(layoutType));
      dispatch(changeSidebarVisibility(sideBarVisibilityType));
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topBarThemeType,
    leftSidBarSizeType,
    leftSideBarViewType,
    sideBarVisibilityType,
    dispatch,
  ]);
  /*
    call dark/light mode
    */
  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };

  const [headerClass, setHeaderClass] = useState('');
  // className add remove in header
  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);
  });
  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass('topbar-shadow');
    } else {
      setHeaderClass('');
    }
  }

  useEffect(() => {
    if (
      sideBarVisibilityType === 'show' ||
      layoutType === 'vertical' ||
      layoutType === 'twocolumn'
    ) {
      document.querySelector('.hamburger-icon').classList.remove('open');
    } else {
      document.querySelector('.hamburger-icon').classList.add('open');
    }
  }, [sideBarVisibilityType, layoutType]);

  return (
    <>
      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType}
          onChangeLayoutMode={onChangeLayoutMode}
        />
        <Sidebar layoutType={layoutType} />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
      <RightSidebar />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
};

export default withRouter(Layout);
