import React, { useState } from 'react';
import { Button } from 'reactstrap';

const Pagination = ({ totalItems = 0, itemsPerPage = 10, onPageChange }) => {
  if (!totalItems) {
    return <></>;
  }
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    if (onPageChange) onPageChange(page);
  };

  const getPageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 5; // Show a maximum of 5 pages
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
      <div className="col-sm">
        <div className="text-muted">
          Showing page <span className="fw-semibold">{currentPage}</span> of{' '}
          <span className="fw-semibold">{totalPages}</span>
        </div>
      </div>
      <div className="col-sm-auto mt-3 mt-sm-0">
        <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
          {/* First Page Button */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <Button
              className="page-link"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              « First
            </Button>
          </li>
          {/* Previous Page Button */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <Button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              ← Prev
            </Button>
          </li>
          {/* Dynamic Page Numbers */}
          {getPageNumbers().map((page) => (
            <li
              key={page}
              className={`page-item ${currentPage === page ? 'active' : ''}`}
            >
              <Button
                className="page-link"
                onClick={() => handlePageChange(page)}
              >
                {page}
              </Button>
            </li>
          ))}
          {/* Next Page Button */}
          <li
            className={`page-item ${
              currentPage === totalPages ? 'disabled' : ''
            }`}
          >
            <Button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next →
            </Button>
          </li>
          {/* Last Page Button */}
          <li
            className={`page-item ${
              currentPage === totalPages ? 'disabled' : ''
            }`}
          >
            <Button
              className="page-link"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              Last »
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pagination;
