import promoRestService from '../../services/PromoRestService';
export const createOrUpdateCombo = async (data) => {
  try {
    const result = await promoRestService?.post('combo/createOrUpdate', data);
    return result?.data;
  } catch (error) {
    return null;
  }
};

export const getCombos = async (data) => {
  try {
    const result = await promoRestService?.post('combo/list', data);
    return result?.data;
  } catch (error) {
    return null;
  }
};

export const createOrUpdateComboFromCsv = async (data) => {
  try {
    const result = await promoRestService?.post(
      'combo/createOrUpdateFromCsv',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return result?.data;
  } catch (error) {
    return null;
  }
};

export const deleteComboById = async (data) => {
  try {
    const result = await promoRestService?.post('combo/delete', data);
    return result?.data;
  } catch (error) {
    return null;
  }
};
