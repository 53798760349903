import React from "react";
import ReactEcharts from "echarts-for-react";

import getChartColorsArray from "../../../../components/Common/ChartsDynamicColor";

//Pie Chart
const ChannelPreference = ({ dataColors }) => {
  var chartPieColors = getChartColorsArray(dataColors);
  var option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
      textStyle: {
        //The style of the legend text
        color: "#858d98",
      },
    },
    color: chartPieColors,
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: "50%",
        data: [
          {
            value: 1048,
            name: "Search Engine",
          },
          {
            value: 735,
            name: "Direct",
          },
          {
            value: 580,
            name: "Email",
          },
          {
            value: 484,
            name: "Union Ads",
          },
          {
            value: 300,
            name: "Video Ads",
          },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
    textStyle: {
      fontFamily: "Poppins, sans-serif",
    },
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={option} />
    </React.Fragment>
  );
};

//Doughnut Chart
const DevicesUsage = ({ dataColors }) => {
  var chartDoughnutColors = getChartColorsArray(dataColors);
  var option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "5%",
      left: "center",
      textStyle: {
        //The style of the legend text
        color: "#858d98",
      },
    },
    color: chartDoughnutColors,
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: "16",
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: 1048,
            name: "Mobile",
          },
          {
            value: 735,
            name: "Website",
          },
          {
            value: 580,
            name: "App",
          },
        ],
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={option} />
    </React.Fragment>
  );
};

export { ChannelPreference, DevicesUsage };
