import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import classnames from 'classnames';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import TransactionDetailsPopup from './TransactionDetailsPopup';

const TransactionTable = () => {
  // Card Header Tabs
  const [cardHeaderTab, setcardHeaderTab] = useState('1');
  const [isLoading, setIsLoading] = useState(false);
  const cardHeaderToggle = (tab) => {
    if (cardHeaderTab !== tab) {
      setcardHeaderTab(tab);
    }
  };

  useEffect(() => {
    loadRewardsTransactions();
  });

  const loadRewardsTransactions = async () => {
    setIsLoading(true);
    try {
    } catch (e) {
      setIsLoading(false);
      console.log(e, 'error');
    }
  };

  const TransactionDetailsAll = [
    {
      id: '1',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      AhlanId: '21213323232',
      customerName: 'Abdul',
      ahlanTier: 'Silver',
      ahlanTierClass: 'silver',
      description: 'Earned',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '2',
      date: 'Tue 19 Dec',
      time: '08:20 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Bari',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Redeemed',
      descriptionNote: 'Store: danubehome.com, Ref: SO123476',
      amount: '- 100 Nos',
      transactionType: 'Debited',
      feaIconClass: 'text-danger',
      feaIcon: 'arrow-up-right',
    },
    {
      id: '3',
      date: 'Tue 19 Dec',
      time: '08:30 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Sajeer K',
      description: 'Earned',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      descriptionNote: 'Store: Itc, Ref: SO129786',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '4',
      date: 'Tue 19 Dec',
      time: '08:31 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Rishal',
      ahlanTier: 'Platinum',
      ahlanTierClass: 'platinum',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 1000 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '5',
      date: 'Tue 19 Dec',
      time: '08:36 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Akhil',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 50 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '6',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      AhlanId: '21213323232',
      customerName: 'Rashid',
      ahlanTier: 'Platinum',
      ahlanTierClass: 'platinum',
      description: 'Earned',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '7',
      date: 'Tue 19 Dec',
      time: '08:20 AM',
      transactionId: '13232325444',
      customerName: 'jamal',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      AhlanId: '2333223232',
      description: 'Redeemed',
      descriptionNote: 'Store: danubehome.com, Ref: SO123476',
      amount: '- 100 Nos',
      transactionType: 'Debited',
      feaIconClass: 'text-danger',
      feaIcon: 'arrow-up-right',
    },
    {
      id: '8',
      date: 'Tue 19 Dec',
      time: '08:30 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'SRK',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Earned',
      descriptionNote: 'Store: Itc, Ref: SO129786',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '9',
      date: 'Tue 19 Dec',
      time: '08:31 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      ahlanTier: 'Silver',
      ahlanTierClass: 'silver',
      customerName: 'Ali',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 1000 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '10',
      date: 'Tue 19 Dec',
      time: '08:36 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      customerName: 'Shameem',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 50.20 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
  ];
  const TransactionDetailsEarned = [
    {
      id: '1',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      AhlanId: '21213323232',
      customerName: 'Abdul',
      ahlanTier: 'Silver',
      ahlanTierClass: 'silver',
      description: 'Earned',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '2',
      date: 'Tue 19 Dec',
      time: '08:20 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Bari',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Redeemed',
      descriptionNote: 'Store: danubehome.com, Ref: SO123476',
      amount: '- 100 Nos',
      transactionType: 'Debited',
      feaIconClass: 'text-danger',
      feaIcon: 'arrow-up-right',
    },
    {
      id: '3',
      date: 'Tue 19 Dec',
      time: '08:30 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Sajeer K',
      description: 'Earned',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      descriptionNote: 'Store: Itc, Ref: SO129786',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '4',
      date: 'Tue 19 Dec',
      time: '08:31 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Rishal',
      ahlanTier: 'Platinum',
      ahlanTierClass: 'platinum',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 1000 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '5',
      date: 'Tue 19 Dec',
      time: '08:36 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Akhil',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 50 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '6',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      AhlanId: '21213323232',
      customerName: 'Rashid',
      ahlanTier: 'Platinum',
      ahlanTierClass: 'platinum',
      description: 'Earned',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
  ];
  const TransactionDetailsRedeem = [
    {
      id: '1',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      AhlanId: '21213323232',
      customerName: 'Abdul',
      ahlanTier: 'Silver',
      ahlanTierClass: 'silver',
      description: 'Earned',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '2',
      date: 'Tue 19 Dec',
      time: '08:20 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Bari',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Redeemed',
      descriptionNote: 'Store: danubehome.com, Ref: SO123476',
      amount: '- 100 Nos',
      transactionType: 'Debited',
      feaIconClass: 'text-danger',
      feaIcon: 'arrow-up-right',
    },
    {
      id: '3',
      date: 'Tue 19 Dec',
      time: '08:30 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Sajeer K',
      description: 'Earned',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      descriptionNote: 'Store: Itc, Ref: SO129786',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '4',
      date: 'Tue 19 Dec',
      time: '08:31 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Rishal',
      ahlanTier: 'Platinum',
      ahlanTierClass: 'platinum',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 1000 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
  ];
  const TransactionDetailsReturns = [
    {
      id: '1',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      AhlanId: '21213323232',
      customerName: 'Abdul',
      ahlanTier: 'Silver',
      ahlanTierClass: 'silver',
      description: 'Earned',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '2',
      date: 'Tue 19 Dec',
      time: '08:20 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Bari',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Redeemed',
      descriptionNote: 'Store: danubehome.com, Ref: SO123476',
      amount: '- 100 Nos',
      transactionType: 'Debited',
      feaIconClass: 'text-danger',
      feaIcon: 'arrow-up-right',
    },
  ];

  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };
  return (
    <React.Fragment>
      <div className="table-card">
        <div className="card-header align-items-center d-flex">
          <div className="flex-shrink-0 ms-2">
            <Nav
              tabs
              className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
            >
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({ active: cardHeaderTab === '1' })}
                  onClick={() => {
                    cardHeaderToggle('1');
                  }}
                >
                  All
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({ active: cardHeaderTab === '2' })}
                  onClick={() => {
                    cardHeaderToggle('2');
                  }}
                >
                  Earned
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({ active: cardHeaderTab === '3' })}
                  onClick={() => {
                    cardHeaderToggle('3');
                  }}
                >
                  Redeem
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({ active: cardHeaderTab === '4' })}
                  onClick={() => {
                    cardHeaderToggle('4');
                  }}
                >
                  Returns
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        <CardBody className="border-right border-left">
          <TabContent activeTab={cardHeaderTab} className="text-muted">
            <TabPane tabId="1" id="ahlan-all">
              <div className="table-responsive table-card">
                <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Date</th>
                      <th>Transaction ID</th>
                      <th>Ahlan ID</th>
                      <th>Ahlan Tier</th>
                      <th>Description</th>
                      <th>Points</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(TransactionDetailsAll || []).map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-13 my-1">{item.id}</h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">{item.date}</h5>
                          <span className="fs-10 text-muted">{item.time}</span>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.transactionId}
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.AhlanId}
                          </h5>
                          <span className="fs-10 text-muted">
                            {item.customerName}
                          </span>
                        </td>
                        <td>
                          <h5
                            className={`fs-10 my-1  badge ${item?.ahlanTierClass}`}
                          >
                            {item.ahlanTier}
                          </h5>
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <span className={`${item?.feaIconClass}`}>
                              <FeatherIcon
                                icon={item?.feaIcon}
                                style={{ width: 16 }}
                              />
                            </span>
                            <div>
                              <h5 className="fs-13 my-1 fw-normal">
                                {item.description}
                              </h5>
                              <p className="fs-10 mb-0 text-muted">
                                {item.descriptionNote}
                              </p>
                              {item.expDate ? (
                                <p className="fs-10 mb-0 text-warning">
                                  Expired on {item.expDate}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5
                            className={`${item?.feaIconClass} fs-13 my-1 fw-semiBold`}
                          >
                            {item.amount}
                          </h5>
                        </td>
                        <td>
                          <TransactionDetailsPopup />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPane>
            <TabPane tabId="2" id="ahlan-earned">
              <div className="table-responsive table-card">
                <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Date</th>
                      <th>Transaction ID</th>
                      <th>Ahlan ID</th>
                      <th>Ahlan Tier</th>
                      <th>Description</th>
                      <th>Points</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(TransactionDetailsEarned || []).map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-13 my-1">{item.id}</h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">{item.date}</h5>
                          <span className="fs-10 text-muted">{item.time}</span>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.transactionId}
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.AhlanId}
                          </h5>
                          <span className="fs-10 text-muted">
                            {item.customerName}
                          </span>
                        </td>
                        <td>
                          <h5
                            className={`fs-10 my-1  badge ${item?.ahlanTierClass}`}
                          >
                            {item.ahlanTier}
                          </h5>
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <span className={`${item?.feaIconClass}`}>
                              <FeatherIcon
                                icon={item?.feaIcon}
                                style={{ width: 16 }}
                              />
                            </span>
                            <div>
                              <h5 className="fs-13 my-1 fw-normal">
                                {item.description}
                              </h5>
                              <p className="fs-10 mb-0 text-muted">
                                {item.descriptionNote}
                              </p>
                              {item.expDate ? (
                                <p className="fs-10 mb-0 text-warning">
                                  Expired on {item.expDate}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5
                            className={`${item?.feaIconClass} fs-13 my-1 fw-semiBold`}
                          >
                            {item.amount}
                          </h5>
                        </td>
                        <td>
                          <TransactionDetailsPopup />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPane>
            <TabPane tabId="3" id="ahlan-redeem">
              <div className="table-responsive table-card">
                <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Date</th>
                      <th>Transaction ID</th>
                      <th>Ahlan ID</th>
                      <th>Ahlan Tier</th>
                      <th>Description</th>
                      <th>Points</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(TransactionDetailsRedeem || []).map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-13 my-1">{item.id}</h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">{item.date}</h5>
                          <span className="fs-10 text-muted">{item.time}</span>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.transactionId}
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.AhlanId}
                          </h5>
                          <span className="fs-10 text-muted">
                            {item.customerName}
                          </span>
                        </td>
                        <td>
                          <h5
                            className={`fs-10 my-1  badge ${item?.ahlanTierClass}`}
                          >
                            {item.ahlanTier}
                          </h5>
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <span className={`${item?.feaIconClass}`}>
                              <FeatherIcon
                                icon={item?.feaIcon}
                                style={{ width: 16 }}
                              />
                            </span>
                            <div>
                              <h5 className="fs-13 my-1 fw-normal">
                                {item.description}
                              </h5>
                              <p className="fs-10 mb-0 text-muted">
                                {item.descriptionNote}
                              </p>
                              {item.expDate ? (
                                <p className="fs-10 mb-0 text-warning">
                                  Expired on {item.expDate}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5
                            className={`${item?.feaIconClass} fs-13 my-1 fw-semiBold`}
                          >
                            {item.amount}
                          </h5>
                        </td>
                        <td>
                          <TransactionDetailsPopup />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPane>
            <TabPane tabId="4" id="ahlan-returns">
              <div className="table-responsive table-card">
                <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Date</th>
                      <th>Transaction ID</th>
                      <th>Ahlan ID</th>
                      <th>Ahlan Tier</th>
                      <th>Description</th>
                      <th>Points</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(TransactionDetailsReturns || []).map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-13 my-1">{item.id}</h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">{item.date}</h5>
                          <span className="fs-10 text-muted">{item.time}</span>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.transactionId}
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.AhlanId}
                          </h5>
                          <span className="fs-10 text-muted">
                            {item.customerName}
                          </span>
                        </td>
                        <td>
                          <h5
                            className={`fs-10 my-1  badge ${item?.ahlanTierClass}`}
                          >
                            {item.ahlanTier}
                          </h5>
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <span className={`${item?.feaIconClass}`}>
                              <FeatherIcon
                                icon={item?.feaIcon}
                                style={{ width: 16 }}
                              />
                            </span>
                            <div>
                              <h5 className="fs-13 my-1 fw-normal">
                                {item.description}
                              </h5>
                              <p className="fs-10 mb-0 text-muted">
                                {item.descriptionNote}
                              </p>
                              {item.expDate ? (
                                <p className="fs-10 mb-0 text-warning">
                                  Expired on {item.expDate}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5
                            className={`${item?.feaIconClass} fs-13 my-1 fw-semiBold`}
                          >
                            {item.amount}
                          </h5>
                        </td>
                        <td>
                          <TransactionDetailsPopup />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPane>
          </TabContent>
        </CardBody>
      </div>

      <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            Showing <span className="fw-semibold">5</span> of{' '}
            <span className="fw-semibold">25</span> Results
          </div>
        </div>
        <div className="col-sm-auto mt-3 mt-sm-0">
          <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
            <li className="page-item disabled">
              <Link to="#" className="page-link">
                ←
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                1
              </Link>
            </li>
            <li className="page-item active">
              <Link to="#" className="page-link">
                2
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                3
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                →
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/*  */}
      <Offcanvas
        isOpen={isRight}
        toggle={toggleRightCanvas}
        id="offcanvasRight"
        direction="end"
      >
        <OffcanvasHeader toggle={toggleRightCanvas} id="offcanvasRightLabel">
          Recent Acitivity
        </OffcanvasHeader>
        <OffcanvasBody>...</OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default TransactionTable;
