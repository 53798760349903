import React, { useState, useEffect } from 'react';
import { getRedemptions } from '../../../actions/promotion/redemption';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { formatDate } from '../../../helpers/formatDate';

const PromotionRedemption = ({ promotionId }) => {
  const [redemptions, setRedemptions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);

  useEffect(() => {
    getRedemptionsFunc();
  }, [pageOffset]);

  const getRedemptionsFunc = async () => {
    try {
      const inputData = {
        pageOffset: pageOffset,
        pageSize: 10,
        promotionId: promotionId,
      };
      const { data, success } = await getRedemptions(inputData);
      if (success) {
        setRedemptions(data?.redemptions);
        setTotalPages(data?.totalPages);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const pages = [...Array(totalPages)?.keys()];

  return (
    <>
      {redemptions?.length ? (
        <div className="table-responsive p-3">
          {(redemptions || [])?.map((redemption, key) => (
            <div className="border rounded p-3 mb-2" key={key}>
              <Row>
                <Col md={8} className="border-end d-flex gap-2">
                  <div className="m-0">
                    <span
                      className="d-flex bg-soft-secondary p-2 rounded-2 opacity-25"
                      style={{ fontSize: 42 }}
                    >
                      <i class="bx bxs-discount text-secondary"></i>
                    </span>
                  </div>
                  <div className="m-0 w-100">
                    <div className="d-flex flex-wrap gap-1 mb-1">
                      <p className="m-0 d-flex flex-wrap align-items-center">
                        <span className="fs-11 text-muted text-capitalize">
                          Redeemed By: <strong>{redemption?.user?._em}</strong>
                        </span>
                      </p>
                    </div>
                    <div className="d-flex flex-wrap gap-1 mb-1">
                      <p className="m-0 d-flex flex-wrap align-items-center">
                        <span className="fs-11 text-muted text-capitalize">
                          Status: <strong>{redemption?.status}</strong>
                        </span>
                      </p>
                    </div>
                    <div className="d-flex flex-wrap align-items-center gap-1 mb-1">
                      <p className="m-0 fs-11 text-muted fw-normal d-flex flex-wrap gap-1">
                        Hold At :
                        <strong>
                          {redemption?.holdAt
                            ? formatDate(redemption?.holdAt)
                            : '-'}
                        </strong>
                      </p>
                      <span className="fs-12 text-muted">|</span>
                      <p className="m-0 fs-11 text-muted fw-normal d-flex flex-wrap gap-1">
                        Redeemed At :
                        <strong>
                          {redemption?.redeemedAt
                            ? formatDate(redemption?.redeemedAt)
                            : '-'}
                        </strong>
                      </p>
                    </div>
                  </div>
                </Col>

                <Col>
                  <p className="m-0 fs-11 text-muted fw-normal d-flex flex-wrap gap-1">
                    Sub Total :
                    <strong>
                      {`AED ${redemption?.response?.summary?.subTotal}` || '-'}
                    </strong>
                  </p>
                  <p className="m-0 fs-11 text-muted fw-normal d-flex flex-wrap gap-1">
                    Discount :
                    <strong>
                      {`AED ${redemption?.response?.summary?.totalDiscount}` ||
                        '-'}
                    </strong>
                  </p>
                  <p className="m-0 fs-11 text-muted fw-normal d-flex flex-wrap gap-1">
                    Grand Total :
                    <strong>
                      {`AED ${redemption?.response?.summary?.finalValue}` ||
                        '-'}
                    </strong>
                  </p>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: 350 }}
        >
          <div style={{ color: '#939393' }}>
            <i className="bx bx-file-blank  p-3 fs-60"></i>
          </div>
          <div className="text-muted fs-18">No redemptions yet</div>
        </div>
      )}
      <div className="align-items-center mt-4 mb-4 justify-content-between row text-center text-sm-start">
        <div className="col-sm"></div>
        {totalPages > 1 ? (
          <div className="col-sm-auto mt-3 mt-sm-0">
            <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
              <li className={`page-item  ${pageOffset <= 1 ? 'disabled' : ''}`}>
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    setPageOffset(pageOffset - 1);
                  }}
                >
                  ←
                </Link>
              </li>

              {pages?.map((page, index) => {
                return (
                  <li
                    className={`page-item ${
                      pageOffset === index ? 'active' : ''
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => setPageOffset(index)}
                    >
                      {index + 1}
                    </Link>
                  </li>
                );
              })}

              <li className="page-item">
                <Link
                  to="#"
                  className={`page-link ${
                    pageOffset >= totalPages ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    setPageOffset(pageOffset + 1);
                  }}
                >
                  →
                </Link>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default PromotionRedemption;
