import flagEn from '../assets/images/flags/en.svg';
import flagAe from '../assets/images/flags/ar.svg';

const languages = {
  en: {
    label: 'English',
    flag: flagEn,
  },
};

export default languages;
