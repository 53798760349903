import Cookies from 'js-cookie';

const DAYS_TO_EXPIRE = 60;

/**
 * Set value
 *
 * @param {*} name
 * @param {*} data
 * @param {*} path
 * @param {*} daysToExpire
 */
export const setItem = (
  name,
  data,
  path = '/',
  daysToExpire = DAYS_TO_EXPIRE
) => {
  const date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  Cookies.set(name, data, { expires: date, path });
};

/**
 * Get value
 */
export const getItem = (cookieName) => {
  const value = Cookies.get(cookieName);
  if (!value) {
    return null;
  }
  return value;
};

/**
 * Remove value
 *
 * @param {*} cookieName
 */
export const deleteItem = (cookieName) => {
  Cookies.remove(cookieName);
};
