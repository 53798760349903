import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { getAllConfigs } from '../../actions/promotion/generals';
import { listMembers } from '../../actions/members/list';
import MemberRow from './components/MemberRow';

const AllCustomers = () => {
  const [modal_center, setmodal_center] = useState(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }

  const AllCustomers = [
    {
      id: '1',
      customerId: '132323232323',
      customerName: 'Jhon Joy',
      customerMob: '+971 565562466',
      customerEmail: 'customer@test.com',
      verificationIconClass: 'bg-success',
      verificationIcon: 'bx bx-check-double',
      customerMobVerification: 'Verified',
      verificationBtnClass: '',
      customerMobVerificationBtn: '',
      createdDate: 'Tue 19 Dec',
      createdTime: '08:00 AM',
      updatedDate: 'Tue 19 Dec',
      updatedTime: '08:00 AM',
      countryId: 'UAE',
      ahlanPoint: '5000 Nos',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      ahlanStatus: '',
      ahlanStatusClass: '',
      walletBalance: '1000 AED',
      walletStatus: '',
      walletStatusClass: '',
      customerStatus: 'Active',
      customerStatusClass: 'soft-success',
      segmentsList: [
        { label: 'Birthday', className: 'success' },
        { label: 'Marketing Promo', className: 'info' },
        { label: 'Bank Promo', className: 'secondary' },
        { label: 'Promotions', className: 'danger' },
      ],
    },
    {
      id: '2',
      customerId: '132323232323',
      customerName: 'Mohammed Rishal',
      customerMob: '+971 565562466',
      customerEmail: 'customer@test.com',
      verificationIconClass: 'bg-success',
      verificationIcon: 'bx bx-check-double',
      customerMobVerification: 'Verified',
      verificationBtnClass: '',
      customerMobVerificationBtn: '',
      createdDate: 'Tue 22 Jan',
      createdTime: '11:00 PM',
      updatedDate: 'Tue 23 Jan',
      updatedTime: '08:00 AM',
      countryId: 'Oman',
      ahlanPoint: '300 Nos',
      ahlanTier: 'Platinum',
      ahlanTierClass: 'platinum',
      ahlanStatus: '',
      ahlanStatusClass: '',
      walletBalance: '100 OMR',
      walletStatus: '',
      walletStatusClass: '',
      customerStatus: 'Active',
      customerStatusClass: 'soft-success',
      segmentsList: [
        { label: 'Birthday', className: 'success' },
        { label: 'Marketing Promo', className: 'info' },
        { label: 'Bank Promo', className: 'secondary' },
        { label: 'Promotions', className: 'danger' },
      ],
    },
    {
      id: '3',
      customerId: '132323232323',
      customerName: 'Abdul Bari',
      customerMob: '+971 565562466',
      customerEmail: 'customer@test.com',
      verificationIconClass: '',
      verificationIcon: '',
      customerMobVerification: '',
      verificationBtnClass: 'bg-dark',
      customerMobVerificationBtn: 'Send OTP',
      createdDate: 'Tue 22 Jan',
      createdTime: '11:00 PM',
      updatedDate: 'Tue 23 Jan',
      updatedTime: '08:00 AM',
      countryId: 'Oman',
      ahlanPoint: '',
      ahlanTierClass: '',
      ahlanStatus: 'Not activated',
      ahlanStatusClass: 'soft-danger',
      walletBalance: '',
      walletStatus: 'Not activated',
      walletStatusClass: 'soft-danger',
      customerStatus: 'Active',
      customerStatusClass: 'soft-success',
      segmentsList: [],
    },
  ];
  document.title = 'Ahlan List';
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [members, setMembers] = useState([]);
  const [configs, setSelectedConfig] = useState(null);
  const getConfigs = async () => {
    try {
      setIsLoading(true);
      const { data } = await getAllConfigs({
        configName: 'members',
        configEnv: process.env.NODE_ENV,
        pageOffset: Math?.max(0 - 1, 0),
        pageSize: 1000,
      });
      setFields([...data]);
      const fieldConfigs = {};
      data.map((item) => {
        let fieldType = null;

        item.values.forEach((value) => {
          if (value.fieldCode === 'fieldType') {
            fieldType = value.fieldValue;
          }
        });

        item.values.forEach((value) => {
          if (value.fieldCode === 'fieldId') {
            fieldConfigs[fieldType] = value.fieldValue;
          }
        });
      });
      setSelectedConfig(fieldConfigs);
      console.log('fieldConfigs', fieldConfigs, data);
      const members = await listMembers();
      setMembers(members);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getConfigs();
  }, []);
  return (
    <>
      {(members || []).map((item, key) => {
        return <MemberRow item={item} key={key} configs={configs} />;
      })}

      <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            Showing <span className="fw-semibold">5</span> of
            <span className="fw-semibold">25</span> Results
          </div>
        </div>
        <div className="col-sm-auto mt-3 mt-sm-0">
          <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
            <li className="page-item disabled">
              <Link to="#" className="page-link">
                ←
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                1
              </Link>
            </li>
            <li className="page-item active">
              <Link to="#" className="page-link">
                2
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                3
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                →
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Grids in Modals */}
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            tog_center();
          }}
        >
          OTP Verification
        </ModalHeader>
        <ModalBody>
          <form action="#">
            <Row className="g-3">
              <Col className="d-flex flex-wrap gap-3">
                <Input
                  type="number"
                  className="form-control text-center"
                  id="OTP_1"
                  placeholder=""
                />
                <Input
                  type="number"
                  className="form-control text-center"
                  id="OTP_2"
                  placeholder=""
                />
                <Input
                  type="number"
                  className="form-control text-center"
                  id="OTP_3"
                  placeholder=""
                />
                <Input
                  type="number"
                  className="form-control text-center"
                  id="OTP_4"
                  placeholder=""
                />
                <Input
                  type="number"
                  className="form-control text-center"
                  id="OTP_5"
                  placeholder=""
                />
              </Col>
              <Col lg={12} className="link-success">
                OTP Send to customer registered mobile number
              </Col>

              <Col lg={12}>
                <a
                  href="#"
                  className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                >
                  Re-send
                </a>
              </Col>

              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <Button
                    color="light"
                    onClick={() => setmodal_center(false)}
                    className="w-25"
                  >
                    Close
                  </Button>
                  <Button color="primary w-75">Submit</Button>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AllCustomers;
