import { toast } from 'react-toastify';

/**
 *
 * @param {*} error
 */
export const alertSuccess = (msg) => {
  toast(msg || 'Operation done successfully...', {
    position: 'top-right',
    hideProgressBar: true,
    closeOnClick: false,
    className: 'bg-success text-white mt-5',
    autoClose: 3500,
  });
};

/**
 *
 * Generate BG color based on their name
 * @param {*} name
 * @returns
 */
export const generateColor = (name = '') => {
  if (!name) {
    return '';
  }
  let numericValue = 10;
  for (let i = 0; i < name.length; i++) {
    numericValue += name.charCodeAt(i);
  }
  const red = (numericValue * 37) % 256;
  const green = (numericValue * 73) % 256;
  const blue = (numericValue * 109) % 256;

  const color = `rgb(${red}, ${green}, ${blue})`;

  return color;
};
/**
 *
 * @param {*} percentage
 * @returns
 */
export const getColorBasedOnPercentage = (percentage) => {
  let color = '';
  if (percentage >= 0 && percentage <= 100) {
    if (percentage <= 10) {
      color = '#e70707';
    } else if (percentage <= 20) {
      color = '#FF0000';
    } else if (percentage <= 30) {
      color = '#FF4500';
    } else if (percentage <= 40) {
      color = '#FFA500';
    } else if (percentage <= 50) {
      color = '#FFD700';
    } else if (percentage <= 60) {
      color = '#FFEC8B';
    } else if (percentage <= 70) {
      color = '#FFFF00';
    } else if (percentage <= 80) {
      color = '#ADFF2F';
    } else if (percentage <= 90) {
      color = '#7CFC00';
    } else {
      color = '#45CB85';
    }
  }
  return color;
};

/**
 *
 * @param {*} data
 * @returns
 */
const arrayToCSV = (data) => {
  const header = Object.keys(data[0]).join(',') + '\n';
  const rows = data.map((obj) => Object.values(obj).join(',') + '\n');
  return header + rows.join('');
};
/**
 *
 * @param {*} data
 * @param {*} filename
 */
export const downloadCSV = (data, filename) => {
  const csvData = arrayToCSV(data);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();

  URL.revokeObjectURL(url);
};

/**
 *
 * @param {*} items
 * @returns
 */
export const extractLinksAndLabels = (items) => {
  const result = [];
  for (const item of items) {
    const { link, subItems } = item;
    if (link && link !== '/#') {
      result.push({
        link,
        label: replaceAndCapitalize(link.replace(/^\/|\/$/g, '')),
      });
    }
    if (subItems && subItems.length > 0) {
      result.push(...extractLinksAndLabels(subItems));
    }
  }
  return result;
};

/**
 *
 * @param {*} str
 * @returns
 */
const replaceAndCapitalize = (str) => {
  let words = str.split('_');
  let capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  let result = capitalizedWords.join(' ');
  return result;
};

/**
 *
 * @param {*} name
 * @param {*} number
 * @returns
 */
export const longTextBreak = (name = '', number = 25) => {
  return name?.length > number ? name?.substring(0, number) + '...' : name;
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const getGreeting = () => {
  const currentHour = new Date().getHours();
  let greeting;

  if (currentHour >= 5 && currentHour < 12) {
    greeting = 'Good Morning';
  } else if (currentHour >= 12 && currentHour < 17) {
    greeting = 'Good Afternoon';
  } else {
    greeting = 'Good Evening';
  }

  return greeting;
};

/**
 * check token expired
 * @returns
 */
export const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }
  const tokenParts = token?.split('.');
  if (tokenParts.length !== 3) {
    return true;
  }

  try {
    const payload = JSON.parse(atob(tokenParts[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    return payload.exp < currentTime;
  } catch (error) {
    return true;
  }
};

/**
 * generate password
 * @param {*} length
 * @returns
 */
export const generatePassword = (length = 8) => {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$*=';
  let password = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset.charAt(randomIndex);
  }

  return password;
};

/**
 * Formats the size
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * download a file
 * @param {*} url
 */
export const downloadFile = async (
  url,
  fileName = new Date().toISOString()
) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}_tag_file.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error('Error downloading the file:', error);
    });
};
