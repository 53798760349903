import { useState } from "react";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import { RuleGroupType } from "react-querybuilder";
import { defaultValidator, QueryBuilder } from "react-querybuilder";
import { FieldsData } from "./Fields";
import "react-querybuilder/dist/query-builder.scss";
import "./styles.scss";
import "bootstrap-icons/font/bootstrap-icons.scss";
import { QueryBuilderBootstrap } from "@react-querybuilder/bootstrap";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Input,
  Label,
} from "reactstrap";
import BreadCrumb from "../../../components/Common/BreadCrumb";
const initialQuery = { combinator: "and", rules: [] };
const CreateTierMaster = () => {
  const [query, setQuery] = useState(initialQuery);
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          titleInActive={"Tire Master"}
          titleActive="Create Tire Master"
        />

        <Row>
          <Col>
            <Card className="card mx-auto">
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center">
                  <Col sm>
                    <h5 className="card-title mb-0 flex-grow-1">
                      Create New Tier Master
                    </h5>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="border-top card-body ">
                <Row className="gap-3">
                  <Col md={12}>
                    <label for="firstName" class="form-label">
                      Tier Type
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option>Select Tier Type</option>
                      <option defaultValue="1">Platinum</option>
                      <option defaultValue="2">Gold</option>
                      <option defaultValue="3">Silver</option>
                    </select>
                  </Col>

                  <Col md={12}>
                    <label for="firstName" class="form-label">
                      Create Rules
                    </label>
                    <QueryBuilderDnD
                      dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}
                    >
                      <QueryBuilderBootstrap>
                        <QueryBuilder
                          fields={FieldsData}
                          query={query}
                          onQueryChange={setQuery}
                          showCloneButtons
                          showLockButtons
                          showNotToggle
                          validator={defaultValidator}
                          controlClassnames={{
                            queryBuilder: "queryBuilder-branches",
                          }}
                        />
                      </QueryBuilderBootstrap>
                    </QueryBuilderDnD>

                    <button className="btn btn-success mt-3">Add Rule</button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateTierMaster;
