import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import RolesPermissionTable from './RolesPermissionTable';

const RolesPermissionsPopup = () => {
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
  }

  return (
    <>
      <Button color="primary" onClick={() => tog_backdrop()}>
        Scrollable Modal
      </Button>

      <Modal
        isOpen={modal_backdrop}
        toggle={() => {
          tog_backdrop();
        }}
        backdrop={'static'}
        id="staticBackdrop"
        centered
        style={{ maxWidth: 991 }}
      >
        <ModalHeader
          className="modal-title p-3"
          id="rolesAndPermissions"
          toggle={() => {
            tog_backdrop();
          }}
        >
          Roles and permissions
        </ModalHeader>
        <ModalBody className="p-4 pt-0">
          <RolesPermissionTable />
        </ModalBody>
      </Modal>
    </>
  );
};

export default RolesPermissionsPopup;
