import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Input,
  Label,
  Form,
  Spinner,
} from 'reactstrap';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import {
  createRoleAction,
  updateRoleAction,
  viewRoleAction,
  getPermissionListAction,
} from '../../../actions/auth/action';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ROUTE_ROLE_CREATE,
  ROUTE_ROLE_LISTING,
} from '../../../constants/route';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';

// Validation schema
const validationSchema = Yup.object({
  enabled: Yup.boolean(),
  name: Yup.string()
    .min(3, 'Name must be at least 3 characters long')
    .max(20, 'Name cannot exceed 20 characters')
    .required('Name is required'),
  description: Yup.string()
    .min(30)
    .required('Description at least 30 characters is required'),
  permissions: Yup.array().min(3, 'At least 3 permission is required'),
});

const CreateRoles = () => {
  const history = useNavigate();
  const { id } = useParams(); // Get the ID from the URL
  const [permissionList, setPermissionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  // change route
  const changeRoute = (route) => {
    history(route);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const permissions = await getPermissionListAction();
        setPermissionList(permissions);

        if (id && id !== 'new') {
          setIsEditMode(true);
          const roleData = await viewRoleAction(id);
          formik.setValues({
            enabled: roleData.enabled,
            name: roleData.name,
            description: roleData.description,
            permissions: roleData.permissions || [],
          });
        } else {
          setIsEditMode(false);
        }
      } catch (error) {
        setPermissionList([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Formik hook
  const formik = useFormik({
    initialValues: {
      enabled: false,
      name: '',
      description: '',
      permissions: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      let result = null;
      try {
        if (isEditMode) {
          result = await updateRoleAction({
            input: { _id: id, ...values },
          });
        } else {
          result = await createRoleAction({ input: { ...values } });
          changeRoute(ROUTE_ROLE_CREATE.replace(':id', result?._id));
        }

        if (result && result._id) {
          alertSuccess(
            isEditMode
              ? 'Role successfully modified...'
              : 'Role successfully created...'
          );
        } else {
          alertError('Something went wrong..!');
        }
      } catch (error) {
        alertError('Something went wrong..!');
      } finally {
        setIsLoading(false);
      }
    },
  });

  // Handle permission toggle
  const handlePermissionToggle = (permission) => {
    formik.setFieldValue(
      'permissions',
      formik.values.permissions.includes(permission)
        ? formik.values.permissions.filter((p) => p !== permission)
        : [...formik.values.permissions, permission]
    );
  };

  document.name = isEditMode ? `Edit Role ` : 'Add new role';

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          titleInActive={'Manage Roles'}
          titleActive={
            isEditMode ? `Edit ${formik?.values?.name}` : 'Add new role'
          }
        />
        <Card>
          <CardBody className="card-body">
            <Form onSubmit={formik.handleSubmit}>
              <div className="mb-4 border rounded-1">
                <h5 className="border-bottom p-3 bg-light">Role information</h5>
                <div className="p-3">
                  <Row>
                    <Col md={12} className="mb-3">
                      <div className="form-check form-switch form-switch-secondary">
                        <Input
                          autoComplete="off"
                          className="form-check-input form-switch-md ml-5"
                          type="checkbox"
                          role="switch"
                          id="enabled"
                          name="enabled"
                          checked={formik.values?.enabled}
                          onChange={() =>
                            formik.setFieldValue(
                              'enabled',
                              !formik.values.enabled
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col md={12} className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Role name *
                      </Label>
                      <Input
                        autoComplete="off"
                        type="text"
                        className={`form-control ${
                          formik.errors.name && formik.touched.name
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.name && formik.touched.name && (
                        <div className="text-danger">{formik.errors.name}</div>
                      )}
                    </Col>
                    <Col md={12} className="mb-3">
                      <Label htmlFor="description" className="form-label">
                        Description *
                      </Label>
                      <textarea
                        className={`form-control ${
                          formik.errors.description &&
                          formik.touched.description
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="description"
                        name="description"
                        rows="3"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                      ></textarea>
                      {formik.errors.description &&
                        formik.touched.description && (
                          <div className="text-danger">
                            {formik.errors.description}
                          </div>
                        )}
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="mb-4 border rounded-1">
                <h5 className="border-bottom p-3 bg-light">Permissions</h5>

                {permissionList && permissionList?.length > 0 ? (
                  permissionList?.map((category) => (
                    <div key={category?.name} className="p-3">
                      <p className="fs-15">
                        <strong>
                          {category.name || 'Permission name missing'}
                        </strong>
                      </p>
                      <Row>
                        {category?.permissions?.map((item) => (
                          <Col md={6} key={item?.permission}>
                            <div className="form-check form-switch form-switch-secondary mb-3 d-flex align-items-center p-0 justify-content-between">
                              <Label
                                className="form-check-label d-flex align-items-center gap-2"
                                htmlFor={item?.permission}
                              >
                                <i
                                  className={`fs-18 opacity-25 ${
                                    item.icon || 'ri-function-line'
                                  }`}
                                ></i>
                                {item?.name || 'Unnamed Permission'}
                              </Label>
                              <Input
                                className="form-check-input form-switch-md"
                                type="checkbox"
                                role="switch"
                                id={item?.permission}
                                checked={formik.values.permissions.includes(
                                  item.permission
                                )}
                                onChange={() =>
                                  handlePermissionToggle(item.permission)
                                }
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                      <hr />
                    </div>
                  ))
                ) : (
                  <div className="p-3 text-center">
                    {isLoading ? (
                      <p>
                        Fetching permissions{' '}
                        <Spinner size="sm" className="me-2">
                          Loading...
                        </Spinner>
                      </p>
                    ) : (
                      <p>No permissions available.</p>
                    )}
                  </div>
                )}

                {formik.errors.permissions && formik.touched.permissions && (
                  <div className="pt-0 p-3">
                    <p className="text-danger mb-0">
                      {formik.errors.permissions}
                    </p>
                  </div>
                )}
              </div>

              <Row>
                <Col className="mb-3">
                  <div className="d-flex gap-2 justify-content-end">
                    <Button
                      type="button"
                      outline
                      color="dark"
                      size="md"
                      onClick={() => changeRoute(ROUTE_ROLE_LISTING)}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      size="md"
                      className="bg-gradient px-5"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spinner size="sm" className="me-2">
                          Loading...
                        </Spinner>
                      ) : null}
                      {isEditMode ? 'Update Role' : 'Create Role'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default CreateRoles;
