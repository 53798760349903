import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import getChartColorsArray from '../../../components/Common/ChartsDynamicColor';

const RevenueChartData = ({ dataColors, series, selectType }) => {
  console.log(selectType, 'selectType');
  const [categories, setCategories] = useState([]);
  var revenueExpensesChartsColors = getChartColorsArray(dataColors);
  // Function to get the number of days in the current month
  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const valueRange = {
    today: 5000,
    lastWeek: 130000,
    lastMonth: 130000,
    currentYear: 150000,
  };

  const formatHour = (hour) => {
    const formattedHour = hour % 12 || 12; // Converts 0 to 12 for 12 AM
    const period = hour < 12 ? 'AM' : 'PM';
    return `${formattedHour}${period}`;
  };
  // Function to generate categories dynamically based on selectType
  const getCategories = (type) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0 = January, 11 = December
    const currentYear = currentDate.getFullYear();
    switch (type) {
      case 'today':
        // Generate last 24 hours
        return Array.from({ length: 24 }, (_, i) => formatHour(i));
      case 'lastWeek':
        // Generate last 7 days
        return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      case 'lastMonth':
        // Get actual number of days in the current month
        const daysInMonth = getDaysInMonth(currentMonth, currentYear);
        return Array.from({ length: daysInMonth }, (_, i) => `${i + 1}`);
      case 'currentYear':
        // Generate last 12 months
        return [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
      default:
        return [];
    }
  };
  // Update categories when selectType changes
  useEffect(() => {
    setCategories(getCategories(selectType));
  }, [selectType]);

  var options = {
    chart: {
      height: 490,
      type: 'area',
      toolbar: 'false',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return 'AED' + value;
        },
      },
      tickAmount: 5,
      min: 0,
      max: valueRange[selectType],
    },
    colors: revenueExpensesChartsColors,
    fill: {
      opacity: 0.06,
      colors: revenueExpensesChartsColors,
      type: 'solid',
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="area"
        height="370"
        className="apex-charts"
      />
    </React.Fragment>
  );
};
export { RevenueChartData };
