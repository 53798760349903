import React from 'react';
import {
  RuleGroupBodyComponents,
  RuleGroupHeaderComponents,
  TestID,
  useRuleGroup,
  useStopEventPropagation,
  update,
} from 'react-querybuilder';
import { FormGroup, Label, Input } from 'reactstrap';

export const RewardTierRuleGroup = React.memo((props) => {
  const { schema, path } = props;

  const rg = useRuleGroup(props);

  const addRule = useStopEventPropagation(rg.addRule);
  const addGroup = useStopEventPropagation(rg.addGroup);
  const cloneGroup = useStopEventPropagation(rg.cloneGroup);
  const toggleLockGroup = useStopEventPropagation(rg.toggleLockGroup);
  const removeGroup = useStopEventPropagation(rg.removeGroup);
  const shiftGroupUp = useStopEventPropagation(rg.shiftGroupUp);
  const shiftGroupDown = useStopEventPropagation(rg.shiftGroupDown);

  return (
    <div
      ref={rg.previewRef}
      title={rg.accessibleDescription}
      className={rg.outerClassName}
      data-testid={TestID.ruleGroup}
      data-dragmonitorid={rg.dragMonitorId}
      data-dropmonitorid={rg.dropMonitorId}
      data-rule-group-id={rg.id}
      data-level={rg.path.length}
      data-path={JSON.stringify(rg.path)}
    >
      <div ref={rg.dropRef} className={rg.classNames.header}>
        <RuleGroupHeaderComponents
          {...rg}
          addRule={addRule}
          addGroup={addGroup}
          cloneGroup={cloneGroup}
          toggleLockGroup={toggleLockGroup}
          removeGroup={removeGroup}
          shiftGroupUp={shiftGroupUp}
          shiftGroupDown={shiftGroupDown}
        />
      </div>
      <div className={rg.classNames.body}>
        <RuleGroupBodyComponents
          {...rg}
          addRule={addRule}
          addGroup={addGroup}
          cloneGroup={cloneGroup}
          toggleLockGroup={toggleLockGroup}
          removeGroup={removeGroup}
          shiftGroupUp={shiftGroupUp}
          shiftGroupDown={shiftGroupDown}
        />
        {rg?.ruleGroup?.rules?.length > 0 ? (
          <FormGroup>
            <Label for="type">Within</Label>
            <Input
              type="select"
              id="type"
              name="type"
              // value={values?.type}
              onChange={(e) => {
                schema?.dispatchQuery(
                  update(
                    schema?.getQuery(),
                    'timePeriod',
                    e?.target?.value,
                    path
                  )
                );
              }}
            >
              <option value="lastSixMonth">Last six months</option>
              <option value="lastYear">Last Year</option>
            </Input>
          </FormGroup>
        ) : null}
        {/* <label>
          Description:
          <input
            type="text"
            defaultValue={rg.ruleGroup.timePeriod || ''}
            onChange={(e) => {
              schema?.dispatchQuery(
                update(schema?.getQuery(), 'timePeriod', e?.target?.value, path)
              );
            }}
            placeholder="Enter description"
          />
        </label> */}
      </div>
    </div>
  );
});

export default RewardTierRuleGroup;
