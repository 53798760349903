import React from 'react';
import { Routes, Route } from 'react-router-dom';

//Layouts
import NonAuthLayout from '../components/Layouts/NonAuthLayout';
import VerticalLayout from '../components/Layouts/index';

//routes
// import { authProtectedRoutes, publicRoutes=[] } from "./allRoutes";
import { useAllRoutes } from './routesProvider';

import { AuthProtected } from './AuthProtected';
import { Outlet } from 'react-router-dom';
const Index = () => {
  const { authProtectedRoutes = [], publicRoutes = [] } = useAllRoutes();

  return (
    <>
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected route={route}>
                  <VerticalLayout>
                    {route.children ? <Outlet /> : route.component}
                  </VerticalLayout>
                </AuthProtected>
              }
              key={idx}
              exact={true}
            >
              {route.children &&
                route.children.map((childRoute, childIdx) => (
                  <Route
                    path={childRoute.path}
                    element={
                      childRoute.children ? <Outlet /> : childRoute.component
                    }
                    key={childIdx}
                    exact={true}
                  >
                    {childRoute.children &&
                      childRoute.children.map((subChildRoute, subChildIdx) => (
                        <Route
                          path={subChildRoute.path}
                          element={
                            subChildRoute?.children ? (
                              <Outlet />
                            ) : (
                              subChildRoute.component
                            )
                          }
                          key={subChildIdx}
                          exact={true}
                        />
                      ))}
                  </Route>
                ))}
            </Route>
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default Index;
