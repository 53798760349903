import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Label,
} from 'reactstrap';
import InnerLoader from '../../../components/Common/InnerLoader';
import './style.scss';
import PromotionConfigForm from './PromotionConfigForm';

const PromotionReportConfig = ({
  promotionId = null,
  promotionDetail,
  fetchPromotionDetail,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [open, setOpen] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [reportConfigs, setReportConfigs] = useState([
    ...(promotionDetail?.reportConfigs || []),
  ]);
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    setReportConfigs([...(promotionDetail?.reportConfigs || [])]);
  }, [promotionDetail]);

  const toggle = (id) => {
    try {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = () => {
    setOpen('');
    fetchPromotionDetail(promotionId);
  };

  console.log(reportConfigs, 'reportConfigs');
  console.log(promotionDetail, 'promotionDetail');

  return (
    <>
      <div className="d-flex flex-row float-end ">
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            setIsOpenModal(true);
          }}
          className="pt-1 pb-1"
        >
          config
        </Button>
      </div>

      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(!isOpenModal);
        }}
        centered={true}
        size="lg"
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            setIsOpenModal(!isOpenModal);
          }}
        >
          Promotion report config
        </ModalHeader>
        <ModalBody className="report-config-modal">
          <div className="hide-accordion-arrow">
            <Accordion open={open} toggle={toggle}>
              <AccordionItem className="mb-3">
                <AccordionHeader targetId="new-config">
                  <i className="bx bx-plus fs-14 pe-2"></i>
                  Add new config
                </AccordionHeader>
                <AccordionBody accordionId="new-config">
                  <PromotionConfigForm
                    promotionId={promotionId}
                    closeHandler={() => setOpen('')}
                    submitHandler={submitHandler}
                  />
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="hide-accordion-arrow">
            <Accordion open={open} toggle={toggle}>
              {reportConfigs?.map((reportConfig, index) => {
                return (
                  <AccordionItem>
                    <AccordionHeader targetId={`${index}`}>
                      <div className="d-flex flex-row justify-content-between align-items-center w-100">
                        <div>
                          <Label className="form-check-label text-capitalize">
                            {reportConfig?.configName}
                          </Label>
                        </div>
                        <div className="d-flex flex-row">
                          <Button
                            color="info"
                            size="sm"
                            className="pt-1 pb-0"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingId(reportConfig?._id);
                              setOpen(`${index}`);
                            }}
                          >
                            <i className="bx bxs-edit fs-15"></i>
                          </Button>
                        </div>
                      </div>
                    </AccordionHeader>
                    {reportConfig?._id === editingId ? (
                      <AccordionBody accordionId={`${index}`}>
                        <PromotionConfigForm
                          promotionId={promotionId}
                          closeHandler={() => setOpen('')}
                          reportConfigForEdit={reportConfig}
                          submitHandler={submitHandler}
                        />
                      </AccordionBody>
                    ) : null}
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>

          {isLoading ? <InnerLoader /> : null}
        </ModalBody>
      </Modal>
    </>
  );
};

export default PromotionReportConfig;
