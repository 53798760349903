import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import OtherReportExportBtn from './components/OtherReportExportBtn';
import OtherReportTable from './OtherReportTable';
import LedgerExportBtn from './components/LedgerExportBtn';
import UpdateDjvButton from './components/UpdateDjvButton';

const modeOptions = [
  { value: 'credit', label: 'Credit' },
  { value: 'debit', label: 'Debit' },
];

const statusOptions = [
  { value: 'approved', label: 'Approved' },
  { value: 'pending', label: 'Pending' },
  { value: 'hold', label: 'Hold' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'expired', label: 'Expired' },
];

const djvOptions = [
  { value: '0', label: 'DJV Not Passed' },
  { value: '1', label: 'DJV Passed' },
];
const WalletOtherReports = () => {
  const [searchFilter, setSearchFilter] = useState(null);
  const [selectedModeFilter, setSelectedModeFilter] = useState(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
  const [selectedDjvFilter, setSelectedDjvFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState([]);
  const today = new Date();
  const [cardHeaderTab, setCardHeaderTab] = useState('expiring-and-expired');
  const [djvPassedTrigger, setDjvPassedTrigger] = useState(false);
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const formatRange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const startDate = selectedDates[0].toISOString().split('T')[0];
      const endDate = selectedDates[1].toISOString().split('T')[0];
      setDateRangeFilter([startDate, endDate]);
      return `${startDate} to ${endDate}`;
    }
    return '';
  };

  const handleDateChange = (selectedDates) => {
    // Normalize dates to remove any time component
    const normalizedDates = selectedDates.map(
      (date) =>
        new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    );
    setDateRange(formatRange(normalizedDates));
  };

  const handleSearch = () => {
    // Implement the search functionality
  };

  const resetSearch = () => {
    setSearchFilter(null);
    setSelectedModeFilter(null);
    setSelectedStatusFilter(null);
    setDateRange('');
    setDateRangeFilter([]);
    setSelectedDjvFilter(null);
  };
  //
  document.title = 'Transactions History';
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Admin'} titleActive="Transaction History" />
        <Card>
          <CardBody className="border border border-dashed card-body">
            <Row className="g-3">
              <Col className="d-flex flex-wrap align-items-start gap-2">
                {/* Conditional rendering for filters based on cardHeaderTab */}
                {cardHeaderTab !== 'ledger' ? (
                  <>
                    <div className="search-box d-inline-block w-100" style={{ maxWidth: 360 }}>
                      <Input
                        className="form-control search"
                        placeholder="Search by Wallet Id / mobile no"
                        autoComplete="off"
                        type="text"
                        name="searchInput"
                        value={searchFilter ?? ''}
                        onChange={(event) => setSearchFilter(event?.target?.value || '')}
                      />
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                    <div>
                      <Flatpickr
                        className="form-control"
                        style={{ width: 200 }}
                        options={{
                          mode: 'range',
                          dateFormat: 'Y-m-d',
                          maxDate: 'today',
                          defaultDate: [thirtyDaysAgo, today],
                          onChange: handleDateChange,
                        }}
                        value={dateRange}
                        placeholder="Start Date to End Date"
                      />
                    </div>
                    <Select
                      placeholder="Select DJV Status"
                      value={selectedDjvFilter}
                      onChange={(option) => setSelectedDjvFilter(option)}
                      options={djvOptions}
                    />
                  </>
                ) : (
                  <div>
                    <Flatpickr
                      className="form-control"
                      style={{ width: 200 }}
                      options={{
                        mode: 'range',
                        dateFormat: 'Y-m-d',
                        maxDate: 'today',
                        defaultDate: [thirtyDaysAgo, today],
                        onChange: handleDateChange,
                      }}
                      value={dateRange}
                      placeholder="Start Date to End Date"
                    />
                  </div>
                )}

                <div className="d-flex gap-2">
                  <Button
                    color="light"
                    outline
                    className="shadow-none py-0 lh-1 d-flex align-items-center justify-content-center border-dark-subtle"
                    style={{ height: 38 }}
                    onClick={() => resetSearch()}
                  >
                    <i className="mdi mdi-restore fs-18 text-dark"></i>
                  </Button>

                  <Button
                    color="primary"
                    outline
                    className="shadow-none py-0 lh-1 d-flex align-items-center justify-content-center"
                    style={{ height: 38 }}
                  >
                    {isLoading ? (
                      <Spinner size="sm" className="flex-shrink-0" role="status">
                        Loading...
                      </Spinner>
                    ) : (
                      <i className="ri-search-line fs-18"></i>
                    )}
                  </Button>
                </div>

                <div className="ms-auto d-flex gap-2">
                  <UpdateDjvButton setDjvPassedTrigger={setDjvPassedTrigger} />
                  {cardHeaderTab === 'expiring-and-expired' ? (<OtherReportExportBtn
                    dateRange={dateRangeFilter}
                    status={selectedStatusFilter?.value}
                    djvStatus={selectedDjvFilter?.value}
                  />) : (<LedgerExportBtn
                    dateRange={dateRangeFilter}
                  />)}
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="border">
            <Row className="g-3">
              <Col>
                <OtherReportTable
                  search={searchFilter}
                  mode={selectedModeFilter?.value}
                  status={selectedStatusFilter?.value}
                  dateRangeFilter={dateRangeFilter}
                  selectedDjvFilter={selectedDjvFilter?.value}
                  setIsLoading={setIsLoading}
                  cardHeaderTab={cardHeaderTab}
                  setCardHeaderTab={setCardHeaderTab}
                  djvPassedTrigger={djvPassedTrigger}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default WalletOtherReports;
