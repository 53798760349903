import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import FeatherIcon from "feather-icons-react";
import {
  Card,
  CardBody,
  Col,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { getWalletSummary } from "../../../actions/wallet";

const Widgets = ({ widgets }) => {
  const [dashboardSummary, setDashboardSummary] = useState([]);

  useEffect(() => {
    getWalletDashboardSummaryFunc();
  }, []);

  const getWalletDashboardSummaryFunc = async () => {
    const data = {
      country: 'ae'
    };

    const res = await getWalletSummary(data);
    if (res?.success) {
      const transformedData = transformApiResponse(res.data);
      setDashboardSummary(transformedData);
    }
  };

  const transformApiResponse = (data) => {

    const toKFormat = (value) => {
      const numericValue = Math.abs(parseFloat(value));
      return numericValue ? (numericValue / 1000).toFixed(1) : 0;
    };

    return [
      {
        id: 1,
        feaIcon: "clock",
        feaIconClass: "warning",
        label: "Today's Wallet Amount",
        value: toKFormat(data.totalCreditAmountToday),
        badge: "ri-arrow-up-line",
        badgeClass: "success",
        percentage: "16.24",
        badgeContent: "previous day",
      },
      {
        id: 2,
        feaIcon: "credit-card",
        feaIconClass: "success",
        label: "Total Wallet Amount",
        value: toKFormat(data.totalCreditAmount),
        badge: "ri-arrow-down-line",
        badgeClass: "danger",
        percentage: "3.96",
        badgeContent: "previous day",
      },
      {
        id: 3,
        feaIcon: "trending-down",
        feaIconClass: "danger",
        label: "Total Redemption",
        value: toKFormat(data.totalDebitAmount),
        badge: "ri-arrow-up-line",
        badgeClass: "success",
        percentage: "16.24",
        badgeContent: "previous day",
      },
      {
        id: 4,
        feaIcon: "users",
        feaIconClass: "primary",
        label: "No.Of Users",
        value: data.totalWallets,
        badge: "ri-arrow-up-line",
        badgeClass: "success",
        percentage: "26.24",
        badgeContent: "previous day",
      },
    ];
  };

  return (
    <React.Fragment>
      <Row>
        {(dashboardSummary || []).map((item, key) => (
          <Col xl={3} key={key}>
            <Card className={`card-animate card card-height-100`}>
              <CardBody>
                <div className="float-end">
                  <UncontrolledDropdown direction="start">
                    <DropdownToggle
                      className="text-reset"
                      tag="a"
                      role="button"
                    >
                      <span className="text-muted fs-18">
                        <i className="mdi mdi-dots-horizontal"></i>
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu dropdown-menu-end">
                      <DropdownItem href="#"> Details </DropdownItem>
                      <DropdownItem href="#"> Refresh </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex">
                    <div className="avatar-xs flex-shrink-0">
                      <span
                        className={`avatar-title bg-${item.feaIconClass} rounded-circle p-2`}
                      >
                        <FeatherIcon icon={item.feaIcon} />
                      </span>
                    </div>
                    <p className="fw-medium text-muted mb-0 p-2">
                      {item.label}
                    </p>
                  </div>

                  <h2 className="mt-2 ff-secondary fw-medium">
                    <span className="counter-value">
                      {item.feaIcon !== "users" ? (
                        <>
                          AED{" "}
                          <CountUp
                            start={0}
                            end={item.value || 0}
                            decimals={0}
                            duration={2}
                          />
                          K
                        </>
                      ) : (
                        <>
                          <CountUp
                            start={0}
                            end={item.value || 0}
                            decimals={0}
                            duration={2}
                          />
                        </>
                      )}
                    </span>
                  </h2>
                  <div className="flex-shrink-0">
                    <p className="mb-0 text-muted">
                      {item.percentage ? (
                        <span
                          className={"badge bg-light text-" + item.badgeClass}
                        >
                          {item.badge ? (
                            <i className={"align-middle " + item.badge}></i>
                          ) : null}{" "}
                          {item.percentage} %{" "}
                        </span>
                      ) : null}
                      {item.badgeContent ? <> vs. {item.badgeContent}</> : null}
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default Widgets;
