import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import classnames from 'classnames';
import {
  CardBody,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';
import TransactionDetailsPopup from './TransactionDetailsPopup';
import { getTransactions } from '../../../actions/wallet/index';
import moment from 'moment';
import Pagination from '../../../components/Common/Pagination';

const NoRecordsMessage = () => {
  return (
    <tr>
      <td colSpan="8" className="text-center py-4">
        <h5 className="fs-13 my-1 fw-normal">No transactions found.</h5>
      </td>
    </tr>
  );
};

const TransactionTable = (props) => {
  const { search, mode, status, mobileNumber, setIsLoading, dateRangeFilter } =
    props;
  const [cardHeaderTab, setcardHeaderTab] = useState('wallet-all');
  const [transactions, setTransactions] = useState([]);
  const [pageOffset, setPageOffset] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);

  const cardHeaderToggle = (tab) => {
    if (cardHeaderTab !== tab) {
      setcardHeaderTab(tab);
    }
  };

  useEffect(() => {
    getTransactionsFunc();
  }, [pageOffset, search, mode, status, dateRangeFilter]);

  const pages = [...Array(totalPages)?.keys()];

  const getTransactionsFunc = async () => {
    setIsLoading(true);
    const data = {
      country: 'ae',
      mobileNumber: mobileNumber,
      page: pageOffset - 1,
      pageSize: pageSize,
      search: search || '',
      mode: mode || '',
      status: status || '',
      sortBy: 'createdAt',
      sortOrder: 'DESC',
      dateRange: dateRangeFilter || [],
    };

    const res = await getTransactions(data);
    if (res?.success) {
      setTransactions(res?.data?.data);
      setTotalPages(res?.data?.pageInfo?.totalPages);
      setTotalItems(res?.data?.pageInfo?.totalItems);
    }
    setIsLoading(false);
  };

  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const tabs = [
    {
      label: 'All',
      id: 'wallet-all',
    },
    {
      label: 'Pending & Expiring',
      id: 'wallet-pending',
    },
  ];

  const getStatusClass = (status) => {
    switch (status) {
      case 'approved':
        return 'badge bg-success';
      case 'pending':
      case 'hold':
        return 'badge bg-warning';
      case 'rejected':
        return 'badge bg-danger';
      default:
        return 'badge bg-secondary'; // fallback class
    }
  };

  return (
    <React.Fragment>
      <div className="table-card">
        <div className="card-header align-items-center d-flex">
          <div className="flex-shrink-0 ms-2">
            <Nav
              tabs
              className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
            >
              {tabs?.map((tab, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: cardHeaderTab === tab?.id,
                      })}
                      onClick={() => {
                        cardHeaderToggle(tab?.id);
                      }}
                    >
                      {tab?.label}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </div>
        </div>
        <CardBody className="border-right border-left">
          <TabContent activeTab={cardHeaderTab} className="text-muted">
            {tabs?.map((tab) => {
              return (
                <TabPane key={tab.id} tabId={tab?.id}>
                  <div className="table-responsive table-card">
                    <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Date</th>
                          <th>Transaction #</th>
                          <th>Mobile # / Name</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th className="text-right">Amount</th>
                          <th>Mode</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.length === 0 ? (
                          <NoRecordsMessage />
                        ) : (
                          transactions.map((transaction, index) => {
                            const ci = 10;
                            const siNo = (pageOffset - 1) * ci + (index + 1);
                            return (
                              <tr key={index}>
                                <td>
                                  <p className="fs-13">{siNo}</p>
                                </td>
                                <td>
                                  <p className="fs-13 fw-normal mb-0">
                                    {moment(transaction?.createdAt).format(
                                      'DD MMMM YYYY'
                                    )}
                                  </p>
                                  <span className="fs-10 text-muted">
                                    {moment(transaction?.createdAt).format(
                                      'hh:mm:ss A'
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <p className="fs-13 fw-normal">
                                    {transaction?.token}
                                  </p>
                                </td>

                                <td>
                                  <p className="fs-13 fw-normal mb-0 d-flex gap-1 align-items-center">
                                    <i class="bx bx-mobile"></i>{' '}
                                    {transaction?.mobileNumber}
                                  </p>
                                  <p className="fs-10 text-muted m-0">
                                    Name :{' '}
                                    {transaction?.fullName
                                      ?.replace('null', '')
                                      .trim()}
                                  </p>
                                  <p className="d-flex gap-1 m-0">
                                    <i class="bx bx-wallet-alt"></i>
                                    <a
                                      href={`list/details/ae/${transaction?.mobileNumber}`}
                                      target="_blank"
                                      className="fs-10"
                                    >
                                      {transaction?.walletId}
                                    </a>
                                  </p>
                                </td>
                                <td>
                                  <div className="d-flex gap-2">
                                    <span
                                      className={`${
                                        transaction?.mode === 'credit'
                                          ? 'text-success'
                                          : 'text-danger'
                                      }`}
                                    >
                                      <FeatherIcon
                                        icon={`${
                                          transaction?.mode === 'credit'
                                            ? 'arrow-up-right'
                                            : 'arrow-down-right'
                                        }`}
                                        style={{ width: 16 }}
                                      />
                                    </span>
                                    <div>
                                      <p className="fs-13 fw-normal">
                                        {transaction?.title}
                                      </p>
                                      <p className="fs-10 m-0 text-muted">
                                        Source : Online , Ref# :{' '}
                                        <b>{transaction?.transactionNo}</b>
                                      </p>
                                      {transaction.expiryAt ? (
                                        moment().isAfter(
                                          moment(transaction?.expiryAt)
                                        ) ? (
                                          <p className="fs-10 m-0 text-danger">
                                            Expired on{' '}
                                            {moment(
                                              transaction?.expiryAt
                                            ).format('DD MMMM YYYY')}
                                          </p>
                                        ) : (
                                          <p className="fs-10 m-0 text-warning">
                                            Expiry on{' '}
                                            {moment(
                                              transaction.expiryAt
                                            ).format('DD MMMM YYYY')}
                                          </p>
                                        )
                                      ) : null}
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  <span
                                    className={getStatusClass(
                                      transaction?.status
                                    )}
                                  >
                                    {transaction?.status}
                                  </span>
                                </td>
                                <td>
                                  <p
                                    className={`${
                                      transaction?.mode === 'credit'
                                        ? 'text-success'
                                        : 'text-danger'
                                    } fs-13 my-1 fw-bold text-right`}
                                  >
                                    {transaction?.mode != 'credit'
                                      ? '- '
                                      : '+ '}
                                    {transaction?.currency}{' '}
                                    {
                                      (
                                        Math.round(
                                          Math.abs(transaction?.amount) * 100
                                        ) / 100
                                      ).toFixed(2)
                                      //Number(transaction?.amount).toFixed(2)
                                    }
                                  </p>
                                </td>
                                <td>
                                  {transaction?.mode.charAt(0).toUpperCase() +
                                    transaction?.mode.slice(1)}
                                </td>
                                <td>
                                  <TransactionDetailsPopup
                                    transactionId={transaction.id}
                                  />
                                  {transaction.status === 'pending' ? (
                                    <button className="rounded-pill btn btn-success py-1 fs-11 ms-2">
                                      Approve
                                    </button>
                                  ) : (
                                    ''
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </TabPane>
              );
            })}
          </TabContent>
        </CardBody>
      </div>

      <Pagination
        totalPages={totalPages}
        totalItems={totalItems}
        currentPage={1}
        onPageChange={setPageOffset}
      />

      <Offcanvas
        isOpen={isRight}
        toggle={toggleRightCanvas}
        id="offcanvasRight"
        direction="end"
      >
        <OffcanvasHeader toggle={toggleRightCanvas} id="offcanvasRightLabel">
          Recent Acitivity
        </OffcanvasHeader>
        <OffcanvasBody>...</OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default TransactionTable;
