import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Widgets from './Widgets';
import { useDispatch, useSelector } from 'react-redux';
import Section from './Section';
import RecentTransaction from './RecentTransaction';
import RevenueChart from './RevenueChart';

const Dashboard = () => {
  document.title = 'Welcome - Home Page ';

  //
  const { dashboard } = useSelector((state) => state?.dashboard || {});
  const { widgets } = dashboard || {};

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row className="mb-0">
            <Col xs={12}>
              <Section />
            </Col>
          </Row>

          {/* Main */}
          <Row>
            <Col xxl={12}>
              <Widgets widgets={widgets} />
            </Col>
          </Row>
          <Row>
            <Col xxl={6}>
              <RecentTransaction />
            </Col>
            <Col xxl={6}>
              <RevenueChart />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
