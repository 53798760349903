import promoRestService from '../../services/PromoRestService';

export const getRedemptions = async (data) => {
  try {
    const result = await promoRestService?.post('redemption/list', data);
    return result?.data;
  } catch (error) {
    return null;
  }
};
