import React, { useState } from 'react';
import {
  Button,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  ButtonGroup,
} from 'reactstrap';
import UnitBasedOption from './UnitBasedOption';
import { DISCOUNT_TYPES } from '../../../constants';
import { Else, If, Then } from 'react-if';
import GiftOption from './GiftOption';

const EffectTransactionUnitBased = ({
  onChange = () => {},
  effect = {},
  eIndex,
  tIndex,
}) => {
  const [options, setOptions] = useState(effect.unitBasedEffects || []);

  const handleAddOption = () => {
    setOptions([
      ...options,
      {
        onUnit: '',
        discountType: 'percentage',
        discountValue: '',
        rule: null,
        collections: [],
      },
    ]);
  };

  const handleValueChange = (index, value, name) => {
    const updatedOptions = [...options];
    updatedOptions[index][name] = value;
    setOptions(updatedOptions);
    onChange('unitBasedEffects', [...updatedOptions], eIndex, tIndex);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
    onChange('unitBasedEffects', [...updatedOptions], eIndex, tIndex);
  };

  const optionCount = options.length;
  return (
    <>
      <div className="w-100">
        {options.map((option, index) => (
          <If condition={effect.discountType === 'gift'}>
            <Then>
              <GiftOption
                key={index}
                optionCount={optionCount}
                option={option}
                index={index}
                setOptions={setOptions}
                handleValueChange={handleValueChange}
                handleRemoveOption={handleRemoveOption}
                combinedUnitBasedDiscount={effect?.combinedUnitBasedDiscount}
                effect={effect}
              />
            </Then>
            <Else>
              <UnitBasedOption
                key={index}
                optionCount={optionCount}
                option={option}
                index={index}
                setOptions={setOptions}
                handleValueChange={handleValueChange}
                handleRemoveOption={handleRemoveOption}
                combinedUnitBasedDiscount={effect?.combinedUnitBasedDiscount}
                effect={effect}
              />
            </Else>
          </If>
        ))}
        <div className="my-2 d-flex gap-2">
          <Button color="primary" onClick={handleAddOption} size="sm">
            <If condition={effect.discountType === 'gift'}>
              <Then>Add Gift {options.length + 1}</Then>
              <Else>
                Add{' '}
                {options.length === 0 ? 'Bundle 1' : `Buy Y${options.length}`}
              </Else>
            </If>
          </Button>
        </div>
      </div>
    </>
  );
};

export default EffectTransactionUnitBased;
