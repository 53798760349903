import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import BreadCrumb from '../../components/Common/BreadCrumb';
import WalletTable from './WalletTable';
import Select from 'react-select';
import { getWallets } from '../../actions/wallet';

const Wallet = () => {

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Admin'} titleActive="Wallet" />
        <Card>
          <CardHeader className="border-0">
            <Row className="g-4 align-items-center">
              <Col sm></Col>
              <Col sm="auto">
                <div className="d-flex flex-wrap align-items-start gap-2">
                  {/* add user */}
                  <button className="btn btn-success add-btn">
                    <i className="ri-user-fill me-1 align-bottom"></i> Create
                    new customer
                  </button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          {/* Filters and search */}
          <CardBody className="border border border-dashed card-body">
            <Row className="g-3">
              <Col>
                <div className={'search-box me-2 mb-2 d-inline-block w-100'}>
                  <Input
                    className="form-control search"
                    placeholder="Search by Wallet Id / mobile no"
                    type="text"
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </Col>
              <Col className="d-flex flex-wrap align-items-start gap-2">
                <Select id="admin_status" placeholder="Select Option">
                  <Option>sdsd</Option>
                </Select>

                <Select id="admin_status" placeholder="Select Option">
                  <Option>sdsd</Option>
                </Select>

                <Button className="btn btn-outline  btn-light">
                  <i className="ri-catalog-fill me-1 align-bottom"></i>
                  Reset
                </Button>

                <Button color={`primary`} className="btn-load">
                  <span className="d-flex align-items-center">
                    <span className="flex-grow-1 me-2">
                      <i className="ri-search-fill me-1 align-bottom"></i>
                      Search
                    </span>
                  </span>
                </Button>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="border">
            <Row className="g-3">
              <Col>
                <WalletTable />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Wallet;
