import React, { useState } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";

const TransactionDetailsPopup = () => {
  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };
  return (
    <React.Fragment>
      <button
        className="rounded-pill btn btn-light py-1 fs-11"
        onClick={toggleRightCanvas}
      >
        View
      </button>
      {/*  */}
      <Offcanvas
        isOpen={isRight}
        toggle={toggleRightCanvas}
        id="offcanvasRight"
        direction="end"
      >
        <OffcanvasHeader
          toggle={toggleRightCanvas}
          id="offcanvasRightLabel"
          className="align-items-start border-bottom"
        >
          <h3 className="fs-16">Transaction Details</h3>
          <p className="fs-13 text-muted mb-2 fw-normal">
            Transaction ID - #132323232323
          </p>
          <p className="fs-11 text-muted mb-0 fw-normal">
            on Tue 19 Dec 08:00 AM
          </p>
        </OffcanvasHeader>
        <OffcanvasBody>
          <Card className="border">
            <CardHeader className="bg-light d-flex flex-column gap-2">
              <h5 className="fs-13 mb-0">Details</h5>
              <p className="fs-12 text-muted mb-0  fw-normal">
                Return Credit <span className="fs-11">(Ref ID: #SO123456)</span>
              </p>
            </CardHeader>
            <CardBody className="d-flex flex-column gap-2">
              <p className="fs-12 mb-0 fw-normal d-flex align-items-center gap-3">
                <span className="fw-medium d-flex align-items-center gap-2">
                  <span className="text-success">
                    <FeatherIcon
                      icon="arrow-down-right"
                      style={{ width: 16 }}
                    />
                  </span>
                  Credit
                </span>
                <strong className="fs-14 fw-semibold text-success">
                  AED 500
                </strong>
              </p>
            </CardBody>
          </Card>
          <Card className="border">
            <CardHeader className="bg-light d-flex flex-column gap-2">
              <h5 className="fs-13 mb-0">Customer Details</h5>
              <p className="fs-12 text-muted mb-0 fw-normal">
                Wallet ID #123456789012
              </p>
            </CardHeader>
            <CardBody className="d-flex flex-column gap-2">
              <p className="fs-12 mb-0 fw-normal d-flex gap-3">
                <span className="text-muted" style={{ width: "80px" }}>
                  Name
                </span>
                :<strong className="fw-medium">Jhon Joy</strong>
              </p>
              <p className="fs-12 mb-0 fw-normal d-flex gap-3">
                <span className="text-muted" style={{ width: "80px" }}>
                  Email ID
                </span>
                :<strong className="fw-medium">care@danubehome.com</strong>
              </p>
              <p className="fs-12 mb-0 fw-normal d-flex gap-3">
                <span className="text-muted" style={{ width: "80px" }}>
                  Mob No
                </span>{" "}
                :<strong className="fw-medium">+971 565862466</strong>
              </p>
            </CardBody>
          </Card>

          <Card className="border">
            <CardHeader className="bg-light d-flex flex-column gap-2">
              <h5 className="fs-13 mb-0">Billing Details</h5>
              <p className="fs-12 text-muted mb-0 fw-normal">
                Store - Danube Home Barsha
              </p>
            </CardHeader>
            <CardBody className="d-flex flex-column gap-2">
              <p className="fs-12 mb-0 fw-normal d-flex gap-3">
                <span className="text-muted" style={{ width: "80px" }}>
                  Store Code
                </span>
                :<strong className="fw-medium">DH231333</strong>
              </p>
              <p className="fs-12 mb-0 fw-normal d-flex gap-3">
                <span className="text-muted" style={{ width: "80px" }}>
                  Created By
                </span>
                :<strong className="fw-medium">Name</strong>
              </p>
              <p className="fs-12 mb-0 fw-normal d-flex gap-3">
                <span className="text-muted" style={{ width: "80px" }}>
                  Pos No
                </span>
                :<strong className="fw-medium">DHPOS231333</strong>
              </p>
            </CardBody>
          </Card>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default TransactionDetailsPopup;
