import { useEffect, useState } from 'react';
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';

import { getPromotionVersions } from '../../../actions/promotion/index';
import './style.scss';
import { formatDate } from '../../../helpers/formatDate';
import { formatTime } from '../../../helpers/formatTime';
import { Link, useParams } from 'react-router-dom';

const PromotionHistory = ({ promotionDetail }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [versions, setVersions] = useState([]);
  const params = useParams();

  useEffect(() => {
    if (params?.id && isOpenModal) {
      getPromotionVersionsFunc();
    }
  }, [params?.id, isOpenModal]);

  const getPromotionVersionsFunc = async () => {
    const variables = {
      input: {
        promotionId: params?.id?.includes('__v')
          ? params?.id?.substring(0, params?.id?.indexOf('__v'))
          : params?.id,
      },
    };
    const { data, success } = await getPromotionVersions(variables);
    if (success) {
      setVersions(data?.versions);
    }
  };

  return (
    <>
      <Button
        color="link"
        size="sm"
        style={{ boxShadow: 'none' }}
        onClick={() => {
          setIsOpenModal(true);
        }}
      >
        {`v${promotionDetail?.version}`}
      </Button>

      <Offcanvas
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(!isOpenModal);
        }}
        id="offcanvasRight"
        direction="end"
      >
        <OffcanvasHeader
          toggle={() => {
            setIsOpenModal(!isOpenModal);
          }}
          id="offcanvasRightLabel"
          className="align-items-start border-bottom"
        >
          Version History
        </OffcanvasHeader>

        <OffcanvasBody>
          <ul className="list-group">
            {versions?.map((version, index) => (
              <li
                key={index}
                className="list-group-item cursor-pointer"
                onClick={() => {
                  setIsOpenModal(false);
                }}
              >
                <div className="d-flex flex-column align-content-start">
                  <Link to={`/promotions/detail/${version?._id}/basic`}>
                    <h6
                      className={`text-decoration-underline ${
                        promotionDetail?.version === version?.version
                          ? 'text-primary'
                          : ''
                      }`}
                    >
                      v{version?.version}
                    </h6>
                  </Link>
                  <span>
                    Published At: {formatDate(version?.updatedAt)}{' '}
                    {formatTime(version?.updatedAt)}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default PromotionHistory;
