import { gql } from '@apollo/client';

export const GET_FIELD_LIST = gql`
  query getFieldList($input: FieldListInput!) {
    getFieldList(input: $input) {
      totalPages
      totalFields
      currentOffset
      fields {
        _id
        fieldGroup
        fieldName
        fieldCode
        dataType
        fieldType
        enableSumTotal
        enableSumQty
        enableContains
        optionValues {
          label
          value
        }
        placeholder
        defaultValue
        treeData
      }
    }
  }
`;

export const GET_FIELD_DETAIL = gql`
  query getFieldDetail($fieldId: String!) {
    getFieldDetail(fieldId: $fieldId) {
      fieldGroup
      fieldName
      fieldCode
      dataType
      fieldType
      defaultValue
      treeData
      fieldUsedIn
      jsonValue
      isMultiple
      enableSumQty
      enableSumTotal
      enableContains

      optionValues {
        label
        value
      }
      valueSource
      errorMessages
      errorMessagesLang {
        langCode
        value
      }
      placeholder
    }
  }
`;

export const CREATE_UPDATE_BASIC_SETTINGS = gql`
  mutation createAndUpdateBasicDetailsField(
    $input: FieldInputBasicSettings!
    $fieldId: String
  ) {
    createAndUpdateBasicDetailsField(input: $input, fieldId: $fieldId) {
      id
      success
      message
    }
  }
`;
export const DELETE_FIELD = gql`
  mutation deleteField($fieldId: String!) {
    deleteField(fieldId: $fieldId) {
      id
      success
      message
    }
  }
`;
