import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Label,
} from 'reactstrap';
import RewardMediasUploadPopup from './RewardMediasUploadPopup';

const RewardMedias = () => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 class="fw-medium mb-0 card-title">All media</h3>
        <RewardMediasUploadPopup />
      </div>
      <Row>
        <Col sm={4} md={3} xxl={2} className="mb-4">
          <div className="p-2 bg-light border rounded border-soft-dark position-relative">
            <div className="position-absolute top-0 end-0 p-2 d-flex gap-1">
              <Button color="info" className="p-1 fs-14 lh-1 rounded-circle">
                <i className="ri-pencil-line" />
              </Button>
              <Button color="danger" className="p-1 fs-14 lh-1 rounded-circle">
                <i className="ri-delete-bin-5-line" />
              </Button>
            </div>
            <div className="rounded-2" style={{ backgroundColor: 'white' }}>
              <img
                src="https://assets.danubehome.com/media/assets/svg/logoEn.svg"
                width={100}
                height={100}
                className="w-100 rounded-2"
                style={{ objectFit: 'contain' }}
              />
            </div>
            <div className="mt-2">
              <p className="mb-0 fs-11">Logo</p>
              <p className="mb-0 fs-11">Country: All</p>
            </div>
          </div>
        </Col>
        <Col sm={4} md={3} xxl={2} className="mb-4">
          <div className="p-2 bg-light border rounded border-soft-dark position-relative">
            <div className="position-absolute top-0 end-0 p-2 d-flex gap-1">
              <Button color="info" className="p-1 fs-14 lh-1 rounded-circle">
                <i className="ri-pencil-line" />
              </Button>
              <Button color="danger" className="p-1 fs-14 lh-1 rounded-circle">
                <i className="ri-delete-bin-5-line" />
              </Button>
            </div>
            <div className="rounded-2" style={{ backgroundColor: 'white' }}>
              <img
                src="https://www.svgrepo.com/show/483049/point.svg"
                width={100}
                height={100}
                className="w-100 rounded-2"
                style={{ objectFit: 'contain' }}
              />
            </div>
            <div className="mt-2">
              <p className="mb-0 fs-11">Icon</p>
              <p className="mb-0 fs-11">Country: All</p>
            </div>
          </div>
        </Col>
        <Col sm={4} md={3} xxl={2} className="mb-4">
          <div className="p-2 bg-light border rounded border-soft-dark position-relative">
            <div className="position-absolute top-0 end-0 p-2 d-flex gap-1">
              <Button color="info" className="p-1 fs-14 lh-1 rounded-circle">
                <i className="ri-pencil-line" />
              </Button>
              <Button color="danger" className="p-1 fs-14 lh-1 rounded-circle">
                <i className="ri-delete-bin-5-line" />
              </Button>
            </div>
            <div className="rounded-2" style={{ backgroundColor: 'white' }}>
              <img
                src="https://images.ctfassets.net/058vu9hqon0j/3ws6ejuKBWC6hmADCWaDGS/5d520fa056aa8f360fd46730da2874a7/MOB-AR_copy_3-100.jpg"
                width={100}
                height={100}
                className="w-100 rounded-2"
                style={{ objectFit: 'contain' }}
              />
            </div>
            <div className="mt-2">
              <p className="mb-0 fs-11">Home Page Banner</p>
              <p className="mb-0 fs-11">Country: All</p>
            </div>
          </div>
        </Col>
        <Col sm={4} md={3} xxl={2} className="mb-4">
          <div className="p-2 bg-light border rounded border-soft-dark position-relative">
            <div className="position-absolute top-0 end-0 p-2 d-flex gap-1">
              <Button color="info" className="p-1 fs-14 lh-1 rounded-circle">
                <i className="ri-pencil-line" />
              </Button>
              <Button color="danger" className="p-1 fs-14 lh-1 rounded-circle">
                <i className="ri-delete-bin-5-line" />
              </Button>
            </div>
            <div className="rounded-2" style={{ backgroundColor: 'white' }}>
              <img
                src="https://images.ctfassets.net/058vu9hqon0j/7uuD2LaFLoQ7u2qSY2dtsB/c62d0907c3596575d8b907f44adf04eb/DESK-ENG-100.jpg"
                width={100}
                height={100}
                className="w-100 rounded-2"
                style={{ objectFit: 'contain' }}
              />
            </div>
            <div className="mt-2">
              <p className="mb-0 fs-11">PLP Banner</p>
              <p className="mb-0 fs-11">Country: All</p>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RewardMedias;
