import React from "react";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../../components/Common/ChartsDynamicColor";

const EngagedScore = ({ dataColors }) => {
  var chartStorkeRadialbarColors = getChartColorsArray(dataColors);
  const series = [76];
  var options = {
    chart: {
      type: "radialBar",
      height: 350,
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: false,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: ["Average Results"],
    colors: chartStorkeRadialbarColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={350}
    />
  );
};
const HeighLtv = ({ dataColors }) => {
  var chartRadialbarBasicColors = getChartColorsArray(dataColors);
  const series = [70];
  var options = {
    chart: {
      height: 250,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
      },
    },
    labels: [],
    dataLabels: {
      enabled: false,
    },
    colors: chartRadialbarBasicColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={150}
      width={150}
    />
  );
};
const Recommended = ({ dataColors }) => {
  var chartRadialbarBasicColors = getChartColorsArray(dataColors);
  const series = [70];
  var options = {
    chart: {
      height: 250,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
      },
    },
    labels: [],
    colors: chartRadialbarBasicColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={150}
      width={150}
    />
  );
};
const PurchaseFrequency = ({ dataColors }) => {
  var chartRadialbarBasicColors = getChartColorsArray(dataColors);
  const series = [70];
  var options = {
    chart: {
      height: 250,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
      },
    },
    labels: [],
    colors: chartRadialbarBasicColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={150}
      width={150}
    />
  );
};
const ConvenienceShopper = ({ dataColors }) => {
  var chartRadialbarBasicColors = getChartColorsArray(dataColors);
  const series = [70];
  var options = {
    chart: {
      height: 250,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
      },
    },
    labels: [],
    colors: chartRadialbarBasicColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={150}
      width={150}
    />
  );
};
const Churn = ({ dataColors }) => {
  var chartRadialbarBasicColors = getChartColorsArray(dataColors);
  const series = [70];
  var options = {
    chart: {
      height: 250,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
      },
    },
    labels: [],
    colors: chartRadialbarBasicColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={150}
      width={150}
    />
  );
};
export {
  EngagedScore,
  HeighLtv,
  Recommended,
  PurchaseFrequency,
  ConvenienceShopper,
  Churn,
};
