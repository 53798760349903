import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import classnames from 'classnames';
import {
  CardBody,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from 'reactstrap';
import TransactionDetailsPopup from './TransactionDetailsPopup';
import { getExpiredTransactions, getLedgerTransactions } from '../../../actions/wallet/index';
import { formatDate } from '../../../helpers/formatDate';
import { formatTime } from '../../../helpers/formatTime';
import moment from 'moment';
import Pagination from '../../../components/Common/Pagination';
import { formatCurrency } from '../../../helpers/formatCurrency';

const NoRecordsMessage = () => {
  return (
    <tr>
      <td colSpan="8" className="text-center py-4">
        <h5 className="fs-13 my-1 fw-normal">No transactions found.</h5>
      </td>
    </tr>
  );
};

const OtherReportTable = (props) => {
  const { search, mode, status, mobileNumber, setIsLoading, dateRangeFilter, cardHeaderTab, setCardHeaderTab, selectedDjvFilter, djvPassedTrigger } = props;
  const [transactions, setTransactions] = useState([]);
  const [pageOffset, setPageOffset] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [isDataLoader, setIsDataLoader] = useState(false);
  const cardHeaderToggle = (tab) => {
    if (cardHeaderTab !== tab.id) {
      setCardHeaderTab(tab.id);
      setTransactions([]);
    }
  };
  useEffect(() => {
    getTransactionsFunc();
  }, [selectedDjvFilter, pageOffset, search, mode, status, mobileNumber, setIsLoading, dateRangeFilter, cardHeaderTab, setCardHeaderTab, djvPassedTrigger]);


  const pages = [...Array(totalPages)?.keys()];

  const getTransactionsFunc = async () => {

    setIsLoading(true);
    setIsDataLoader(true);
    let data = {};
    let endpoint = getExpiredTransactions;
    if (cardHeaderTab === 'ledger') {
      endpoint = getLedgerTransactions;
      data = {
        country: 'ae',
        page: pageOffset - 1,
        pageSize: pageSize,
        sortBy: 'date',
        sortOrder: 'DESC',
        dateRange: dateRangeFilter || []
      };
    } else {
      data = {
        country: 'ae',
        mobileNumber: mobileNumber,
        page: pageOffset - 1,
        pageSize: pageSize,
        search: search || '',
        mode: mode || '',
        status: 'expired',
        sortBy: 'createdAt',
        sortOrder: 'DESC',
        dateRange: dateRangeFilter || [],
        isDjvPassed: selectedDjvFilter || ''
      };
    }

    let res = await endpoint(data);
    if (res?.success) {
      setTransactions(res?.data?.data);
      setTotalPages(res?.data?.pageInfo?.totalPages);
      setTotalItems(res?.data?.pageInfo?.totalItems);
    }
    setIsLoading(false);
    setIsDataLoader(false);
  };

  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const tabs = [
    {
      label: 'Expiring And Expired',
      id: 'expiring-and-expired',
    },
    {
      label: 'Ledger',
      id: 'ledger',
    },
  ];
  const getStatusClass = (status) => {
    switch (status) {
      case 'approved':
        return 'badge bg-success';
      case 'pending':
      case 'hold':
        return 'badge bg-warning';
      case 'rejected':
        return 'badge bg-danger';
      default:
        return 'badge bg-secondary'; // fallback class
    }
  };
  return (
    <React.Fragment>
      <div className="table-card">
        <div className="card-header align-items-center d-flex">
          <div className="flex-shrink-0 ms-2">
            <Nav
              tabs
              className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
            >
              {tabs.map((tab, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({ active: cardHeaderTab === tab.id })}
                    onClick={() => cardHeaderToggle(tab)}
                  >
                    {tab.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>
        </div>
        <CardBody className="border-right border-left">
          <TabContent activeTab={cardHeaderTab} className="text-muted">
            {tabs?.map((tab) => {
              return (
                <TabPane key={tab.id} tabId={tab?.id}>
                  <div className="table-responsive table-card">
                    {isDataLoader ? (
                      <div className="w-100 h-100 position-fixed top-0 start-0 bg-dark bg-opacity-25 z-3">
                        <div className="position-absolute top-50 start-50">
                          <Spinner color="dark">Loading...</Spinner>
                        </div>
                      </div>
                    ) :
                      (<table className="table table-hover table-centered align-middle table-nowrap mb-0">
                        <thead>
                          {cardHeaderTab === 'ledger' ? (
                            <tr>
                              <th className="text-center">#</th>
                              <th className="text-center">Country</th>
                              <th className="text-center">Date</th>
                              <th className="text-end pe-5">Daily Debit</th>
                              <th className="text-end pe-5">Daily Credit</th>
                              <th className="text-end pe-5">Expired</th>
                              <th className="text-end pe-5">Daily Balance</th>
                              <th className="text-end pe-5">Available Balance</th>
                            </tr>
                          ) : (
                            <tr>
                              <th></th>
                              <th>Date</th>
                              <th>Transaction #</th>
                              <th>Mobile # / Name</th>
                              <th>Description</th>
                              <th>Status</th>
                              <th>DJV Passed</th>
                              <th className="text-right">Amount</th>
                              <th>Mode</th>
                              <th></th>
                            </tr>
                          )}
                        </thead>
                        <tbody>
                          {transactions.length === 0 ? (
                            <NoRecordsMessage />
                          ) : (
                            transactions.map((transaction, index) => {
                              const ci = 10;
                              const siNo = (pageOffset - 1) * ci + (index + 1);
                              return cardHeaderTab === 'ledger' ? (
                                <tr key={index}>
                                  <td>
                                    <p className="fs-13 text-center">{siNo}</p>
                                  </td>
                                  <td>
                                    <p className="fs-13 fw-normal mb-0 text-center">
                                      {transaction?.country}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="fs-13 fw-normal text-center">
                                      {formatDate(transaction?.date, 'MM/DD/YYYY')}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="fs-13 fw-bold text-end pe-5 text-danger">
                                      {formatCurrency(transaction?.DailyDebit, transaction?.country)}
                                    </p>

                                  </td>
                                  <td>
                                    <p className="fs-13 fw-bold text-end pe-5 text-success">
                                      {formatCurrency(transaction?.DailyCredit, transaction?.country)}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="fs-13 fw-bold text-end pe-5 text-danger">
                                      {formatCurrency(transaction?.Expired, transaction?.country)}
                                    </p>
                                  </td>
                                  <td>
                                    <p className={`fs-13 fw-bold text-end pe-5 ${transaction?.DailyBalance < 0 ? 'text-danger' : transaction?.DailyBalance > 0 ? 'text-success' : ''}`}>
                                      {formatCurrency(transaction?.DailyBalance, transaction?.country)}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="fs-13 fw-bold text-end pe-5">
                                      {formatCurrency(transaction?.AvailableBalance, transaction?.country)}
                                    </p>
                                  </td>
                                </tr>

                              ) : (
                                <tr key={index}>
                                  <td>
                                    <p className="fs-13">{siNo}</p>
                                  </td>

                                  <td>
                                    <p className="fs-13 fw-normal mb-0">
                                      {formatDate(transaction?.createdAt)}
                                    </p>
                                    <span className="fs-10 text-muted">
                                      {formatTime(transaction?.createdAt)}
                                    </span>
                                  </td>
                                  <td>
                                    <p className="fs-13 fw-normal">
                                      {transaction?.token}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="fs-13 fw-normal mb-0 d-flex gap-1 align-items-center">
                                      <i class="bx bx-mobile"></i>{' '}
                                      {transaction?.mobileNumber}
                                    </p>
                                    <p className="fs-10 text-muted m-0">
                                      Name :  {transaction?.fullName?.replace('null', '').trim()}
                                    </p>
                                    <p className="d-flex gap-1 m-0">
                                      <i class="bx bx-wallet-alt"></i>
                                      <a
                                        href={`list/details/ae/${transaction?.mobileNumber}`}
                                        target="_blank"
                                        className="fs-10"
                                      >
                                        {transaction?.walletId}
                                      </a>
                                    </p>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <span
                                        className={`${transaction?.mode === 'credit'
                                          ? 'text-success'
                                          : 'text-danger'
                                          }`}
                                      >
                                        <FeatherIcon
                                          icon={`${transaction?.mode === 'credit'
                                            ? 'arrow-up-right'
                                            : 'arrow-down-right'
                                            }`}
                                          style={{ width: 16 }}
                                        />
                                      </span>
                                      <div>
                                        <p className="fs-13 fw-normal">
                                          {transaction?.title}
                                        </p>
                                        <p className="fs-10 m-0 text-muted">
                                          Source : Online , Ref# :{' '}
                                          <b>{transaction?.transactionNo}</b>
                                        </p>
                                        {transaction.expiryAt ? (
                                          moment().isAfter(
                                            moment(transaction?.expiryAt)
                                          ) ? (
                                            <p className="fs-10 m-0 text-danger">
                                              Expired on{' '}
                                              {moment(
                                                transaction?.expiryAt
                                              ).format('DD MMMM YYYY')}
                                            </p>
                                          ) : (
                                            <p className="fs-10 m-0 text-warning">
                                              Expiry on{' '}
                                              {moment(
                                                transaction.expiryAt
                                              ).format('DD MMMM YYYY')}
                                            </p>
                                          )
                                        ) : null}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span
                                      className={getStatusClass(
                                        transaction?.status
                                      )}
                                    >
                                      {transaction?.status}
                                    </span>
                                  </td>
                                  <td>
                                    <span className={`${transaction.isDjvPassed === true
                                      ? 'text-success'
                                      : 'text-danger'
                                      } fs-13 my-1 fw-normal text-right`}>
                                      {transaction.isDjvPassed === true ? 'Yes' : 'No'}
                                    </span>
                                  </td>
                                  <td>
                                    <p
                                      className={`${transaction?.mode === 'credit'
                                        ? 'text-success'
                                        : 'text-danger'
                                        } fs-13 my-1 fw-bold text-right`}
                                    >
                                      {transaction?.mode != 'credit'
                                        ? '- '
                                        : '+ '}
                                      {transaction?.currency}{' '}
                                      {
                                        (
                                          Math.round(
                                            Math.abs(transaction?.amount) * 100
                                          ) / 100
                                        ).toFixed(2)
                                        //Number(transaction?.amount).toFixed(2)
                                      }
                                    </p>
                                  </td>
                                  <td>
                                    {transaction?.mode.charAt(0).toUpperCase() +
                                      transaction?.mode.slice(1)}
                                  </td>
                                  <td>
                                    <TransactionDetailsPopup
                                      transactionId={transaction.id}
                                    />
                                    {transaction.status === 'pending' ? (
                                      <button className="rounded-pill btn btn-success py-1 fs-11 ms-2">
                                        Approve
                                      </button>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>)}
                  </div>
                  <Pagination
                    totalPages={totalPages}
                    totalItems={totalItems}
                    currentPage={1}
                    onPageChange={setPageOffset}
                  />
                </TabPane>
              );
            })}
          </TabContent>
        </CardBody>
      </div>
    </React.Fragment>
  );
};

export default OtherReportTable;
