import React, { useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  FormGroup,
  Label,
  InputGroupText,
  Row,
  Col,
} from 'reactstrap';
import { If } from 'react-if';
import QueryBuilderApp from '../QueryBuilderApp';
import { DISCOUNT_TYPES } from '../../../constants';
import EffectTransactionConditional from './EffectTransactionConditional';

const GiftOption = ({
  optionCount,
  index,
  handleValueChange,
  handleRemoveOption,
  option,
  combinedUnitBasedDiscount,
  effect = {},
}) => {
  const [showDiscountRule, setShowDiscountRule] = useState(
    !!option?.discountValue
  );
  const [showItemQueryBuilder, setShowItemQueryBuilder] = useState(
    !!option?.rule || true
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <div
      key={index}
      className="border p-3 mb-3 rounded position-relative"
      style={{ background: 'white' }}
    >
      <div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <Label className="mb-0">
            <b>{`${effect.discountType === 'gift' ? 'Gift' : 'Bundle'} ${
              index + 1
            }`}</b>{' '}
            <i>of</i> <b>{optionCount}</b>
          </Label>
          <Button
            aria-label="delete"
            color="soft-dark"
            onClick={() => handleRemoveOption(index)}
            size="sm"
            className="shadow-none rule-remove btn-danger fs-20 p-0"
          >
            <i class="bi bi-x"></i>
          </Button>
        </div>

        <If condition={showItemQueryBuilder}>
          <>
            <div className="">
              <If
                condition={
                  option.matchItemAgainstGroup === undefined ||
                  option.matchItemAgainstGroup === ''
                }
              >
                <div>
                  <label className=" mb-3 text-muted text-small">
                    Choose the gift items
                  </label>
                  <QueryBuilderApp
                    data={option.rule || undefined}
                    updateQuery={(query) =>
                      handleValueChange(index, query, 'rule')
                    }
                    fieldGroups={['item']}
                  />
                </div>
              </If>
            </div>
          </>
        </If>
        <If condition={effect.discountType !== 'segmented'}>
          <Row className="mb-2 mt-2">
            <Col>
              <FormGroup className="mb-3">
                <Label className="mb-0">Min Units</Label>
                <Input
                  type="number"
                  value={option.minUnits}
                  onChange={(e) =>
                    handleValueChange(index, e.target.value, 'minUnits')
                  }
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-3">
                <Label className="mb-0">Max Units</Label>
                <Input
                  type="number"
                  value={option.onUnit}
                  onChange={(e) =>
                    handleValueChange(index, e.target.value, 'onUnit')
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </If>
      </div>
    </div>
  );
};

export default GiftOption;
