import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Col, CardHeader, Row, Button } from 'reactstrap';
import InnerLoader from '../../../components/Common/InnerLoader';
import { getRewardsMaster } from '../../../actions/rewards';
import { formatDate } from '../../../helpers/formatDate';
import { formatTime } from '../../../helpers/formatTime';
import { useNavigate } from 'react-router-dom';

const RewardTypeList = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [rewardTypes, setRewardTypes] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const getRewardsFunc = async () => {
    try {
      setIsLoading(true);

      const data = {
        pageOffset: pageOffset,
        pageSize: pageSize,
      };
      const res = await getRewardsMaster(data);

      setTotalPages(res?.data?.totalPages);
      setRewardTypes(res?.data?.rewardTypes || []);
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRewardsFunc();
  }, [pageOffset]);

  const pages = [...Array(totalPages)?.keys()];

  return (
    <>
      <CardHeader className="border-0">
        <Row className="pb-4 align-items-center">
          <Col sm></Col>
          <Col sm="auto">
            <div className="d-flex flex-wrap align-items-start">
              <Button
                color="primary"
                onClick={() => {
                  navigate(`/reward-types/detail/new`);
                }}
                className="btn btn-success add-btn"
              >
                + Create New Reward Type
              </Button>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <div className="table-responsive table-card">
        <table className="table table-nowrap table-centered align-middle">
          <thead className="bg-light text-muted">
            <tr>
              <th>Name</th>
              <th>Created At</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(rewardTypes || []).map((rewardType, key) => (
              <tr key={key}>
                <td>
                  <div className="d-flex align-items-center">
                    <div>
                      <h5 className="fs-13 mb-1">{rewardType?.name}</h5>
                    </div>
                  </div>
                </td>

                <td>
                  <h5 className="fs-13 mb-1 fw-normal">
                    {rewardType?.createdAt
                      ? formatDate(rewardType?.createdAt)
                      : '-'}
                  </h5>
                  <span className="fs-10 text-muted">
                    {rewardType?.createdAt
                      ? formatTime(rewardType?.createdAt)
                      : '-'}
                  </span>
                </td>
                <td>{rewardType?.isActive ? 'Active' : 'Disabled'}</td>
                <td>
                  <div className="d-flex flex-row gap-3">
                    <a
                      size="sm"
                      style={{ width: 82, right: 135 }}
                      className="cursor-pointer text-decoration-underline position-absolute fs-11"
                      color="outline-primary"
                      onClick={() => {
                        navigate(
                          `/reward-types/detail/${rewardType?._id}/basic`
                        );
                      }}
                    >
                      Edit
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
        <div className="col-sm"></div>
        {totalPages > 1 ? (
          <div className="col-sm-auto mt-3 mt-sm-0">
            <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
              <li className={`page-item  ${pageOffset < 1 ? 'disabled' : ''}`}>
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    setPageOffset(pageOffset - 1);
                  }}
                >
                  ←
                </Link>
              </li>

              {pages?.map((page, index) => {
                return (
                  <li
                    className={`page-item ${
                      pageOffset === index ? 'active' : ''
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => setPageOffset(index)}
                    >
                      {index + 1}
                    </Link>
                  </li>
                );
              })}

              <li className="page-item">
                <Link
                  to="#"
                  className={`page-link ${
                    pageOffset >= totalPages - 1 ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    setPageOffset(pageOffset + 1);
                  }}
                >
                  →
                </Link>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
      {isLoading ? <InnerLoader /> : null}
    </>
  );
};

export default RewardTypeList;
