const layoutTypes = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  TWO_COLUMN: 'twocolumn',
  SEMIBOX: 'semibox',
};

const layoutModeTypes = {
  LIGHT_MODE: 'light',
  DARKMODE: 'dark',
};

const leftSidebarTypes = {
  LIGHT: 'light',
  DARK: 'dark',
  BLUE: 'blue',
  GRADIENT: 'gradient',
  GRADIENT_2: 'gradient-2',
  GRADIENT_3: 'gradient-3',
  GRADIENT_4: 'gradient-4',
};

const layoutWidthTypes = {
  FLUID: 'lg',
  BOXED: 'boxed',
};

const layoutPositionTypes = {
  FIXED: 'fixed',
  SCROLLABLE: 'scrollable',
};

const topBarThemeTypes = {
  LIGHT: 'light',
  DARK: 'dark',
};

const leftSidBarSizeTypes = {
  DEFAULT: 'lg',
  //DEFAULT: 'sm',
  COMPACT: 'md',
  SMALLICON: 'sm',
  SMALLHOVER: 'sm-hover',
};

const leftSideBarViewTypes = {
  DEFAULT: 'default',
  DETACHED: 'detached',
};

const preloaderTypes = {
  ENABLE: 'enable',
  DISABLE: 'disable',
};

const sideBarVisibilityTypes = {
  SHOW: 'show',
  HIDDEN: 'hidden',
};

export {
  layoutTypes,
  layoutModeTypes,
  leftSidebarTypes,
  layoutWidthTypes,
  layoutPositionTypes,
  topBarThemeTypes,
  leftSidBarSizeTypes,
  leftSideBarViewTypes,
  preloaderTypes,
  sideBarVisibilityTypes,
};
