import React from 'react';
const RolesPermissionTable = () => {
  const Permissions = [
    {
      admin: [
        {
          label1: 'View Orders Details',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label2: 'Export Order Report in CSV',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label3: 'Cancel an Order',
          RolesPermission: 'mdi-close',
          status: 'danger',
        },
        {
          label4: 'Capture an Order',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label5: 'Refund an Order',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label6: 'Authorize an order',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label7: 'View Order Histories/Activities',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label8: 'Create an Order from Portal',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label8: 'Edit Order Reference Number',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label10: 'Order List View V2',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label11: 'View Invoices Summary',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label12: 'Export Invoice Individually',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label13: 'View Settlement Info',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label14: 'Export Multiple Invoices in a Single Zip',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label15: 'Add Webhook',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label16: 'Edit Webhook',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label17: 'Delete Webhook',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label18: 'View Webhooks',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label19: 'In-store Barcode',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label20: 'Update In-store Barcode',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label21: 'In Store',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label22: 'Get Merchant Contacts',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label23: 'Edit contact info',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label24: 'Generate Api Key',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label25: 'Create Account Address',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label26: 'Merchant Address List',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label27: 'Delete Role',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label28: 'Add Role',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label29: 'Edit Role',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label30: 'View Users',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label31: 'Add User',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label32: 'Edit User',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label33: 'Delete User',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label34: 'Export Transaction Report in CSV',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label35: 'View Transactions',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label36: 'Dashboard OverView',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label37: 'Create Store',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label38: 'View Store',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label39: 'Update Store',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label40: 'Activate/Deactivate Store',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label41: 'View Disputes List',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label42: 'View Disputes Details',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label43: 'Download Dispute Report',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label44: 'Refund Dispute',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
        {
          label45: 'Challenge Dispute',
          RolesPermission: 'mdi-check',
          status: 'success',
        },
      ],
    },
  ];
  return (
    <>
      <div className="tableFixHead rounded-4 border">
        <table class="table table-centered align-top table-nowrap mb-0">
          <thead>
            <tr>
              <th className="bg-light px-2 py-3">Permission</th>
              <th className="px-4 py-3" style={{ background: '#fce3de' }}>
                Admin
              </th>
              <th className="bg-light px-4 py-3">Finance</th>
              <th className="bg-light px-4 py-3">Staff</th>
              <th className="bg-light px-4 py-3">Executive</th>
              <th className="bg-light px-4 py-3">In-store Staff</th>
              <th className="bg-light px-4 py-3">Lead Finance</th>
              <th className="bg-light px-4 py-3">Dispute Manager</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-close text-danger fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-close text-danger fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-close text-danger fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-close text-danger fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-close text-danger fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-close text-danger fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-close text-danger fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-close text-danger fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-close text-danger fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-close text-danger fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-close text-danger fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
            <tr>
              <th className="bg-light">View Orders Details</th>
              <td id="admin" className="text-center bg-soft-danger">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="executive" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="in-store-staff" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="lead-finance" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
              <td id="dispute-manager" className="text-center">
                <i
                  className={`mdi mdi-check text-success fs-18`}
                  style={{ lineHeight: 0 }}
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RolesPermissionTable;
