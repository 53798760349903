import React, { useState, useEffect, useContext, createContext } from 'react';
import { getAccessListAction } from '../actions/auth/action';

// Global permission context
const PermissionContext = createContext();

export const usePermissions = () => {
  return useContext(PermissionContext);
};

const PermissionProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(new Set()); // Use Set for efficient lookups
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await getAccessListAction();
        setPermissions(new Set(response)); // Convert array to Set
        setLoading(false);
      } catch (err) {
        setError(err.message || 'Error fetching permissions');
        setLoading(false);
      }
    };

    fetchPermissions();
  }, []);

  const value = { permissions, loading, error };

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};

const Authorize = ({ permission, children }) => {
  const { permissions, loading, error } = usePermissions();

  // error
  if (loading) return <div>Loading permissions...</div>;
  if (error) return <div>Error loading permissions: {error}</div>;

  // permission is allowed
  return permissions?.has(permission) ? <>{children}</> : null;
};

export { PermissionProvider, Authorize };
