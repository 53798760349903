import promoRestService from '../../services/PromoRestService';
export const createOrUpdateTeam = async (data) => {
  try {
    const result = await promoRestService?.post('team/create', data);
    return result?.data;
  } catch (error) {
    return null;
  }
};

export const getTeams = async (data) => {
  try {
    const result = await promoRestService?.post('team/list', data);
    return result?.data;
  } catch (error) {
    return null;
  }
};
