import { ApolloClientPromoService } from '../../services/ApolloClientPromoService';
import { USER_REPORTS_DATA } from '../../gql/reports';
export const userExportReportDataAction = async ({ pagination }) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: USER_REPORTS_DATA,
      operationName: 'USER_REPORTS_DATA',
      variables: { pagination },
      fetchPolicy: 'network-only',
    });
    return data?.exportReportList || {};
  } catch (error) {
    return error?.message || 'Something went wrong...';
  }
};
