import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import { getTransactionDetails } from "../../../actions/wallet";

const TransactionDetailsPopup = ({ transactionId }) => {
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [isRight, setIsRight] = useState(false);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      const res = await getTransactionDetails({ id: transactionId });
      if (res?.success) {
        setTransactionDetails(res.data);
      }
    };

    if (isRight) {
      fetchTransactionDetails();
    }
  }, [transactionId, isRight]);

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };
  const getModeDetails = (mode) => {
    if (mode === 'credit') {
      return { className: 'text-success', icon: 'arrow-up-right' };
    } else if (mode === 'debit') {
      return { className: 'text-danger', icon: 'arrow-down-right' };
    }
    return { className: '', icon: '' };
  };

  const modeDetails = transactionDetails ? getModeDetails(transactionDetails.mode) : {};

  return (
    <React.Fragment>
      <button
        className="rounded-pill btn btn-light py-1 fs-11"
        onClick={toggleRightCanvas}
      >
        View
      </button>
      {/*  */}
      <Offcanvas
        isOpen={isRight}
        toggle={toggleRightCanvas}
        id="offcanvasRight"
        direction="end"
      >
        <OffcanvasHeader
          toggle={toggleRightCanvas}
          id="offcanvasRightLabel"
          className="align-items-start border-bottom"
        >
          <h3 className="fs-16">Transaction Details</h3>
          {transactionDetails && (
            <p className="fs-13 text-muted mb-2 fw-normal">
              Transaction ID - {transactionDetails.token}
            </p>
          )}
          {transactionDetails && (
            <p className="fs-11 text-muted mb-0 fw-normal">
              on {new Date(transactionDetails.createdAt).toLocaleString()}
            </p>
          )}
        </OffcanvasHeader>
        <OffcanvasBody>
          {transactionDetails ? (<>
            <Card className="border">
              <CardHeader className="bg-light d-flex flex-column gap-2">
                <h5 className="fs-13 mb-0">Details</h5>
                <p className="fs-12 text-muted mb-0  fw-normal">
                  Return Credit <span className="fs-11">(Ref ID: #{transactionDetails.transactionNo})</span>
                </p>
              </CardHeader>
              <CardBody className="d-flex flex-column gap-2">
                <p className="fs-12 mb-0 fw-normal d-flex align-items-center gap-3">
                  <span className="fw-medium d-flex align-items-center gap-2">
                    <span className={`${modeDetails.className}`}>
                      <FeatherIcon
                        icon={modeDetails.icon}
                        style={{ width: 16 }}
                      />
                    </span>
                    {transactionDetails.mode}
                  </span>
                  <strong className={`fs-14 fw-semibold ${modeDetails.className}`}>
                    {transactionDetails.wallet.currency} {Number(transactionDetails.amount).toFixed(2)}
                  </strong>
                </p>
              </CardBody>
            </Card>
            <Card className="border">
              <CardHeader className="bg-light d-flex flex-column gap-2">
                <h5 className="fs-13 mb-0">Customer Details</h5>
                <p className="fs-12 text-muted mb-0 fw-normal">
                  Wallet ID #{transactionDetails.wallet.uniqueId}
                </p>
              </CardHeader>
              <CardBody className="d-flex flex-column gap-2">
                <p className="fs-12 mb-0 fw-normal d-flex gap-3">
                  <span className="text-muted" style={{ width: "80px" }}>
                    Name
                  </span>
                  :<strong className="fw-medium">{transactionDetails.wallet.firstName}{transactionDetails.wallet.lastName}</strong>
                </p>
                <p className="fs-12 mb-0 fw-normal d-flex gap-3">
                  <span className="text-muted" style={{ width: "80px" }}>
                    Email ID
                  </span>
                  :<strong className="fw-medium">{transactionDetails.wallet.email}</strong>
                </p>
                <p className="fs-12 mb-0 fw-normal d-flex gap-3">
                  <span className="text-muted" style={{ width: "80px" }}>
                    Mob No
                  </span>{" "}
                  :<strong className="fw-medium">{transactionDetails.wallet.mobileNumber}</strong>
                </p>
              </CardBody>
            </Card>

            {/* <Card className="border">
              <CardHeader className="bg-light d-flex flex-column gap-2">
                <h5 className="fs-13 mb-0">Billing Details</h5>
                <p className="fs-12 text-muted mb-0 fw-normal">
                  Store - Danube Home Barsha
                </p>
              </CardHeader>
              <CardBody className="d-flex flex-column gap-2">
                <p className="fs-12 mb-0 fw-normal d-flex gap-3">
                  <span className="text-muted" style={{ width: "80px" }}>
                    Store Code
                  </span>
                  :<strong className="fw-medium">DH231333</strong>
                </p>
                <p className="fs-12 mb-0 fw-normal d-flex gap-3">
                  <span className="text-muted" style={{ width: "80px" }}>
                    Created By
                  </span>
                  :<strong className="fw-medium">Name</strong>
                </p>
                <p className="fs-12 mb-0 fw-normal d-flex gap-3">
                  <span className="text-muted" style={{ width: "80px" }}>
                    Pos No
                  </span>
                  :<strong className="fw-medium">DHPOS231333</strong>
                </p>
              </CardBody>
            </Card> */}
          </>
          ) : (
            <p>Loading...</p>
          )}
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment >
  );
};

export default TransactionDetailsPopup;
