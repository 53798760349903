import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  InputGroup,
  FormFeedback,
  ModalFooter,
} from 'reactstrap';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import Select from 'react-select';
const SingleOptions = [
  { value: 'UAE', label: 'UAE' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Bahrain', label: 'Bahrain' },
  { value: 'Qatar', label: 'Qatar' },
];
const RewardMediasUploadPopup = () => {
  const [modal_scroll, setmodal_scroll] = useState(false);
  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
  }
  const [selectedFiles, setselectedFiles] = useState([]);
  const [files, setFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }
  const [selectedMulti2, setselectedMulti2] = useState(null);

  function handleMulti2(selectedMulti2) {
    setselectedMulti2(selectedMulti2);
  }

  return (
    <>
      <Button color="success" onClick={() => tog_scroll()}>
        <i className="r ri-image-add-line fs-14 me-2 align-bottom"></i>
        Add asset
      </Button>

      <Modal
        isOpen={modal_scroll}
        toggle={() => {
          tog_scroll();
        }}
        scrollable={true}
        id="exampleModalScrollable"
        style={{ maxWidth: 991 }}
      >
        <ModalHeader
          className="modal-title"
          id="rolesAndPermissions"
          toggle={() => {
            tog_scroll();
          }}
        >
          Asset (Untitled/Name)
        </ModalHeader>
        <ModalBody className="py-0">
          <Row>
            <Col md={12} xl={12} xxl={12}>
              <div className="p-3 bg-light border rounded border-soft-dark">
                <Row>
                  <Col sm={6} md={4} xxl={4}>
                    <Label>Name</Label>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          type="number"
                          value=""
                          placeholder="Name"
                          min={0}
                          className="rounded-2"
                        />

                        <FormFeedback>ddsdsd</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} xxl={4}>
                    <Label>Image Title</Label>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          type="number"
                          value=""
                          placeholder="Image Title"
                          min={0}
                          className="rounded-2"
                        />

                        <FormFeedback>ddsdsd</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} xxl={4}>
                    <Label>Alt Tag</Label>
                    <FormGroup>
                      <InputGroup>
                        <Input
                          type="number"
                          value=""
                          placeholder="Alt Tag"
                          min={0}
                          className="rounded-2"
                        />

                        <FormFeedback>ddsdsd</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={12}>
                    <Label
                      htmlFor="choices-multiple-remove-button"
                      className="form-label"
                    >
                      Choose Countries
                    </Label>
                    <Select
                      value={selectedMulti2}
                      isMulti={true}
                      isClearable={true}
                      onChange={() => {
                        handleMulti2();
                      }}
                      options={SingleOptions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6 className="fs-14 mb-3">Select Image to Upload</h6>
                  </Col>
                </Row>
                <div className="p-3 border rounded-2 bg-light-subtle">
                  <Row>
                    <Col md={6} className="mb-3">
                      <p className="mb-1 d-flex align-items-center gap-1">
                        <label className="mb-0">Desktop File</label>
                        <span className="text-muted fs-11 ">
                          English (required)
                        </span>
                      </p>
                      <div className="mediaUpload border border-dashed rounded-3">
                        <FilePond
                          files={files}
                          onupdatefiles={setFiles}
                          allowMultiple={true}
                          maxFiles={3}
                          name="files"
                          className="filepond filepond-input-multiple fs-14"
                        />
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <p className="mb-1 d-flex align-items-center gap-1">
                        <label className="mb-0">Desktop File</label>
                        <span className="text-muted fs-11 ">Arabic </span>
                      </p>
                      <div className="mediaUpload border border-dashed rounded-3">
                        <FilePond
                          files={files}
                          onupdatefiles={setFiles}
                          allowMultiple={true}
                          maxFiles={3}
                          name="files"
                          className="filepond filepond-input-multiple fs-14"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <p className="mb-1 d-flex align-items-center gap-1">
                        <label className="mb-0">Mobile File</label>
                        <span className="text-muted fs-11 ">English</span>
                      </p>
                      <div className="mediaUpload border border-dashed rounded-3">
                        <FilePond
                          files={files}
                          onupdatefiles={setFiles}
                          allowMultiple={true}
                          maxFiles={3}
                          name="files"
                          className="filepond filepond-input-multiple fs-14"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <p className="mb-1 d-flex align-items-center gap-1">
                        <label className="mb-0">Mobile File</label>
                        <span className="text-muted fs-11 ">Arabic </span>
                      </p>
                      <div className="mediaUpload border border-dashed rounded-3">
                        <FilePond
                          files={files}
                          onupdatefiles={setFiles}
                          allowMultiple={true}
                          maxFiles={3}
                          name="files"
                          className="filepond filepond-input-multiple fs-14"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex gap-2 justify-content-end mt-3">
                  <Button
                    outline
                    color="danger"
                    size="md"
                    onClick={() => setmodal_scroll(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    size="md"
                    className="bg-gradient px-5"
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="bg-warning-subtle border-warning border-opacity-50 rounded-1 border w-100">
            <p class="p-2 fs-12 fw-medium fst-italic text-muted mb-0">
              Note: When uploading media such as logos, banners, and icons,
              choose the appropriate image type from SVG, PNG, JPEG, GIF and MP4
              formats..
            </p>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RewardMediasUploadPopup;
