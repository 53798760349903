import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { getPromotions } from '../../../actions/promotion';
import { formatDate } from '../../../helpers/formatDate';
import { formatTime } from '../../../helpers/formatTime';
//Import Flatepicker
import Flatpickr from 'react-flatpickr';
import { If } from 'react-if';

const CouponList = ({
  coupons,
  updateCoupon,
  isShowSummery,
  totalPages,
  pageOffset,
  setPageOffset,
  pageSize,
  setIsRefreshTable,
  isRefreshTable,
  createOrUpdateCouponFunc,
  deleteCouponByIdFunc,
  onCreateClick,
}) => {
  const pages = [...Array(totalPages)?.keys()];

  // Function to toggle edit mode for a coupon
  const toggleEditMode = (index) => {
    const updatedCoupons = coupons.map((coupon, i) =>
      i === index ? { ...coupon, isEditing: !coupon.isEditing } : coupon
    );
    updateCoupon(index, updatedCoupons);
  };

  return (
    <>
      <div className="table-responsive table-responsive bg-light border rounded border-bottom-0">
        <div className="p-3 d-flex gap-3 justify-content-end border-bottom">
          <Button
            size="sm"
            outline
            color="secondary"
            className="px-3"
            onClick={onCreateClick}
          >
            <i class="ri-coupon-2-line label-icon align-middle fs-16 me-2"></i>
            Create Coupon Code
          </Button>
          <Button size="sm" outline color="secondary" className="px-3">
            <i class="ri-download-2-line label-icon align-middle fs-16 me-2"></i>
            Download
          </Button>
          <div
            className="search-box d-inline-block w-75"
            style={{ maxWidth: 260 }}
          >
            <Input
              className="form-control search"
              placeholder="Search by Code"
              type="text"
            />
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
        <table className="table table-nowrap table-centered align-middle mb-0">
          <thead className="bg-light text-muted">
            <tr>
              <th className="text-center"></th>
              <th>Coupon Code</th>
              <th>Start Date</th>
              <th>End date</th>
              <th>Redeemable Quantity</th>
              <th>Remaining</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {(coupons || []).map((coupon, index) => (
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center justify-content-center">
                    <h5 className="m-0 fs-13 fw-normal">
                      {(pageOffset - 1) * pageSize + (index + 1)}
                    </h5>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <h5 className="m-0 fs-13 fw-normal">{coupon?.code}</h5>
                  </div>
                </td>
                <td>
                  <Flatpickr
                    className="form-control"
                    style={{ maxWidth: 180 }}
                    value={coupon?.startsAt}
                    options={{
                      enableTime: true,
                      // dateFormat: 'Y-m-d H:i',
                      dateFormat: 'Y-m-d h:i K',
                      time_24hr: false,
                    }}
                    onChange={(values) => {
                      updateCoupon(index, {
                        ...coupon,
                        startsAt: values?.[0],
                        isEdited: true,
                      });
                    }}
                  />
                </td>
                <td>
                  <Flatpickr
                    className="form-control"
                    style={{ maxWidth: 180 }}
                    value={coupon?.expiresAt}
                    options={{
                      enableTime: true,
                      // dateFormat: 'Y-m-d H:i',
                      dateFormat: 'Y-m-d h:i K',
                      time_24hr: false,
                    }}
                    onChange={(values) => {
                      updateCoupon(index, {
                        ...coupon,
                        expiresAt: values?.[0],
                        isEdited: true,
                      });
                    }}
                  />
                </td>
                <td>
                  <Input
                    required
                    style={{ maxWidth: 120 }}
                    type="number"
                    id="redeemableQuantity"
                    name="redeemableQuantity"
                    value={coupon?.redeemableQuantity}
                    min={1}
                    onChange={(e) => {
                      updateCoupon(index, {
                        ...coupon,
                        redeemableQuantity: e?.target?.value,
                        isEdited: true,
                      });
                    }}
                  />
                </td>
                <td>
                  <h5 className="m-0 fs-13 fw-normal">
                    {coupon?.redeemableQuantity - coupon?.redeemedQuantity}
                  </h5>
                </td>
                <td>
                  <div
                    style={{ maxWidth: 160 }}
                    className="form-check form-switch form-switch-secondary d-flex gap-2 align-items-center"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizesm"
                      checked={coupon?.isActive}
                      onChange={(event) => {
                        createOrUpdateCouponFunc(
                          [
                            {
                              ...coupon,
                              isActive: event?.target?.checked,
                            },
                          ],
                          pageOffset
                        );
                      }}
                    />
                    <label
                      className={`fs-12 my-1 fw-normal ${
                        coupon?.isActive ? 'text-success' : 'text-danger'
                      } `}
                      htmlFor="customSwitchsizesm"
                    >
                      {coupon?.isActive ? 'Enabled' : 'Disabled'}
                    </label>
                  </div>
                  {/* <h5
                    className={`fs-13 my-1 fw-normal ${
                      coupon?.isActive ? 'text-success' : 'text-danger'
                    }`}
                  >
                    {coupon?.isActive ? 'Active' : 'Expired'}
                  </h5> */}
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    {coupon?.isEdited ? (
                      <Button
                        type="submit"
                        className="btn btn-label btn-success btn-sm fw-medium fs-12"
                      >
                        <i class="mdi mdi-clipboard-check-multiple-outline label-icon align-middle fs-16"></i>
                        UPDATE
                      </Button>
                    ) : (
                      <>
                        <Button
                          type="button"
                          size="lg"
                          outline
                          color="danger"
                          className="btn-ghost-danger shadow-none px-2 py-1"
                          onClick={() => deleteCouponByIdFunc(coupon?._id)}
                        >
                          <i class="ri-delete-bin-line align-middle"></i>
                        </Button>
                        <Button
                          type="button"
                          size="lg"
                          outline
                          color="secondary"
                          className="btn-ghost-secondary shadow-none px-2 py-1"
                        >
                          <i class="ri-pencil-line align-middle"></i>
                        </Button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="align-items-center pt-2 justify-content-between row text-center text-sm-start">
        {totalPages > 1 ? (
          <div className="col-sm-auto mt-3 mt-sm-0">
            <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
              <li className={`page-item  ${pageOffset <= 1 ? 'disabled' : ''}`}>
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    setPageOffset(pageOffset - 1);
                  }}
                >
                  ←
                </Link>
              </li>

              {pages?.map((page, index) => {
                return (
                  <li
                    className={`page-item ${
                      pageOffset - 1 === index ? 'active' : ''
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => setPageOffset(index + 1)}
                    >
                      {index + 1}
                    </Link>
                  </li>
                );
              })}

              <li className="page-item">
                <Link
                  to="#"
                  className={`page-link ${
                    pageOffset >= totalPages ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    setPageOffset(pageOffset + 1);
                  }}
                >
                  →
                </Link>
              </li>
            </ul>
          </div>
        ) : null}
        <If condition={!coupons.length}>
          <div className="py-4 text-center">
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/msoeawqm.json"
                trigger="loop"
                colors="primary:#405189,secondary:#0ab39c"
                style={{ width: '72px', height: '72px' }}
              ></lord-icon>
            </div>

            <div className="mt-4">
              <h5>No Coupons Created Yet!</h5>
              <Button
                size="sm"
                outline
                color="secondary"
                className="px-3"
                onClick={onCreateClick}
              >
                <i class="ri-coupon-2-line label-icon align-middle fs-16 me-2"></i>
                Create Coupon Code
              </Button>
            </div>
          </div>
        </If>
      </div>
    </>
  );
};

export default CouponList;
