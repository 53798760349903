import React, { useState } from 'react';
import { Input, Label } from 'reactstrap';

const CheckboxRect = ({
  value,
  onChange,
  id,
  checked,
  title,
  description,
  icon,
  name,
}) => {
  return (
    <div className="form-check card-radio" onClick={onChange}>
      <Input
        id={id}
        name={name}
        type="radio"
        className="form-check-input"
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <Label className="form-check-label" htmlFor="shippingMethod01">
        <span className="fs-28 float-end mt-2 text-wrap d-block fw-semibold">
          <i className={`${icon} text-success`}></i>
        </span>
        <span className="fs-14 mb-1 text-wrap d-block">{title}</span>
        <span className="text-muted fw-normal text-wrap d-block">
          {description}
        </span>
      </Label>
    </div>
  );
};
export default CheckboxRect;
