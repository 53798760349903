import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import CreateTeamForm from './CreateTeamForm';

const CreateTeamModal = ({ submitHandler = () => {}, team = null }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <div>
      <Button
        color="primary"
        size="sm"
        onClick={() => {
          setIsOpenModal(true);
        }}
        className="btn btn-success add-btn"
      >
        {team ? <i className="bx bx-edit fs-18"></i> : '+ New Team'}
      </Button>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(!isOpenModal);
        }}
        centered={true}
        size="lg"
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            setIsOpenModal(!isOpenModal);
          }}
        >
          Create new Team
        </ModalHeader>
        <ModalBody>
          <CreateTeamForm
            submitHandler={() => {
              setIsOpenModal(false);
              submitHandler();
            }}
            team={team}
            closeHandler={() => setIsOpenModal(false)}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreateTeamModal;
