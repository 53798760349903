import { useState } from 'react';
import { generateCouponsFromCsv } from '../../../actions/promotion';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';

import { alertError, alertSuccess } from '../../../helpers/errorHandling';

const CreateCouponFromCsv = ({
  onCancel,
  getStores = () => {},
  setCoupons,
  coupons,
  setPageOffset,
  setTotalPages,
  setIsRefreshTable,
  promotionId,
  promotionDetail,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);

  const generateCouponsFromCsvFunc = async (file) => {
    setIsLoading(true);
    const variables = {
      input: { file: file, promotionId: promotionId },
    };

    const { data, success, message } = await generateCouponsFromCsv(variables);
    if (data?.coupons?.length > 0 && success) {
      setCoupons([...data?.coupons]);
      setPageOffset(data?.pageOffset);
      setTotalPages(data?.totalPages);
      setIsRefreshTable(true);
    }
    setIsLoading(false);

    if (success) {
      setCsvFile(null);
      alertSuccess(message);
    } else {
      alertError(message);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Form>
            <Dropzone
              onDrop={(files) => {
                setCsvFile(files?.[0]);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone d-flex align-items-center">
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            {csvFile ? (
              <div className="dropzone-previews mt-3" id="file-previews">
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {csvFile?.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{csvFile?.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            ) : null}
          </Form>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col lg={12} md={12} className="mt-4">
          <Button
            color="secondary"
            className="rounded-pill py-1 px-4"
            size="md"
            onClick={() => {
              generateCouponsFromCsvFunc(csvFile);
            }}
            disabled={!csvFile}
          >
            Create
          </Button>
        </Col>
      </Row>
    </>
    // <Row gap={2} marginTop={0.2}>
    //   <Col xl={12} lg={12} md={12} sm={12} xs={12}>

    //     <Dropzone
    //       onDrop={(files) => {
    //         setCsvFile(files?.[0]);
    //       }}
    //     >
    //       {({ getRootProps, getInputProps }) => (
    //         <div className="dropzone">
    //           <div className="dz-message needsclick mt-2" {...getRootProps()}>
    //             <input {...getInputProps()} />
    //             <div className="mb-3">
    //               <i className="display-4 text-muted bx bxs-cloud-upload" />
    //             </div>
    //             <h4>Drop files here or click to upload.</h4>
    //           </div>
    //         </div>
    //       )}
    //     </Dropzone>
    //   </Col>
    //   {csvFile ? (
    //     <Col xl={12} lg={12} md={12} sm={12} xs={12}>
    //       <span variant="h7" component="span">
    //         {csvFile?.name}
    //       </span>
    //     </Col>
    //   ) : null}

    //   <Col xl={12} lg={12} md={12} sm={12} xs={12}>
    //     <Button
    //       color="primary"
    //       size="sm"
    //       onClick={() => {
    //         generateCouponsFromCsvFunc(csvFile);
    //       }}
    //       disabled={!csvFile}
    //     >
    //       Create
    //     </Button>
    //   </Col>
    //   {isLoading ? <FullPageLoader /> : null}
    // </Row>
  );
};

CreateCouponFromCsv.propTypes = {};

export default CreateCouponFromCsv;
