import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Spinner } from 'reactstrap';
import { updateDjvBulk } from '../../../../actions/wallet';
import { toast } from 'react-toastify';

const UpdateDjvButton = (props) => {
    const { setDjvPassedTrigger } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [updatedCount, setUpdatedCount] = useState(0); // To store count of updated records

    // Toggle upload modal visibility
    const toggleModal = () => setIsModalOpen(!isModalOpen);

    // Toggle success modal visibility
    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);

    // Handle file selection
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    // Upload file to the server
    const handleUpload = async () => {
        if (!file) return toast.error("Please select a file to upload.");

        setUploading(true);

        // Create FormData object to send file
        const formData = new FormData();
        formData.append("file", file);

        const res = await updateDjvBulk(formData);
        if (res?.success) {
            setUpdatedCount(res.updatedCount); // Set count of updated records
            setSuccessModalOpen(true); // Open success modal
            setDjvPassedTrigger(true);
        } else {
            toast.error(res?.message || "Failed to upload the file.");
        }

        setUploading(false);
        setIsModalOpen(false);
        setFile(null); // Reset file selection after upload
    };

    return (
        <>
            <Button color="secondary" onClick={toggleModal}>
                Update DJV
            </Button>

            {/* Upload Modal */}
            <Modal isOpen={isModalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Upload DJV File</ModalHeader>
                <ModalBody>
                    <Input type="file" onChange={handleFileChange} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleUpload} disabled={uploading}>
                        {uploading ? <Spinner size="sm" /> : "Upload"}
                    </Button>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>

            {/* Success Modal */}
            <Modal
                isOpen={successModalOpen}
                toggle={toggleSuccessModal}
                backdrop="static"
                centered
            >
                <ModalHeader toggle={toggleSuccessModal}></ModalHeader>
                <ModalBody className="text-center pb-5">
                    <div
                        className={`${updatedCount === 0 ? 'bg-danger-subtle' : 'bg-success-subtle'
                            } rounded-circle d-inline-flex align-items-center justify-content-center p-3`}
                    >
                        <i
                            className={`mdi ${updatedCount === 0 ? 'mdi-alert-circle text-danger' : 'mdi-file-check text-success'
                                } lh-1`}
                            style={{ fontSize: '3rem' }}
                        ></i>
                    </div>
                    <div className="mt-4">
                        <h4 className="mb-3">
                            {updatedCount === 0 ? 'No Transactions Updated' : 'Djv Passed Successfully'}
                        </h4>
                        <p className="text-muted mb-0">
                            {updatedCount} {updatedCount === 1 ? 'transaction' : 'transactions'} updated.
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color={updatedCount === 0 ? 'danger' : 'success'} onClick={toggleSuccessModal}>
                        OK
                    </Button>
                </ModalFooter>
            </Modal>

        </>
    );
};

export default UpdateDjvButton;
