import React, { useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import './Org.scss';

const OrgNode = ({ node, onAdd, onUpdate, onDelete }) => {
  return (
    <div className="org-node">
      <div>{node.name}</div>
      <div className="node-actions">
        <button onClick={() => onAdd(node.id)}>Add Child</button>
        <button onClick={() => onUpdate(node.id)}>Update</button>
        <button onClick={() => onDelete(node.id)}>Delete</button>
      </div>
    </div>
  );
};

const OrgChartComponent = () => {
  const [data, setData] = useState({
    id: 1,
    name: 'Chairman',
    children: [
      {
        id: 2,
        name: 'Brand A',
        children: [
          { id: 3, name: 'Country 1', children: [] },
          { id: 4, name: 'Country 2', children: [] },
        ],
      },
      {
        id: 5,
        name: 'Brand B',
        children: [
          { id: 6, name: 'Country 3', children: [] },
          { id: 7, name: 'Country 4', children: [] },
        ],
      },
    ],
  });

  // Function to add a new child node
  const handleAddNode = (parentId) => {
    const nodeName = prompt('Enter the name of the new node:');
    if (!nodeName) return;

    const newNode = {
      id: Date.now(), // Unique ID for the new node
      name: nodeName,
      children: [],
    };

    const addNodeRecursively = (node) => {
      if (node.id === parentId) {
        node.children = [...node.children, newNode];
      } else if (node.children) {
        node.children = node.children.map(addNodeRecursively);
      }
      return node;
    };

    setData((prevData) => addNodeRecursively({ ...prevData }));
  };

  // Function to update an existing node
  const handleUpdateNode = (nodeId) => {
    const newName = prompt('Enter the new name for the node:');
    if (!newName) return;

    const updateNodeRecursively = (node) => {
      if (node.id === nodeId) {
        node.name = newName;
      } else if (node.children) {
        node.children = node.children.map(updateNodeRecursively);
      }
      return node;
    };

    setData((prevData) => updateNodeRecursively({ ...prevData }));
  };

  // Function to delete a node
  const handleDeleteNode = (nodeId) => {
    const deleteNodeRecursively = (node) => {
      if (node.id === nodeId) {
        return null; // Remove the node
      } else if (node.children) {
        node.children = node.children
          .map(deleteNodeRecursively)
          .filter((child) => child !== null);
      }
      return node;
    };

    setData((prevData) => deleteNodeRecursively({ ...prevData }));
  };

  // Render the nodes recursively
  const renderNode = (node) => (
    <TreeNode
      label={
        <OrgNode
          key={node.id}
          node={node}
          onAdd={handleAddNode}
          onUpdate={handleUpdateNode}
          onDelete={handleDeleteNode}
        />
      }
    >
      {node.children.length > 0 && node.children.map(renderNode)}
    </TreeNode>
  );

  return (
    <div className="org-chart-container">
      <Tree
        label={
          <OrgNode
            node={data}
            onAdd={handleAddNode}
            onUpdate={handleUpdateNode}
            onDelete={handleDeleteNode}
          />
        }
      >
        {data.children.map(renderNode)}
      </Tree>
    </div>
  );
};

export default OrgChartComponent;
