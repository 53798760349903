import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import TransactionDetailsPopup from './TransactionDetailsPopup';
import { getMemberTransactions } from '../../../../actions/members/list';

const TransactionTable = ({ member }) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchTransactions = async () => {
    setIsLoading(true);
    const data = await getMemberTransactions(member);

    setTransactions(data);
    setIsLoading(false);
  };
  useEffect(() => {
    if (member?._id) {
      fetchTransactions();
    }
  }, [member]);
  const TransactionDetailsAll = [
    {
      id: '1',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      grandTotal: 'AED 2441.600',
      couponCode: 'DH21 (56.400)',
      shippingFee: 'AED 59.000',
      status: 'Success',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '2',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      grandTotal: 'AED 2441.600',
      couponCode: 'DH21 (56.400)',
      shippingFee: 'AED 59.000',
      status: 'Success',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '3',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      grandTotal: 'AED 2441.600',
      couponCode: 'DH21 (56.400)',
      shippingFee: 'AED 59.000',
      status: 'Success',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '4',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      grandTotal: 'AED 2441.600',
      couponCode: 'DH21 (56.400)',
      shippingFee: 'AED 59.000',
      status: 'Success',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '5',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      grandTotal: 'AED 2441.600',
      couponCode: 'DH21 (56.400)',
      shippingFee: 'AED 59.000',
      status: 'Payment Failed',
      reason:
        'You aborted the payment. Please retry or choose another payment method.',
      feaIconClass: 'text-danger',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
  ];

  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };
  console.log('transactions', transactions);
  return (
    <>
      <div className="table-responsive table-card">
        <table className="table table-hover table-centered align-middle table-nowrap mb-0">
          <thead>
            <tr>
              <th></th>
              <th>Date</th>
              <th>Transaction ID</th>
              <th>Grand Total</th>
              <th>Payment Type</th>
              <th>Device</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(transactions || []).map((item, key) => (
              <tr key={key}>
                <td>
                  <div className="d-flex align-items-center">
                    <div>
                      <h5 className="fs-13 my-1">{item.transactionId}</h5>
                    </div>
                  </div>
                </td>
                <td>
                  <h5 className="fs-13 my-1 fw-normal">
                    {item.createdAt?.value}
                  </h5>
                  <span className="fs-10 text-muted">{item.time}</span>
                </td>
                <td>
                  <h5 className="fs-13 my-1 fw-normal">
                    #{item.transactionId}
                  </h5>
                </td>
                <td>
                  <div className="d-flex gap-2">
                    <div>
                      <h5 className="fs-13 my-1">{item.grandTotal}</h5>
                      {item.couponCode ? (
                        <p className="fs-10 mb-0 text-success">
                          Coupon {item.couponCode}
                        </p>
                      ) : null}
                      {item.shippingFee ? (
                        <p className="fs-10 mb-0">
                          Shipping Fee: {item.shippingFee}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <span class="badge rounded-pill bg-light fw-normal fs-10 text-muted d-flex align-items-center gap-1 border border-dark-subtle">
                      <i class="bx bx-credit-card"></i> cc
                    </span>
                    <span class="badge rounded-pill bg-light fw-normal fs-10 text-muted border border-dark-subtle">
                      apple pay
                    </span>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-1 fs-18">
                    <i class="bx bx-mobile-alt"></i>
                    <i class="bx bxl-apple"></i>
                    <i class="bx bx-globe"></i>
                  </div>
                </td>
                <td>
                  <h5
                    className={`${item?.feaIconClass} fs-12 my-1 fw-semiBold`}
                  >
                    {item.status}
                  </h5>
                  {item.reason ? (
                    <p
                      className={`${item?.feaIconClass} fs-9 mb-0 text-wrap`}
                      style={{ maxWidth: 180 }}
                    >
                      Reason: {item.reason}
                    </p>
                  ) : null}
                </td>
                <td>
                  <TransactionDetailsPopup />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            Showing <span className="fw-semibold">5</span> of{' '}
            <span className="fw-semibold">25</span> Results
          </div>
        </div>
        <div className="col-sm-auto mt-3 mt-sm-0">
          <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
            <li className="page-item disabled">
              <Link to="#" className="page-link">
                ←
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                1
              </Link>
            </li>
            <li className="page-item active">
              <Link to="#" className="page-link">
                2
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                3
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                →
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/*  */}
      <Offcanvas
        isOpen={isRight}
        toggle={toggleRightCanvas}
        id="offcanvasRight"
        direction="end"
      >
        <OffcanvasHeader toggle={toggleRightCanvas} id="offcanvasRightLabel">
          Recent Acitivity
        </OffcanvasHeader>
        <OffcanvasBody>...</OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default TransactionTable;
