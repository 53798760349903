import React from "react";
import ReactApexChart from "react-apexcharts";
import { dataSeries, githubdata } from "./series";

import * as moment from "moment";
import getChartColorsArray from "../../../../components/Common/ChartsDynamicColor";

const AverageSpending = ({ dataColors }) => {
  var areachartSplineColors = getChartColorsArray(dataColors);
  const series = [
    {
      name: "series1",
      data: [0, 1000, 5000, 10000],
    },
  ];
  var options = {
    chart: {
      height: 150,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    colors: areachartSplineColors,
    xaxis: {
      type: "day",
      categories: ["Jan", "Feb", "Mar", "Apr", "May"],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="area"
        height="140"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const AverageVisit = ({ dataColors }) => {
  var linechartBasicColors = getChartColorsArray(dataColors);
  const series = [
    {
      name: "visit",
      data: [10, 5, 10, 9, 12, 15, 2],
    },
  ];
  var options = {
    chart: {
      height: 150,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    markers: {
      size: 4,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    colors: linechartBasicColors,
    title: {
      text: "",
      align: "left",
      style: {
        fontWeight: 500,
      },
    },

    xaxis: {
      type: "year",
      categories: ["2018", "2019", "2020", "2021", "2022", "2023", "2024"],
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="line"
        height="150"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export { AverageSpending, AverageVisit };
