import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { ROUTE_USER_LOGIN, ROUTE_UN_AUTHORIZED } from '../constants/route';
import { getItem } from '../helpers/cookies';
import { AUTH_TOKEN } from '../constants';
import { userProfileAction } from '../actions/auth/action';
import { useDispatch } from 'react-redux';
import FullPageLoader from '../components/Common/FullPageLoader';
import { getLoggedUserUser } from '../guard/admin';
import { setErrorMsg } from '../slices/auth/reducer';
import { isTokenExpired } from '../helpers/general';

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const token = getItem(AUTH_TOKEN);
  const { route } = props || {};

  // token missing
  if (!token) {
    return (
      <Navigate
        to={{ pathname: ROUTE_USER_LOGIN, state: { from: props.location } }}
      />
    );
  }

  // if auth key expired

  const tokenExpired = isTokenExpired(token);
  if (tokenExpired) {
    dispatch(setErrorMsg('Your session expired, Please login again.!'));

    return (
      <Navigate
        to={{ pathname: ROUTE_USER_LOGIN, state: { from: props.location } }}
      />
    );
  }

  // get user info
  const userInfo = getLoggedUserUser();
  const { email } = userInfo;
  if (!email) {
    userProfileAction(dispatch);
  }
  return <>{props.children}</>;
};

export { AuthProtected };
