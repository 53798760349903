import axios from 'axios';
import { AUTH_TOKEN } from '../../constants';
import { ROUTE_DASHBOARD } from '../../constants/route';
import {
  CREATE_ROLE,
  CREATE_SUB_USER,
  PERMISSION_LIST,
  VIEW_ROLE,
  USER_LOGIN,
  USER_PROFILE,
  UPDATE_ROLE,
  ROLES_LISTING,
  USERS_LISTING,
  ROLE_OPTION_LIST,
  SUB_USER_VIEW,
} from '../../gql/auth';
import { setItem } from '../../helpers/cookies';
import { errorHandling } from '../../helpers/errorHandling';
import { ApolloClientPromoService } from '../../services/ApolloClientPromoService';
import { setUser, setErrorMsg } from '../../slices/auth/reducer';
import promoRestService from '../../services/PromoRestService';

/**
 * Login action
 * @param {*} param0
 * @param {*} dispatch
 * @param {*} history
 */
export const userLoginAction = async ({ input }, dispatch, history) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: USER_LOGIN,
      operationName: 'USER_LOGIN',
      variables: { input },
      fetchPolicy: 'network-only',
    });
    const response = data?.login || {};
    const { token, user } = response || {};

    if (token) {
      dispatch(setUser(user || {}));
      setItem(AUTH_TOKEN, token);
      history(ROUTE_DASHBOARD);
    } else {
      dispatch(setErrorMsg('Something went wrong...'));
    }
    return response;
  } catch (error) {
    dispatch(setErrorMsg(error?.message || 'Something went wrong...'));
    errorHandling(error);
  }
  // unset error msg after 5s
  setTimeout(() => {
    dispatch(setErrorMsg(null));
  }, 3500);
};

/**
 * Profile action
 * @param {*} param0
 * @param {*} dispatch
 * @param {*} history
 */
export const userProfileAction = async (dispatch) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: USER_PROFILE,
      operationName: 'USER_PROFILE',
      fetchPolicy: 'network-only',
    });
    const user = data?.profile || {};

    if (user) {
      dispatch(setUser(user || {}));
    } else {
      dispatch(setUser({}));
      dispatch(setErrorMsg('Something went wrong...'));
    }
    return user;
  } catch (error) {
    dispatch(setErrorMsg(error?.message || 'Something went wrong...'));
    errorHandling(error);
  }
};

/**
 * Create sub user
 * @param {*} param0
 * @returns
 */
export const createSubUserAction = async ({ input }) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: CREATE_SUB_USER,
      operationName: 'CREATE_SUB_USER',
      variables: { input },
      fetchPolicy: 'network-only',
    });
    const response = data?.createSubUser || {};
    return response;
  } catch (error) {
    errorHandling(error);
  }
};

/**
 * Create sub user
 * @param {*} param0
 * @returns
 */
export const getPermissionListAction = async () => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: PERMISSION_LIST,
      operationName: 'PERMISSION_LIST',
      fetchPolicy: 'network-only',
    });

    const response = data?.permissionList || [];
    return response;
  } catch (error) {
    errorHandling(error);
  }
};

/**
 * create role
 * @param {*} param0
 * @returns
 */
export const createRoleAction = async ({ input }) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: CREATE_ROLE,
      operationName: 'CREATE_ROLE',
      variables: { input },
      fetchPolicy: 'network-only',
    });

    return data?.createRole || {};
  } catch (error) {
    errorHandling(error);
  }
};

/**
 * update role
 * @param {*} param0
 * @returns
 */
export const updateRoleAction = async ({ id, input }) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: UPDATE_ROLE,
      operationName: 'UPDATE_ROLE',
      variables: { id, input },
      fetchPolicy: 'network-only',
    });

    return data?.updateRole || {};
  } catch (error) {
    errorHandling(error);
  }
};

/**
 * create role
 * @param {*} param0
 * @returns
 */
export const viewRoleAction = async (id) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: VIEW_ROLE,
      operationName: 'VIEW_ROLE',
      variables: { id },
      fetchPolicy: 'network-only',
    });

    return data?.viewRole || {};
  } catch (error) {
    errorHandling(error);
  }
};

/**
 * fetch all roles
 * @param {*} param0
 * @returns
 */
export const fetchAllRolesAction = async ({ filter, pagination }) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: ROLES_LISTING,
      operationName: 'ROLES_LISTING',
      variables: { filter, pagination },
      fetchPolicy: 'network-only',
    });

    return data?.roleListing || {};
  } catch (error) {
    errorHandling(error);
  }
};

/**
 * fetch all users
 * @param {*} param0
 * @returns
 */
export const fetchAllUsersAction = async ({ filter, pagination }) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: USERS_LISTING,
      operationName: 'USERS_LISTING',
      variables: { filter, pagination },
      fetchPolicy: 'network-only',
    });

    return data?.userListing || {};
  } catch (error) {
    errorHandling(error);
  }
};

/**
 *  get all access list
 * @param {*} id
 * @returns
 */
export const getAccessListAction = async (id) => {
  try {
    const response = await promoRestService?.get(`auth/access-list`);
    return response?.data || [];
  } catch (error) {
    return [];
  }
};

/**
 * fetch all users
 * @param {*} param0
 * @returns
 */
export const getRolesOptionsListAction = async () => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: ROLE_OPTION_LIST,
      operationName: 'ROLE_OPTION_LIST',
      fetchPolicy: 'network-only',
    });

    return data?.getRolesOptions || [];
  } catch (error) {
    errorHandling(error);
  }
};
/**
 * Profile action
 * @param {*} param0
 * @param {*} dispatch
 * @param {*} history
 */
export const viewSubUserAction = async (_id) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: SUB_USER_VIEW,
      operationName: 'SUB_USER_VIEW',
      fetchPolicy: 'network-only',
      variables: { _id },
    });
    const user = data?.viewSubUser || {};
    return user;
  } catch (error) {
    dispatch(setErrorMsg(error?.message || 'Something went wrong...'));
    errorHandling(error);
  }
};
