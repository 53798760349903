import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import FormulaCreationTool from './FormulaCreationTool';
import EffectTransaction from './EffectTransaction';
import { If } from 'react-if';
import { EFFECT_TYPES_OBJ } from '../../../constants';
import Flatpickr from 'react-flatpickr';
import * as Yup from 'yup';

export default function Effect({
  tier,
  tIndex,
  generateId,
  effectTypes,
  discountTypes,
  tiers,
  setTiers,
  duplicateEffect,
  effectChangeHandler,
  deleteEffectHandler,
  rewardTypes,
  walletTypes,
  rewardList = [],
  promotionId,
}) {
  const usedEffects = [];
  const usedRewards = [];

  tier?.effects?.forEach((effect) => {
    usedEffects.push(effect?.effectType);
    if (effect?.effectType === 'rewardPoints') {
      usedRewards.push(effect?.rewardId);
    }
  });

  const handleAddEffect = (effectType, rewardId = null) => {
    const newEffects = [
      ...tier?.effects,
      {
        id: generateId(),
        effectType,
        rewardTypeId: rewardId,
        // discountType: discountTypes?.[0]?.value,
      },
    ];
    const newTier = {
      ...tier,
      effects: newEffects,
    };
    const newTiers = [...tiers];
    newTiers[tIndex] = newTier;
    setTiers(newTiers);
  };

  return (
    <>
      <CardHeader className="py-2">
        <Row>
          <Col className="d-flex align-items-center justify-content-between">
            <div className="fs-14 fw-medium mb-0 card-title">Effects</div>
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-sm btn-secondary">
                + Add New Effect
              </DropdownToggle>
              <DropdownMenu>
                {effectTypes?.map((item, index) => {
                  if (item?.value === 'rewardPoints') {
                    const availableRewards = rewardList.filter(
                      (reward) => !usedRewards.includes(reward.value)
                    );

                    if (availableRewards?.length > 0) {
                      return (
                        <UncontrolledDropdown
                          key={`et-${index}`}
                          direction="right"
                        >
                          <DropdownToggle
                            tag="div"
                            className="dropdown-item d-flex justify-content-between align-items-center"
                            caret
                          >
                            {item?.label}
                          </DropdownToggle>
                          <DropdownMenu>
                            {availableRewards.map((reward, rewardIndex) => (
                              <DropdownItem
                                key={`r-${rewardIndex}`}
                                onClick={() => {
                                  handleAddEffect(
                                    item?.value,
                                    reward.value || reward?.label
                                  );
                                }}
                              >
                                {reward?.label}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      );
                    }
                  } else if (!usedEffects.includes(item.value) || true) {
                    return (
                      <DropdownItem
                        value={item?.value}
                        key={`et-${index}`}
                        onClick={() => {
                          handleAddEffect(item?.value);
                        }}
                      >
                        {item?.label}
                      </DropdownItem>
                    );
                  }
                  return null;
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </CardHeader>
      <If condition={tier?.effects?.length}>
        <CardBody>
          <Droppable droppableId={`${tIndex}`} type="effect">
            {(provided, snapshot) => (
              <div {...provided?.droppableProps} ref={provided.innerRef}>
                {tier?.effects?.map((effect, eIndex) => {
                  return (
                    <Draggable
                      key={effect?.id?.toString()}
                      draggableId={effect?.id?.toString()}
                      index={eIndex}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="mb-3 border rounded-1"
                        >
                          <CardHeader className="bg-rule p-0">
                            <Row>
                              <Col className="d-flex align-items-center gap-4">
                                <div className="d-flex align-items-center gap-2">
                                  <i
                                    className="bx bx-grid-vertical ms-2 me-2 fs-18"
                                    {...provided.dragHandleProps}
                                  ></i>
                                  <span variant="subtitle2" display="block">
                                    {`Effect ${eIndex + 1}`} <i>of</i>{' '}
                                    {tier?.effects?.length}:{' '}
                                    <b>
                                      {
                                        EFFECT_TYPES_OBJ[effect?.effectType]
                                          ?.label
                                      }
                                    </b>
                                    {effect?.effectType === 'rewardPoints' &&
                                      effect?.rewardId && (
                                        <>
                                          {' - '}
                                          {
                                            rewardList.find(
                                              (reward) =>
                                                reward.value ===
                                                  effect.rewardId ||
                                                reward.label === effect.rewardId
                                            )?.label
                                          }
                                        </>
                                      )}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                  {/* <Button
                                    aria-label="duplicate"
                                    color="secondary"
                                    size="sm"
                                    onClick={() =>
                                      duplicateEffect(eIndex, tIndex)
                                    }
                                    className="shadow-none"
                                  >
                                    <i className="bx bx-copy fs-18"></i>
                                  </Button> */}
                                  <Button
                                    aria-label="delete"
                                    color="soft-dark"
                                    onClick={() =>
                                      deleteEffectHandler(eIndex, tIndex)
                                    }
                                    size="sm"
                                    className="shadow-none rule-remove btn-danger fs-20"
                                  >
                                    <i className="bi bi-x"></i>
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <div className="d-flex flex-wrap gap-2 align-items-start">
                              {effect?.effectType === 'transactions' ? (
                                <EffectTransaction
                                  effect={effect}
                                  effectChangeHandler={effectChangeHandler}
                                  eIndex={eIndex}
                                  tIndex={tIndex}
                                  discountTypes={discountTypes}
                                  promotionId={promotionId}
                                />
                              ) : null}

                              {effect?.effectType === 'rewardPoints' ? (
                                <>
                                  <FormGroup>
                                    <Label id="effect-reward-type-select-label">
                                      Reward Type
                                    </Label>
                                    <Input
                                      type="select"
                                      className="form-select"
                                      id="reward-type-select"
                                      value={effect?.rewardType}
                                      defaultValue={rewardTypes?.[0]?.value}
                                      onChange={(e) => {
                                        effectChangeHandler(
                                          'rewardType',
                                          e.target.value,
                                          eIndex,
                                          tIndex
                                        );
                                      }}
                                    >
                                      {rewardTypes?.map((item, index) => (
                                        <option
                                          value={item?.value}
                                          key={`rt-${index}`}
                                        >
                                          {item?.label}
                                        </option>
                                      ))}
                                    </Input>
                                  </FormGroup>

                                  {effect?.rewardType === 'fixed' ? (
                                    <div className="mb-3">
                                      <Label id="effect-reward-type-select-label">
                                        Reward Value
                                      </Label>
                                      <Input
                                        id="outlined-effect-reward-value-number"
                                        value={`${effect?.rewardValue}`}
                                        label="Reward Value"
                                        type="number"
                                        size="small"
                                        required
                                        className="w-100"
                                        onChange={(e) => {
                                          effectChangeHandler(
                                            'rewardValue',
                                            Number(e?.target?.value),
                                            eIndex,
                                            tIndex
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </>
                              ) : null}

                              {effect?.rewardType === 'rewardFormula' ? (
                                <div className="w-75 mb-3">
                                  <Label id="effect-reward-type-select-label">
                                    Reward Formula
                                  </Label>
                                  <FormulaCreationTool
                                    formula={effect?.rewardFormula}
                                    updateFormula={(value) => {
                                      effectChangeHandler(
                                        'rewardFormula',
                                        value,
                                        eIndex,
                                        tIndex
                                      );
                                    }}
                                    style={{ height: 37.5 }}
                                  />
                                </div>
                              ) : null}

                              {effect?.effectType === 'giftVoucher' ? (
                                <div className="mb-3">
                                  <Label id="effect-reward-type-select-label">
                                    Gift Voucher Amount
                                  </Label>
                                  <Input
                                    id="outlined-gift-voucher-amount-value-number"
                                    value={`${effect?.giftVoucherAmount}`}
                                    label="Gift Voucher Amount"
                                    type="number"
                                    size="small"
                                    required
                                    style={{
                                      width: '100%',
                                    }}
                                    LabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => {
                                      effectChangeHandler(
                                        'giftVoucherAmount',
                                        Number(e?.target?.value),
                                        eIndex,
                                        tIndex
                                      );
                                    }}
                                  />
                                </div>
                              ) : null}

                              {effect?.effectType === 'wallet' ? (
                                <>
                                  <FormGroup>
                                    <Label id="effect-wallet-type-select-label">
                                      Wallet Type
                                    </Label>
                                    <Input
                                      type="select"
                                      id="wallet-type-select"
                                      value={effect?.walletType}
                                      defaultValue={walletTypes?.[0]?.value}
                                      onChange={(e) => {
                                        effectChangeHandler(
                                          'walletType',
                                          e.target.value,
                                          eIndex,
                                          tIndex
                                        );
                                      }}
                                      className="form-select"
                                    >
                                      {walletTypes?.map((item, index) => (
                                        <option
                                          value={item?.value}
                                          key={`wt-${index}`}
                                        >
                                          {item?.label}
                                        </option>
                                      ))}
                                    </Input>
                                  </FormGroup>
                                </>
                              ) : null}

                              {effect?.effectType === 'wallet' &&
                              effect?.walletType === 'fixed' ? (
                                <div className="mb-3">
                                  <Label id="effect-wallet-type-select-label">
                                    Wallet Amount
                                  </Label>
                                  <Input
                                    id="outlined-wallet-amount-value-number"
                                    value={`${effect?.walletAmount}`}
                                    label="Wallet Amount"
                                    type="number"
                                    size="small"
                                    required
                                    className="w-100"
                                    LabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => {
                                      effectChangeHandler(
                                        'walletAmount',
                                        Number(e?.target?.value),
                                        eIndex,
                                        tIndex
                                      );
                                    }}
                                  />
                                </div>
                              ) : null}

                              {effect?.effectType === 'wallet' &&
                              effect?.walletType === 'walletFormula' ? (
                                <div className="w-75 mb-3">
                                  <Label id="effect-reward-type-select-label">
                                    Reward Formula
                                  </Label>
                                  <FormulaCreationTool
                                    formula={effect?.walletFormula}
                                    updateFormula={(value) => {
                                      effectChangeHandler(
                                        'walletFormula',
                                        value,
                                        eIndex,
                                        tIndex
                                      );
                                    }}
                                  />
                                </div>
                              ) : null}

                              {effect?.effectType !== 'transactions' &&
                              effect?.rewardType !== 'rewardFormula' &&
                              effect?.walletType !== 'walletFormula' ? (
                                <div className="mb-3">
                                  <Label id="effect-wallet-type-select-label">
                                    Maximum Limit
                                  </Label>
                                  <Input
                                    id="outlined-effect-value-number"
                                    value={`${effect?.maxLimit}`}
                                    label="Maximum Limit"
                                    type="number"
                                    size="small"
                                    required
                                    style={{
                                      width: '100%',
                                    }}
                                    LabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => {
                                      effectChangeHandler(
                                        'maxLimit',
                                        Number(e?.target?.value),
                                        eIndex,
                                        tIndex
                                      );
                                    }}
                                  />
                                </div>
                              ) : null}

                              {effect?.effectType === 'rewardPoints' ? (
                                <div className="d-flex flex-row w-100 gap-2">
                                  <div className="">
                                    <Label for="endDate">Expiry Date</Label>
                                    {!effect?.neverExpires && (
                                      <Flatpickr
                                        className="form-control"
                                        id="expiryDate"
                                        value={effect?.expiryDate || ''}
                                        onChange={(values) => {
                                          effectChangeHandler(
                                            'expiryDate',
                                            values?.[0],
                                            eIndex,
                                            tIndex
                                          );
                                        }}
                                        options={{
                                          enableTime: true,
                                          dateFormat: 'Y-m-d h:i K',
                                          time_24hr: false,
                                        }}
                                      />
                                    )}
                                    <div className="form-check form-switch form-switch-custom form-switch-dark mb-2 mt-2 d-flex gap-2 justify-content-center align-items-center p-0">
                                      <Label
                                        className="form-check-label"
                                        htmlFor="neverExpires"
                                      >
                                        Never Expire
                                      </Label>
                                      <Input
                                        className="form-check-input m-0"
                                        type="checkbox"
                                        role="switch"
                                        id="neverExpires"
                                        checked={effect?.neverExpires === true}
                                        onChange={() => {
                                          effectChangeHandler(
                                            'neverExpires',
                                            !effect?.neverExpires,
                                            eIndex,
                                            tIndex
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="">
                                    <Label for="endDate">Hold period</Label>
                                    {!effect?.reflectImmediately && (
                                      <Input
                                        id="outlined-effect-value-number"
                                        value={`${effect?.holdPeriod}`}
                                        label="Hold period"
                                        type="number"
                                        size="small"
                                        required
                                        style={{
                                          width: '100%',
                                        }}
                                        LabelProps={{
                                          shrink: true,
                                        }}
                                        onChange={(e) => {
                                          effectChangeHandler(
                                            'holdPeriod',
                                            Number(e?.target?.value),
                                            eIndex,
                                            tIndex
                                          );
                                        }}
                                      />
                                    )}
                                    <div className="form-check form-switch form-switch-custom form-switch-dark mb-2 mt-2 d-flex gap-2 justify-content-center align-items-center p-0">
                                      <Label
                                        className="form-check-label"
                                        htmlFor="neverExpires"
                                      >
                                        Immediately
                                      </Label>
                                      <Input
                                        className="form-check-input m-0"
                                        type="checkbox"
                                        role="switch"
                                        id="reflectImmediately"
                                        checked={
                                          effect?.reflectImmediately === true
                                        }
                                        onChange={() => {
                                          effectChangeHandler(
                                            'reflectImmediately',
                                            !effect?.reflectImmediately,
                                            eIndex,
                                            tIndex
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </CardBody>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </CardBody>
      </If>
    </>
  );
}
