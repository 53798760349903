import axios from 'axios';
import { getItem, deleteItem } from '../helpers/cookies';
import { AUTH_TOKEN } from '../constants';

const endpoint = process.env.REACT_APP_WALLET_SERVICE_REST_ENDPOINT;
const WalletRestService = axios?.create({
  baseURL: endpoint,
});

WalletRestService.interceptors.request.use(
  async (config) => {
    const token = getItem(AUTH_TOKEN);
    config.headers['Content-Type'] = config.headers['Content-Type']
      ? config.headers['Content-Type']
      : 'application/json';
    config.headers['authorization'] = token ? `Bearer ${token}` : '';
    config.headers.platform = 'web';
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default WalletRestService;
