import React from "react";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../../components/Common/ChartsDynamicColor";

const CustomerLifeTimeValue = ({ dataColors }) => {
  var chartColumnDistributedColors = getChartColorsArray(dataColors);
  const series = [
    {
      data: [
        5000, 10000, 15000, 7000, 1200, 2106, 5410, 14500, 1100, 7980, 6120,
        4321,
      ],
    },
  ];
  var options = {
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {},
      },
    },
    colors: chartColumnDistributedColors,
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          colors: [
            "#038edc",
            "#51d28c",
            "#f7cc53",
            "#f34e4e",
            "#564ab1",
            "#5fd0f3",
          ],
          fontSize: "12px",
        },
      },
    },
  };

  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="bar"
      height={350}
    />
  );
};

export { CustomerLifeTimeValue };
