import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import withRouter from '../../components/Common/withRouter';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { COMPANY_NAME, DEVELOPER_NAME } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import logoLight from '../../assets/images/logo-light.png';
import { userLoginAction } from '../../actions/auth/action';

/**
 *
 * @param {*} props
 * @returns
 */
const Login = () => {
  //
  const history = useNavigate();
  const dispatch = useDispatch();

  //
  const userState = useSelector((state) => state.user || {});
  const { errorMsg } = userState || {};
  //
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please enter your Email'),
      password: Yup.string().required('Please enter your Password'),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      const { email, password } = values || {};
      triggerApi({ email, password });
    },
  });

  // Api Call
  const triggerApi = async ({ email, password }) => {
    await userLoginAction({ input: { email, password } }, dispatch, history);
    setIsLoading(false);
  };

  document.title = 'Welcome back to ' + COMPANY_NAME;

  return (
    <React.Fragment>
      <div className="auth-page-wrapper d-flex justify-content-center align-items-center min-vh-100 overflow-hidden">
        <div className="auth-page-content overflow-hidden p-0">
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={8} className="auth-one-bg rounded-end-5 overflow-hidden">
                <div className="bg-overlay rounded-start-5"></div>
                <Row className="h-100">
                  <Col md={12}>
                    <div className="position-relative d-flex flex-column p-3">
                      <div className="text-left">
                        <img src={logoLight} />
                      </div>
                    </div>
                  </Col>
                  <Col md={12} className="h-100">
                    <Row className="h-100">
                      <Col
                        sm={10}
                        md={8}
                        lg={8}
                        xl={7}
                        xxl={5}
                        className="m-auto"
                      >
                        <h2 className="mb-5 p-0  text-light">
                          In publishing and graphic design
                        </h2>
                        <ul className="list-group m-0 p-0 d-flex flex-column gap-4">
                          <li
                            className="position-relative list-group-item border-0 py-0 text-light"
                            style={{ paddingLeft: 60 }}
                          >
                            <span
                              className="position-absolute top-0 start-0 d-flex justify-content-center align-items-center rounded-circle bg-danger bg-gradient text-light fs-22"
                              style={{ width: 42, height: 42 }}
                            >
                              <i class="bx bx-book-open"></i>
                            </span>
                            <p className="mb-1 fs-16  text-light">
                              <strong>Relying on meaningful content.</strong>
                            </p>
                            <p className="text-light">
                              In publishing and graphic design, Lorem ipsum is a
                              placeholder text commonly used to demonstrate the
                              visual form of a document or a typeface without
                            </p>
                          </li>

                          <li
                            className="position-relative list-group-item border-0 py-0"
                            style={{ paddingLeft: 60 }}
                          >
                            <span
                              className="position-absolute top-0 start-0 d-flex justify-content-center align-items-center rounded-circle bg-danger bg-gradient text-light fs-22"
                              style={{ width: 42, height: 42 }}
                            >
                              <i class="bx bx-book-open"></i>
                            </span>
                            <p className="mb-1 fs-16  text-light">
                              <strong>Relying on meaningful content.</strong>
                            </p>
                            <p className=" text-light">
                              In publishing and graphic design, Lorem ipsum is a
                              placeholder text commonly used to demonstrate the
                              visual form of a document or a typeface without
                            </p>
                          </li>

                          <li
                            className="position-relative list-group-item border-0 py-0"
                            style={{ paddingLeft: 60 }}
                          >
                            <span
                              className="position-absolute top-0 start-0 d-flex justify-content-center align-items-center rounded-circle bg-danger bg-gradient text-light fs-22"
                              style={{ width: 42, height: 42 }}
                            >
                              <i class="bx bx-book-open"></i>
                            </span>
                            <p className="mb-1 fs-16  text-light">
                              <strong>Relying on meaningful content.</strong>
                            </p>
                            <p className=" text-light">
                              In publishing and graphic design, Lorem ipsum is a
                              placeholder text commonly used to demonstrate the
                              visual form of a document or a typeface without
                            </p>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col lg={4}>
                <Card className="min-vh-100 m-0 rounded-0 shadow-0">
                  <CardBody className="p-5 mw-100 d-flex flex-column justify-content-center overflow-hidden">
                    {/* <div className="bg-overlay rounded-start-5"></div> */}
                    <Row>
                      <Col
                        md={12}
                        sm={12}
                        lg={12}
                        xl={12}
                        xxl={10}
                        className="m-auto"
                      >
                        <div className="text-center mt-2">
                          <h2 className="text-dark fw-bold">Welcome Back !</h2>
                          <p className="text-dark">
                            Please enter your account details
                          </p>
                        </div>
                        {errorMsg && <Alert color="danger"> {errorMsg} </Alert>}
                        <div className="p-2 mt-4">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                            action="#"
                          >
                            <div className="mb-3">
                              <Label
                                htmlFor="email"
                                className="form-label text-dark"
                              >
                                Email
                              </Label>
                              <Input
                                autoComplete="off"
                                name="email"
                                className="form-control rounded-5"
                                placeholder="Enter email"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ''}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <div className="float-end">
                                <Link
                                  to="/forgot-password"
                                  className="text-dark"
                                >
                                  Forgot password?
                                </Link>
                              </div>
                              <Label
                                className="form-label text-dark"
                                htmlFor="password-input"
                              >
                                Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  name="password"
                                  value={validation.values.password || ''}
                                  type={showPassword ? 'text' : 'password'}
                                  className="form-control pe-5  rounded-5"
                                  placeholder="Enter Password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                                <button
                                  className="btn btn-link position-absolute end-0 top-0  text-decoration-none text-muted shadow-none"
                                  onClick={() => setShowPassword(!showPassword)}
                                  type="button"
                                  id="password-addon"
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </button>
                              </div>
                            </div>

                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="auth-remember-check"
                              />
                              <Label
                                className="form-check-label text-dark"
                                htmlFor="auth-remember-check"
                              >
                                Remember me
                              </Label>
                            </div>

                            <div className="mt-4">
                              <Button
                                color="dark"
                                disabled={isLoading ? true : false}
                                className="w-100 rounded-5"
                                type="submit"
                              >
                                {isLoading ? (
                                  <Spinner size="sm" className="me-2">
                                    Loading...
                                  </Spinner>
                                ) : null}
                                Sign In
                              </Button>
                            </div>

                            <div className="my-4">
                              I accept {name}{' '}
                              <a
                                href="#"
                                className="text-info text-decoration-underline"
                              >
                                terms & conditions
                              </a>
                            </div>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <footer className="footer" style={{ zIndex: 9 }}>
          <Container fluid>
            <Row>
              <Col lg={12}>
                <div className="d-flex  justify-content-between">
                  <div className="text-left">
                    <p className="mb-0 text-light">
                      &copy; {new Date().getFullYear()} {COMPANY_NAME}. Crafted
                      with
                      <i className="mdi mdi-heart text-danger"></i> by{' '}
                      {DEVELOPER_NAME}
                    </p>
                  </div>
                  <div className="text-right">
                    <ul
                      className="d-flex gap-3 p-0 m-0"
                      style={{ listStyle: 'none' }}
                    >
                      <li className="text-dark">
                        <a
                          href="#"
                          className="link-dark link-offset-2 text-decoration-underline link-underline-opacity-0 link-underline-opacity-0-hover"
                        >
                          Help
                        </a>
                      </li>
                      <li className="text-dark">
                        <a
                          href="#"
                          className="link-dark link-offset-2 text-decoration-underline link-underline-opacity-0 link-underline-opacity-0-hover"
                        >
                          Terms and conditions
                        </a>
                      </li>
                      <li className="text-dark">
                        <a
                          href="#"
                          className="link-dark link-offset-2 text-decoration-underline link-underline-opacity-0 link-underline-opacity-0-hover"
                        >
                          Privacy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
