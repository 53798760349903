import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Button,
} from 'reactstrap';
import { userExportReportDataAction } from '../../actions/exports/action';
import Pagination from '../../components/Common/Pagination';

const ExportsListTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  // Fetch data from the API
  const fetchList = useCallback(async (data) => {
    setIsLoading(true);
    try {
      const response = await userExportReportDataAction({ ...data });
      const { items = [], pagination = {} } = response || {};
      const { currentPage = 1, totalPages = 1, totalItems = 0 } = pagination;
      setList(items);
      setCurrentPage(currentPage);
      setTotalPages(totalPages);
      setTotalItems(totalItems);
    } catch (error) {
      console.error('Failed to fetch admin list:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch data based on the filter values change
  useEffect(() => {
    const delay = setTimeout(() => {
      fetchList({
        pagination: { page: currentPage, limit: 10 },
        // filter: {
        //   search,
        //   enabled,
        // },
      });
    }, 300); // Debounce time of 300 milliseconds
    return () => clearTimeout(delay);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleFileDownload = (filePath) => {
    if (!filePath) return;
    const link = document.createElement('a');
    link.href = filePath;
    const fileName = filePath.split('/').pop() || 'downloaded-file';
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //currentPage, fetchList

  return (
    <>
      <div className="table-responsive table-card">
        <table className="table table-hover table-centered align-top table-nowrap mb-0">
          <thead>
            <tr>
              <th>SI.No</th>
              <th>Requested on</th>
              <th>Requested By</th>
              <th>Report type</th>
              <th>Expirty</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item, key) => {
                const expiryDate = new Date(item?.expiryDate);
                const currentDate = new Date();
                const ci = 10;
                const siNo = (currentPage - 1) * ci + (key + 1);
                return (
                  <tr key={key}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div>
                          <h5 className="fs-13 my-1">{siNo}</h5>
                        </div>
                      </div>
                    </td>
                    <td>
                      <h5 className="fs-13 my-1 fw-normal">
                        {new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(item?.createdAt))}
                      </h5>
                      <span className="fs-10 text-muted">
                        {new Date(item?.createdAt).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                      </span>
                    </td>

                    <td>
                      <h5 className="fs-13 my-1 fw-normal">
                        {item?.additionalInfo?.email}
                      </h5>
                    </td>
                    <td>
                      <h5 className="fs-13 my-1 fw-normal">
                        {item?.additionalInfo?.reportType}
                        <br />
                        <span>
                          {item?.additionalInfo?.reportType === 'Wallet Members List'
                            ? item?.additionalInfo?.dateTo
                              ? `Date upto ${item?.additionalInfo?.dateTo}`
                              : ''
                            : `${item?.additionalInfo?.dateFrom} - ${item?.additionalInfo?.dateTo}`}
                        </span>
                      </h5>
                    </td>

                    <td>
                      <h5 className="fs-13 my-1 fw-normal">
                        {new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(item?.expiryDate))}
                      </h5>
                      <span className="fs-10 text-muted">
                        {new Date(item?.expiryDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                      </span>
                    </td>
                    <td className="text-center">
                      {currentDate < expiryDate ? (
                        <Button
                          color="success"
                          className="badge"
                          onClick={() => handleFileDownload(item?.filePath)}
                        >
                          <i className={`ri-download-2-line fs-18`}></i>
                        </Button>
                      ) : (
                        'Expired'
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {/* <Pagination
        totalPages={totalPages}
        totalItems={totalItems}
        currentPage={1}
        onPageChange={setCurrentPage}
      /> */}

      <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            Showing <span className="fw-semibold">{currentPage}</span> of{' '}
            <span className="fw-semibold">{totalPages}</span> Results
          </div>
        </div>
        <div className="col-sm-auto mt-3 mt-sm-0">
          <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <Button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                ←
              </Button>
            </li>
            {[...Array(totalPages)]?.map((_, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === index + 1 ? 'active' : ''
                  }`}
              >
                <Button
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Button>
              </li>
            ))}
            <li
              className={`page-item ${currentPage === totalPages ? 'disabled' : ''
                }`}
            >
              <Button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                →
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ExportsListTable;
