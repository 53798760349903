import PropTypes from "prop-types";
import React from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
// import profile from "../../assets/images/bg.png";
import logoLight from "../../assets/images/logo-light.png";
import { COMPANY_NAME, PROJECT_NAME } from "../../constants";

const ForgetPasswordPage = (props) => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      //
    },
  });

  const { forgetError, forgetSuccessMsg } = useSelector((state) => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  document.title = "Reset Password";
  return (
    <div className="auth-page-content">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <Link to="/" className="d-inline-block auth-logo">
                  <img src={logoLight} alt="" height="20" />
                </Link>
              </div>
              <p className="mt-3 fs-15 fw-medium">{PROJECT_NAME}</p>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="mt-4">
              <CardBody className="p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary">Forgot Password?</h5>
                  <p className="text-muted">
                    Reset password with {COMPANY_NAME}
                  </p>

                  <lord-icon
                    src="https://cdn.lordicon.com/rhvddzym.json"
                    trigger="loop"
                    colors="primary:#0ab39c"
                    className="avatar-xl"
                    style={{ width: "120px", height: "120px" }}
                  ></lord-icon>
                </div>

                <Alert
                  className="alert-borderless alert-warning text-center mb-2 mx-2"
                  role="alert"
                >
                  Enter your email and instructions will be sent to you!
                </Alert>
                <div className="p-2">
                  {forgetError && forgetError ? (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {forgetError}
                    </Alert>
                  ) : null}
                  {forgetSuccessMsg ? (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {forgetSuccessMsg}
                    </Alert>
                  ) : null}
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-4">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          <div>{validation.errors.email}</div>
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="text-center mt-4">
                      <button className="btn btn-success w-100" type="submit">
                        Send Reset Link
                      </button>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>

            <div className="mt-4 text-center">
              <p className="mb-0">
                Wait, I remember my password...{" "}
                <Link
                  to="/login"
                  className="fw-semibold text-primary text-decoration-underline"
                >
                  {" "}
                  Click here{" "}
                </Link>{" "}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
