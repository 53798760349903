import { PROMOTION_API_SERVICE_BASE_URL } from '../../constants';
import promoRestService from '../../services/PromoRestService';

export const viewBasket = async (data) => {
  const result = await promoRestService.post(
    `${PROMOTION_API_SERVICE_BASE_URL}basket/calculate`,
    data
  );
  return result;
};

export const saveBasket = async (data) => {
  const result = await promoRestService.post(
    `${PROMOTION_API_SERVICE_BASE_URL}basket/save`,
    data
  );
  return result;
};

export const getBaskets = async (data) => {
  const result = await promoRestService.get(
    `${PROMOTION_API_SERVICE_BASE_URL}basket/list`,
    { params: data }
  );
  return result;
};
