import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
  Label,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { Link } from 'react-router-dom';

const UserFilters = () => {
  return (
    <CardBody className="border border border-dashed card-body">
      <Row className="g-3">
        <Col>
          <div className={'search-box me-2 mb-2 d-inline-block w-100'}>
            <Input
              className="form-control search"
              placeholder="Search by User Id / Mobile no / E-mail"
              type="text"
            />
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </Col>
        <Col className="d-flex flex-wrap align-items-start gap-2">
          <Select id="admin_status" placeholder="Select Option">
            <Option>sdsd</Option>
          </Select>

          <Select id="admin_status" placeholder="Select Option">
            <Option>sdsd</Option>
          </Select>

          <Button className="btn btn-outline  btn-light">
            <i className="ri-catalog-fill me-1 align-bottom"></i>
            Reset
          </Button>

          <Button color={`primary`} className="btn-load">
            <span className="d-flex align-items-center">
              <span className="flex-grow-1 me-2">
                <i className="ri-search-fill me-1 align-bottom"></i>
                Search
              </span>
            </span>
          </Button>
        </Col>
      </Row>
    </CardBody>
  );
};

export default UserFilters;
