import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
} from 'reactstrap';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import RolesPermissionsPopup from './RolesPermissionsPopup';
import { Link } from 'react-router-dom';
import { ROUTE_ROLE_CREATE } from '../../../constants/route';
import RoleItem from '../../../components/Roles/Role';
import { fetchAllRolesAction } from '../../../actions/auth/action';
import RoleFilters from '../../../components/Roles/Filters';
import Pagination from '../../../components/Common/Pagination';

const RoleList = () => {
  document.title = 'Manage Roles';

  const [search, setSearch] = useState(null);
  const [enabled, setEnabled] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  // Fetch data from the API
  const fetchList = useCallback(async (data) => {
    setIsLoading(true);

    try {
      const response = await fetchAllRolesAction({ ...data });

      const { items = [], pagination = {} } = response || {};
      const { currentPage = 1, totalPages = 1, totalItems = 0 } = pagination;

      setList(items);
      setCurrentPage(currentPage);
      setTotalPages(totalPages);
      setTotalItems(totalItems);
    } catch (error) {
      console.error('Failed to fetch admin list:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch data based on the filter values change
  useEffect(() => {
    const delay = setTimeout(() => {
      fetchList({
        pagination: { page: currentPage, limit: 10 },
        filter: {
          search,
          enabled,
        },
      });
    }, 300); // Debounce time of 300 milliseconds
    return () => clearTimeout(delay);
  }, [search, enabled, currentPage, fetchList]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Roles'} titleActive="Manage Roles" />
        <Card>
          <CardHeader className="border-0">
            <Row className="g-4 align-items-center">
              <Col sm></Col>
              <Col sm="auto">
                <div className="d-flex flex-wrap align-items-start gap-2">
                  <Button color="secondary" outline className="shadow-none">
                    Compare
                  </Button>
                  <RolesPermissionsPopup />
                  <Link
                    to={ROUTE_ROLE_CREATE?.replace(':id', 'new')}
                    className="btn btn-success add-btn"
                  >
                    <i className="ri-user-fill me-1 align-bottom"></i> Create
                    new role
                  </Link>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <RoleFilters setSearch={setSearch} setEnabled={setEnabled} />
          <CardBody className="border">
            <Row className="g-3">
              <Col>
                <div className="border rounded p-3 mb-2">
                  <div className="table-responsive table-card">
                    <table className="table table-hover table-centered align-top table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>Role</th>
                          <th>Description</th>
                          <th>Allowed</th>
                          {/* <th>Denied</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list?.map((item, index) => (
                          <tr key={`${item?._id}-${index}`}>
                            <RoleItem item={item} />
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination
                      totalPages={totalPages}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default RoleList;
