import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import {
  getPublishedPromotions,
  updatePromotionPriority,
} from '../../../actions/promotion/index';
import InnerLoader from '../../../components/Common/InnerLoader';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
const PromotionPrioritization = () => {
  const navigate = useNavigate();
  const [promotions, setPromotions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPublishedPromotionsFunc();
  }, []);

  const getPublishedPromotionsFunc = async () => {
    setIsLoading(true);
    const inputData = {
      pageSize: 20,
      pageOffset: 0,
    };

    const { success, data } = await getPublishedPromotions(inputData);
    if (success) {
      setPromotions(data?.promotions);
      setTotalPages(data?.totalPages);
      setTimeout(() => {
        setIsLoading(false);
      }, 400);
    }
  };

  const onDragEnd = async (result) => {
    try {
      if (!result?.destination) {
        return;
      }

      // Reorder the promotions array
      const reorderedPromotions = [...promotions];
      const [removed] = reorderedPromotions?.splice(result?.source?.index, 1);
      reorderedPromotions?.splice(result?.destination?.index, 0, removed);

      setPromotions([...reorderedPromotions]);

      // Recalculate the priorities
      const updatedPromotionOrder = await Promise?.all(
        reorderedPromotions.map((promo, index) => ({
          promotionId: promo?._id,
          priority: index + 1, // Assign new priority based on new position
        }))
      );

      await updatePromotionPriority({
        priorityList: updatedPromotionOrder,
      });
    } catch (error) {
      console.log(error);
    }
  };

  document.title = 'Promotion Prioritization';
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          titleInActive="Promotions"
          titleActive="Prioritization Order"
        />
        <div className="card border ps-4 pe-4 pt-4 pb-1 min-vh-100">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" type="priority">
              {(provided, snapshot) => (
                <div {...provided?.droppableProps} ref={provided?.innerRef}>
                  {promotions?.map((promotion, index) => {
                    return (
                      <Draggable
                        key={promotion?._id?.toString()}
                        draggableId={promotion?._id?.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div className="d-flex flex-row align-items-center mb-3 ">
                            <div className="me-3 fs-13 text-muted">
                              {index + 1}
                            </div>
                            <div
                              ref={provided?.innerRef}
                              {...provided?.draggableProps}
                              {...provided?.dragHandleProps}
                              className="border d-flex flex-row py-3 w-100"
                            >
                              <div>
                                <i className="bx bx-grid-vertical ms-2 me-2 fs-18"></i>
                              </div>
                              <div className="text-capitalize fs-14 text-muted">
                                {promotion?.basicSettings?.promotionName}
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Container>
      {isLoading ? <InnerLoader /> : null}
    </div>
  );
};

export default PromotionPrioritization;
