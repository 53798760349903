import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";

const Address = () => {
  document.title = "Profile Settings ";

  return (
    <>
      <Row>
        <Col md={4}>
          <Card className="border border-muted">
            <CardHeader className="d-flex align-items-center">
              <div className="flex-grow-1">
                <p className="d-flex align-items-center gap-2 mb-0 fs-13">
                  <strong>Address - 1</strong>
                  <span className="fs-10 test-mute">(Office)</span>
                </p>
              </div>
              {/* <div className="list-inline card-toolbar-menu d-flex gap-2 align-items-center mb-0">
                <button
                  type="button"
                  class="btn btn-outline-secondary rounded-pill waves-effect py-1 px-2 fs-10"
                >
                  Edit
                </button>
                <button
                  type="button"
                  class="btn btn-outline-danger rounded-pill waves-effect py-1 px-2 fs-10"
                >
                  Delete
                </button>
              </div> */}
            </CardHeader>
            <CardBody className="p-3">
              <div>
                <p>
                  <span className="fs-10 mb-1 text-muted d-block">Name</span>
                  Abdul Bari
                </p>
                <p>
                  <span className="fs-10 mb-1 text-muted d-block">Address</span>
                  B16 Flat No 203 7CR7+4F8 - Al Qusais Industrial Area - Al
                  Qusais Industrial Area 5 - Dubai - United Arab Emirates
                </p>
                <p>
                  <span className="fs-10 mb-1 text-muted d-block">
                    Mobile No.
                  </span>
                  0565765550
                </p>
                <p>
                  <span className="fs-10 mb-1 text-muted d-block">
                    Company Name
                  </span>
                  Test Company LLC
                </p>
                <p className="mb-0">
                  <span className="fs-10 mb-1 text-muted d-block">TRN No.</span>
                  123455678797978
                </p>
              </div>
            </CardBody>
            {/* <CardFooter>
              <div className="form-check form-switch form-switch-custom form-switch-secondary">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="SwitchCheck10"
                />
                <label className="form-check-label" for="SwitchCheck10">
                  Default Address
                </label>
              </div>
            </CardFooter> */}
          </Card>
        </Col>
        <Col md={4}>
          <Card className="border border-muted">
            <CardHeader className="d-flex align-items-center">
              <div className="flex-grow-1">
                <p className="d-flex align-items-center gap-2 mb-0 fs-13">
                  <strong>Address - 1</strong>
                  <span className="fs-10 test-mute">(Home)</span>
                </p>
              </div>
              {/* <div className="list-inline card-toolbar-menu d-flex gap-2 align-items-center mb-0">
                <button
                  type="button"
                  class="btn btn-outline-secondary rounded-pill waves-effect py-1 px-2 fs-10"
                >
                  Edit
                </button>
                <button
                  type="button"
                  class="btn btn-outline-danger rounded-pill waves-effect py-1 px-2 fs-10"
                >
                  Delete
                </button>
              </div> */}
            </CardHeader>
            <CardBody className="p-3">
              <div>
                <p>
                  <span className="fs-10 mb-1 text-muted d-block">Name</span>
                  Abdul Bari
                </p>
                <p>
                  <span className="fs-10 mb-1 text-muted d-block">Address</span>
                  B16 Flat No 203 7CR7+4F8 - Al Qusais Industrial Area - Al
                  Qusais Industrial Area 5 - Dubai - United Arab Emirates
                </p>
                <p className="mb-0">
                  <span className="fs-10 mb-1 text-muted d-block">
                    Mobile No.
                  </span>
                  0565765550
                </p>
              </div>
            </CardBody>
            {/* <CardFooter>
              <div className="form-check form-switch form-switch-custom form-switch-secondary">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="SwitchCheck11"
                />
                <label className="form-check-label" for="SwitchCheck11">
                  Default Address
                </label>
              </div>
            </CardFooter> */}
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <button className="d-flex gap-2 border border-dashed border-muted bg-light-subtle w-100 p-4 fs-14 align-items-center justify-content-center">
            <i class="bx bx-plus fs-20"></i> Add New Address
          </button>
        </Col>
      </Row> */}
    </>
  );
};

export default Address;
