import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Input,
  Label,
} from 'reactstrap';

import { getTeams } from '../../../actions/promotion/team';
import InnerLoader from '../../../components/Common/InnerLoader';
import './style.scss';
import { Link } from 'react-router-dom';
import CreateTeamForm from '../../Configuration/Teams/CreateTeamForm';
const ChooseTeam = ({ selectedTeamsProp = [], teamHandler = () => {} }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [open, setOpen] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(
    [...selectedTeamsProp] || []
  );

  const [selectedTeamIds, setSelectedTeamIds] = useState(
    selectedTeamsProp?.map((item) => item?.teamId) || []
  );

  const [editingId, setEditingId] = useState(null);

  const toggle = (id) => {
    try {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTeamsFunc = async () => {
    try {
      setIsLoading(true);

      const data = {
        pageOffset: pageOffset,
        pageSize: 10,
      };
      const res = await getTeams(data);

      setTotalPages(res?.data?.totalPages);
      setTeams(res?.data?.teams || []);
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } catch (error) {
      console.log(error);
    }
  };

  const resetToInitialState = async () => {
    try {
      setOpen('');
      setPageOffset(0);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    resetToInitialState();
  }, [isOpenModal]);

  useEffect(() => {
    if (isOpenModal) {
      getTeamsFunc();
    }
  }, [pageOffset, isOpenModal]);

  useEffect(() => {
    const ids = [...selectedTeams?.map((item) => item?.teamId)];
    setSelectedTeamIds([...ids]);
  }, [selectedTeams]);

  useEffect(() => {
    try {
      const ids = [...selectedTeamsProp?.map((item) => item?.teamId)];
      setSelectedTeamIds([...ids]);
      setSelectedTeams([...selectedTeamsProp]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedTeamsProp]);

  const selectTeamHandler = async (team) => {
    try {
      const updatedSelectedTeams = [...selectedTeams];
      const index = selectedTeams?.findIndex(
        (item) => item?.teamId === team?._id
      );

      if (index === -1) {
        setSelectedTeams([
          ...updatedSelectedTeams,
          {
            teamId: team?._id,
            teamName: team?.teamName,
            percentage: 0,
          },
        ]);
      } else {
        setSelectedTeams([
          ...updatedSelectedTeams?.filter((item) => item?.teamId !== team?._id),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formSubmitHandler = async () => {
    await resetToInitialState();
    getTeamsFunc();
  };

  const formCloseHandler = async () => {
    setOpen('');
  };

  const pages = [...Array(totalPages)?.keys()];

  return (
    <>
      <Button
        color="primary"
        size="sm"
        onClick={() => {
          setIsOpenModal(true);
        }}
        className="pt-1 pb-1"
      >
        Choose Team
      </Button>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(!isOpenModal);
        }}
        centered={true}
        size="lg"
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            setIsOpenModal(!isOpenModal);
          }}
        >
          Please select the Team you wish to include.
        </ModalHeader>
        <ModalBody className="choose-team-modal">
          <div className="hide-accordion-arrow">
            <Accordion open={open} toggle={toggle}>
              <AccordionItem className="mb-3">
                <AccordionHeader targetId="new-team">
                  <i className="bx bx-plus fs-14 pe-2"></i>
                  Create new Team
                </AccordionHeader>
                <AccordionBody accordionId={`new-team`}>
                  <CreateTeamForm
                    submitHandler={formSubmitHandler}
                    closeHandler={formCloseHandler}
                  />
                </AccordionBody>
              </AccordionItem>
            </Accordion>
            <Accordion open={open} toggle={() => {}}>
              {teams?.map((team, index) => {
                return (
                  <AccordionItem>
                    <AccordionHeader targetId={`${index}`}>
                      <div className="d-flex flex-row justify-content-between align-items-center w-100">
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id="auth-remember-check"
                            onClick={(e) => {
                              e?.stopPropagation();
                              selectTeamHandler(team);
                            }}
                            checked={selectedTeamIds?.includes(team?._id)}
                          />
                          <Label
                            className="form-check-label text-capitalize"
                            onClick={(e) => {
                              e?.stopPropagation();
                              selectTeamHandler(team);
                            }}
                          >
                            {team?.teamName}
                          </Label>
                        </div>

                        <div className="d-flex flex-row">
                          <Button
                            color="info"
                            size="sm"
                            className="pt-1 pb-0"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingId(team?._id);
                              setOpen(`${index}`);
                            }}
                          >
                            <i className="bx bxs-edit fs-15"></i>
                          </Button>
                        </div>
                      </div>
                    </AccordionHeader>
                    {team?._id === editingId ? (
                      <AccordionBody accordionId={`${index}`}>
                        <CreateTeamForm
                          submitHandler={formSubmitHandler}
                          team={team}
                          closeHandler={formCloseHandler}
                        />
                      </AccordionBody>
                    ) : null}
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>

          <div className="d-flex flex-row justify-content-between align-items-center mt-4">
            <div className="align-items-center justify-content-between row text-center text-sm-start">
              {totalPages > 1 ? (
                <div className="col-sm-auto mt-3 mt-sm-0">
                  <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                    <li
                      className={`page-item  ${
                        pageOffset < 1 ? 'disabled' : ''
                      }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => {
                          setPageOffset(pageOffset - 1);
                        }}
                      >
                        ←
                      </Link>
                    </li>

                    {pages?.map((page, index) => {
                      return (
                        <li
                          className={`page-item ${
                            pageOffset === index ? 'active' : ''
                          }`}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => setPageOffset(index)}
                          >
                            {index + 1}
                          </Link>
                        </li>
                      );
                    })}

                    <li className="page-item">
                      <Link
                        to="#"
                        className={`page-link ${
                          pageOffset + 1 >= totalPages ? 'disabled' : ''
                        }`}
                        onClick={() => {
                          setPageOffset(pageOffset + 1);
                        }}
                      >
                        →
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
            {teams?.length > 0 ? (
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  teamHandler(selectedTeams);
                  setIsOpenModal(false);
                }}
              >
                Update
              </Button>
            ) : null}
          </div>
          {isLoading ? <InnerLoader /> : null}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChooseTeam;
